<script setup>
import { onMounted, ref } from "vue";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();
const items = ref([]);

permissions.$subscribe(() => {
  buildSidebarItems();
});
function buildSidebarItems() {
  items.value.length = 0;
  allItems.forEach((item) => {
    if (permissions.$state["Elevate-" + item.privName]) {
      items.value.push(item);
    }
  });
}

onMounted(async () => {
  if (appBar.appRunning != "Elevate") {
    appBar.$patch({
      appRunning: "Elevate",
      type: "None",
      items: [],
      selected: "",
    });
  }
  buildSidebarItems();
});

const allItems = [
  // { title: "Assets", icon: "mdi-archive", link: "AssetsBulkUploader" },
  {
    title: "Elevate24",
    //icon: "mdi-chart-bar",
    icon: "custom:elevateIcon",
    link: "ElevateLicence",
    privName: "Reports",
    value: false,
  },
  {
    title: "Report",
    icon: "mdi-chart-bar",
    link: "ElevateReport",
    privName: "Reports",
    value: false,
  },
];

// Required to make the router-view reload when user clicks the link again
// See the key value set on <router-view> below.
const randomKey = ref("");
function updateRandom() {
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}
</script>

<template>
  <v-navigation-drawer permanent width="190" theme="jigsawDark">
    <v-list>
      <!-- <v-list-item link :to="{ name: 'ElevateLicence' }" active-color="#e7541e">
        <template v-slot:prepend>
          <v-img class="mr-2" src="@/assets/elevateIcon.png" width="24"></v-img>
        </template>
        <v-list-item-title> Elevate24 </v-list-item-title>
      </v-list-item> -->

      <template v-for="item in items" :key="item.title">
        <v-list-item
          link
          :to="{ name: item.link }"
          active-color="#e7541e"
          @click="updateRandom()"
        >
          <template v-slot:prepend>
            <v-icon class="mr-2" size="x-large">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title class="pl-n4">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view :key="randomKey" />
    </v-container>
  </v-main>
</template>
