<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";
import DashboardPieChart from "@/views/dashboard/DashboardPieChart.vue";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();
import ElaDashboardDrillDown from "./ElaDashboardDrillDown.vue";
import ElaComputerDetailV2 from "./ElaComputerDetailV2.vue";
import VersionChart from "./components/VersionChartV2.vue";
import ApplicationsList from "./components/ApplicationsList";
import ToggleSwitch from "./components/ToggleSwitch.vue";
import HardwareOverview from "./components/HardwareOverview.vue";

import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();

appBar.$subscribe((mutation, state) => {
  console.log("Change Triggered", mutation);
  console.log(state);
  if (appBar.selected != "") {
    getData();
  }
});

const data = ref({});

let deviceTypes = reactive({
  macOS: [],
  iOS: [],
  iPadOS: [],
  tvOS: [],
  Windows: [],
  Android: [],
  Unknown: [],
});

const enrollmentTypes = reactive({
  Company: [],
  Personal: [],
});

const chartData = {};
var deviceTypesChartData = {};
var enrollmentTypesChartData = {};

let mdmType;
let macOSCisBenchmark = [];
let windowsCisBenchmark = [];

function getEnrollmentTypes() {
  enrollmentTypes.Company = [];
  enrollmentTypes.Personal = [];

  allDevices.forEach((device) => {
    if (device.Device_Owner_Type == "personal") {
      enrollmentTypes.Personal.push(device);
    }
    if (device.Device_Owner_Type == "company") {
      enrollmentTypes.Company.push(device);
    }
  });

  enrollmentTypesChartData = buildPieChart(enrollmentTypes);
}

const randomKey = ref("");
function updateRandom() {
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}

// When set to false use reports that only show devices
// that have checked in the last 30 days.
const showAllDevices = ref(true);
let allDevices = [];

var showChart = ref(false);

function processCIS(content) {
  if (content.cisReport != false) {
    if (content.cisReport.macOS.length > 0) {
      macOSCisBenchmark = content.cisReport.macOS.sort(
        (a, b) => b.count - a.count
      );
    }
    if (content.cisReport.Windows.length > 0) {
      windowsCisBenchmark = content.cisReport.Windows.sort(
        (a, b) => b.count - a.count
      );
    }
  }
}

function postProcessing(newData, mdmType) {
  // Set MDM Information variables.
  switch (mdmType) {
    case "Jamf":
      newData.content.enrollmentAccounts =
        newData.jamfInformation.data.enrollmentAccounts;
      newData.content.apnsExpiry = newData.jamfInformation.data.apnsExpiry;
      newData.content.vppAccounts = newData.jamfInformation.data.vppAccounts;
      break;
    case "Intune":
      newData.content.enrollmentAccounts =
        newData.intuneInformation.data.enrollmentAccounts;
      newData.content.apnsExpiry =
        newData.intuneInformation.data.apnsExpiry?.expirationDate;
      newData.content.vppAccounts = newData.intuneInformation.data.vppAccounts;
      break;
    default:
      console.error("dateStatus() - Unknown MDM Type");
  }

  console.log("iOSVersions");
  chartData.iOSVersions = buildPieChartFromSummarised(
    newData.content.iOSVersions
  );
  // chartData.iOSMajorVersions = buildPieChartFromSummarised(
  //   newData.content.iOSMajorVersions
  // );
  console.log("macOSVersions");
  chartData.macOSVersions = buildPieChartFromSummarised(
    newData.content.macOSVersions
  );

  // chartData.macOSMajorVersions = buildPieChartFromSummarised(
  //   newData.content.macOSMajorVersions
  // );
  // newData.content.macOSMajorVersions.chartData = buildPieChartFromSummarised(
  //   newData.content.macOSMajorVersions
  // );
  console.log("modelReport");

  newData.content.modelReport.chartData = buildPieChartFromSummarised(
    newData.content.modelReport
  );

  newData.content.offline10 = 0;
  newData.content.offline90 = 0;
  newData.content.notEncrypted = 0;
  newData.content.lowDiskSpace = 0;

  if (newData.content.iosOffline10) {
    newData.content.offline10 += newData.content.iosOffline10.count;
  }
  if (newData.content.iosOffline90) {
    newData.content.offline90 += newData.content.iosOffline90.count;
  }
  if (newData.content.macOSOffline10) {
    newData.content.offline10 += newData.content.macOSOffline10.count;
  }
  if (newData.content.macOSOffline90) {
    newData.content.offline90 += newData.content.macOSOffline90.count;
  }
  if (newData.content.macOSNotEncrypted) {
    newData.content.notEncrypted += newData.content.macOSNotEncrypted.count;
  }
  if (newData.content.macOSLowDiskSpace) {
    newData.content.lowDiskSpace += newData.content.macOSLowDiskSpace.count;
  }

  if (newData.content.iosOffline30 && newData.content.macOSOffline30) {
    newData.content.devicesOffline30 = {
      count:
        newData.content.iosOffline30.count +
        newData.content.macOSOffline30.count,
      records: [
        newData.content.iosOffline30.records,
        newData.content.macOSOffline30.records,
      ],
    };
  }
  if (newData.content.iosOffline60 && newData.content.macOSOffline60) {
    newData.content.devicesOffline60 = {
      count:
        newData.content.iosOffline60.count +
        newData.content.macOSOffline60.count,
      records: [
        newData.content.iosOffline60.records,
        newData.content.macOSOffline60.records,
      ],
    };
  }

  if (newData.content.iosOffline90 && newData.content.macOSOffline90) {
    newData.content.devicesOffline90 = {
      count:
        newData.content.iosOffline90.count +
        newData.content.macOSOffline90.count,
      records: [
        newData.content.iosOffline90.records,
        newData.content.macOSOffline90.records,
      ],
    };
  }

  // This section is for Intune specific data processing //
  if (mdmType == "Intune") {
    console.log("WindowsVersions");
    chartData.windowVersions = buildPieChartFromSummarised(
      newData.content.windowsVersions
    );

    console.log("AndroidVersions");
    chartData.AndroidVersions = buildPieChartFromSummarised(
      newData.content.AndroidVersions
    );

    // Tally up non encrypted devices
    newData.content.notEncrypted += newData.content.windowsNotEncrypted.count;
    newData.content.notEncrypted += newData.content.macOSNotEncrypted.count;
    newData.content.notEncrypted += newData.content.iOSNotEncrypted.count;
    newData.content.notEncrypted += newData.content.AndroidNotEncrypted.count;

    // Tally up low disk space devices
    newData.content.lowDiskSpace += newData.content.macOSLowDiskSpace.count;
    newData.content.lowDiskSpace += newData.content.iOSLowDiskSpace.count;
    newData.content.lowDiskSpace += newData.content.windowsLowDiskSpace.count;
    newData.content.lowDiskSpace += newData.content.AndroidLowDiskSpace.count;

    if (newData.content.windowsOffline10) {
      newData.content.offline10 += newData.content.windowsOffline10.count;
    }
    if (newData.content.windowsOffline90) {
      newData.content.offline90 += newData.content.windowsOffline90.count;
    }

    // Tally up devices that have been offline for 30 days
    if (
      newData.content.iosOffline30 &&
      newData.content.macOSOffline30 &&
      newData.content.windowsOffline30 &&
      newData.content.AndroidOffline30
    ) {
      newData.content.devicesOffline30 = {
        count:
          newData.content.iosOffline30.count +
          newData.content.macOSOffline30.count +
          newData.content.windowsOffline30.count +
          newData.content.AndroidOffline30.count,
        records: [
          newData.content.iosOffline30.records,
          newData.content.macOSOffline30.records,
          newData.content.windowsOffline30.records,
          newData.content.AndroidOffline30.records,
        ],
      };
    }

    // Tally up devices that have been offline for 60 days
    if (
      newData.content.iosOffline60 &&
      newData.content.macOSOffline60 &&
      newData.content.windowsOffline60 &&
      newData.content.AndroidOffline60
    ) {
      newData.content.devicesOffline60 = {
        count:
          newData.content.iosOffline60.count +
          newData.content.macOSOffline60.count +
          newData.content.windowsOffline60.count +
          newData.content.AndroidOffline60.count,
        records: [
          newData.content.iosOffline60.records,
          newData.content.macOSOffline60.records,
          newData.content.windowsOffline60.records,
          newData.content.AndroidOffline60.records,
        ],
      };
    }

    // Tally up devices that have been offline for 90 days
    if (
      newData.content.iosOffline90 &&
      newData.content.macOSOffline90 &&
      newData.content.windowsOffline90 &&
      newData.content.AndroidOffline90
    ) {
      newData.content.devicesOffline90 = {
        count:
          newData.content.iosOffline90.count +
          newData.content.macOSOffline90.count +
          newData.content.windowsOffline90.count +
          newData.content.AndroidOffline90.count,
        records: [
          newData.content.iosOffline90.records,
          newData.content.macOSOffline90.records,
          newData.content.windowsOffline90.records,
          newData.content.AndroidOffline90.records,
        ],
      };
    }
  }
  return newData;
}

function dateStatus(dateToCheck) {
  let now = new Date();
  let toCheck = new Date(dateToCheck);
  if (!toCheck) {
    return "#d60000";
  }

  var difference = toCheck.getTime() - now.getTime();
  difference = difference / (1000 * 3600 * 24);
  if (difference > 30) {
    return "green";
  }
  if (difference < 7) {
    return "#d60000";
  }

  return "yellow";
}

function dateStatusADE(enrollmentAccounts) {
  var color = "green";
  enrollmentAccounts.forEach((account) => {
    let result = dateStatus(account.tokenExpirationDate);
    if (result == "#d60000") {
      color = "#d60000";
      return;
    }
    if (color == "#d60000") {
      return;
    } else {
      if (result == "yellow") {
        color = "yellow";
      }
    }
  });
  return color;
}

function dateStatusVPP(vppAccounts) {
  var color = "green";
  vppAccounts.forEach((account) => {
    let result = dateStatus(account.expirationDate);
    if (result == "#d60000") {
      color = "#d60000";
      return;
    }
    if (color == "#d60000") {
      return;
    } else {
      if (result == "yellow") {
        color = "yellow";
      }
    }
  });
  return color;
}

function addLicenseCharts() {
  try {
    var colour = "#eb5d0b";
    var percentage =
      (parseInt(data.value.jamfInformation.data.managedComputers) /
        parseInt(data.value.jamfInformation.data.computerLicenses)) *
      100;

    var totalLicenses = parseInt(
      data.value.jamfInformation.data.computerLicenses
    );

    if (percentage > 100) {
      colour = "#d60000";
    }

    let mdmLicensesMac = {
      chart: {
        type: "radialBar",
        value: data.value.jamfInformation.data.managedComputers,
        total: data.value.jamfInformation.data.computerLicenses,
        // redrawOnParentResize: false,
        // redrawOnWindowResize: false,
        dropShadow: {
          enabled: true,
          blur: 1,
          top: 1,
          left: 1,
          opacity: 0.1,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
      series: [percentage],
      labels: ["In Use"],
      colors: [colour],
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#333",
            startAngle: -90,
            endAngle: 90,
            dropShadow: {
              enabled: true,
              blur: 1,
              top: 1,
              left: 1,
              opacity: 0.1,
            },
          },
          dataLabels: {
            name: {
              show: true,

              colors: ["#d60000"],
            },
            value: {
              show: true,
              offsetY: 0,
              //fontSize: "30px",
              formatter: function (val, opts) {
                //console.log(opts);
                return opts.config.chart.value + "/" + opts.config.chart.total;
              },
            },
            style: {},
          },
        },
      },
    };
    chartData.mdmLicensesMac = mdmLicensesMac;
  } catch (error) {
    console.log(error);
  }
  try {
    let value = parseInt(data.value.jamfInformation.data.mangedDevices);
    let total = parseInt(data.value.jamfInformation.data.deviceLicenses);
    let percentage = (value / total) * 100;

    colour = "#eb5d0b";
    if (percentage > 100) {
      colour = "#d60000";
    }
    let mdmLicensesIOS = {
      chart: {
        type: "radialBar",
        value: value,
        total: total,
        dropShadow: {
          enabled: true,
          blur: 1,
          top: 1,
          left: 1,
          opacity: 0.1,
        },
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: "none",
            /* none, lighten, darken */
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
      series: [percentage],
      labels: ["In Use"],
      colors: [colour],
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#333",
            startAngle: -90,
            endAngle: 90,
            dropShadow: {
              enabled: true,
              blur: 1,
              top: 1,
              left: 1,
              opacity: 0.1,
            },
          },
          dataLabels: {
            name: {
              show: true,

              colors: ["#d60000"],
            },
            value: {
              show: true,
              offsetY: 0,
              //fontSize: "30px",
              formatter: function (val, opts) {
                //console.log(opts);
                return opts.config.chart.value + "/" + opts.config.chart.total;
              },
            },
            style: {},
          },
        },
      },
    };
    chartData.mdmLicensesIOS = mdmLicensesIOS;
  } catch (error) {
    console.log(error);
  }
}

function addOnlineChart() {
  var devicesOnline = devicesTotalOffline();
  var totalDevices = devicesTotal();

  var percentage = (devicesOnline / totalDevices) * 100;

  chartData.devicesOnline = {
    chart: {
      type: "radialBar",
      value: devicesOnline,
      total: totalDevices,
      dropShadow: {
        enabled: true,
        blur: 1,
        top: 1,
        left: 1,
        opacity: 0.1,
      },
    },
    series: [percentage],
    labels: ["Online"],
    colors: ["#eb5d0b"],
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#333",
          opacity: 1,
          startAngle: -90,
          endAngle: 90,
          dropShadow: {
            enabled: true,
            blur: 1,
            top: 1,
            left: 1,
            opacity: 0.1,
          },
        },
        // track: {
        //   background: "#333",
        //   startAngle: -90,
        //   endAngle: 90,
        // },
        dataLabels: {
          name: {
            show: true,

            colors: ["#d60000"],
          },
          value: {
            show: true,
            offsetY: 0,
            //fontSize: "30px",
            formatter: function (val, opts) {
              //console.log(opts);
              return opts.config.chart.value + "/" + opts.config.chart.total;
            },
          },
          style: {},
        },
      },
    },
  };
}

const updatingTxt = ref("");

function showBetaContent(application) {
  /* Add this function to filter beta content to users with the correct permissions, make sure you include the following if not already at the top of the file;
    import { permissionsStore } from "@/stores/permissions";
    const permissions = permissionsStore();
  */
  try {
    return !!permissions.$state[application + "-BetaFeatures"];
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function getData() {
  try {
    selectedTab.value = "loading";
    updating.value = true;
    updatingTxt.value = "Requesting Data...";
    if (appBar.selected != "") {
      const result = await apiQuery("ELA", "cmd24/query", {
        mdmServerId: appBar.selected,
        dataRequested: "dashboard",
        maxage: 86400,
      });
      if (result.content) {
        mdmType = mdmServer.$state.type;
        updatingTxt.value = "Processing...";
        data.value = postProcessing(result, mdmType);
        addOnlineChart();
        if (data.value.jamfInformation) {
          addLicenseCharts();
        }

        // Clear out CIS data to prevent crossover during appBar changes before processing.
        macOSCisBenchmark.length = 0;
        windowsCisBenchmark.length = 0;
        processCIS(data.value.content);

        if (mdmType == "Intune") {
          // Processing this takes a little longer, so await until done.
          await getAllDeviceRecords();
          getEnrollmentTypes();

          // Only show the pie charts if there is data to show, avoiding possible errors.
          showChart.value = true;
        }
        updating.value = false;
        selectedTab.value = "overview";
        updateRandom();
      }
    }
  } catch (error) {
    console.log(error);
    updatingTxt.value = "Failed to Get Data";
  }
}

onMounted(() => {
  getData();
});

const dummyDataold = {
  labels: ["12.1.0", "10.15.7", "10.14.6", "10.15.6", "13.1.0", "11.5.2"],
  datasets: [
    {
      data: [1, 1, 1, 1, 1, 1],
      backgroundColor: [
        "#e8dff5",
        "#fce1e4",
        "#fcf4dd",
        "#ddedea",
        "#daeaf6",
        "#d4afb9",
      ],
    },
  ],
};

// const options = {
//   chart: { type: "pie" },
// };

function buildPieChartFromSummarised(chartData) {
  var series = [];
  var labels = [];
  if (!chartData.summarisedData) {
    return;
  }
  Object.keys(chartData.summarisedData).forEach((key) => {
    //console.log("Key", key);
    labels.push(key);
    series.push(chartData.summarisedData[key].count);
  });
  return {
    options: {
      chart: { type: "pie" },
      // plotOptions: { pie: { customScale: 0.5 } },
      labels: labels,
      j24ChartData: chartData,
    },
    chartData: series,
  };
}

const updating = ref(false);
const alertTxt = ref("");

async function requestUpdate() {
  updating.value = true;
  try {
    if (appBar.selected != "") {
      const result = await apiQuery("ELA", "cmd24/query", {
        mdmServerId: appBar.selected,
        dataRequested: "dashboardUpdate",
        maxage: 86400,
      });
      //console.log(result);
      if (!result.status == "UpdateRequested") {
        throw "Unable to request updated Data";
      }
      var updateComplete = false;
      var updateSuccessful = false;
      var maxAttempts = 30;
      var attempts = 0;
      while (!updateComplete && attempts < maxAttempts) {
        attempts += 1;
        await sleep(3000);
        let result = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "dashboardCheckUpdated",
          lastUpdated: data.value.date,
        });
        // Returns notUpdated == true if has not been updated yet
        if (result.content) {
          data.value = {};
          data.value = postProcessing(result);
          updateComplete = true;
          updateSuccessful = true;
        }
      }
      if (!updateSuccessful) {
        alertTxt.value = "Unable to request updated data";
      }
    }
  } catch (error) {
    console.log(error);
    alertTxt.value = "Unable to request updated data";
  }
  updating.value = false;
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function lastUpdated() {
  if (!data.value.date) {
    return "";
  }
  const now = new Date();
  const then = new Date(data.value.date);
  //console.log(now.getTime(), then.getTime());
  const difference = Math.floor((now.getTime() - then.getTime()) / 1000);
  let output = ``;
  //console.log(difference);
  if (difference < 60) {
    // Less than a minute has passed:
    output = `${difference} seconds ago`;
  } else if (difference < 3600) {
    // Less than an hour has passed:
    output = `${Math.floor(difference / 60)} minutes ago`;
  } else if (difference < 86400) {
    // Less than a day has passed:
    output = `${Math.floor(difference / 3600)} hours ago`;
  } else if (difference < 2620800) {
    // Less than a month has passed:
    output = `${Math.floor(difference / 86400)} days ago`;
  } else if (difference < 31449600) {
    // Less than a year has passed:
    output = `${Math.floor(difference / 2620800)} months ago`;
  } else {
    // More than a year has passed:
    output = `${Math.floor(difference / 31449600)} years ago`;
  }
  return "Last updated " + output;
}

function devicesTotal() {
  try {
    var total = 0;
    if (data.value.content.deviceCounts.ios) {
      total += data.value.content.deviceCounts.ios;
    }
    if (data.value.content.deviceCounts.ipados) {
      total += data.value.content.deviceCounts.ipados;
    }
    if (data.value.content.deviceCounts.macOS) {
      total += data.value.content.deviceCounts.macOS;
    }
    if (data.value.content.deviceCounts.windows) {
      total += data.value.content.deviceCounts.windows;
    }
    if (data.value.content.deviceCounts.android) {
      total += data.value.content.deviceCounts.android;
    }
    return total;
  } catch (error) {
    console.log(error);
    return 0;
  }
}

function devicesTotalOffline() {
  try {
    var total = 0;
    if (data.value.content.iosOnline?.count) {
      total += data.value.content.iosOnline.count;
    }
    if (data.value.content.macOSOnline?.count) {
      total += data.value.content.macOSOnline.count;
    }
    if (data.value.content.windowsOnline?.count) {
      total += data.value.content.windowsOnline.count;
    }
    if (data.value.content.iPadOSOnline?.count) {
      total += data.value.content.iPadOSOnline.count;
    }
    if (data.value.content.AndroidOSOnline?.count) {
      total += data.value.content.AndroidOSOnline.count;
    }
    return total;
  } catch (error) {
    console.log(error);
    return 0;
  }
}

const drillDown = ref({});

function clickHandler(event, chartContext, config) {
  console.log("clickhandler config", config);
  if (!config.w.config.j24ChartData) {
    console.log("stacked config");
    var reportIds;
    if (Array.isArray(config.w.config.series[config.seriesIndex].records)) {
      reportIds = [...config.w.config.series[config.seriesIndex].records];
    } else {
      reportIds = [config.w.config.series[config.seriesIndex].records];
    }
    drillDown.value = {
      mdmServerId: appBar.selected,
      reportId: reportIds,
    };
    selectedTab.value = "drillDown";
    return;
  }

  drillDown.value = {
    mdmServerId: appBar.selected,
    reportId: [
      config.w.config.j24ChartData.summarisedData[
        Object.keys(config.w.config.j24ChartData.summarisedData)[
          config.dataPointIndex
        ]
      ].records,
    ],
  };
  selectedTab.value = "drillDown";
}

function clickDrilldown(value, filterSerials = false) {
  var dd = {
    mdmServerId: appBar.selected,
    reportId: [],
  };
  if (filterSerials) {
    dd.filterSerials = filterSerials;
  }

  console.log("Drill down value", value);
  switch (value) {
    case "offline10":
      if (data.value.content.iosOffline10?.records) {
        dd.reportId.push(data.value.content.iosOffline10.records);
      }
      if (data.value.content.macOSOffline10?.records) {
        dd.reportId.push(data.value.content.macOSOffline10.records);
      }
      if (data.value.content.windowsOffline10?.records) {
        dd.reportId.push(data.value.content.windowsOffline10.records);
      }
      if (data.value.content.iPadOSOffline10?.records) {
        dd.reportId.push(data.value.content.iPadOSOffine10.records);
      }
      if (data.value.content.AndroidOffline10?.records) {
        dd.reportId.push(data.value.content.AndroidOffine10.records);
      }
      break;
    case "offline90":
      if (data.value.content.iosOffline90?.records) {
        dd.reportId.push(data.value.content.iosOffline90.records);
      }
      if (data.value.content.macOSOffline90?.records) {
        dd.reportId.push(data.value.content.macOSOffline90.records);
      }
      if (data.value.content.windowsOffline90?.records) {
        dd.reportId.push(data.value.content.windowsOffline90.records);
      }
      if (data.value.content.iPadOSOffline90?.records) {
        dd.reportId.push(data.value.content.iPadOSOffline90.records);
      }
      if (data.value.content.AndroidOffline90?.records) {
        dd.reportId.push(data.value.content.AndroidOffline90.records);
      }
      break;
    case "online":
      if (data.value.content.iosOnline?.records) {
        dd.reportId.push(data.value.content.iosOnline.records);
      }
      if (data.value.content.macOSOnline?.records) {
        dd.reportId.push(data.value.content.macOSOnline.records);
      }
      if (data.value.content.windowsOnline?.records) {
        dd.reportId.push(data.value.content.windowsOnline.records);
      }
      if (data.value.content.iPadOSOnline?.records) {
        dd.reportId.push(data.value.content.iPadOSOnline.records);
      }
      if (data.value.content.AndroidOSOnline?.records) {
        dd.reportId.push(data.value.content.AndroidOSOnline.records);
      }
      break;
    case "notEncrypted":
      if (data.value.content.iOSNotEncrypted?.records) {
        dd.reportId.push(data.value.content.iOSNotEncrypted.records);
      }
      if (data.value.content.macOSNotEncrypted?.records) {
        dd.reportId.push(data.value.content.macOSNotEncrypted.records);
      }
      if (data.value.content.windowsNotEncrypted?.records) {
        dd.reportId.push(data.value.content.windowsNotEncrypted.records);
      }
      if (data.value.content.iPadOSNotEncrypted?.records) {
        dd.reportId.push(data.value.content.iPadOSNotEncrypted.records);
      }
      if (data.value.content.AndroidNotEncrypted?.records) {
        dd.reportId.push(data.value.content.AndroidNotEncrypted.records);
      }
      break;
    case "lowDiskSpace":
      if (data.value.content.macOSLowDiskSpace?.records) {
        dd.reportId.push(data.value.content.macOSLowDiskSpace.records);
      }
      if (data.value.content.iOSLowDiskSpace?.records) {
        dd.reportId.push(data.value.content.iOSLowDiskSpace.records);
      }
      if (data.value.content.windowsLowDiskSpace?.records) {
        dd.reportId.push(data.value.content.windowsLowDiskSpace.records);
      }
      if (data.value.content.iPadOSLowDiskSpace?.records) {
        dd.reportId.push(data.value.content.iPadOSLowDiskSpace.records);
      }
      if (data.value.content.AndroidLowDiskSpace?.records) {
        dd.reportId.push(data.value.content.AndroidLowDiskSpace.records);
      }
      break;
    case "allDevices":
      if (data.value.content.macOSAll?.records) {
        dd.reportId.push(data.value.content.macOSAll.records);
      }
      if (data.value.content.windowsAll?.records) {
        dd.reportId.push(data.value.content.windowsAll?.records);
      }
      if (data.value.content.iOSAll?.records) {
        dd.reportId.push(data.value.content.iOSAll?.records);
      }
      if (data.value.content.iPadOSAll?.records) {
        dd.reportId.push(data.value.content.iPadOSAll?.records);
      }
      if (data.value.content.AndroidOSAll?.records) {
        dd.reportId.push(data.value.content.AndroidOSAll?.records);
      }
      break;
    default:
      if (value.length) {
        console.log("Value Length");
        value.forEach((a) => {
          if (a.records) {
            dd.reportId.push(a.records);
          } else {
            dd.reportId.push(a);
          }
        });
      } else {
        if (value.records) {
          dd.reportId.push(value.records);
        }
      }
      break;
  }
  console.log("DD", dd);
  drillDown.value = dd;
  selectedTab.value = "drillDown";
}

const selectedTab = ref("loading");
const platforms = ref("macOS");

function safeLength(value) {
  if (typeof value != "object") {
    return 0;
  }
  if (value.length) {
    return value.length;
  }
  return 0;
}

const dialogContent = ref({});
const dialog = ref(false);

function expiresOrExpired(date) {
  let expiryDate = new Date(date);
  let now = new Date();
  if (now.getTime() > expiryDate.getTime()) {
    return "Expired";
  }
  return "Expires";
}

function formatDate(date) {
  let dateObj = new Date(date);
  return dateObj.toLocaleDateString();
}

function clickAPNS(apnsExpiry) {
  dialogContent.value.title = "Apple Push Notification Service Certificate";
  dialogContent.value.data = [];
  dialogContent.value.data.push(
    "APNS Certificate " +
      expiresOrExpired(apnsExpiry) +
      " on " +
      formatDate(apnsExpiry)
  );
  dialog.value = true;
}

function clickADE(enrollmentAccounts) {
  dialogContent.value.title = "Automated Device Enrollment Accounts";
  dialogContent.value.data = [];
  enrollmentAccounts.forEach((value) => {
    dialogContent.value.data.push(
      value.name +
        " - Token " +
        expiresOrExpired(value.tokenExpirationDate) +
        " on " +
        formatDate(value.tokenExpirationDate)
    );
  });
  dialog.value = true;
}

function clickVPP(vppAccounts) {
  dialogContent.value.title = "VPP / Apps and Books Accounts";
  dialogContent.value.data = [];
  vppAccounts.forEach((value) => {
    dialogContent.value.data.push(
      value.name +
        " - Token " +
        expiresOrExpired(value.expirationDate) +
        " on " +
        formatDate(value.expirationDate)
    );
  });
  dialog.value = true;
}

function convertObjToArray(obj) {
  var result = [];
  for (const key in obj) {
    result.push({ ...obj[key], name: key });
  }
  return result;
}

function buildPieChart(chartData) {
  var series = [];
  var labels = [];
  Object.keys(chartData).forEach((key) => {
    labels.push(key);
    series.push(chartData[key].length);
  });

  return {
    options: {
      chart: { type: "pie" },
      labels: labels,
      j24ChartData: chartData,
      colors: ["#eb5d0b", "#7b264b", "#374f50", "#abb9c2", "#eeeeee"],
      dataLabels: {
        style: {
          colors: ["#111111"],
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    chartData: series,
  };
}

async function getAllDeviceRecords() {
  // Clear arrays in deviceTypes and addDevices, this prevents stacking between appBar changes.
  allDevices.length = 0;

  Object.values(deviceTypes).forEach((value) => {
    if (Array.isArray(value)) {
      value.length = 0;
    }
  });

  const allMacosResult = await apiQuery("ELA", "cmd24/query", {
    mdmServerId: appBar.selected,
    dataRequested: "dashboardDetail",
    id: data.value.content.macOSAll.records,
  });
  if (allMacosResult) {
    allDevices.push(...allMacosResult.content);
  }
  const allIOSDevices = await apiQuery("ELA", "cmd24/query", {
    mdmServerId: appBar.selected,
    dataRequested: "dashboardDetail",
    id: data.value.content.iOSAll.records,
  });
  if (allIOSDevices) {
    allDevices.push(...allIOSDevices.content);
  }
  const allWindowsDevices = await apiQuery("ELA", "cmd24/query", {
    mdmServerId: appBar.selected,
    dataRequested: "dashboardDetail",
    id: data.value.content.windowsAll.records,
  });
  if (allWindowsDevices) {
    allDevices.push(...allWindowsDevices.content);
  }
  const allAndroidDevices = await apiQuery("ELA", "cmd24/query", {
    mdmServerId: appBar.selected,
    dataRequested: "dashboardDetail",
    id: data.value.content.AndroidOSAll.records,
  });
  if (allAndroidDevices) {
    allDevices.push(...allAndroidDevices.content);
  }

  allDevices.forEach((device) => {
    if (
      device.Operating_System_Name == "macOS" ||
      device.Operating_System_Name == "Mac OS X"
    ) {
      deviceTypes.macOS.push(device);
      return;
    } else {
      if (device.Model.includes("iPad")) {
        deviceTypes.iPadOS.push(device);
        return;
      }
      if (device.Model.includes("iPhone")) {
        deviceTypes.iOS.push(device);
        return;
      }
    }
    if (device.Model.includes("Apple TV")) {
      deviceTypes.tvOS.push(device);
      return;
    }
    if (device.Operating_System_Name == "Windows") {
      deviceTypes.Windows.push(device);
      return;
    }
    if (device.Operating_System_Name == "Android") {
      deviceTypes.Android.push(device);
      return;
    }

    deviceTypes.Unknown.push(device);
  });

  // Remove items that are empty.
  let deviceTypesFiltered = {};
  for (const key in deviceTypes) {
    if (deviceTypes[key].length > 0) {
      deviceTypesFiltered[key] = deviceTypes[key];
    }
  }

  deviceTypesChartData = buildPieChart(deviceTypesFiltered);
}

function clickHandlerPieChart(event, chartContext, config) {
  drillDown.value = {};
  console.log("Click Handler Config", config);
  let series = config.w.config.series[config.seriesIndex];
  console.log("Series", series);
  if (series.devices) {
    drillDown.value = {
      mdmServerId: appBar.selected,
      manualMode: true,
      allDevices: series.devices,
      serialNumbers: series.devices.map((x) => x.Serial_Number),
    };
    selectedTab.value = "drillDown";
  }
  if (config.w.config.j24ChartData) {
    const key = config.w.config.labels[config.dataPointIndex];
    drillDown.value = {
      mdmServerId: appBar.selected,
      manualMode: true,
      allDevices: config.w.config.j24ChartData[key],
      serialNumbers: config.w.config.j24ChartData[key].map(
        (x) => x.Serial_Number
      ),
    };
    selectedTab.value = "drillDown";
  }
}
</script>

<template>
  <v-window v-model="selectedTab">
    <template v-if="selectedTab != 'loading'">
      <v-row
        v-if="
          selectedTab == 'overview' ||
          selectedTab == 'software' ||
          selectedTab == 'hardware'
        "
      >
        <v-col class="mb-2">
          <v-tabs v-model="selectedTab" color="#e7541e">
            <v-tab value="overview">Overview</v-tab>
            <v-tab value="software" :disabled="loading">Software</v-tab>
            <v-tab value="hardware">Hardware</v-tab>
          </v-tabs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          {{ lastUpdated()
          }}<v-btn
            variant="text"
            icon="mdi-refresh"
            :loading="updating"
            @click="requestUpdate()"
          ></v-btn>
        </v-col>
      </v-row>
    </template>

    <v-window-item value="loading" class="mx-auto pa-2">
      <v-card class="mx-auto mt-16" max-width="500" v-if="!appBar.selected">
        <v-card-title>No MDM Selected</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text
          >Please select an MDM Server from the menu above.</v-card-text
        >
      </v-card>
      <v-card
        class="mx-auto mt-16"
        max-width="500"
        min-width="300"
        v-if="updating && appBar.selected"
      >
        <v-card-title>Fetching Data</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <v-progress-circular
                indeterminate
                color="#eb5d0b"
                :size="83"
              ></v-progress-circular>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row
            ><v-col>
              {{ updatingTxt }}
            </v-col></v-row
          >
        </v-card-text>
      </v-card>
    </v-window-item>

    <v-window-item value="drillDown">
      <ElaDashboardDrillDown
        v-if="drillDown.mdmServerId"
        :mdmServerId="drillDown.mdmServerId"
        :reportId="drillDown.reportId"
        :allDevices="drillDown.allDevices"
        :serialNumbers="drillDown.serialNumbers"
        :filterSerials="drillDown.filterSerials"
        :manualMode="drillDown.manualMode"
        @close="
          drillDown = {};
          selectedTab = 'overview';
        "
      ></ElaDashboardDrillDown>
    </v-window-item>

    <v-window-item v-if="data.content" value="software" class="pa-1">
      <v-window v-if="mdmType == 'Intune'">
        <v-tabs v-model="platforms">
          <v-tab v-if="data.content.macOSAll.count > 0" value="macOS"
            ><v-icon>mdi-apple</v-icon>macOS</v-tab
          >
          <v-tab v-if="data.content.windowsAll.count > 0" value="Windows"
            ><v-icon>mdi-microsoft-windows</v-icon>Windows</v-tab
          >
        </v-tabs>
        <v-window v-model="platforms" class="pa-3">
          <v-window-item value="macOS">
            <v-row>
              <v-col cols="12">
                <ApplicationsList
                  :devices="data.content.macOSAll"
                  platform="macOS"
                ></ApplicationsList>
              </v-col> </v-row
          ></v-window-item>

          <v-window-item value="Windows">
            <v-row>
              <v-col cols="12">
                <ApplicationsList
                  :devices="data.content.windowsAll"
                  platform="Windows"
                ></ApplicationsList>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-window>
      <v-window v-if="mdmType != 'Intune'">
        <v-row>
          <v-col cols="12">
            <ApplicationsList
              :devices="data.content.macOSAll"
              platform="macOS"
            ></ApplicationsList>
          </v-col>
        </v-row>
      </v-window>
    </v-window-item>

    <v-window-item value="hardware">
      <v-row>
        <v-col cols="12">
          <HardwareOverview
            :allMacs="data.content.macOSAll"
            :allIOS="data.content.iOSAll"
            :allWindows="data.content.windowsAll"
            :allAndroid="data.content.AndroidOSAll"
            :macModels="data.content.modelReport"
            :iosModels="data.content.iosModelReport"
            :windowsModels="data.content.windowsModelReport"
            :androidModels="data.content.androidModelReport"
          ></HardwareOverview>
        </v-col>
      </v-row>
    </v-window-item>

    <v-window-item value="overview" class="pa-1">
      <!-- <v-row>
        <v-col>
          <v-tabs v-model="selectedTab" color="#e7541e">
            <v-tab value="overview">Overview</v-tab>
            <v-tab value="hardware">Hardware</v-tab>
          </v-tabs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          {{ lastUpdated()
          }}<v-btn
            variant="text"
            icon="mdi-refresh"
            :loading="updating"
            @click="requestUpdate()"
          ></v-btn>
        </v-col>
      </v-row> -->

      <!-- This is for the Jamf and Kandji overview ------------------------------------------------------->
      <template v-if="data.content && mdmType != 'Intune'">
        <v-dialog v-model="dialog" scrollable max-width="700">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="auto">
                  {{ dialogContent.title }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px">
              <template v-for="text in dialogContent.data" :key="text">
                {{ text }}<br />
              </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto"
                  ><v-btn @click="dialog = false">Close</v-btn></v-col
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-row v-if="data.jamfInformation">
          <v-col v-if="chartData.mdmLicensesMac">
            <v-card :height="172" class="pa-0">
              <v-card-title class="pb-0">macOS Licenses</v-card-title>

              <apexchart
                height="115%"
                class="pb-2"
                :options="chartData.mdmLicensesMac"
                :series="chartData.mdmLicensesMac.series"
              ></apexchart>
            </v-card>
          </v-col>

          <v-col v-if="chartData.mdmLicensesIOS">
            <v-card :height="172" class="pa-0">
              <v-card-title class="pb-0">iOS Licenses</v-card-title>
              <v-card-text>
                <apexchart
                  height="115%"
                  class="pb-2"
                  :options="chartData.mdmLicensesIOS"
                  :series="chartData.mdmLicensesIOS.series"
                ></apexchart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4"
            ><v-card :height="172"
              ><v-card-title class="">MDM Information</v-card-title
              ><v-card-text
                ><v-table class="text-left">
                  <tr
                    @click="clickAPNS(data.content.apnsExpiry)"
                    class="pointer"
                  >
                    <th>APNS Certificate Status</th>
                    <td class="pointer">
                      <v-icon :color="dateStatus(data.content.apnsExpiry)"
                        >mdi-circle</v-icon
                      >
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.enrollmentAccounts.length > 0"
                    @click="clickADE(data.content.enrollmentAccounts)"
                    class="pointer"
                  >
                    <th>ADE Expiry</th>
                    <td
                      @click="clickADE(data.content.enrollmentAccounts)"
                      class="pointer"
                    >
                      <v-icon
                        :color="dateStatusADE(data.content.enrollmentAccounts)"
                      >
                        mdi-circle
                      </v-icon>
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.vppAccounts.length > 0"
                    @click="clickVPP(data.content.vppAccounts)"
                    class="pointer"
                  >
                    <th>VPP Expiry</th>
                    <td
                      @click="clickVPP(data.content.vppAccounts)"
                      class="pointer"
                    >
                      <v-icon :color="dateStatusVPP(data.content.vppAccounts)">
                        mdi-circle
                      </v-icon>
                    </td>
                  </tr>
                </v-table></v-card-text
              ></v-card
            ></v-col
          >
        </v-row>

        <v-row>
          <!-- <v-col cols="6">
            <v-card>
              <v-card-title>Managed Devices</v-card-title>
              <v-card-text>
                <v-table>
                  <tr v-if="data.content.deviceCounts.macOS">
                    <td>macOS</td>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown(data.content.macOSAll)"
                    >
                      {{ data.content.deviceCounts.macOS }}
                    </td>
                  </tr>
                  <tr v-if="data.content.deviceCounts.ios">
                    <td>iOS/iPad OS</td>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown(data.content.iOSAll)"
                    >
                      {{ data.content.deviceCounts.ios }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Total</td>
                    <td>{{ devicesTotal() }}</td>
                  </tr>
                </v-table>
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col cols="12" sm="4">
            <v-card height="170" class="pa-0">
              <v-card-title class="pb-0">
                Devices Online (Last 24 Hours)</v-card-title
              >

              <apexchart
                class="pa-0"
                height="115%"
                :options="chartData.devicesOnline"
                :series="chartData.devicesOnline.series"
                @dataPointSelection="clickDrilldown('online')"
              />
              <!-- <v-row class="text-center">
                  <v-col>
                    <v-sheet
                      ><div>In Last 24 Hours:</div>
                      <div>
                        <h1>
                          <v-row>
                            <v-spacer></v-spacer>
                            <v-col
                              cols="auto"
                              class="pointer text-decoration-underline text-right"
                              @click="clickDrilldown('online')"
                              >{{ devicesTotalOffline() }}</v-col
                            >
                            <v-col cols="auto">/</v-col>
                            <v-col cols="auto">{{ devicesTotal() }}</v-col>
                            <v-spacer></v-spacer>
                          </v-row>
                        </h1></div
                    ></v-sheet>
                  </v-col>
                </v-row> -->
            </v-card>
          </v-col>
          <v-col cols="4" v-if="data.content.nonCompliantDevices">
            <v-card height="170" class="pa-0">
              <v-card-title class="pb-0">Compliant Devices</v-card-title>

              <apexchart
                class="pa-0"
                height="115%"
                :options="chartData.compliantDevices"
                :series="chartData.compliantDevices.series"
                @dataPointSelection="
                  clickDrilldown(data.content.nonCompliantDevices)
                "
              />
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card height="170">
              <v-card-title class=""> Devices Offline </v-card-title>
              <v-card-text>
                <v-row class="text-center">
                  <v-col>
                    <v-table class="text-left">
                      <tr
                        class="pointer"
                        @click="
                          clickDrilldown(data.content.devicesOffline30.records)
                        "
                      >
                        <th>Offline for 30 - 60 Days</th>
                        <td
                          class="pointer text-decoration-underline"
                          @click="
                            clickDrilldown(
                              data.content.devicesOffline30.records
                            )
                          "
                        >
                          {{ data.content.devicesOffline30.count }}
                        </td>
                      </tr>
                      <tr
                        class="pointer"
                        @click="
                          clickDrilldown(data.content.devicesOffline60.records)
                        "
                      >
                        <th>Offline for 60 - 90 Days</th>
                        <td
                          class="pointer text-decoration-underline"
                          @click="
                            clickDrilldown(
                              data.content.devicesOffline60.records
                            )
                          "
                        >
                          {{ data.content.devicesOffline60.count }}
                        </td>
                      </tr>
                      <tr
                        class="pointer"
                        @click="
                          clickDrilldown(data.content.devicesOffline90.records)
                        "
                      >
                        <th>Offline for more than 90 days</th>
                        <td
                          class="pointer text-decoration-underline"
                          @click="
                            clickDrilldown(
                              data.content.devicesOffline90.records
                            )
                          "
                        >
                          {{ data.content.devicesOffline90.count }}
                        </td>
                      </tr>
                    </v-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card height="170">
              <v-card-title>Device Health</v-card-title>
              <v-card-text>
                <v-table class="text-left">
                  <tr
                    v-if="
                      data.content.batteryHealthReport &&
                      data.content.batteryHealthReport.length > 0
                    "
                    class="pointer"
                    @click="
                      clickDrilldown(
                        'allDevices',
                        data.content.batteryHealthReport
                      )
                    "
                  >
                    <th>Battery Requires Service</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="
                        clickDrilldown(
                          'allDevices',
                          data.content.batteryHealthReport
                        )
                      "
                    >
                      {{ data.content.batteryHealthReport.length }}
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.lowDiskSpace > 0"
                    class="pointer"
                    @click="clickDrilldown('lowDiskSpace')"
                  >
                    <th>Low Disk Space</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown('lowDiskSpace')"
                    >
                      {{ data.content.lowDiskSpace }}
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.notEncrypted > 0"
                    class="pointer"
                    @click="clickDrilldown('notEncrypted')"
                  >
                    <th>Not Encrypted</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown('notEncrypted')"
                    >
                      {{ data.content.notEncrypted }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      data.content.diskHealthReport &&
                      data.content.diskHealthReport.length > 0
                    "
                    class="pointer"
                    @click="
                      clickDrilldown(
                        'allDevices',
                        data.content.diskHealthReport
                      )
                    "
                  >
                    <th>Disk Failure Warning</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="
                        clickDrilldown(
                          'allDevices',
                          data.content.diskHealthReport
                        )
                      "
                    >
                      {{ data.content.diskHealthReport.length }}
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.nonCompliantDevices"
                    class="pointer"
                    @click="clickDrilldown(data.content.nonCompliantDevices)"
                  >
                    <th>Non-Compliant Devices</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown(data.content.nonCompliantDevices)"
                    >
                      {{ data.content.nonCompliantDevices.count }}
                    </td>
                  </tr>
                </v-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            v-if="
              data.content.windowsVersions &&
              data.content.windowsVersions.summarisedData
            "
          >
            <v-card>
              <v-card-title class="pb-2 mb-2">
                <v-row>
                  <v-col cols="auto"> Windows Versions </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="text-right">
                    <ToggleSwitch v-model="showAllDevices"></ToggleSwitch>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <VersionChart
                  :key="randomKey + 'windows'"
                  v-if="showAllDevices"
                  class="mt-3"
                  :windowsVersions="
                    showAllDevices
                      ? data.content.windowsVersions
                      : data.content.windowsVersions30
                  "
                  @clickDataPoint="clickHandler"
                ></VersionChart>
                <VersionChart
                  v-else
                  :key="randomKey + 'windowsv'"
                  class="mt-3"
                  :windowsVersions="data.content.windowsVersions30"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="
              data.content.macOSVersions &&
              data.content.macOSVersions.summarisedData
            "
          >
            <v-card>
              <v-card-title class="pb-2 mb-2">
                <v-row>
                  <v-col cols="auto"> macOS Versions </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="text-right">
                    <ToggleSwitch v-model="showAllDevices"></ToggleSwitch>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <VersionChart
                  v-if="showAllDevices"
                  class="mt-3"
                  :key="randomKey + 'macos'"
                  :macOSVersions="
                    showAllDevices
                      ? data.content.macOSVersions
                      : data.content.macOSVersions30
                  "
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
                <VersionChart
                  v-else
                  :key="randomKey + 'macosv'"
                  class="mt-3"
                  :macOSVersions="data.content.macOSVersions30"
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-col cols="6" v-if="data.content.macOSMajorVersions">
            <v-card>
              <v-card-title class=""> macOS Major Verions </v-card-title>
              <v-card-text>
                <apexchart
                  :options="chartData.macOSMajorVersions.options"
                  :series="chartData.macOSMajorVersions.chartData"
                  @dataPointSelection="clickHandler"
                />
              </v-card-text>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="6" v-if="data.content.macOSVersions">
            <v-card>
              <v-card-title class="">macOS Minor Verions</v-card-title>
              <v-card-text>
                <apexchart
                  id="j24field"
                  :options="chartData.macOSVersions.options"
                  :series="chartData.macOSVersions.chartData"
                  @dataPointSelection="clickHandler"
                />
              </v-card-text>
            </v-card>
          </v-col> -->

          <v-col
            cols="12"
            v-if="
              data.content.iOSVersions &&
              data.content.iOSVersions.summarisedData
            "
          >
            <v-card>
              <v-card-title class="pb-2 mb-2">
                <v-row>
                  <v-col cols="auto"> iOS/iPadOS Versions </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <ToggleSwitch v-model="showAllDevices"></ToggleSwitch>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <VersionChart
                  v-if="showAllDevices"
                  class="mt-3"
                  :key="randomKey + 'ios'"
                  :iOSVersions="data.content.iOSVersions"
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
                <VersionChart
                  v-else
                  class="mt-3"
                  :key="randomKey + 'iosv'"
                  :iOSVersions="data.content.iOSVersions30"
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- <v-col cols="6" v-if="data.content.iOSMajorVersions">
            <v-card>
              <v-card-title class="">iOS/iPadOS Major Verions</v-card-title>
              <v-card-text>
                <apexchart
                  :options="chartData.iOSMajorVersions.options"
                  :series="chartData.iOSMajorVersions.chartData"
                  @dataPointSelection="clickHandler"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" v-if="data.content.iOSVersions">
            <v-card>
              <v-card-title class="">iOS/iPadOS Minor Verions</v-card-title>
              <v-card-text>
                <apexchart
                  :options="chartData.iOSVersions.options"
                  :series="chartData.iOSVersions.chartData"
                  @dataPointSelection="clickHandler"
                />
              </v-card-text>
            </v-card>
          </v-col> -->
        </v-row>

        <!-- <v-row>
          <v-col cols="6" v-if="data.content.batteryHealthReport">
            <v-card>
              <v-card-title class="">
                Devices with Battery Requiring Service
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="text-center"
                    ><h1
                      class="pointer text-decoration-underline pa-5"
                      @click="clickDrilldown(data.content.batteryHealthReport)"
                    >
                      {{ data.content.batteryHealthReport.length }} Devices
                    </h1></v-col
                  >
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" v-if="data.content.devicesOnLatestRelease">
            <v-card>
              <v-card-title class="">
                Devices on Latest Operating System
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <h3>Updated to Major Version</h3>
                    <div
                      class="pointer text-decoration-underline text-center"
                      @click="
                        clickDrilldown(
                          data.content.devicesOnLatestRelease.latestMinorVersion
                            .upToDate
                        )
                      "
                    >
                      Yes:
                      {{
                        data.content.devicesOnLatestRelease.latestMinorVersion
                          .upToDateCount
                      }}
                    </div>
                    <div
                      class="pointer text-decoration-underline text-center"
                      @click="
                        clickDrilldown(
                          data.content.devicesOnLatestRelease.latestMinorVersion
                            .not
                        )
                      "
                    >
                      No:
                      {{
                        data.content.devicesOnLatestRelease.latestMinorVersion
                          .notCount
                      }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <h3>Fully Updated</h3>
                    <div
                      class="pointer text-decoration-underline text-center"
                      @click="
                        clickDrilldown(
                          data.content.devicesOnLatestRelease.latestRelease
                            .upToDate
                        )
                      "
                    >
                      Yes:
                      {{
                        data.content.devicesOnLatestRelease.latestRelease
                          .upToDateCount
                      }}
                    </div>
                    <div
                      class="pointer text-decoration-underline text-center"
                      @click="
                        clickDrilldown(
                          data.content.devicesOnLatestRelease.latestRelease.not
                        )
                      "
                    >
                      No:
                      {{
                        data.content.devicesOnLatestRelease.latestRelease
                          .notCount
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row v-if="showBetaContent('CMD24')">
          <v-col v-if="macOSCisBenchmark.length" cols="12">
            <v-card>
              <v-card-title>macOS - Failed CIS Overview</v-card-title>
              <v-card-text>
                <v-table
                  hover
                  density="compact"
                  scrollable
                  height="300"
                  fixed-header
                >
                  <thead>
                    <tr>
                      <th class="font-weight-bold">Reference</th>
                      <th class="font-weight-bold">Name</th>
                      <th class="font-weight-bold">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cis in macOSCisBenchmark" :key="cis">
                      <td>{{ cis.cis }}</td>
                      <td>{{ cis.name }}</td>
                      <td>{{ cis.count }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!-- This is for the Intune overview ---------------------------------------------------------------->
      <template v-if="data.content && mdmType == 'Intune'">
        <v-dialog v-model="dialog" scrollable max-width="700">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="auto">
                  {{ dialogContent.title }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px">
              <template v-for="text in dialogContent.data" :key="text">
                {{ text }}<br />
              </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto"
                  ><v-btn @click="dialog = false">Close</v-btn></v-col
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-row>
          <v-col cols="4">
            <v-card height="170" class="pa-0">
              <v-card-title class="pb-0">
                Devices Online (Last 24 Hours)</v-card-title
              >

              <apexchart
                class="pa-0"
                height="115%"
                :options="chartData.devicesOnline"
                :series="chartData.devicesOnline.series"
                @dataPointSelection="clickDrilldown('online')"
              />
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card v-if="showChart" height="170" class="pa-0">
              <v-card-title class="pb-0">Enrolled Devices</v-card-title>
              <v-card-text class="pa-0">
                <apexchart
                  class="pa-0"
                  height="80%"
                  :options="deviceTypesChartData.options"
                  :series="deviceTypesChartData.chartData"
                  @dataPointSelection="clickHandlerPieChart"
                ></apexchart>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card v-if="showChart" height="170" class="pa-0">
              <v-card-title class="pb-0">Enrolment Type</v-card-title>
              <apexchart
                class="pa-0"
                height="73%"
                :options="enrollmentTypesChartData.options"
                :series="enrollmentTypesChartData.chartData"
                @dataPointSelection="clickHandlerPieChart"
              />
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card height="170">
              <v-card-title class=""> Devices Offline </v-card-title>
              <v-card-text>
                <v-row class="text-center">
                  <v-col>
                    <v-table class="text-left">
                      <tr
                        class="pointer"
                        @click="
                          clickDrilldown(data.content.devicesOffline30.records)
                        "
                      >
                        <th>Offline for 30 - 60 Days</th>
                        <td
                          class="pointer text-decoration-underline"
                          @click="
                            clickDrilldown(
                              data.content.devicesOffline30.records
                            )
                          "
                        >
                          {{ data.content.devicesOffline30.count }}
                        </td>
                      </tr>
                      <tr
                        class="pointer"
                        @click="
                          clickDrilldown(data.content.devicesOffline60.records)
                        "
                      >
                        <th>Offline for 60 - 90 Days</th>
                        <td
                          class="pointer text-decoration-underline"
                          @click="
                            clickDrilldown(
                              data.content.devicesOffline60.records
                            )
                          "
                        >
                          {{ data.content.devicesOffline60.count }}
                        </td>
                      </tr>
                      <tr
                        class="pointer"
                        @click="
                          clickDrilldown(data.content.devicesOffline90.records)
                        "
                      >
                        <th>Offline for more than 90 days</th>
                        <td
                          class="pointer text-decoration-underline"
                          @click="
                            clickDrilldown(
                              data.content.devicesOffline90.records
                            )
                          "
                        >
                          {{ data.content.devicesOffline90.count }}
                        </td>
                      </tr>
                    </v-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card height="170">
              <v-card-title>Device Health</v-card-title>
              <v-card-text>
                <v-table class="text-left">
                  <tr
                    v-if="data.content.batteryHealthReport"
                    class="pointer"
                    @click="
                      clickDrilldown(
                        'allDevices',
                        data.content.batteryHealthReport
                      )
                    "
                  >
                    <th>Battery Requires Service</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="
                        clickDrilldown(
                          'allDevices',
                          data.content.batteryHealthReport
                        )
                      "
                    >
                      {{ data.content.batteryHealthReport.length }}
                    </td>
                  </tr>
                  <tr class="pointer" @click="clickDrilldown('lowDiskSpace')">
                    <th>Low Disk Space</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown('lowDiskSpace')"
                    >
                      {{ data.content.lowDiskSpace }}
                    </td>
                  </tr>
                  <tr class="pointer" @click="clickDrilldown('notEncrypted')">
                    <th>Not Encrypted</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown('notEncrypted')"
                    >
                      {{ data.content.notEncrypted }}
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.diskHealthReport"
                    class="pointer"
                    @click="
                      clickDrilldown(
                        'allDevices',
                        data.content.diskHealthReport
                      )
                    "
                  >
                    <th>Disk Failure Warning</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="
                        clickDrilldown(
                          'allDevices',
                          data.content.diskHealthReport
                        )
                      "
                    >
                      {{ data.content.diskHealthReport.length }}
                    </td>
                  </tr>
                  <tr
                    v-if="data.content.nonCompliantDevices"
                    class="pointer"
                    @click="clickDrilldown(data.content.nonCompliantDevices)"
                  >
                    <th>Non-Compliant Devices</th>
                    <td
                      class="pointer text-decoration-underline"
                      @click="clickDrilldown(data.content.nonCompliantDevices)"
                    >
                      {{ data.content.nonCompliantDevices.count }}
                    </td>
                  </tr>
                </v-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4"
            ><v-card :height="172"
              ><v-card-title class="">Apple Information</v-card-title
              ><v-card-text
                ><v-table class="text-left">
                  <tr
                    v-if="data.content.apnsExpiry"
                    @click="clickAPNS(data.content.apnsExpiry)"
                    class="pointer"
                  >
                    <th>APNS Certificate Status</th>
                    <td class="pointer">
                      <v-icon :color="dateStatus(data.content.apnsExpiry)"
                        >mdi-circle</v-icon
                      >
                    </td>
                  </tr>
                  <tr v-else>
                    <th>APNS certificate</th>
                    <td>Not Setup</td>
                  </tr>
                  <tr
                    v-if="data.content.enrollmentAccounts.length > 0"
                    @click="clickADE(data.content.enrollmentAccounts)"
                    class="pointer"
                  >
                    <th>ADE Expiry</th>
                    <td
                      @click="clickADE(data.content.enrollmentAccounts)"
                      class="pointer"
                    >
                      <v-icon
                        :color="dateStatusADE(data.content.enrollmentAccounts)"
                      >
                        mdi-circle
                      </v-icon>
                    </td>
                  </tr>
                  <tr v-else>
                    <th>Automated Device Enrollment token</th>
                    <td>Not Setup</td>
                  </tr>
                  <tr
                    v-if="data.content.vppAccounts.length > 0"
                    @click="clickVPP(data.content.vppAccounts)"
                    class="pointer"
                  >
                    <th>Apps & Books Expiry</th>
                    <td
                      @click="clickVPP(data.content.vppAccounts)"
                      class="pointer"
                    >
                      <v-icon :color="dateStatusVPP(data.content.vppAccounts)">
                        mdi-circle
                      </v-icon>
                    </td>
                  </tr>
                  <tr v-else>
                    <th>Apps & Books token</th>
                    <td>Not Setup</td>
                  </tr>
                </v-table></v-card-text
              ></v-card
            ></v-col
          >
        </v-row>

        <v-row>
          <v-col
            cols="12"
            v-if="
              data.content.windowsVersions &&
              data.content.windowsVersions.summarisedData
            "
          >
            <v-card>
              <v-card-title class="pb-2 mb-2">
                <v-row>
                  <v-col cols="auto"> Windows Versions </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="text-right">
                    <ToggleSwitch v-model="showAllDevices"></ToggleSwitch>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <VersionChart
                  v-if="showAllDevices"
                  class="mt-3"
                  :windowsVersions="
                    showAllDevices
                      ? data.content.windowsVersions
                      : data.content.windowsVersions30
                  "
                  :windowsUpdates="data.content.latestWindowsReleases"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
                <VersionChart
                  v-else
                  class="mt-3"
                  :windowsVersions="data.content.windowsVersions30"
                  :windowsUpdates="data.content.latestWindowsReleases"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="
              data.content.macOSVersions &&
              data.content.macOSVersions.summarisedData
            "
          >
            <v-card>
              <v-card-title class="pb-2 mb-2">
                <v-row>
                  <v-col cols="auto"> macOS Versions </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="text-right">
                    <ToggleSwitch v-model="showAllDevices"></ToggleSwitch>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <VersionChart
                  v-if="showAllDevices"
                  class="mt-3"
                  :macOSVersions="
                    showAllDevices
                      ? data.content.macOSVersions
                      : data.content.macOSVersions30
                  "
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
                <VersionChart
                  v-else
                  class="mt-3"
                  :macOSVersions="data.content.macOSVersions30"
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="
              data.content.iOSVersions &&
              data.content.iOSVersions.summarisedData
            "
          >
            <v-card>
              <v-card-title class="pb-2 mb-2">
                <v-row>
                  <v-col cols="auto"> iOS/iPadOS Versions </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <ToggleSwitch v-model="showAllDevices"></ToggleSwitch>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <VersionChart
                  v-if="showAllDevices"
                  class="mt-3"
                  :iOSVersions="data.content.iOSVersions"
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
                <VersionChart
                  v-else
                  class="mt-3"
                  :iOSVersions="data.content.iOSVersions30"
                  :appleUpdates="data.content.appleUpdates"
                  @clickDataPoint="clickHandler"
                ></VersionChart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="showBetaContent('CMD24')">
          <v-col v-if="windowsCisBenchmark.length" cols="12">
            <v-card>
              <v-card-title>Windows - Failed CIS Overview</v-card-title>
              <v-card-text>
                <v-table
                  hover
                  density="compact"
                  scrollable
                  height="300"
                  fixed-header
                >
                  <thead>
                    <tr>
                      <th class="font-weight-bold">Reference</th>
                      <th class="font-weight-bold">Name</th>
                      <th class="font-weight-bold">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cis in windowsCisBenchmark" :key="cis">
                      <td>{{ cis.cis }}</td>
                      <td>{{ cis.name }}</td>
                      <td>{{ cis.count }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="macOSCisBenchmark.length" cols="12">
            <v-card>
              <v-card-title>macOS - Failed CIS Overview</v-card-title>
              <v-card-text>
                <v-table
                  hover
                  density="compact"
                  scrollable
                  height="300"
                  fixed-header
                >
                  <thead>
                    <tr>
                      <th class="font-weight-bold">Reference</th>
                      <th class="font-weight-bold">Name</th>
                      <th class="font-weight-bold">Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cis in macOSCisBenchmark" :key="cis">
                      <td>{{ cis.cis }}</td>
                      <td>{{ cis.name }}</td>
                      <td>{{ cis.count }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-window-item>
  </v-window>
</template>
