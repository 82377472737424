<template>
  <!-- <v-select
    class="ma-0"
    hide-details="auto"
    v-if="appBar.type == 'select'"
    density="compact"
    variant="solo"
    theme="jigsawLight"
    :label="appBar.header"
    :items="appBar.items"
    v-model="appBar.selected"
  ></v-select> -->
  <v-col :cols="mobile ? '4' : '5'" class="pl-5" v-if="appBar.type == 'select'">
    <VSelectSearch
      :disabled="locked"
      class="ma-0"
      :items="appBar.items"
      no-data-text="Loading please wait..."
      :label="appBar.header"
      activateSearch
      v-model="appBar.selected"
      variant="solo"
      theme="jigsawLight"
      density="compact"
      hideDetails="auto"
    ></VSelectSearch>
  </v-col>
  <v-col
    cols="auto"
    v-if="appBar.showLock == 'yes' && isJigsawEmail(props.email)"
  >
    <v-icon v-if="locked" class="ma-2 pointer" @click="unlock">mdi-lock</v-icon>
    <v-icon v-else class="ma-2 pointer" @click="lock">mdi-lock-open</v-icon>
  </v-col>
</template>

<script setup>
import { appBarStore } from "@/stores/appBar";
import VSelectSearch from "@/components/VSelectSearch.vue";
import { storeToRefs } from "pinia";
import { defineProps, onMounted, ref } from "vue";
import { useDisplay } from "vuetify";
const { mobile } = useDisplay();

const props = defineProps({
  email: String,
});

function isJigsawEmail(email) {
  if (email.includes("@j24demo.co.uk") || email.includes("@jigsaw24.com")) {
    return true;
  }
  return false;
}

const appBar = appBarStore();
console.log(appBar);
// const { type, header } = storeToRefs(appBar);
//const { items } = storeToRefs(appBar);

function lock() {
  console.log("Lock");
  console.log(appBar.selected);
  localStorage.setItem(
    "lockAppBar" + appBar.appRunning,
    appBar.$state.selected
  );
  locked.value = true;
}

function unlock() {
  console.log("Unlock");
  localStorage.setItem("lockAppBar" + appBar.appRunning, "");
  locked.value = false;
}

const locked = ref(false);

appBar.$subscribe((mutation, state) => {
  console.log("Mutation", mutation);
  console.log("State", state);

  const localItem = localStorage.getItem("lockAppBar" + appBar.appRunning);
  console.log("Local Item", localItem);
  console.log("lockAppBar" + appBar.appRunning);
  if (localItem) {
    console.log("Menu is locked");
    appBar.selected = localItem;
    locked.value = true;
  }
});

// onMounted(() => {

// });
</script>
