<script setup>
import { onMounted, ref, reactive } from "vue";
import { apiQuery } from "@/api/j24api";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { load } from "webfontloader";
import VSelectSearch from "@/components/VSelectSearch.vue";
import ElevateUsers from "./ElevateUsers.vue";
import { faL } from "@fortawesome/free-solid-svg-icons";

const licences = reactive([]);
const customers = reactive([]);
const loading = ref(false);
const editing = ref(false);
const addingNew = ref(false);
const expandPrevious = ref(false);
const now = new Date();
const editItem = ref({});
const alertTxt = ref("");
const searchValue = ref("");

function searchFields() {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

// Have to do this to stop warning
const falseValue = false;
const trueValue = false;
const licenceTypes = ["standard", "enterprise"];

async function getData() {
  loading.value = true;
  try {
    const result = await apiQuery("ELA", "licences", {
      type: "Elevate",
      apiAction: "GetLicences",
    });
    console.log(result);
    licences.length = 0;
    licences.push(...result.licences);
    customers.length = 0;
    customers.push(...result.customers);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

const headers = [
  { text: "Name", value: "name", sortable: true },
  { text: "Count", value: "count", sortable: true },
  { text: "Type", value: "type", sortable: true },
  { text: "Creation Date", value: "creationDate", sortable: true },
  { text: "Start Date", value: "startdate", sortable: true },
  { text: "End Date", value: "enddate", sortable: true },
  { text: "Actions", value: "actions" },
];

onMounted(() => {
  getData();
});

function formatDate(date) {
  if (date == undefined) {
    return undefined;
  }
  let text = "";
  text = new Date(date).toLocaleString("en-GB");
  if (text == "Invalid Date") {
    return "";
  }
  return text;
}

function editLicence(licence) {
  editing.value = true;
  editItem.value = licence;
}

function addNew() {
  editItem.value = {
    enddate: new Date().toISOString(),
    startdate: new Date().toISOString(),
  };
  addingNew.value = true;
}

function cancel() {
  errorMessages.value = {};
  editing.value = false;
  addingNew.value = false;
  editItem.value = {};
}

const errorMessages = ref({});

function checkFields(_, clickedSave = false) {
  // Dont run validation unless user has clicked save yet

  if (!clickedSave && !Object.keys(errorMessages.value).length) {
    return true;
  }
  errorMessages.value = {};
  var validated = true;

  if (!editItem.value.name) {
    errorMessages.value.name = "Licence Name is required.";
    validated = false;
  }
  if (!editItem.value.newCustomer && !editItem.value.elaCustomerId) {
    errorMessages.value.elaCustomerId = "A Customer must be selected/created.";
    errorMessages.value.newCustomer = "A Customer must be selected/created.";
    validated = false;
  }

  if (!editItem.value.orderReference) {
    errorMessages.value.orderReference = "An order reference is required.";
    validated = false;
  }
  if (!editItem.value.count) {
    errorMessages.value.count = "A licence count is required";
    validated = false;
  }
  if (!editItem.value.type) {
    errorMessages.value.type = "Licence type must be selected";
    validated = false;
  }

  if (!editing.value) {
    if (licences.find((licence) => licence.name == editItem.value.name)) {
      errorMessages.value.name = "Duplicate Licence Name";
    }
  }

  return validated;
}

async function save() {
  if (!checkFields("", true)) {
    return;
  }

  loading.value = true;
  console.log(editItem.value);
  //console.log(editItem.value.enddate.toISOString());
  try {
    let enddate = new Date(editItem.value.enddate);
    let startdate = new Date(editItem.value.startdate);
    editItem.value = {
      ...editItem.value,
      enddate: enddate.toISOString(),
      startdate: startdate.toISOString(),
    };

    if (editItem.value.newCustomer) {
      try {
        const result = await apiQuery("J24Auth", "elevateLicences", {
          apiAction: "createCustomer",
          name: editItem.value.newCustomer,
        });
        console.log(result);
        editItem.value.elaCustomerId = result._id;
      } catch (error) {
        alertTxt.value =
          "Failed to create customer - check customer does not already exist";
        loading.value = false;
        return;
      }
    }
    // if (!editItem.value.elaCustomerId) {
    //   return;
    // }

    if (editing.value) {
      await apiQuery("ELA", "licences", {
        type: "Elevate",
        apiAction: "UpdateLicence",
        updatedLicence: editItem.value,
      });
    }

    if (addingNew.value) {
      const result = await apiQuery("ELA", "licences", {
        type: "Elevate",
        apiAction: "CreateNewCustomer",
        newLicence: editItem.value,
      });
      console.log(result);
      if (result.licence) {
        editItem.value = result;
        // Switch mode to editing so that if any subsequent
        // changes are made we do not add another licence record
        editing.value = true;
        addingNew.value = false;
      }
    }
  } catch (error) {
    console.log(error);
  }

  getData();
}

async function newLicenceKey() {
  loading.value = true;
  try {
    let enddate = new Date(editItem.value.enddate);
    let startdate = new Date(editItem.value.startdate);
    editItem.value = {
      ...editItem.value,
      enddate: enddate.toISOString(),
      startdate: startdate.toISOString(),
    };
    const result = await apiQuery("ELA", "licences", {
      type: "Elevate",
      apiAction: "CreateNewLicence",
      newLicence: editItem.value,
    });
    if (result.licence) {
      editItem.value = result;
    }
  } catch (error) {
    console.log(error);
  }

  loading.value = false;
}

const selectedTab = ref("licence");
</script>
<template>
  <v-row v-if="alertTxt">
    <v-col>
      <v-alert class="pa-3" color="error">{{ alertTxt }}</v-alert>
    </v-col>
  </v-row>

  <template v-if="!editing && !addingNew">
    <v-row>
      <v-col>
        <v-btn @click="addNew()">Add New Licence</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field
          class=""
          density="compact"
          label="Search"
          v-model="searchValue"
          variant="underlined"
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <EasyDataTable
          :headers="headers"
          :items="licences"
          theme-color="#e7541e"
          alternating
          buttons-pagination
          :loading="loading"
          :search-field="searchFields()"
          :search-value="searchValue"
        >
          <template #item-creationDate="item">
            {{ formatDate(item.creationDate) }}
          </template>
          <template #item-startdate="item">
            {{ formatDate(item.startdate) }}
          </template>
          <template #item-enddate="item">
            {{ formatDate(item.enddate) }}
          </template>
          <template #item-actions="item">
            <div>
              <v-icon @click="editLicence(item)">mdi-pencil</v-icon>
            </div>
          </template>
        </EasyDataTable>
      </v-col>
    </v-row>
  </template>

  <template v-if="editing || addingNew">
    <v-tabs v-model="selectedTab" color="#e7541e" class="mb-3">
      <v-tab value="licence">Licence</v-tab>
      <v-tab v-if="!addingNew" value="users">Reporting Users</v-tab>
    </v-tabs>

    <v-window v-model="selectedTab">
      <v-window-item value="licence">
        <v-row>
          <v-col cols="6">
            <v-text-field
              density="compact"
              label="Licence Name"
              v-model="editItem.name"
              :readonly="editing"
              :error-messages="errorMessages.name"
              :rules="[checkFields]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <VSelectSearch
              label="Select Associated Customer"
              :items="customers"
              itemTitle="name"
              itemValue="_id"
              v-model="editItem.elaCustomerId"
              :activateSearch="trueValue"
              :error-messages="errorMessages.elaCustomerId"
              :rules="[checkFields]"
              density="compact"
            ></VSelectSearch>
          </v-col>
        </v-row>
        <v-row v-if="addingNew">
          <v-col cols="6">
            If Customer is not listed create a new customer here
            <br />
            <v-text-field
              density="compact"
              label="New Customer Name"
              v-model="editItem.newCustomer"
              :error-messages="errorMessages.newCustomer"
              :rules="[checkFields]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              density="compact"
              label="Order Reference"
              v-model="editItem.orderReference"
              :error-messages="errorMessages.orderReference"
              :rules="[checkFields]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              density="compact"
              label="Licence Count"
              v-model="editItem.count"
              :rules="[checkFields]"
              :error-messages="errorMessages.count"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              label="Licence Type"
              density="compact"
              :items="licenceTypes"
              v-model="editItem.type"
              :rules="[checkFields]"
              :error-messages="errorMessages.type"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-card>
              <v-card-title>Start Date</v-card-title>
              <VueDatePicker
                class="pa-3"
                v-model="editItem.startdate"
                inline
                auto-apply
                :enable-time-picker="falseValue"
                :month-change-on-scroll="false"
              ></VueDatePicker>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card>
              <v-card-title>End Date</v-card-title>
              <VueDatePicker
                class="pa-3"
                v-model="editItem.enddate"
                inline
                auto-apply
                :enable-time-picker="falseValue"
                :month-change-on-scroll="false"
              ></VueDatePicker>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-if="editItem.key">
          <v-col cols="6">
            <v-text-field
              density="compact"
              label="Key"
              v-model="editItem.key"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="editItem.licence">
          <v-col cols="6">
            <v-textarea
              density="compact"
              label="Licence"
              v-model="editItem.licence"
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="editItem.previousLicenses">
          <v-col cols="6">
            <v-expansion-panels>
              <v-expansion-panel title="Previous Licenses">
                <v-expansion-panel-text>
                  <v-row
                    v-for="licence in editItem.previousLicenses"
                    :key="licence"
                  >
                    <v-col>
                      <v-text-field
                        density="compact"
                        label="Name"
                        v-model="licence.name"
                        readonly
                      ></v-text-field>
                      <v-text-field
                        density="compact"
                        label="Start Date"
                        v-model="licence.startdate"
                        readonly
                      ></v-text-field>
                      <v-text-field
                        density="compact"
                        label="End Date"
                        v-model="licence.enddate"
                        readonly
                      ></v-text-field>
                      <v-text-field
                        density="compact"
                        label="Count"
                        v-model="licence.count"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-textarea
                        rows="8"
                        v-model="licence.licence"
                        readonly
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-btn :loading="loading" @click="save()">Save</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-if="editing" @click="newLicenceKey()"
              >Create New Licence</v-btn
            >
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="3" class="text-right">
            <v-btn @click="cancel()">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="users">
        <ElevateUsers :licence="editItem"></ElevateUsers>
      </v-window-item>
    </v-window>
  </template>
</template>

<style>
.dp__select {
  color: black;
}
</style>
