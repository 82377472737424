<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";
import ElaComputerDetail from "./ElaComputerDetailV2.vue";
const appBar = appBarStore();
import { storeToRefs } from "pinia";
import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();
const mdmServerType = ref("");
const columnsMenu = ref(false);
import ElaDashboardDrillDown from "./ElaDashboardDrillDown.vue";
import { useDisplay } from "vuetify";
const { mobile } = useDisplay();

const { type } = storeToRefs(mdmServer);

const headers = reactive([
  { text: "Device Name", value: "general.name", sortable: true, show: true },
  {
    text: "Last Contact Time",
    value: "general.lastContactTime",
    sortable: true,
    show: true,
  },
]);

const defaultCols = [
  { text: "Device Name", value: "general.name", sortable: true },
  {
    text: "Last Contact Time",
    value: "general.lastContactTime",
    sortable: true,
  },
];

function resetCols() {
  headers.length = 0;
  headers.push(...defaultCols);
  headers.forEach((header) => {
    availableHeaders.forEach((aHeader) => {
      if (header.value == aHeader.value) {
        availableHeaders.show == header.show;
      }
    });
  });
  localStorage.setItem("cmd24ComputersCols", JSON.stringify(headers));
}

function translateIos(devices) {
  const newDevices = [];
  devices.forEach((device) => {
    newDevices.push({
      type: "mobileDevice",
      id: device.id,
      general: {
        name: device.name,
      },
      hardware: {
        serialNumber: device.serialNumber,
        model: device.model,
        modelIdentifier: device.modelIdentifier,
      },
      userAndLocation: {
        username: device.username,
      },
    });
  });
  return newDevices;
}

function translateIosIntune(devices) {
  const newDevices = [];
  devices.forEach((device) => {
    newDevices.push({
      type: "mobileDevice",
      id: device.id,
      general: {
        name: device.name,
        lastEnrolledDate: device.lastEnrollmentTimestamp,
        lastContactTime: device.lastInventoryUpdateTimestamp,
      },
      hardware: {
        serialNumber: device.serialNumber,
        model: device.model,
        modelIdentifier: device.model,
      },
      userAndLocation: {
        username: device.username,
        realname: device.location.username,
        position: "N/A",
        email: device.username,
      },
      operatingSystem: {
        name: device.operatingSystem.name,
      },
    });
  });
  return newDevices;
}

function translateKandji(devices) {
  const newDevices = [];
  devices.forEach((device) => {
    newDevices.push({
      type: device.general.platform == "Mac" ? "computer" : "mobileDevice",
      id: device.JSS_Computer_ID,
      general: {
        name: device.Computer_Name,
        lastEnrolledDate: device.Last_Enrollment,
        lastContactTime: device.Last_Check_in,
      },
      hardware: {
        serialNumber: device.Serial_Number,
        model: device.Model,
        modelIdentifier: device.Model_Identifier,
      },
      userAndLocation: {
        username: device.Username,
        realname: device.Full_name,
        position: "N/A",
        email: "",
      },
      operatingSystem: {
        name: device.Operating_System_Version,
      },
    });
  });
  return newDevices;
}

const availableHeaders = reactive([
  { text: "ID", value: "id", sortable: true, show: false },
  { text: "Device Name", value: "general.name", sortable: true, show: true },
  {
    text: "Serial Number",
    value: "hardware.serialNumber",
    sortable: true,
    show: false,
  },
  { text: "Asset Tag", value: "general.assetTag", sortable: true, show: false },
  {
    text: "Last Contact Time",
    value: "general.lastContactTime",
    sortable: true,
    show: true,
  },
  {
    text: "Last Enrollment Date",
    value: "general.lastEnrolledDate",
    sortable: true,
    show: false,
  },
  { text: "Model", value: "hardware.model", sortable: true, show: false },
  {
    text: "Model Identifier",
    value: "hardware.modelIdentifier",
    sortable: true,
    show: false,
  },
  {
    text: "Username",
    value: "userAndLocation.username",
    sortable: true,
    show: false,
  },
  {
    text: "Full Name",
    value: "userAndLocation.realname",
    sortable: true,
    show: false,
  },
  {
    text: "Email",
    value: "userAndLocation.email",
    sortable: true,
    show: false,
  },
  {
    text: "Position",
    value: "userAndLocation.position",
    sortable: true,
    show: false,
  },
]);

var doNotUpdateHeaders = false;
watch(availableHeaders, (currentValue) => {
  if (!doNotUpdateHeaders) {
    //console.log("Available header changed", currentValue);
    currentValue.forEach((col) => {
      if (col.show) {
        if (!headers.find((h) => h.value == col.value)) {
          headers.push(col);
        }
      } else {
        headers.forEach((current, index) => {
          if (current.value == col.value) {
            headers.splice(index, 1);
          }
        });
      }
    });
    localStorage.setItem("cmd24ComputersCols", JSON.stringify(headers));
  }
});

const items = reactive([]);
const loading = ref(false);
const selectedComputer = ref(false);
const alertShow = ref(false);
const alertTxt = ref("");
const searchValue = ref("");
const reportIds = ref([]);

appBar.$subscribe((mutation, state) => {
  //console.log("Change Triggered", mutation);
  //console.log(state);
  if (appBar.selected != "") {
    mdmServerType.value = mdmServer.$state.type;
    getData();
  }
});

async function getData() {
  reportIds.value.length = 0;
  try {
    if (appBar.selected != "") {
      loading.value = true;
      if (mdmServerType.value == "Jamf" || mdmServerType.value == "Kandji") {
        const dashboardData = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "dashboard",
          maxage: 86400,
        });
        let dashReportIds = [];
        if (dashboardData.content?.macOSAll?.records) {
          dashReportIds.push(dashboardData.content.macOSAll.records);
        }
        if (dashboardData.content?.iOSAll?.records) {
          dashReportIds.push(dashboardData.content.iOSAll.records);
        }
        reportIds.value.push(...dashReportIds);
      }
      if (mdmServer.$state.type == "Intune") {
        const computers = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "computers",
          maxage: 86400,
        });

        items.length = 0;
        items.push(...computers.result.results);

        const devices = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "devices",
          maxage: 86400,
        });
        items.push(...translateIosIntune(devices.result.results));
      }
    }
  } catch (error) {
    console.log(error);
    alertShow.value = true;
    alertTxt.value = "Error - Unable to get computers.";
  }
  loading.value = false;
}

const selectComputer = (computer) => {
  selectedComputer.value = computer;
};

function searchFields() {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

onMounted(() => {
  mdmServerType.value = mdmServer.$state.type;
  getData();
  var localHeaders = localStorage.getItem("cmd24ComputersCols");
  if (localHeaders) {
    doNotUpdateHeaders = true;
    try {
      localHeaders = JSON.parse(localHeaders);
      if (localHeaders.length) {
        headers.length = 0;
        headers.push(...localHeaders);
      }
      localHeaders.forEach((lHeader) => {
        availableHeaders.forEach((header, index) => {
          if (lHeader.value == header.value) {
            availableHeaders[index].show = lHeader.show;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
    doNotUpdateHeaders = false;
  }
});
</script>

<template>
  <template v-if="!appBar.selected">
    <v-card class="mx-auto mt-16" max-width="500" v-if="!appBar.selected">
      <v-card-title>No MDM Selected</v-card-title>
      <v-spacer></v-spacer>
      <v-card-text
        >Please select an MDM Server from the menu above.</v-card-text
      >
    </v-card>
  </template>

  <template v-if="loading">
    <v-card class="mx-auto mt-16" max-width="500" min-width="300">
      <v-card-title>Fetching Data</v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-progress-circular
              indeterminate
              color="#eb5d0b"
              :size="83"
            ></v-progress-circular>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row
          ><v-col>
            {{ updatingTxt }}
          </v-col></v-row
        >
      </v-card-text>
    </v-card>
  </template>

  <template v-if="selectedComputer">
    <ela-computer-detail
      :data="selectedComputer"
      @back="selectedComputer = false"
    />
  </template>
  <template v-if="!selectedComputer">
    <v-alert
      class="my-3"
      v-model="alertShow"
      type="error"
      closable
      density="compact"
      >{{ alertTxt }}</v-alert
    >

    <template v-if="!loading">
      <template v-if="mdmServerType == 'Intune'">
        <v-row>
          <v-col>
            <v-text-field
              class="pl-1"
              density="compact"
              label="Search"
              v-model="searchValue"
              variant="underlined"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn prepend-icon="mdi-format-columns"
              >{{ columnsMenu ? "Close" : "Columns" }}
              <v-menu
                activator="parent"
                v-model="columnsMenu"
                :close-on-content-click="false"
              >
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-btn
                        prepend-icon="mdi-refresh"
                        variant="text"
                        @click="resetCols()"
                        >Reset</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    v-for="(col, index) in availableHeaders"
                    :key="col.value"
                  >
                    <v-list-item-action>
                      <v-checkbox-btn
                        v-model="availableHeaders[index].show"
                        :label="col.text"
                      ></v-checkbox-btn>
                    </v-list-item-action>

                    <!-- <v-list-item-action-text
                  >{{ col.text }}{{ index }}</v-list-item-action-text
                > -->
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </v-col>
        </v-row>
        <EasyDataTable
          table-class-name="customize-table"
          :search-field="searchFields()"
          :search-value="searchValue"
          :headers="headers"
          :items="items"
          :loading="loading"
          theme-color="#e7541e"
          alternating
          @click-row="selectComputer"
        />
      </template>
      <template v-else>
        <ElaDashboardDrillDown
          :class="mobile ? 'pa-0 ma-0' : ''"
          v-if="reportIds.length"
          :mdmServerId="appBar.selected"
          :reportId="reportIds"
          :hideExit="true"
        ></ElaDashboardDrillDown>
      </template>
    </template>
  </template>
</template>

<style scoped>
.v-sheet--border {
  v-border-color: red;
}
.v-card-title {
  font-weight: 700;
}

.customize-table {
  --easy-table-header-font-color: #000000;
  --easy-table-body-row-font-color: #000000;
  --easy-table-body-even-row-font-color: #000000;
}
</style>
