<script setup>
import { onMounted, reactive, ref } from "vue";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { appBarMobileStore } from "@/stores/appBarMobile";
const appBarMobile = appBarMobileStore();

import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();

async function getMDMServers() {
  try {
    const mdmservers = await apiQuery("ELA", "cmd24/mdmservers", {});
    console.log(result);
    var result = [];
    mdmservers.forEach((server) => {
      // Only add those that have data collection enabled
      if (server.options?.cmd24DataCollection) {
        result.push({
          title: server.name,
          value: server._id,
          options: server.options,
          type: server.type,
        });
      }
    });
    return result;
  } catch (error) {
    console.log(error);
    return;
  }
}

appBar.$subscribe(() => {
  console.log("MDM Server Changed");
  if (appBar.selected != "") {
    const match = appBar.items.find(
      (server) => server.value == appBar.selected
    );
    if (match) {
      mdmServer.$patch(match);
    }
  }
});

onMounted(async () => {
  if (appBarMobile.showMenuIcon) {
    showNav.value = false;
  } else {
    showNav.value = true;
  }
  if (appBar.appRunning != "ELA") {
    appBar.$reset();
    appBar.$patch({
      appRunning: "ELA",
      type: "select",
      header: "Loading Please Wait...",
      items: [],
      selected: "",
      showLock: "yes",
    });
    // Try Local Items first
    var mdmServersLocal = localStorage.getItem("mdmServers");
    try {
      mdmServersLocal = JSON.parse(mdmServersLocal);
      if (mdmServersLocal) {
        if (!appBar.selected && mdmServersLocal.length == 1) {
          appBar.$patch({
            items: mdmServersLocal,
            header: "Select MDM",
            selected: mdmServersLocal[0].value,
          });
        } else {
          appBar.$patch({
            items: mdmServersLocal,
            header: "Select MDM",
          });
        }
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("mdmServers");
    }

    const mdmServers = await getMDMServers();
    if (!appBar.selected && mdmServers.length == 1) {
      appBar.$patch({
        items: mdmServers,
        header: "Select MDM",
        selected: mdmServers[0].value,
      });
    } else {
      appBar.$patch({
        items: mdmServers,
        header: "Select MDM",
      });
    }

    localStorage.setItem("mdmServers", JSON.stringify(mdmServers));
  }
});

const items = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    link: "ElaDashboardOverview",
    value: false,
  },
  // {
  //   title: "Dashboard",
  //   icon: "mdi-view-dashboard",
  //   link: "ElaDashboard",
  //   value: false,
  // },
  {
    title: "Devices",
    icon: "mdi-cellphone-link",
    link: "ElaComputers",
    value: false,
  },
];

// Required to make the router-view reload when user clicks the link again
// See the key value set on <router-view> below.
const randomKey = ref("");
function updateRandom() {
  if (appBarMobile.showMenuIcon) {
    appBarMobile.showMenu = false;
    showNav.value = false;
  }

  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}

const showNav = ref(false);
appBarMobile.$subscribe((mutation, state) => {
  if (!appBarMobile.showMenuIcon) {
    showNav.value = true;
  } else {
    showNav.value = state.showMenu;
  }
});
</script>

<template>
  <v-navigation-drawer
    v-model="showNav"
    permanent
    width="190"
    theme="jigsawDark"
  >
    <v-list>
      <!-- <div class="v-list-item pl-0 ml-2">
        <v-img
          class="pa-0 ml-0"
          :width="45"
          src="@/assets/command24Icon.png"
        ></v-img>
        <div class="text-h7 pl-2 cursor-default">
          <strong>Command 24</strong>
        </div>
      </div> -->
      <template v-for="item in items" :key="item.title">
        <v-list-item link :to="{ name: item.link }" @click="updateRandom()">
          <template v-slot:prepend>
            <v-icon class="pa-0 mr-3">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title class="pa-0">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid class="pa-0 ma-0">
      <router-view :key="randomKey" />
    </v-container>
  </v-main>
</template>
