<script setup>
import { onMounted, ref } from "vue";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();
const items = ref([]);
import router from "@/router";

permissions.$subscribe(() => {
  buildSidebarItems();
  loadFirstPage();
});

function buildSidebarItems() {
  items.value.length = 0;
  allItems.forEach((item) => {
    if (permissions.$state["ElaAdmin-" + item.privName]) {
      items.value.push(item);
    }
  });
}

onMounted(async () => {
  console.log("Curent Route", router.currentRoute.value.name);
  if (appBar.appRunning != "ELAAdmin") {
    appBar.$patch({
      appRunning: "ELAAdmin",
      type: "None",
      items: [],
      selected: "",
    });
  }
  buildSidebarItems();

  loadFirstPage();
});

function loadFirstPage() {
  if (!router.currentRoute.value.name) {
    if (items.value.length > 0) {
      router.push({ name: items.value[0].link });
    }
  }
}

const allItems = [
  // { title: "Assets", icon: "mdi-archive", link: "AssetsBulkUploader" },
  {
    title: "MDM Servers",
    icon: "mdi-server",
    link: "ElaAdminMdmServers",
    privName: "MdmServers",
    value: false,
  },
  {
    title: "Users",
    icon: "mdi-account-multiple",
    link: "ElaAdminUsers",
    privName: "Users",
    value: false,
  },
  {
    title: "Elevate Licences",
    icon: "mdi-certificate-outline",
    link: "ElaAdminElevateLicences",
    privName: "ElevateLicences",
    value: false,
  },
  {
    title: "Agent Licences",
    icon: "mdi-certificate-outline",
    link: "ElaAdminAgentLicences",
    privName: "ElevateLicences",
    value: false,
  },
  {
    title: "Integrations Managment",
    icon: "mdi-puzzle-outline",
    link: "ElaAdminIntegrationsManagement",
    privName: "IntegrationsManagement",
    value: false,
  },
];

// Required to make the router-view reload when user clicks the link again
// See the key value set on <router-view> below.
const randomKey = ref("");
function updateRandom() {
  console.log("Current Route", router.currentRoute.value.name);
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}
</script>

<template>
  <v-navigation-drawer permanent width="190" theme="jigsawDark">
    <v-list>
      <div class="v-list-item">
        <v-icon class="pa-3" color="#e7541e">mdi-cog</v-icon>
        <div class="text-h7 pa-3 cursor-default">
          <strong>Management</strong>
        </div>
      </div>
      <template v-for="item in items" :key="item.title">
        <v-list-item
          link
          :to="{ name: item.link }"
          active-color="#e7541e"
          @click="updateRandom()"
        >
          <template v-slot:prepend>
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title class="pl-n4">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view :key="randomKey" />
    </v-container>
  </v-main>
</template>
