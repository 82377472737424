<script setup>
import { apiQuery } from "@/api/j24api";
import { ref, reactive, defineProps, watch, onMounted } from "vue";
import PasscodeField from "@/views/ela/components/PasscodeField.vue";

import Jamf from "./mdmTemplates/JamfCommands";

import Intune from "./mdmTemplates/IntuneCommands";
import Kandji from "./mdmTemplates/KandjiCommands";

import { appBarStore } from "@/stores/appBar";

import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();

import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();

const appBar = appBarStore();
const loading = ref(false);

const props = defineProps({
  data: Object,
  serialNumber: String,
});
const completed = reactive([]);
const pending = reactive([]);
const failed = reactive([]);
const elaCommands = reactive([]);
const selectedTab = ref("Pending");

const elaCommandsHeaders = [
  {
    text: "Time",
    value: "dateFormatted",
  },
  {
    text: "Command",
    value: "command",
  },
  {
    text: "Status",
    value: "statusTxt",
  },
  { text: "", value: "passcode" },
];

function populateElaCommands(result) {
  elaCommands.length = 0;
  if (result.elaLogs) {
    result.elaLogs.forEach((log) => {
      if (log.command) {
        let dateFormatted = new Date(log.date);
        var newLog = {
          dateFormatted: dateFormatted.toLocaleString("en-GB"),
          statusTxt: log.status == "Sent" ? "Sent to MDM" : log.status,
          showPasscode: false,
          ...log,
        };
        if (newLog.command == "lock") {
          newLog.command = "Lock Device";
        }
        if (newLog.command == "erase") {
          newLog.command = "Erase Device";
        }
        elaCommands.push(newLog);
      }
    });
  }
}

function translateKandjiCommands(commands) {
  console.log("Kandji Commands", commands);
  commands.results.forEach((command) => {
    switch (command.status) {
      case 1:
      case 2:
      case 5:
        {
          let issued = new Date(command.date_requested);
          command.issued = issued.toLocaleString("en-GB");
          let lastPush = new Date(command.last_pushed);
          command.last_push = lastPush.toLocaleString("en-GB");
          command.name = command.command_type;
          pending.push(command);
        }
        break;
      case 3:
        {
          let completedDate = new Date(command.date_completed);
          command.completed = completedDate.toLocaleString("en-GB");
          command.name = command.command_type;
          completed.push(command);
        }
        break;
      case 4:
        {
          let completedDate = new Date(command.date_completed);
          command.completed = completedDate.toLocaleString("en-GB");
          command.name = command.command_type;
          failed.push(command);
        }
        break;
    }
  });
}

async function getData() {
  loading.value = true;
  //setup();
  switch (mdmServer.$state.type) {
    case "Kandji":
      try {
        //var result;
        completed.length = 0;
        pending.length = 0;
        failed.length = 0;

        result = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "commands",
          id: props.data.id,
          maxage: 0,
        });
        translateKandjiCommands(result.commands);
        populateElaCommands(result);
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
      break;
    case "Jamf":
      try {
        var result;
        completed.length = 0;
        pending.length = 0;
        failed.length = 0;

        if (props.data.type == "mobileDevice") {
          result = await apiQuery("ELA", "cmd24/query", {
            mdmServerId: appBar.selected,
            dataRequested: "commands",
            mobileDeviceId: props.data.id,
            maxage: 0,
          });
          result.result.mobile_device_history.management_commands.completed.forEach(
            (command, index) => {
              result.result.mobile_device_history.management_commands.completed[
                index
              ].completed = command.date_time_completed;
            }
          );
          result.result.mobile_device_history.management_commands.pending.forEach(
            (command, index) => {
              result.result.mobile_device_history.management_commands.pending[
                index
              ].issued = command.date_time_issued;
            }
          );
          completed.push(
            ...result.result.mobile_device_history.management_commands.completed
          );
          pending.push(
            ...result.result.mobile_device_history.management_commands.pending
          );
          failed.push(
            ...result.result.mobile_device_history.management_commands.failed
          );
        } else {
          result = await apiQuery("ELA", "cmd24/query", {
            mdmServerId: appBar.selected,
            dataRequested: "commands",
            id: props.data.id,
            maxage: 0,
          });
          completed.push(...result.result.computer_history.commands.completed);
          pending.push(...result.result.computer_history.commands.pending);
          failed.push(...result.result.computer_history.commands.failed);
        }
        populateElaCommands(result);
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
      break;
    case "Intune":
      try {
        const result = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "commands",
          id: props.data.id,
          maxage: 0,
        });

        completed.length = 0;
        pending.length = 0;
        failed.length = 0;

        completed.push(...(await IntuneCommandTranslator("done", result)));
        pending.push(...(await IntuneCommandTranslator("pending", result)));
        failed.push(...(await IntuneCommandTranslator("failed", result)));
        populateElaCommands(result);
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
      break;
  }
}

async function IntuneCommandTranslator(state, commands) {
  //// Translates the Intune commands output to conform with the existing method of Vue input.

  var array = [];
  var json;
  var startDateTime;
  var lastUpdatedDateTime;

  switch (state) {
    case "pending":
      commands.forEach((command) => {
        if (command.actionState === state) {
          startDateTime = new Date(command.startDateTime).toLocaleString(
            "en-GB"
          );
          lastUpdatedDateTime = new Date(
            command.lastUpdatedDateTime
          ).toLocaleString("en-GB");

          json = {
            issued: startDateTime,
            last_push: lastUpdatedDateTime,
            name: formatText(command.actionName),
            status: formatText(command.actionState),
          };

          array.push(json);
        }
      });
      break;
    case "done":
      commands.forEach((command) => {
        if (command.actionState === state) {
          lastUpdatedDateTime = new Date(
            command.lastUpdatedDateTime
          ).toLocaleString("en-GB");

          if (command.actionName === "remoteLock" && command.unlockPin) {
            json = {
              completed: lastUpdatedDateTime,
              name: "Remote Lock - Unlock PIN: " + command.unlockPin,
            };
          } else {
            json = {
              completed: lastUpdatedDateTime,
              name: formatText(command.actionName),
            };
          }

          array.push(json);
        }
      });
      break;
    case "failed":
      commands.forEach((command) => {
        if (command.actionState === state) {
          lastUpdatedDateTime = new Date(
            command.lastUpdatedDateTime
          ).toLocaleString("en-GB");

          json = {
            completed: lastUpdatedDateTime,
            name: formatText(command.actionName),
          };

          array.push(json);
        }
      });
      break;
    default:
      console.error("Cannot translate commands");
  }

  return array;
}

function formatText(string) {
  //// To cleanup Intune output, capitalises the first letter, then adds a space after every uppercase letter.
  var capitalise;
  var finalWord;
  capitalise = string.charAt(0).toUpperCase() + string.slice(1);
  finalWord = capitalise.replace(/([A-Z])/g, " $1").trim();
  return finalWord;
}

var dialogButtonPressedActions = {};
var setupDialog = {};

function setup() {
  commands.length = 0;
  switch (mdmServer.$state.type) {
    case "Kandji":
      if (props.data.type == "mobileDevice") {
        console.log("Kandji Mobile Device");
        commands.push(...Kandji.mobileCommands);
        setupDialog = Kandji.setupDialogMobile;
        dialogButtonPressedActions = Kandji.dialogButtonPressedMobile;
      } else {
        commands.push(...Kandji.commands);
        setupDialog = Kandji.setupDialog;
        dialogButtonPressedActions = Kandji.dialogButtonPressed;
      }
      break;
    case "Jamf":
      if (props.data.type == "mobileDevice") {
        console.log("Jamf Mobile Device");
        commands.push(...Jamf.mobileCommands);
        dialogButtonPressedActions = Jamf.dialogButtonPressedMobile;
        setupDialog = Jamf.setupDialogMobile;
      } else {
        console.log("Jamf Computer");
        commands.push(...Jamf.commands);
        dialogButtonPressedActions = Jamf.dialogButtonPressed;
        setupDialog = Jamf.setupDialog;
      }
      break;
    case "Intune":
      console.log("Intune Device");
      if (props.data.operatingSystem.name === "Windows") {
        commands.push(...Intune.windowsCommands);
      }
      if (props.data.operatingSystem.name === "macOS") {
        commands.push(...Intune.macosCommands);
      }
      if (props.data.operatingSystem.name === "iOS") {
        commands.push(...Intune.iosCommands);
      }
      if (props.data.operatingSystem.name === "iPadOS") {
        commands.push(...Intune.iosCommands);
      }
      dialogButtonPressedActions = Intune.dialogButtonPressed;
      setupDialog = Intune.setupDialog;
      break;
    // }
  }
}

const commands = reactive([]);

onMounted(async () => {
  getData();
  await setup();
  if (permissions.$state) {
    const newCommands = [];
    commands.forEach((command, index) => {
      if (permissions.$state["CMD24-" + command.permission]) {
        newCommands.push(command);
      }
    });
    commands.length = 0;
    commands.push(...newCommands);
  }
});

const dialog = ref({});
dialog.value.Show = ref(false);
dialog.value.Action = ref("");
dialog.value.Title = ref("");
dialog.value.Text = ref("");
dialog.value.Text2 = ref("");
dialog.value.TxtField = ref(false);
dialog.value.TxtFieldLabel = ref("");
dialog.value.TxtFieldValue = ref("");
dialog.value.TxtField2 = ref(false);
dialog.value.TxtFieldLabel2 = ref("");
dialog.value.TxtFieldValue2 = ref("");
dialog.value.Button = ref("");
dialog.value.ButtonDisabled = ref(false);
dialog.value.StatusTxt = ref("");

function resetDialog() {
  dialog.value = {};
}

async function dialogButtonPressed() {
  var autoClose = true;
  try {
    switch (mdmServer.$state.type) {
      case "Kandji":
        dialog.value = await dialogButtonPressedActions(
          dialog.value,
          appBar.selected,
          props.data.id
        );
        if (!dialog.value.command) {
          return;
        } else {
          const result = await apiQuery("ELA", "cmd24/command", {
            serialNumber: props.serialNumber,
            ...dialog.value.command,
          });
          console.log(result);
          dialog.value.StatusTxt = "Command Sent";
          if (dialog.value.Action == "LockDevice" && result.PIN) {
            dialog.value.v2 = {
              confirm: {
                type: "textfield",
                label: "LOCK",
                preText: "The PIN to unlock the device is",
                value: result.PIN,
              },
            };
            autoClose = false;
          }
          if (dialog.value.Action == "GetFileVaultKey" && result.recoveryKey) {
            dialog.value.Text = "FileVault Key: " + result.recoveryKey;
            dialog.value.StatusTxt = "Retrived Key";
            autoClose = false;
          }
          await sleep(5000);
        }
        break;
      case "Jamf":
        console.log("Jamf");
        dialog.value = await dialogButtonPressedActions(
          dialog.value,
          appBar.selected,
          props.data.id
        );
        if (!dialog.value.command) {
          return;
        } else {
          const result = await apiQuery("ELA", "cmd24/command", {
            serialNumber: props.serialNumber,
            ...dialog.value.command,
          });
          console.log(result);
          console.log(dialog.value);
          dialog.value.StatusTxt = "Command Sent";
          if (dialog.value.Action == "GetFileVaultKey" && result.recoveryKey) {
            dialog.value.Text = "FileVault Key: " + result.recoveryKey;
            dialog.value.StatusTxt = "Retrieved Key";
            autoClose = false;
          }
          await sleep(5000);
        }
        break;
      case "Intune":
        console.log("Intune");
        dialog.value = await dialogButtonPressedActions(
          dialog.value,
          appBar.selected,
          props.data.id
        );
        if (!dialog.value.command) {
          return;
        } else {
          const result = await apiQuery("ELA", "cmd24/command", {
            serialNumber: props.serialNumber,
            ...dialog.value.command,
          });
          console.log(result);
          dialog.value.StatusTxt = "Command Sent";
          if (dialog.value.Action == "getFileVaultKey" && result.recoveryKey) {
            dialog.value.Text = "FileVault Key: " + result.recoveryKey;
            dialog.value.StatusTxt = "Retrieved Key";
            autoClose = false;
          }
          await sleep(5000);
        }
        break;
    }
  } catch (error) {
    console.log(error);
    if (error.message && error.message.includes("429")) {
      dialog.value.StatusTxt = "ERROR - Too many commands sent in 24 hr period";
    } else {
      dialog.value.StatusTxt = "ERROR - Unable to send command";
    }
    await sleep(5000);
  }
  if (autoClose) {
    resetDialog();
  }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function sendCommand(value) {
  resetDialog();
  dialog.value.Show = true;
  dialog.value.Button = "Send Command";
  dialog.value = setupDialog(value, dialog.value);
  // const result = await apiQuery("ELA", "cmd24/command", {
  //   mdmServerId: appBar.selected,
  //   command: value,
  //   computerId: props.data.id,
  // });
  // console.log(result);
}

const commandsHeaders = [
  {
    text: "Time",
    value: "completed",
  },
  {
    text: "Command Name",
    value: "name",
  },
];

const pendingHeaders = [
  {
    text: "Issued",
    value: "issued",
  },
  {
    text: "Last Push",
    value: "last_push",
  },
  {
    text: "Command Name",
    value: "name",
  },
  { text: "Status", value: "status" },
];
</script>

<template>
  <v-card class="ma-2">
    <v-row>
      <v-col cols="12" sm="3">
        <v-list density="compact">
          <v-list-subheader>Send Command</v-list-subheader>
          <v-list-item
            v-for="command in commands"
            :key="command.value"
            :value="command.value"
            @click="sendCommand(command.value)"
            :title="command.title"
          >
            <template v-slot:prepend>
              <v-icon :icon="command.icon"></v-icon>
            </template>
            <!-- <v-list-item-title v-text="command.title"></v-list-item-title> -->
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="9">
        <v-tabs v-model="selectedTab" color="#e7541e">
          <v-tab value="Pending">Pending {{ pending.length }}</v-tab>
          <v-tab value="Failed">Failed {{ failed.length }}</v-tab>
          <v-tab value="Completed">Completed {{ completed.length }}</v-tab>
          <v-tab value="ELA Commands"
            >ELA Commands {{ elaCommands.length }}</v-tab
          >
          <v-spacer></v-spacer>

          <v-btn variant="plain" icon="mdi-refresh" @click="getData()"></v-btn>
        </v-tabs>

        <v-window v-model="selectedTab">
          <v-window-item value="Pending">
            <EasyDataTable
              :headers="pendingHeaders"
              :items="pending"
              :loading="loading"
              alternating
              theme-color="#e7541e"
            />
          </v-window-item>

          <v-window-item value="Failed">
            <EasyDataTable
              :headers="commandsHeaders"
              :items="failed"
              alternating
              theme-color="#e7541e"
            />
          </v-window-item>

          <v-window-item value="Completed">
            <EasyDataTable
              :headers="commandsHeaders"
              :items="completed"
              alternating
              theme-color="#e7541e"
            />
          </v-window-item>

          <v-window-item value="ELA Commands">
            <EasyDataTable
              :headers="elaCommandsHeaders"
              :items="elaCommands"
              alternating
              theme-color="#e7541e"
            >
              <template #item-passcode="item">
                <PasscodeField v-if="item.passcode" v-model="item.passcode" />
              </template>
            </EasyDataTable>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-card>

  <div class="text-center">
    <v-dialog v-model="dialog.Show" max-width="600">
      <v-card>
        <v-card-title>{{ dialog.Title }}</v-card-title>
        <v-card-text>
          <template v-if="dialog.v2">
            <template v-for="field in dialog.v2" :key="field">
              {{ field.preText }}
              <v-text-field
                v-if="field.type == 'textfield'"
                v-model="field.value"
                :label="field.label"
              />
              <v-checkbox
                v-if="field.type == 'checkbox'"
                v-model="field.value"
                :label="field.label"
              ></v-checkbox>
            </template>
          </template>
          <template v-else>
            {{ dialog.Text }}
            <v-text-field
              v-if="dialog.TxtField"
              :label="dialog.TxtFieldLabel"
              v-model="dialog.TxtFieldValue"
            ></v-text-field>
            {{ dialog.Text2 }}
            <v-text-field
              v-if="dialog.TxtField2"
              :label="dialog.TxtFieldLabel2"
              v-model="dialog.TxtFieldValue2"
            ></v-text-field>
          </template>
        </v-card-text>
        <v-card-actions class="text-left">
          <v-row class="text-left">
            <v-col class="text-left"
              ><v-btn variant="tonal" @click="resetDialog()"> close </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <v-btn
                variant="tonal"
                @click="dialogButtonPressed()"
                :disabled="dialog.ButtonDisabled"
              >
                <template v-if="dialog.StatusTxt">
                  {{ dialog.StatusTxt }} </template
                ><template v-else>{{ dialog.Button }}</template></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
