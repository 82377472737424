<script setup>
import { apiQuery } from "@/api/j24api";
import { ref, reactive, defineProps, watch, onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";
import ElaAgentDataExpanded from "./ElaAgentDataExpanded.vue";
import LineGraph from "./components/LineGraph.vue";

const appBar = appBarStore();
const loading = ref(false);
const hardwareStats = ref({});
const processStats = ref({});
const hardwareStatsLatest = ref({});

const props = defineProps({
  data: Object,
});

async function getData() {
  loading.value = true;
  try {
    const result = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "agentData",
      computerID: props.data.id,
    });
    console.log(result);
    if (result.hardwareStats) {
      hardwareStats.value = result.hardwareStats;
      hardwareStatsLatest.value = result.hardwareStats[0].log[0];
    }
    if (result.processStats) {
      processStats.value = result.processStats;
    }
  } catch (error) {
    console.log(error);
  }
  cpuChart();
  memoryChart();
  loading.value = false;
}

function isObject(value) {
  if (typeof value == "object") {
    return true;
  }
  return false;
}

onMounted(async () => {
  getData();
});

function convertCamel(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  var converted = result.charAt(0).toUpperCase() + result.slice(1);
  converted = converted.replace("G B", "GB");
  converted = converted.replace("Cpu", "CPU");
  converted = converted.replace("Ad ", "AD ");
  converted = converted.replace("O S", "OS");
  return converted;
}

const cpuChartData = ref({});

function cpuChart() {
  var dataPoints = [];
  var labels = [];
  hardwareStats.value.forEach((stat) => {
    dataPoints.push(Math.round(100 - stat.log[0].cpuUsageIdlePercent));
    labels.push(stat.logTimeStamp.split("T")[1].split("Z")[0]);
  });
  cpuChartData.value = {
    datasets: [
      {
        borderColor: "rgb(231, 84, 30)",
        data: dataPoints,
        //label: "CPU Usage",
        tension: 0.1,
        fill: true,
      },
    ],
    labels: labels,
  };
  //console.log("cpuChart", cpuChartData.value);
}

const memoryChartData = ref({});
function memoryChart() {
  var dataPoints = [];
  var labels = [];
  hardwareStats.value.forEach((stat) => {
    dataPoints.push(Math.round(stat.log[0].memoryPressurePercent));
    labels.push(stat.logTimeStamp.split("T")[1].split("Z")[0]);
  });
  memoryChartData.value = {
    datasets: [
      {
        borderColor: "rgb(231, 84, 30)",
        data: dataPoints,
        tension: 0.1,
        fill: true,
      },
    ],
    labels: labels,
  };
}
</script>

<template>
  <template v-if="loading">
    <v-row class="pt-6">
      <v-spacer></v-spacer>
      <v-col>
        <v-progress-circular indeterminate size="64" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>

  <v-row v-else class="pt-3">
    <v-col v-if="cpuChartData" cols="6">
      <v-card class="ma-2">
        <v-card-title class="bg-grey-lighten-2">CPU Usage</v-card-title>
        <v-card-text>
          <LineGraph :chartData="cpuChartData" />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="memoryChartData" cols="6">
      <v-card class="ma-2">
        <v-card-title class="bg-grey-lighten-2">Memory Usage</v-card-title>
        <v-card-text>
          <LineGraph :chartData="memoryChartData" />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card class="ma-2">
        <v-card-title class="bg-grey-lighten-2">Data</v-card-title>
        <v-card-text>
          <v-table class="text-left">
            <template v-for="(line, index) in hardwareStatsLatest" :key="index">
              <template v-if="isObject(hardwareStatsLatest[index])">
                <ElaAgentDataExpanded :data="line" :first="index" />
              </template>
              <template v-else>
                <tr>
                  <th></th>
                  <th>{{ convertCamel(index) }}</th>
                  <td>{{ line }}</td>
                </tr>
              </template>
            </template>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
