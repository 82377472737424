<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";
import DashboardOverviewCustomer from "./DashboardOverviewCustomer.vue";

const instances = reactive([]);
const selectedInstance = ref({});
const showCustomer = ref(false);
const loading = ref(false);

async function getData() {
  try {
    loading.value = true;
    const result = await apiQuery("ELA", "dashboard/overview", {});
    instances.length = 0;
    instances.push(...result);
    console.log(instances);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

const headers = [
  { text: "Instance", value: "name", sortable: true },
  { text: "Type", value: "type", sortable: true },
  { text: "Alerts", value: "alerts.length", sortable: true },
  { text: "Warnings", value: "warnings.length", sortable: true },
  { text: "Version", value: "data.jamfVersion", sortable: true },
  { text: "APNS Expiry", value: "data.apnsExpiry", sortable: true },
  { text: "VPP Expiry", value: "vppAccounts" },
  { text: "DEP Expiry", value: "enrollmentAccounts" },
  { text: "Total Macs(Licensed)", value: "licenseMacs" },
  { text: "Total iOS(Licensed)", value: "licenseIOS" },
];

const searchValue = ref("");
function searchFields(headers) {
  const fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

function arrayToString(data, key) {
  var txt = "";
  if (!data) {
    return txt;
  }
  if (!data.length) {
    return txt;
  }
  data.forEach((item) => {
    txt += item[key] + " ";
  });
  return txt;
}

onMounted(() => {
  getData();
});

const selectCustomer = (item) => {
  console.log(item);
  selectedInstance.value = item;
  showCustomer.value = true;
};

function safeLength(value) {
  if (typeof value != "object") {
    return 0;
  }
  if (value.length) {
    return value.length;
  }
  return 0;
}

const rowsPerPage = 100;
</script>

<template>
  <DashboardOverviewCustomer
    v-if="showCustomer"
    :mdmServer="selectedInstance"
    :itemKeyObj="itemKeys"
    @cancel="showCustomer = false"
  />
  <v-row class="pb-3" v-if="!showCustomer">
    <v-col>
      <v-text-field
        density="compact"
        hide-details
        variant="underlined"
        label="Search"
        v-model="searchValue"
      ></v-text-field>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <EasyDataTable
    v-if="!showCustomer"
    :items="instances"
    :headers="headers"
    :loading="loading"
    theme-color="#e7541e"
    @click-row="selectCustomer"
    :rows-per-page="rowsPerPage"
    :search-field="searchFields(headers)"
    :search-value="searchValue"
  >
    <template #item-licenseMacs="item">
      {{ item.data.managedComputers ?? "" }}({{
        item.data.computerLicenses ?? ""
      }})
    </template>
    <template #item-licenseIOS="item">
      {{ item.data.mangedDevices ?? "" }}({{ item.data.deviceLicenses ?? "" }})
    </template>
    <template #item-vppAccounts="item">
      <template v-if="safeLength(item.data.vppAccounts) < 3">
        {{ arrayToString(item.data.vppAccounts, "expirationDate") ?? "" }}
      </template>
      <template v-else> Multiple </template>
    </template>

    <template #item-enrollmentAccounts="item">
      <template v-if="safeLength(item.data.enrollmentAccounts) < 3">
        {{
          arrayToString(item.data.enrollmentAccounts, "tokenExpirationDate") ??
          ""
        }}
      </template>
      <template v-else> Multiple </template>
    </template>
  </EasyDataTable>
</template>
