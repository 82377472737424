const commands = [
  {
    title: "Erase Device",
    value: "erase",
    icon: "mdi-eraser",
    permission: "SendEraseComputer",
  },
  {
    title: "Lock Computer",
    value: "lock",
    icon: "mdi-lock",
    permission: "SendDeviceLock",
  },
  {
    title: "Send Blank Push",
    value: "blankpush",
    icon: "mdi-radiobox-blank",
    permission: "SendBlankPush",
  },
  {
    title: "Enable Bluetooth",
    value: "SettingsEnableBluetooth",
    icon: "mdi-bluetooth",
    permission: "SettingsEnableBluetooth",
  },
  {
    title: "Disable Bluetooth",
    value: "SettingsDisableBluetooth",
    icon: "mdi-bluetooth-off",
    permission: "SettingsDisableBluetooth",
  },
  {
    title: "Enable Remote Desktop",
    value: "EnableRemoteDesktop",
    icon: "mdi-remote-desktop",
    permission: "EnableRemoteDesktop",
  },
  {
    title: "Disable Remote Desktop",
    value: "DisableRemoteDesktop",
    icon: "mdi-remote-desktop",
    permission: "DisableRemoteDesktop",
  },
  {
    title: "Get FileVault Key",
    value: "GetFileVaultKey",
    icon: "mdi-key-variant",
    permission: "GetFileVaultKey",
  },
];

const mobileCommands = [
  {
    title: "Update Inventory",
    value: "updateInventory",
    icon: "mdi-clipboard-text-outline",
    permission: "SendUpdateInventory",
  },
  // Removed as it is a pointless command
  // {
  //   title: "Lock Device",
  //   value: "lockDevice",
  //   icon: "mdi-lock",
  //   permission: "SendDeviceLock",
  // },
  {
    title: "Clear Passcode",
    value: "clearPasscode",
    icon: "mdi-lock-off",
    permission: "SendClearPasscode",
  },
  // {
  //   title: "Clear Screen Time Passcode",
  //   value: "clearscreentimepasscode",
  //   icon: "mdi-clipboard-text-outline",
  //   permission: "SendClearScreenTimePasscode",
  // },
  {
    title: "Restart Device",
    value: "restart",
    icon: "mdi-restart",
    permission: "SendRestartDevice",
  },
  {
    title: "Shut Down Device",
    value: "shutdown",
    icon: "mdi-power",
    permission: "SendShutdownDevice",
  },
  {
    title: "Enable Lost Mode",
    value: "lostMode",
    icon: "mdi-radar",
    permission: "SendEnableLostModeDevice",
  },
  {
    title: "Disable Lost Mode",
    value: "disableLostMode",
    icon: "mdi-radar",
    permission: "SendEnableLostModeDevice",
  },
  {
    title: "Erase Device",
    value: "erase",
    icon: "mdi-eraser",
    permission: "SendEraseDevice",
  },
];

function setupDialogMobile(value, dialog) {
  switch (value) {
    case "updateInventory":
      dialog.Title = "Update Inventory";
      dialog.Text = "Requests an updated inventory from the device.";
      dialog.Action = "updateInventory";
      break;
    case "lockDevice":
      dialog.Title = "Lock Device";
      dialog.Text = "Requests the device to lock.";
      dialog.Action = "lockDevice";
      break;
    case "clearPasscode":
      dialog.Title = "Clear Passcode";
      dialog.Text =
        "Clears the passcode set on the device allowing the user to reset it.";
      dialog.Action = "clearPasscode";
      break;
    case "restart":
      dialog.Title = "Restart";
      dialog.Text = "Request the device to restart.";
      dialog.Action = "restart";
      break;
    case "shutdown":
      dialog.Title = "Shutdown";
      dialog.Text = "Request the device to shut down.";
      dialog.Action = "shutdown";
      break;
    case "disableLostMode":
      dialog.Title = "Disable Lost Mode";
      dialog.Text = "Request the device to disable lost mode.";
      dialog.Action = "disableLostMode";
      break;
    case "lostMode":
      dialog.Title = "Enable Lost Mode";
      dialog.v2 = {
        message: {
          type: "textfield",
          label: "Message",
          preText:
            "Specify a message to be displayed on the device. (The message or phone field must be set)",
          value: "",
        },
        phone: {
          type: "textfield",
          label: "Phone Number",
          preText:
            "Phone number to be displayed on the device whilst in lost mode.",
          value: "",
        },
        footnote: {
          type: "textfield",
          label: "Footnote",
          preText: "Footnote displayed on the device whilst in lost mode.",
          value: "",
        },
        sound: {
          type: "checkbox",
          label: "Play Lost Sound",
          value: false,
        },
      };
      dialog.Action = "lostMode";
      break;
    case "erase":
      (dialog.Title = "Erase Device"),
        (dialog.v2 = {
          confirm: {
            type: "textfield",
            label: "ERASE",
            preText:
              "WARNING - this action will erase the device which will result in data loss. Please type ERASE in the textbox below to confirm",
            value: "",
          },
        });
      dialog.Action = "erase";
      break;
    default:
      console.log("No Dialog Configuration");
  }
  return dialog;
}

function setupDialog(value, dialog) {
  switch (value) {
    case "GetFileVaultKey":
      dialog.Title = "Get FileVault Key";
      dialog.Text = "Requests the FileVault key for the device.";
      dialog.Action = "GetFileVaultKey";
      break;
    case "erase":
      dialog.v2 = {
        passcode: {
          type: "textfield",
          label: "6 Digit Passcode",
          preText:
            "Please specify a passcode to use to wipe the device. This must be a 6-digit passcode",
          value: "",
        },
        confirm: {
          type: "textfield",
          label: "ERASE",
          preText:
            "WARNING - this action will erase the device which will result in data loss. Please type ERASE in the textbox below to confirm",
          value: "",
        },
      };

      dialog.Title = "Erase Device";
      dialog.Text =
        "Please specify a passcode to use to wipe the device. This must be a 6-digit passcode";
      dialog.TxtField = true;
      dialog.TxtFieldLabel = "6 Digit Passcode";
      dialog.Text2 =
        "WARNING - this action will erase the device which will result in data loss. Please type ERASE in the field below to confirm.";
      dialog.TxtField2 = true;
      dialog.TxtFieldLabel2 = "ERASE";
      dialog.Action = "Erase Device";
      break;
    case "lock":
      dialog.Title = "Lock Device";
      dialog.Text =
        "Please specify a passcode to use to lock the device. This must be a 6-digit passcode";
      dialog.TxtField = true;
      dialog.TxtFieldLabel = "6 Digit Passcode";
      dialog.Action = "Lock Device";
      break;
    case "blankpush":
      dialog.Title = "Blank Push";
      dialog.Text =
        "Sending a blank push requests the MDM Service on the device to check in.";
      dialog.Action = "Send Blank Push";
      break;
    case "SettingsEnableBluetooth":
      dialog.Title = "Enable Bluetooth";
      dialog.Text = "This command will enable bluetooth.";
      dialog.Action = "SettingsEnableBluetooth";
      break;
    case "SettingsDisableBluetooth":
      dialog.Title = "Disable Bluetooth";
      dialog.Text = "This command will disable bluetooth.";
      dialog.Action = "SettingsDisableBluetooth";
      break;
    case "EnableRemoteDesktop":
      dialog.Title = "Enable Remote Desktop";
      dialog.Text = "This command will enable remote desktop connections.";
      dialog.Action = "EnableRemoteDesktop";
      break;
    case "DisableRemoteDesktop":
      dialog.Title = "Disable Remote Desktop";
      dialog.Text = "This command will disable remote desktop connections.";
      dialog.Action = "DisableRemoteDesktop";
      break;
    default:
      console.log("No Dialog Configuration");
  }
  return dialog;
}

function dialogButtonPressedMobile(dialog, mdmServerId, id) {
  try {
    switch (dialog.Action) {
      case "erase":
        if (dialog.v2.confirm.value != "ERASE") {
          dialog.StatusTxt = "Please confirm by typing ERASE";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "EraseDevice",
          mobileDeviceId: id,
        };
        return dialog;
      case "lostMode":
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          mobileDeviceId: id,
          lostModeMessage: dialog.v2.message.value,
          lostModePhone: dialog.v2.phone.value,
          lostModeFootnote: dialog.v2.footnote.value,
          lostModeWithSound: dialog.v2.sound.value,
        };
        return dialog;
      case "disableLostMode":
      case "updateInventory":
      case "lockDevice":
      case "clearPasscode":
      case "restart":
      case "shutdown":
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          mobileDeviceId: id,
        };
        return dialog;
      default:
        console.log("Unknown Command");
        return dialog;
    }
  } catch (error) {
    console.log(error);
  }
}

function dialogButtonPressed(dialog, mdmServerId, id) {
  console.log(dialog);
  try {
    switch (dialog.Action) {
      case "GetFileVaultKey": {
        console.log("Get FileVault Key");
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Getting Key...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "GetFileVaultKey",
          computerId: id,
        };
        return dialog;
      }
      case "Erase Device": {
        console.log("Erase Device");
        console.log("Dialog Object", dialog);
        console.log(dialog.TxtFieldValue);
        const regexTest = new RegExp("\\d{6}");
        if (!regexTest.test(dialog.v2.passcode.value)) {
          dialog.StatusTxt = "PIN must be a 6 digits";
          return dialog;
        }
        if (dialog.v2.confirm.value != "ERASE") {
          dialog.StatusTxt = "Please confirm by typing ERASE";
          return dialog;
        }

        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "EraseDevice",
          computerId: id,
          passcode: dialog.v2.passcode.value,
        };
        return dialog;
      }

      case "Lock Device": {
        console.log("Lock Device");
        const regexTest = new RegExp("\\d{6}");
        if (!regexTest.test(dialog.TxtFieldValue)) {
          dialog.StatusTxt = "PIN must be a 6 digits";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "DeviceLock",
          computerId: id,
          passcode: dialog.TxtFieldValue,
        };
        return dialog;
      }

      case "Send Blank Push": {
        console.log("Send Blank Push");

        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "blankpush",
          computerId: id,
        };
        return dialog;
      }

      case "SettingsEnableBluetooth":
      case "SettingsDisableBluetooth":
      case "EnableRemoteDesktop":
      case "DisableRemoteDesktop": {
        console.log(dialog.Action);

        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          computerId: id,
        };
        return dialog;
      }
      default:
        console.log("Unknown Command");
        return dialog;
    }
  } catch (error) {
    console.log(error);
  }
}

module.exports = {
  commands,
  mobileCommands,
  dialogButtonPressed,
  setupDialog,
  setupDialogMobile,
  dialogButtonPressedMobile,
};
