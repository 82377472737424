<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits, watch } from "vue";
import { apiQuery } from "@/api/j24api";
import DevicesList from "./components/DevicesList.vue";
import ElaComputerDetailV2 from "./ElaComputerDetailV2.vue";

const props = defineProps({
  mdmServerId: String,
  reportId: Object,
  manualMode: Boolean,
  allDevices: Object,
  serialNumbers: Object,
  hideExit: Boolean,
  mandatoryHeaders: Object,
  filterSerials: Object,
  // If emit clickRow is true this component will not try to load
  // device record when a row is clicked. Instead it will emit
  // clickRow
  emitClickRow: Boolean,
});

const emit = defineEmits("clickRow");
const selectedTab = ref("list");
const deviceDetail = ref({});

function clickRow(item) {
  selectedTab.value = "deviceDetail";
  if (props.emitClickRow) {
    emit("clickRow", item);
    return;
  }
  console.log("Click Row", item);
  //Check we have required fields
  deviceDetail.value = {};
  if (item.JSS_Computer_ID) {
    deviceDetail.value = {
      type: "computer",
      id: item.JSS_Computer_ID,
      general: { name: item.name },
    };
  }
  if (item.JSS_Mobile_Device_ID) {
    deviceDetail.value = {
      type: "mobileDevice",
      id: item.JSS_Mobile_Device_ID,
      general: { name: item.name },
    };
  }
  updateRandom();
}

const data = ref({ content: [] });
const loading = ref(true);

const headers = reactive([
  { text: "Device Name", value: "name", sortable: true, show: true },
  {
    text: "Last Contact Time",
    value: "Last_Check_in",
    sortable: true,
    show: true,
  },
]);

const allHeaders = [
  { text: "Device Name", value: "name", sortable: true, show: true },
  {
    text: "Operating System",
    value: "Operating_System_Name",
    sortable: true,
    show: true,
  },
  { text: "Total Memory", value: "RAM", sortable: true, show: true },
  { text: "Model", value: "model", sortable: true, show: true },
  { text: "Last Check In", value: "Last_Check_in", sortable: true, show: true },
  {
    text: "Last Enrollment",
    value: "Last_Enrollment",
    sortable: true,
    show: true,
  },
  {
    text: "Last Inventory Update",
    value: "Last_Inventory_Update",
    sortable: true,
    show: true,
  },
  {
    text: "Operating System Version",
    value: "Operating_System_Version",
    sortable: true,
    show: true,
  },
  { text: "Serial Number", value: "Serial_Number", sortable: true, show: true },
  { text: "Username", value: "Username", sortable: true, show: true },
  { text: "Full Name", value: "Full_Name", sortable: true, show: true },
  { text: "Email Address", value: "Email_Address", sortable: true, show: true },
  { text: "Department", value: "Department", sortable: true, show: true },
  { text: "SerialNumber", value: "SerialNumber", sortable: true, show: true },
  { text: "SerialNumber", value: "SerialNumber", sortable: true, show: true },
];

function translator() {
  data.value.content.forEach((record, index) => {
    if (record.Total_RAM_MB) {
      record.RAM = record.Total_RAM_MB / 1024 + " GB";
    }
    if (!record.Last_Check_in && record.Last_Inventory_Update) {
      record.Last_Check_in = record.Last_Inventory_Update;
    }
    if (!record.Operating_System_Name && record.Model) {
      if (record.Model.includes("iPhone")) {
        record.Operating_System_Name = "iOS";
      }
      if (record.Model.includes("iPad")) {
        record.Operating_System_Name = "iPadOS";
      }
      if (record.Model.includes("Apple TV")) {
        record.Operating_System_Name = "tvOS";
      }
    }
    if (!record.Operating_System_Version && record.iOS_Version) {
      record.Operating_System_Version = record.iOS_Version;
    }
    data.value.content[index] = record;
  });
}

const searchValue = ref("");
function searchFields() {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

watch(props.serialNumbers, (current) => {
  data.value.content.length = 0;
  props.allDevices.forEach((device) => {
    if (current.find((a) => a == device.Serial_Number)) {
      data.value.content.push(device);
    }
  });
});

onMounted(async () => {
  loading.value = true;
  try {
    if (props.manualMode) {
      //console.log("Manual Mode");
      props.allDevices.forEach((device) => {
        if (props.serialNumbers.find((a) => a == device.Serial_Number)) {
          data.value.content.push(device);
        }
      });
    } else {
      var promises = [];
      props.reportId.forEach((id) => {
        promises.push(
          apiQuery("ELA", "cmd24/query", {
            mdmServerId: props.mdmServerId,
            id: id,
            dataRequested: "dashboardDetail",
          })
        );
      });

      var results = [];
      await Promise.all(promises).then((result) => {
        results.push(...result);
      });

      // const result = await apiQuery("ELA", "cmd24/query", {
      //   mdmServerId: props.mdmServerId,
      //   id: props.reportId,
      //   dataRequested: "dashboardDetail",
      // });
      data.value.content = [];
      var devicesArray = [];
      results.forEach((result) => {
        if (result.content) {
          //data.value.content.push(...result.content);
          devicesArray.push(...result.content);
        }
      });

      if (props.filterSerials && props.filterSerials.length) {
        // console.log("Filtering Serial numbers");
        // console.log("Devices Array", devicesArray);
        // console.log("FilterSerials", props.filterSerials);
        var filtered = [];
        devicesArray.forEach((device) => {
          if (props.filterSerials.includes(device.Serial_Number)) {
            //console.log("Found Match", device);
            filtered.push(device);
          } else {
            //console.log("Not Match", device);
          }
        });
        devicesArray.length = 0;
        devicesArray.push(...filtered);
      }

      data.value.content.push(...devicesArray);
    }
    await translator();
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
});

const randomKey = ref("");
function updateRandom() {
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}
</script>

<template>
  <v-window v-model="selectedTab">
    <v-window-item value="list">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <DevicesList
            :data="data.content"
            :loading="loading"
            :mandatoryHeaders="props.mandatoryHeaders"
            :hideBackButton="props.hideExit"
            @clickRow="clickRow"
            @clickBack="$emit('close')"
          ></DevicesList>
        </v-card-text>
      </v-card>
    </v-window-item>

    <v-window-item value="deviceDetail">
      <v-card-title> </v-card-title>
      <ElaComputerDetailV2
        :data="deviceDetail"
        @back="selectedTab = 'list'"
        :key="randomKey"
      />
    </v-window-item>
  </v-window>
</template>
