<script setup>
import { reactive, ref, watch, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const loading = ref(false);
const alert = ref("");
const saving = ref(false);
const displayConfirmDelete = ref(false);

const dataSources = reactive([]);
const dataSource = ref({});
const addDataSource = ref(false);
const editDataSource = ref(false);
const searchValue = ref("");
const serviceNowTypes = ["None", "Case", "Request"];

const chooseMDMServers = ref(false);
const mdmServers = reactive([]);

const datasourceTypes = [
  {
    title: "Advanced Computer Search",
    value: "advancedcomputersearches",
  },
  {
    title: "Advanced Mobile Devices Search",
    value: "advancedmobiledevicesearches",
  },
  { title: "Computer Group", value: "computergroups" },
  { title: "Mobile Device Group", value: "mobiledevicegroups" },
];

const headers = [
  { text: "Data Type", value: "type", sortable: true },
  { text: "Data Name", value: "name", sortable: true },
  { text: "Global", value: "global", sortable: true },
  { text: "Actions", value: "actions" },
];

function addNewPressed() {
  addDataSource.value = true;
  dataSource.value.global = true;
}

async function getMDMServers() {
  if (mdmServers.length == 0) {
    const result = await apiQuery("J24Auth", "elaadmin/mdmservers", {
      apiAction: "get",
    });
    if (result) {
      result.forEach((mdmServer) => {
        mdmServers.push(mdmServer);
      });
    }
  }
}

async function getDataSources() {
  loading.value = true;
  const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
    apiAction: "get",
  });
  dataSources.length = 0;
  dataSources.push(...result);
  loading.value = false;
}

async function saveDataSource() {
  saving.value = true;
  var apiAction = "create";
  if (editDataSource.value) {
    apiAction = "update";
  }
  try {
    const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
      apiAction: apiAction,
      JamfDataSource: dataSource.value,
    });
    editDataSource.value = false;
    addDataSource.value = false;
    loading.value = true;
    dataSource.value = {};
    getDataSources();
  } catch (error) {
    alert.value = "Error saving record";
    console.log(error);
  }
  saving.value = false;
}

async function deleteItem(item) {
  dataSource.value = item;
  displayConfirmDelete.value = true;
}

async function confirmDelete() {
  try {
    loading.value = true;
    displayConfirmDelete.value = false;
    const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
      apiAction: "delete",
      JamfDataSource: dataSource.value,
    });
    getDataSources();
  } catch (error) {
    alert.value = "Error Deleting record";
    console.log(error);
    loading.value = false;
  }
}

function resetDataSource() {
  dataSource.value = {};
  editDataSource.value = false;
}

function editItem(item) {
  editDataSource.value = true;
  dataSource.value = item;
  addDataSource.value = true;
}

onMounted(() => {
  getDataSources();
  getMDMServers();
});

const alertOperators = ["Less Than", "Equals", "Greater Than"];
</script>

<template>
  <template v-if="addDataSource">
    <v-select
      density="compact"
      :items="datasourceTypes"
      label="Data Source Type"
      v-model="dataSource.type"
    ></v-select>
    <v-text-field
      density="compact"
      v-model="dataSource.name"
      label="Name of Object"
    />
    <v-row no-gutters
      ><v-col cols="3">
        <v-checkbox
          density="compact"
          v-model="dataSource.global"
          label="Global Data Source"
        />
      </v-col>
      <v-col cols="7">
        <v-select
          v-if="!dataSource.global"
          density="compact"
          v-model="dataSource.mdmServers"
          :items="mdmServers"
          item-value="_id"
          item-title="name"
          label="Select MDM Servers to collect data from"
          no-data-text="Loading please wait..."
          multiple
          chips
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-select
      v-if="dataSource.global || dataSource.excludedMdmServers?.length"
      density="compact"
      v-model="dataSource.excludedMdmServers"
      :items="mdmServers"
      item-value="_id"
      item-title="name"
      label="Select MDM Servers to exclude"
      no-data-text="Loading please wait..."
      multiple
      chips
    ></v-select>
    <v-checkbox
      density="compact"
      v-if="
        dataSource.type == 'advancedcomputersearches' ||
        dataSource.type == 'advancedmobiledevicesearches'
      "
      v-model="dataSource.SummariseData"
      label="Summarise Data"
    />
    <v-text-field
      v-if="dataSource.SummariseData"
      label="Field to use to summarise"
      density="compact"
      v-model="dataSource.SummariseDataField"
    ></v-text-field>
    <template v-if="!dataSource.SummariseData">
      <h3>Default Alert Configuration</h3>
      <v-select
        label="Alert when value is"
        density="compact"
        :items="alertOperators"
        v-model="dataSource.AlertOperator"
      ></v-select>
      <v-text-field
        v-if="!dataSource.AlertUseAnotherSource"
        density="compact"
        v-model="dataSource.AlertValue"
        label="Static Value"
      />
      <v-checkbox
        density="compact"
        v-model="dataSource.AlertUseAnotherSource"
        label="Use Another Source"
      />
      <v-select
        v-if="dataSource.AlertUseAnotherSource"
        label="Choose Other Data Source"
        v-model="dataSource.AlertOtherSource"
        density="compact"
        :items="dataSources"
        item-title="name"
        item-value="_id"
      ></v-select>
      <v-checkbox
        v-if="dataSource.AlertUseAnotherSource"
        density="compact"
        v-model="dataSource.AlertUsePercentage"
        label="Use Percentage"
      />
      <v-slider
        v-if="dataSource.AlertUsePercentage && dataSource.AlertUseAnotherSource"
        v-model="dataSource.AlertPercentage"
        density="compact"
        label="Choose Percentage"
        thumb-label="always"
        max="100"
        step="1"
      ></v-slider>
      <h3>Warning Configuration</h3>
      <v-select
        label="Warn when value is"
        density="compact"
        :items="alertOperators"
        v-model="dataSource.WarnOperator"
      ></v-select>
      <v-text-field
        v-if="!dataSource.WarnUseAnotherSource"
        density="compact"
        v-model="dataSource.WarnValue"
        label="Static Value"
      />
      <v-checkbox
        density="compact"
        v-model="dataSource.WarnUseAnotherSource"
        label="Use Another Source"
      />
      <v-select
        v-if="dataSource.WarnUseAnotherSource"
        label="Choose Other Data Source"
        v-model="dataSource.WarnOtherSource"
        density="compact"
        :items="dataSources"
        item-title="name"
        item-value="_id"
      ></v-select>
      <v-checkbox
        v-if="dataSource.WarnUseAnotherSource"
        density="compact"
        v-model="dataSource.WarnUsePercentage"
        label="Use Percentage"
      />
      <v-slider
        v-if="dataSource.WarnUsePercentage && dataSource.WarnUseAnotherSource"
        v-model="dataSource.WarnPercentage"
        density="compact"
        label="Choose Percentage"
        thumb-label="always"
        max="100"
        step="1"
      ></v-slider>
    </template>
    <h3>ServiceNow Event</h3>
    <v-select
      label="Choose the type of ServiceNow Event"
      v-model="dataSource.serviceNowType"
      density="compact"
      :items="serviceNowTypes"
      item-title="name"
      item-value="_id"
    ></v-select>
    <v-row class="mb-1">
      <v-col cols="2">
        <v-btn
          :disabled="saving"
          size="small"
          @click="
            addDataSource = false;
            resetDataSource();
          "
          >Cancel</v-btn
        ></v-col
      >
      <v-col cols="2">
        <v-btn size="small" :disabled="saving" @click="saveDataSource()">
          Save</v-btn
        >
      </v-col>
      <v-spacer />
    </v-row>
  </template>

  <template v-if="!addDataSource">
    <v-row no-gutters style="height: 35px" class="mt-2 mb-2">
      <v-col class="flex-grow-0 flex-shrink 0" cols="2">
        <v-btn
          height="33px"
          prepend-icon="mdi-upload"
          size="small"
          @click="addNewPressed()"
          >Add New</v-btn
        >
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Search"
          density="compact"
          variant="underlined"
          v-model="searchValue"
          height="25px"
          class="pl-2"
        />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="dataSources"
      theme-color="#e7541e"
      alternating
      buttons-pagination
      :loading="loading"
      search-field="name"
      :search-value="searchValue"
    >
      <template #item-actions="item">
        <div>
          <v-icon @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)" class="ml-1">mdi-delete</v-icon>
        </div>
      </template>
    </EasyDataTable>
  </template>

  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>

  <v-dialog v-model="displayConfirmDelete" persistent
    ><v-card>
      <v-card-title>Confirm Delete Item</v-card-title>
      <v-card-text
        >Please confirm that you wish to delete this item.</v-card-text
      >
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn @click="displayConfirmDelete = false">Cancel</v-btn
        ><v-btn @click="confirmDelete()">Delete</v-btn></v-card-actions
      >
    </v-card></v-dialog
  >

  <!-- <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay> -->
</template>
