<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const loading = ref(false);
const addServer = ref(false);
const editServer = ref(false);

const headers = [
  { text: "Customer", value: "customer", sortable: true },
  { text: "Name", value: "name" },
  { text: "Type", value: "type" },
  { text: "Actions", value: "actions" },
];

const items = reactive([]);
const customers = reactive([]);
const patchLicenses = reactive([]);
const editMdmServer = ref({ options: {} });
const showPW = ref(false);

const saving = ref(false);

const searchValue = ref("");
function searchFields(headers) {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

async function save() {
  var apiAction = "add";
  if (editServer.value) {
    apiAction = "update";
  }
  saving.value = true;
  try {
    await apiQuery("J24Auth", "elaadmin/mdmservers", {
      apiAction: apiAction,
      mdmserver: editMdmServer.value,
    });

    console.log("Checking PatchLicenses");
    await updateAssociatedPatchLicenses(
      editMdmServer.value._id,
      editMdmServer.value.associatedPatchLicenses
    );

    editMdmServer.value = {};
    editServer.value = false;
    addServer.value = false;
  } catch (error) {
    console.log(error);
  }
  saving.value = false;
  getData();
}

async function updateAssociatedPatchLicenses(mdmServerID, updatedAssociations) {
  if (!addServer.value) {
    await checkAssociatedPatchLicensesForDelete(
      mdmServerID,
      updatedAssociations
    );
    var original;
    items.forEach((item) => {
      if (item._id == mdmServerID) {
        original = item;
      }
    });

    //   var original = items.find((i) => i._id === mdmServerID);
    for (const key in updatedAssociations) {
      var alreadyExists = false;
      original.associatedPatchLicenses.forEach((associated) => {
        if (associated._id == updatedAssociations[key]._id) {
          alreadyExists = true;
        }
      });

      if (alreadyExists) {
        console.log(
          "Associated Patch License Already exists no change required"
        );
      } else {
        await apiQuery("J24Auth", "elaadmin/mdmservers", {
          apiAction: "updatePatchLicense",
          licenseID: updatedAssociations[key]._id,
          mdmServerID: mdmServerID,
        });
      }
    }
  } else {
    if (updatedAssociations == undefined) {
      return;
    }
    for (const key in updatedAssociations) {
      for (const key in updatedAssociations) {
        await apiQuery("J24Auth", "elaadmin/mdmservers", {
          apiAction: "updatePatchLicense",
          licenseID: updatedAssociations[key]._id,
          mdmServerID: mdmServerID,
        });
      }
    }
  }
}

// Checks if we need to delete any associated patch licenses entries.
async function checkAssociatedPatchLicensesForDelete(
  mdmServerID,
  updatedAssociations
) {
  var original = items.find((i) => i._id === mdmServerID);

  //original = original[0];
  console.log("Original --", original);
  if (!original.associatedPatchLicenses.length) {
    console.log("No Associations set originally");
    return;
  }
  original.associatedPatchLicenses.forEach((orgAssociation) => {
    var deleteEntry = true;
    if (updatedAssociations.some((i) => i._id === orgAssociation._id)) {
      deleteEntry = false;
    }
    if (deleteEntry) {
      console.log("I am deleting ", orgAssociation);
      apiQuery("J24Auth", "elaadmin/mdmservers", {
        apiAction: "updatePatchLicense",
        licenseID: orgAssociation._id,
        mdmServerID: "",
      });
    }
  });
}

async function getData() {
  loading.value = true;
  try {
    const [resMdmServers, resCustomers] = await Promise.all([
      apiQuery("J24Auth", "elaadmin/mdmservers", {
        apiAction: "get",
      }),
      apiQuery("J24Auth", "customers", { apiAction: "get" }),
    ]);

    items.length = 0;
    resMdmServers.forEach((server) => {
      const matchCustomer = resCustomers.filter(
        (customer) => customer._id == server.customerID
      );
      server.associatedPatchLicenses = [];
      if (matchCustomer.length) {
        items.push({ customer: matchCustomer[0].name, ...server });
      } else {
        items.push({ customer: "Unknown", ...server });
      }
    });
    customers.length = 0;
    customers.push(...resCustomers);

    const patchLicensesResult = await apiQuery(
      "J24Auth",
      "elaadmin/mdmservers",
      {
        apiAction: "getPatchLicenses",
      }
    );
    //console.log(patchLicensesResult);
    if (patchLicensesResult) {
      patchLicenses.length = 0;
      patchLicensesResult.forEach((license) => {
        patchLicenses.push(license);
      });
    }

    patchLicenses.forEach((patchLicense) => {
      //console.log(patchLicense);
      if (patchLicense.mdmServerID) {
        items.forEach((mdmServer, index) => {
          if (patchLicense.mdmServerID == mdmServer._id) {
            if (!items[index].associatedPatchLicenses) {
              items[index].associatedPatchLicenses = [];
            }
            items[index].associatedPatchLicenses.push(patchLicense);
          }
        });
      }
    });
    //console.log(items);
    items.forEach((mdmServer, index) => {
      if (typeof mdmServer.options == "undefined") {
        items[index].options = {};
      }
    });
    //items.push(...resMdmServers);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

function openEdit(item) {
  editServer.value = true;
  editMdmServer.value = item;
}

const displayConfirmDelete = ref(false);
const serverToDelete = ref({});
function deleteServer(item) {
  serverToDelete.value = item;
  displayConfirmDelete.value = true;
}

async function confirmDelete() {
  try {
    displayConfirmDelete.value = false;
    loading.value = true;
    await apiQuery("J24Auth", "elaadmin/mdmservers", {
      apiAction: "delete",
      mdmserver: serverToDelete.value,
    });
  } catch (error) {
    console.log(error);
  }
  serverToDelete.value = {};
  getData();
}

const testingConnection = ref(false);
const testConnectionResult = ref("");

async function testConnection(item) {
  testingConnection.value = true;
  testConnectionResult.value = "Testing...";
  let result = await apiQuery("ELA", "tools/testmdmconnection", {
    mdmServerId: item._id,
  });
  console.log(result);
  if (result.computers || result.mobileDevices) {
    testConnectionResult.value =
      "Success - Found " +
      result.computers +
      " Computers and " +
      result.mobileDevices +
      " Mobile Devices";
    return;
  }
  if (result.error) {
    testConnectionResult.value = "Error - " + result.error;
    return;
  }
  testConnectionResult.value = "Running Test Failed with unknown error";
  console.log(result);
}

onMounted(() => {
  getData();
});
</script>

<template>
  <v-alert v-model="testingConnection" closable>{{
    testConnectionResult
  }}</v-alert>

  <template v-if="addServer || editServer">
    <v-text-field
      v-if="editMdmServer._id"
      label="ID"
      v-model="editMdmServer._id"
      readonly
      variant="underlined"
    ></v-text-field>
    <v-select
      label="Customer"
      variant="underlined"
      :items="customers"
      item-title="name"
      item-value="_id"
      v-model="editMdmServer.customerID"
    ></v-select>
    <v-select
      label="Type"
      variant="underlined"
      :items="['Jamf', 'Kandji', 'Intune']"
      v-model="editMdmServer.type"
    ></v-select>
    <v-text-field
      label="MDM Name"
      variant="underlined"
      v-model="editMdmServer.name"
    />
    <template v-if="editMdmServer.type == 'Jamf'">
      <v-text-field
        label="MDM URL"
        variant="underlined"
        v-model="editMdmServer.url"
      />
      <v-text-field
        label="Username"
        variant="underlined"
        v-model="editMdmServer.username"
      />
      <v-text-field
        label="Password"
        :type="showPW ? 'text' : 'password'"
        :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
        variant="underlined"
        v-model="editMdmServer.password"
        @click:append="showPW = !showPW"
      />
    </template>

    <template v-if="editMdmServer.type == 'Kandji'">
      <v-text-field
        label="API URL - https://SubDomain.api.kandji.io"
        variant="underlined"
        v-model="editMdmServer.url"
      ></v-text-field>
      <v-text-field
        label="API Token"
        :type="showPW ? 'text' : 'password'"
        :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
        variant="underlined"
        v-model="editMdmServer.apiToken"
        @click:append="showPW = !showPW"
      />
    </template>

    <template v-if="editMdmServer.type == 'Intune'">
      <v-text-field
        label="Tenant ID"
        variant="underlined"
        v-model="editMdmServer.tenantId"
      ></v-text-field>
      <v-text-field
        label="Client ID"
        variant="underlined"
        v-model="editMdmServer.clientId"
      ></v-text-field>
      <v-text-field
        label="Client Secret"
        :type="showPW ? 'text' : 'password'"
        :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
        variant="underlined"
        v-model="editMdmServer.apiToken"
        @click:append="showPW = !showPW"
      />
    </template>

    <v-checkbox
      label="Enable Data Collection (Required for Dashboard)"
      v-model="editMdmServer.dataCollection"
      variant="underlined"
    />
    <v-text-field
      v-if="editMdmServer.dataCollection"
      label="ServiceNow Account Number (ACCT****)"
      v-model="editMdmServer.accountNo"
      variant="underlined"
    ></v-text-field>
    <v-select
      v-if="!addServer"
      label="Associated Patch Licenses"
      v-model="editMdmServer.associatedPatchLicenses"
      chips
      multiple
      :items="patchLicenses"
      item-title="name"
      return-object
    />

    <h3>Command 24 Options</h3>
    <v-checkbox
      label="Enable Data Collection"
      variant="underlined"
      density="compact"
      hide-details
      v-model="editMdmServer.options.cmd24DataCollection"
    ></v-checkbox>
    <v-checkbox
      label="Enable Commands"
      variant="underlined"
      density="compact"
      hide-details
      v-model="editMdmServer.options.commands"
    ></v-checkbox>
    <v-checkbox
      label="Show Patching Logs"
      variant="underlined"
      density="compact"
      hide-details
      v-model="editMdmServer.options.showPatchLogs"
    ></v-checkbox>
    <v-checkbox
      label="Show CIS Information"
      variant="underlined"
      density="compact"
      hide-details
      v-model="editMdmServer.options.agentCIS"
    ></v-checkbox>
    <v-text-field
      label="Agent Licence Name"
      variant="underlined"
      density="compact"
      v-model="editMdmServer.options.agentLicence"
    >
    </v-text-field>
    <v-text-field
      label="Elevate Licence Name"
      variant="underlined"
      density="compact"
      v-model="editMdmServer.options.elevateLicence"
    >
    </v-text-field>
    <v-text-field
      label="Patching Licence Name"
      variant="underlined"
      density="compact"
      v-model="editMdmServer.options.patchingLicence"
    >
    </v-text-field>
    <v-row class="pt-5">
      <v-col cols="auto">
        <v-btn @click="save()" :disabled="saving">Save</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          @click="
            addServer = false;
            editServer = false;
            editMdmServer = {};
          "
          :disabled="saving"
          >Cancel</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>
  <template v-if="!addServer && !editServer">
    <v-row>
      <v-col>
        <v-text-field
          density="compact"
          hide-details
          variant="underlined"
          label="Search"
          v-model="searchValue"
        ></v-text-field>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn
          right="true"
          height="33px"
          prepend-icon="mdi-plus"
          size="small"
          class="pa-2 ml-3"
          @click="addServer = true"
          >Add Server</v-btn
        >
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="items"
      theme-color="#e7541e"
      alternating
      buttons-pagination
      class="mt-3"
      :loading="loading"
      :search-field="searchFields(headers)"
      :search-value="searchValue"
    >
      <template #item-actions="item">
        <div>
          <v-btn @click="openEdit(item)" icon size="small" variant="text"
            ><v-icon>mdi-pencil</v-icon
            ><v-tooltip activator="parent">Edit</v-tooltip></v-btn
          >
          <v-btn @click="testConnection(item)" icon size="small" variant="text"
            ><v-icon>mdi-connection</v-icon
            ><v-tooltip activator="parent">Test Connection</v-tooltip></v-btn
          >
          <v-btn @click="deleteServer(item)" icon size="small" variant="text"
            ><v-icon>mdi-delete</v-icon
            ><v-tooltip activator="parent">Delete</v-tooltip></v-btn
          >
        </div>
      </template>
    </EasyDataTable>

    <br />
    Deleting a server from here will not delete the data collected, which may
    still be visible in the dashboard. To remove this data please complete
    <a href="https://forms.office.com/e/C6KVihTi9E">this</a> form.
  </template>
  <v-dialog v-model="displayConfirmDelete" persistent scrollable max-width="700"
    ><v-card>
      <v-card-title>Confirm Delete MDM Server</v-card-title>
      <v-card-text>
        Please confirm that you wish to delete this server, this action cannot
        be undone.
      </v-card-text>
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn @click="displayConfirmDelete = false">Cancel</v-btn
        ><v-btn @click="confirmDelete()">Delete</v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
