<template>
  <v-row>
    <v-col>
      <h3>{{ title }}</h3>
    </v-col>
  </v-row>
  <v-row>
    <v-col>{{ topText }}</v-col>
  </v-row>

  <template v-if="!csvContents.length">
    <v-row>
      <v-col>
        <v-file-input
          v-if="!csvContents.length"
          accept=".csv"
          label="Select CSV File"
          @change="parse_csv"
          density="compact"
        />
      </v-col>
      <v-col>
        <download-csv :data="blankCSV()" :name="templateFilename()"
          ><v-btn size="small" prepend-icon="mdi-download"
            >Download Template</v-btn
          ></download-csv
        >
      </v-col>
    </v-row>
  </template>

  <template v-if="csvErrorContents.length">
    <h3>The following will not be imported due to errors</h3>
    <EasyDataTable
      class="mt-3"
      :headers="dataTableHeaders()"
      :items="csvErrorContents"
      theme-color="#e7541e"
      alternating
      buttons-pagination
    />
    <v-alert
      class="mt-3"
      v-if="csvErrors != ''"
      type="error"
      icon="mdi-alert"
      >{{ csvErrors }}</v-alert
    >
  </template>
  <template v-if="csvContents.length">
    <h3>The following will be imported. Please confirm</h3>
    <EasyDataTable
      class="mt-3"
      :headers="dataTableHeaders()"
      :items="csvContents"
      theme-color="#e7541e"
      alternating
      buttons-pagination
    />
  </template>
  <v-row>
    <v-col class="text-left"
      ><v-btn
        class="mt-3"
        v-if="csvContents.length"
        @click="completeImport()"
        :disabled="saving"
        >Import</v-btn
      ></v-col
    >
    <v-col><v-spacer /></v-col>
    <v-col class="text-right">
      <v-btn @click="$emit('cancel')" class="mt-3">Cancel</v-btn>
    </v-col>
  </v-row>
  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>
</template>

<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits } from "vue";
import {
  assetsHeaders,
  contactsHeaders,
  deliveryHeaders,
  orderHeaders,
  addressesHeaders,
} from "./cat24Types";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";

const appBar = appBarStore();
//import { papa } from "papaparse";
// eslint-disable-next-line
const papa = require("papaparse");

//const fileUploaded = ref(false);
const csvContents = reactive([]);
const csvErrorContents = reactive([]);
const csvErrors = ref("");
const saving = ref(false);

const props = defineProps({
  importType: String,
  currentData: Object,
  duplicateCheckField: String,
});

const emit = defineEmits(["cancel", "updated"]);

function templateFilename() {
  switch (props.importType) {
    case "contacts":
      return "ContactsTemplate";

    case "deliveries":
      return "DeliveriesTemplate";
    default:
      return "data";
  }
}

async function parse_csv(event) {
  let files = event.target.files || event.datatransfer.files;
  if (!files.length) return console.log("Invalid File");

  var file = event.target.files[0];
  await papa.parse(file, {
    header: true,
    complete: (results) => {
      csvContents.length = 0;
      csvErrorContents.length = 0;
      csvErrors.value = "";
      validateCsv(results);
      //console.log("CSV Contents", csvContents);
    },
  });
}

function validateCsv(results) {
  if (results.errors.length > 0) {
    csvErrors.value =
      "There were errors importing the CSV, please check data carefully. ";
  }
  results.data.forEach((result) => {
    var contentError = false;
    var validatedResult = validateDate(result);
    if (!validatedResult) {
      contentError = true;
    }
    validatedResult = validateTime(result);
    if (!validatedResult) {
      contentError = true;
    }
    if (!checkRequiredFields(result) || !checkForDuplicates(result)) {
      //onsole.log(checkForDuplicates(result));
      contentError = true;
    }
    if (contentError) {
      csvErrorContents.push(result);
    } else {
      csvContents.push(validatedResult);
    }
  });
}

function validateTime(record) {
  var valid = true;
  headersSelected.forEach((header) => {
    if (header.time) {
      const timeString = record[header.value];
      if (/^([0-2][0-9]:[0-5][0-9])/.test(timeString)) {
        console.log("Short Format Time");
        record[header.value] = timeString + ":00";
        return;
      }
      if (/^([0-2][0-9]:[0-5][0-9]:[0-5][0-9])/.test(timeString)) {
        console.log("Long Format Time");
        return;
      }
      valid = false;
    }
  });
  return record;
}

function validateDate(record) {
  var valid = true;
  headersSelected.forEach((header) => {
    if (header.date) {
      console.log(record[header.value]);
      const dateString = record[header.value];
      var date;
      valid = false;
      //Lets check for dd/mm/yyyy format
      if (
        /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(
          dateString
        )
      ) {
        var dateParts = dateString.split("/");
        date = new Date(
          dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0] + " 01:00:00"
        );
        console.log(date);
        if (date == "Invalid Date") {
          valid = false;
          return;
        }
        valid = true;
        record[header.value] = date.toISOString().split("T")[0];
      }
      // Check for yyyy-mm-dd format
      if (/^([2][0][0-9][0-9]-[0-1][0-9]-[0-3][0-9])/.test(dateString)) {
        console.log("yyyy-mm-dd format");
        date = new Date(dateString + " 01:00:00");
        if (date == "Invalid Date") {
          valid = false;
          return;
        }
        valid = true;
        record[header.value] = date.toISOString().split("T")[0];
      }
      if (!valid) {
        csvErrors.value += "Invalide Date Format " + dateString;
      }
    }
  });
  if (!valid) {
    return false;
  }
  return record;
}

function checkForDuplicates(record) {
  if (!props.currentData?.length) {
    return true;
  }
  //console.log("Checking ", record);
  var statusGood = true;
  props.currentData.forEach((current) => {
    //console.log(current);
    if (
      record[props.duplicateCheckField] == current[props.duplicateCheckField]
    ) {
      console.log("Found Duplicate");
      csvErrors.value +=
        "Duplicate Record " + record[props.duplicateCheckField];
      statusGood = false;
    }
  });
  //console.log("how");
  return statusGood;
}

function checkRequiredFields(record) {
  var statusGood = true;
  headersSelected.forEach((header) => {
    if (header.required) {
      console.log(header);
      // eslint-disable-next-line
      if (!record.hasOwnProperty(header.value)) {
        statusGood = false;
        csvErrors.value += "Required field '" + header.value + "' is missing. ";
      } else {
        if (record[header.value] == "") {
          csvErrors.value +=
            "Required field '" + header.value + "' is null value. ";
          statusGood = false;
        }
      }
    }
  });
  return statusGood;
}

const assetsTopText =
  "Assets can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const assetsTitle = "Import Asset Data";

const contactsTopText =
  "Contacts can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const contactsTitle = "Import Contacts Data";

const deliveriesTopText =
  "Delivery slots can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const deliveriesTitle = "Import Delivery Slots";

const ordersTopText =
  "Orders can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const ordersTitle = "Import Orders";

const addressesTopText =
  "Delivery addresses can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const addressesTitle = "Import Delivery Addresses";

const title = ref("");
const topText = ref("");
var headersSelected = reactive([]);

function dataTableHeaders() {
  const result = [];
  headersSelected.forEach((header) => {
    result.push({ text: header.text, value: header.value });
  });
  return result;
}

function blankCSV() {
  var data = {};
  headersSelected.forEach((header) => {
    data[header.value] = "";
  });
  return [data];
}

onMounted(() => {
  console.log(props);
  switch (props.importType) {
    case "assets":
      title.value = assetsTitle;
      topText.value = assetsTopText;
      assetsHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    case "contacts":
      title.value = contactsTitle;
      topText.value = contactsTopText;
      contactsHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    case "deliveries":
      title.value = deliveriesTitle;
      topText.value = deliveriesTopText;
      deliveryHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    case "orders":
      title.value = ordersTitle;
      topText.value = ordersTopText;
      orderHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    case "addresses":
      title.value = addressesTitle;
      topText.value = addressesTopText;
      addressesHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    default:
      console.log("No Type Prop set for cat24ImportCSV");
  }
});

const alert = ref("");

async function completeImport() {
  saving.value = true;
  var importedRows = 0;
  try {
    const chunkSize = 2000;
    for (let i = 0; i < csvContents.length; i += chunkSize) {
      console.log("Chunking - Start Point " + i);
      const chunk = csvContents.slice(i, i + chunkSize);
      const payload = {
        apiAction: "add",
        customer: appBar.selected,
        [props.importType]: chunk,
      };
      await apiQuery("ELA", "cat24/" + props.importType, payload);
      importedRows = i + chunkSize;
    }
    emit("updated");
  } catch (error) {
    if (importedRows > 0) {
      alert.value =
        "Error Importing Records, however rows up to " +
        importedRows +
        " were imported correctly";
    } else {
      alert.value = "Error Adding Records";
    }

    console.log(error);
    saving.value = false;
  }
}
</script>
