// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify, ThemeDefinition } from "vuetify";
import Vue3EasyDataTable from "vue3-easy-data-table";

import elevateIcon from "@/customIcons/elevateIcon.vue";
import command24Icon from "@/customIcons/command24Icon.vue";
import { aliases, mdi } from "vuetify/iconsets/mdi";
//import type { IconSet, IconProps } from "vuetify";
import { h } from "vue";
import { IconProps } from "vuetify";
import { IconSet } from "vuetify";

const customSvgNameToComponent: any = {
  elevateIcon,
  command24Icon,
};

const customSVGs: any = {
  component: (props: any) => h(customSvgNameToComponent[props.icon]),
};

const aliasesCustom = {
  ...aliases,
  elevateIcon,
  command24Icon,
};

// const myCustomLightTheme: ThemeDefinition = {
//   dark: false,
//   colors: {
//     background: "#FFFFFF",
//     surface: "#FFFFFF",
//     primary: "#6200EE",
//     "primary-darken-1": "#3700B3",
//     secondary: "#03DAC6",
//     "secondary-darken-1": "#018786",
//     error: "#B00020",
//     info: "#2196F3",
//     success: "#4CAF50",
//     warning: "#FB8C00",
//   },
// };

// Jigsaw Orange = #e7541e

export default createVuetify({
  icons: {
    defaultSet: "mdi",
    // aliases: {
    //   ...aliasesCustom,
    // },
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  theme: {
    defaultTheme: "jigsawLight",
    themes: {
      myCustomLightTheme: {
        dark: false,
        colors: {
          background: "#ffffff",
          surface: "#000000",
          primary: "#6200EE",
          "primary-darken-1": "#3700B3",
          secondary: "#e7541e",
          "secondary-darken-1": "#018786",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
      jigsawLight: {
        dark: false,
        colors: {
          background: "#ffffff",
          surface: "#ffffff",
          primary: "#e7541e",
          "primary-darken-1": "#3700B3",
          secondary: "#e7541e",
          "secondary-darken-1": "#018786",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
      jigsawDark: {
        dark: true,
        colors: {
          background: "#ffffff",
          surface: "#000000",
          primary: "#e7541e",
          "primary-darken-1": "#3700B3",
          secondary: "#e7541e",
          "secondary-darken-1": "#018786",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },

      whiteTheme: {
        dark: false,
        colors: {
          background: "#FFFFFF",
          surface: "#FFFFFF",
          primary: "#6200EE",
          "primary-darken-1": "#3700B3",
          secondary: "#03DAC6",
          "secondary-darken-1": "#018786",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
