<script setup>
import { reactive, ref, onMounted, defineProps } from "vue";
import { apiQuery } from "@/api/j24api";

onMounted(() => {
  getData();
});

const props = defineProps({
  appId: String,
});

let appData = ref({ patches: [] });

const headers = [
  { text: "Version", value: "version", sortable: true },
  { text: "Package Name", value: "packageName", sortable: true },
  { text: "Release Date", value: "releaseDate", sortable: true },
  { text: "Approval Date", value: "approvedAt", sortable: true },
  { text: "Approved By", value: "approvedBy", sortable: true },
];

const loading = ref(false);

async function getData() {
  loading.value = true;
  let result = await apiQuery("ELA", "patching/patchingVersions", {
    apiAction: "GetAppDetail",
    appId: props.appId,
  });
  if (result.appData) {
    appData.value = result.appData;
  }
  loading.value = false;
}

const showDialog = ref(false);
const manualVersion = ref({});
function addUnknownClick() {
  showDialog.value = true;
  manualVersion.value = {};
}

async function manualVersionConfirm() {
  loading.value = true;
  await apiQuery("ELA", "patching/patchingVersions", {
    apiAction: "AddManualVersion",
    newVersion: { _id: appData.value._id, ...manualVersion.value },
  });
  showDialog.value = false;
  getData();
}

const showRawData = ref(false);
const rawData = ref({});
function displayRawData(data) {
  rawData.value = JSON.stringify(data, undefined, 3);
  showRawData.value = true;
}
</script>

<template>
  <v-dialog v-model="showRawData" max-width="900">
    <v-card>
      <v-card-title
        ><v-row>
          <v-col cols="auto">Raw Data</v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto"
            ><v-icon @click="showRawData = false">mdi-close</v-icon></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-textarea v-model="rawData" rows="20" readonly></v-textarea>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showDialog" max-width="500">
    <v-card>
      <v-card-title>Add Manual Version</v-card-title>
      <v-card-text>
        <strong>WARNING!</strong> do not add a version which is newer than the
        previous version in this definition. The previous version is
        {{ appData.previousVersion }}
        <v-text-field
          label="Patch Version"
          v-model="manualVersion.version"
        ></v-text-field>
        <v-text-field
          label="Patch Name"
          hint="Optional"
          persistent-hint
          v-model="manualVersion.pkgName"
        ></v-text-field>
        <v-text-field
          label="Package MD5"
          hint="Optional"
          persistent-hint
          v-model="manualVersion.pkgMd5"
        ></v-text-field>
        <v-card-actions>
          <v-row>
            <v-col cols="auto">
              <v-btn variant="tonal" @click="showDialog = false">Cancel</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto"
              ><v-btn
                v-if="manualVersion.version"
                :loading="loading"
                variant="tonal"
                @click="manualVersionConfirm()"
                >Submit</v-btn
              ></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-row>
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <v-btn @click="displayRawData(appData)"
        ><v-icon>mdi-code-json</v-icon></v-btn
      >
    </v-col>
    <v-col cols="auto">
      <v-btn @click="addUnknownClick()"> Add Unknown Version </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <EasyDataTable
        showIndex
        :headers="headers"
        :items="appData.patches"
        :loading="loading"
        alternating
        theme-color="#e7541e"
      >
        <template #item-index="item"
          ><v-icon @click="displayRawData(item)"
            >mdi-code-json</v-icon
          ></template
        >
      </EasyDataTable>
    </v-col>
  </v-row>
</template>
