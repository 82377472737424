<script setup>
import { apiQuery } from "@/api/j24api";
import { ref, reactive, defineProps, defineEmits, onMounted, watch } from "vue";
import { appBarStore } from "@/stores/appBar";
import ELaComputerPatching from "./ElaComputerPatchingLogs.vue";
import ElaComputerCommands from "./ElaComputerCommands.vue";
import ElaComputerCIS from "./ElaComputerCIS.vue";
import Jamf from "./mdmTemplates/Jamf";
import JamfMobileDevice from "./mdmTemplates/JamfMobileDevice";
import IntuneMobileDevice from "./mdmTemplates/IntuneMobileDevice";
import Intune from "./mdmTemplates/Intune";
import BasicTable from "./components/BasicTable.vue";
import ElaAgentData from "./ElaAgentData.vue";

import { permissionsStore } from "@/stores/permissions";
import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();

const permissions = permissionsStore();

const appBar = appBarStore();
const selectedTab = ref("General");

const props = defineProps({
  data: Object,
});

//const dataDetail = ref({});
const emit = defineEmits(["back"]);
const loading = ref(false);

const devicePage = reactive([]);
const deviceDetail = ref({});

const alertShow = ref(false);
const alertTxt = ref("");

const mobileDevice = ref(false);

import { useDisplay } from "vuetify";

const { mobile } = useDisplay();

onMounted(async () => {
  await getDetail();
});

// Add watcher to props.data to run getDetail when it changes
watch(
  () => props.data,
  async () => {
    devicePage.length = 0;
    deviceDetail.value = {};
    alertShow.value = false;
    alertTxt.value = "";
    mobileDevice.value = false;
    await getDetail();
  }
);

function getLatestVersion(version) {
  // Get Major version from version string 14.5.6 -> 14
  var majorVersion = version.split(".")[0];
  var latestVersion = version;
  try {
    if (props.macOSVersions) {
      props.appleUpdates.PublicAssetSets.macOS.forEach((assetSet) => {
        if (assetSet.ProductVersion.split(".")[0] == majorVersion) {
          latestVersion = assetSet.ProductVersion;
        }
      });
    }
    if (props.iOSVersions) {
      props.appleUpdates.PublicAssetSets.iOS.forEach((assetSet) => {
        if (assetSet.ProductVersion.split(".")[0] == majorVersion) {
          if (
            assetSet.SupportedDevices.find((device) => device.includes("iPad"))
          ) {
            latestVersion = assetSet.ProductVersion;
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  return latestVersion;
}

function compareVersion(version1, version2) {
  if (version1 == version2) {
    return 0;
  }
  // Compare version number strings in format 14.2.3 to determine which is newer can be in format 14.2 or 14.2.4
  var v1 = version1.split(".");
  var v2 = version2.split(".");
  var v1Length = v1.length;
  var v2Length = v2.length;
  var length = v1Length > v2Length ? v1Length : v2Length;
  for (var i = 0; i < length; i++) {
    var v1Num = Number(v1[i]) || 0;
    var v2Num = Number(v2[i]) || 0;
    if (v1Num > v2Num) {
      return 1;
    } else if (v1Num < v2Num) {
      return -1;
    }
  }
}

function squashVersions(data) {
  var versions = [];
  data.forEach((item) => {
    var version = [
      {
        count: 0,
        version: "Older",
      },
      {
        count: 0,
        version: "Latest",
      },
    ];
    if (item.versions.length) {
      let latestVersion = getLatestVersion(item.versions[0].version);
      item.versions.forEach((v) => {
        if (compareVersion(v.version, latestVersion) >= 0) {
          version[0].count++;
        } else {
          version[1].count++;
        }
      });

      versions.push({
        versionName: item.versionName,
        versions: version,
      });
    }
  });
  //console.log("Squashed Versions", versions);
  return versions;
}

async function getDetail() {
  loading.value = true;
  try {
    var queryRes;
    // If device is a Jamf mobile device query mobile device instead.
    if (props.data.type == "mobileDevice") {
      mobileDevice.value = true;
      queryRes = await apiQuery("ELA", "cmd24/query", {
        mdmServerId: appBar.selected,
        dataRequested: "devicesDetail",
        deviceIds: [props.data.id],
        maxage: 86400,
      });
      //console.log(queryRes);
      queryRes = queryRes[0];
    } else {
      queryRes = await apiQuery("ELA", "cmd24/query", {
        mdmServerId: appBar.selected,
        dataRequested: "computerDetail",
        id: props.data.id,
        maxage: 86400,
      });
    }
    if (queryRes.result) {
      //console.log("MDM Type", mdmServer.$state.type);
      deviceDetail.value = queryRes.result;
      switch (mdmServer.$state.type) {
        case "Jamf":
          if (props.data.type == "mobileDevice") {
            devicePage.push(...JamfMobileDevice(queryRes.result));
            applicationsHeaders.length = 0;
            applicationsHeaders.push(...mobileApplicationsHeaders);
            deviceDetail.value.applications =
              deviceDetail.value.ios?.applications;
          } else {
            devicePage.push(...Jamf(queryRes.result));
          }
          break;
        case "Intune":
          if (props.data.type == "mobileDevice") {
            devicePage.push(...IntuneMobileDevice(queryRes.result));
          } else {
            devicePage.push(...Intune(queryRes.result));
          }
          break;
        case "Kandji":
          //console.log("queryRes", queryRes);
          if (queryRes.result.device.general.platform == "Mac") {
            let translated = translateKandjiMac(queryRes.result.device);
            //console.log(translated);
            devicePage.push(...Jamf(translated));
            deviceDetail.value.applications = translated.applications;
          } else {
            let translated = translateKandjiDevice(queryRes.result.device);
            devicePage.push(...JamfMobileDevice(translated));
            deviceDetail.value.applications = translated.applications;
            applicationsHeaders.length = 0;
            applicationsHeaders.push(...mobileApplicationsHeaders);
          }

          break;
        default:
          console.log("Invalid MDM Type");
      }
    }
  } catch (error) {
    console.log(error);
    alertTxt.value = "Unable to get device record";
    alertShow.value = true;
  }
  loading.value = false;
}

function translateKandjiDevice(d) {
  var capacity = 0;
  if (d.volumes.length) {
    capacity = d.volumes[0].capacity.split(" ")[0]
      ? Number(d.volumes[0].capacity.split(" ")[0]) * 1024
      : 0;
  }
  var available = 0;
  if (d.volumes.length) {
    available = d.volumes[0].available.split(" ")[0]
      ? Number(d.volumes[0].available.split(" ")[0]) * 1024
      : 0;
  }
  const translated = {
    serialNumber: d.hardware_overview.serial_number,
    id: d.device_id,
    lastInventoryUpdateTimestamp: d.mdm.last_check_in,
    lastEnrollmentTimestamp: d.general.last_enrollment,
    udid: d.hardware_overview.udid,
    wifiMacAddress: d.network.mac_address,

    ios: {
      model: d.general.model,
      modelIdentifier: d.hardware_overview.model_identifier,
      capacityMb: capacity,
      availableMb: available,
      security: {
        deviceActivationLockEnabled:
          d.activation_lock.device_activation_lock_enabled,
        userActivationLockEnabled:
          d.activation_lock.user_activation_lock_enabled,
        activationLockSupported: d.activation_lock.activation_lock_supported,
      },
    },
  };

  var applications = [];
  if (d.applications.apps.length) {
    applications = d.applications.apps.map((app) => {
      return {
        name: app.app_name,
        shortVersion: app.version,
        identifier: app.bundle_id,
      };
    });
  }
  translated.applications = applications;
  return translated;
}

function translateKandjiMac(d) {
  //console.log(d);
  var localUserAccounts = [];
  if (d.users.regular_users.length) {
    localUserAccounts = d.users.regular_users.map((user) => {
      return {
        fullName: user.name,
        homeDirectory: user.path,
        ...user,
      };
    });
  }

  var diskEncryption = {
    bootPartitionEncryptionDetails: {
      partitionFileVault2State: "Not Encrypted",
    },
    individualRecoveryKeyValidityStatus: "Invalid",
  };
  if (d.filevault?.filevault_enabled) {
    diskEncryption.bootPartitionEncryptionDetails.partitionFileVault2State =
      "Encrypted";
    if (d.filevault?.filevault_prk_escrowed) {
      diskEncryption.individualRecoveryKeyValidityStatus = "Valid";
    }
  }

  const translated = {
    localUserAccounts: localUserAccounts,
    diskEncryption: diskEncryption,
    hardware: {
      serialNumber: d.hardware_overview.serial_number,
      model: d.general.model,
      totalRamMegabytes: d.hardware_overview.memory
        ? d.hardware_overview.memory.split(" ")[0] * 1024
        : 0,
      modelIdentifier: d.hardware_overview.model_identifier,
      processorArchitecture: d.hardware_overview.processor_name.includes(
        "Apple"
      )
        ? "arm64"
        : "x86_64",
      processorType: d.hardware_overview.processor_name,
      coreCount: d.hardware_overview.total_number_of_cores,
      macAddress: d.network.mac_address,
    },
    general: {
      lastContactTime: d.kandji_agent.last_check_in,
      assetTag: d.general.asset_tag,
    },
    operatingSystem: { version: d.general.os_version },
    userAndLocation: { username: d.general.last_user },
  };
  //console.log("Translated", translated);

  var applications = [];
  if (d.applications.apps.length) {
    applications = d.applications.apps.map((app) => {
      return {
        name: app.app_name,
        version: app.version,
        path: app.path,
        bundleId: app.bundle_id,
      };
    });
  }
  translated.applications = applications;
  return translated;
}

// This function should not be needed but prettier will not allow  -  in template
function checkPermission(permission) {
  // console.log(permissions.$state);
  // console.log(permissions[permission.replace(" ", "")]);
  try {
    return permissions.$state[permission];
  } catch (error) {
    console.log(error);
    return false;
  }
  // return permissions[permission.$state[permission.replace(" ", "")]];
}

function shouldShowTab(permission, mdmOption) {
  //return true;
  // console.log("MDMSERVER", JSON.stringify(mdmServer.$state));
  // console.log(mobileDevice.value);
  // console.log(mdmServer.$state.options);
  // console.log(mdmServer.$state.options[mdmOption]);
  // console.log(checkPermission(permission));

  // if (mobileDevice.value) {
  //   return false;
  // }
  if (!mdmServer.$state.options) {
    return false;
  }
  if (!mdmServer.$state.options[mdmOption]) {
    return false;
  }
  if (!checkPermission(permission)) {
    return false;
  }
  return true;
}

function searchFields(headers) {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

const applicationsSearchValue = ref("");
const applicationsHeaders = [
  {
    text: "Name",
    value: "name",
    sortable: true,
  },
  {
    text: "Path",
    value: "path",
    sortable: true,
  },
  {
    text: "Version",
    value: "version",
    sortable: true,
  },
];

const mobileApplicationsHeaders = [
  {
    text: "Name",
    value: "name",
    sortable: true,
  },
  {
    text: "Version",
    value: "shortVersion",
    sortable: true,
  },
  {
    text: "Identifier",
    value: "identifier",
    sortable: true,
  },
];

function findSerialNumber() {
  var serialNumber = "";
  devicePage.forEach((section) => {
    section.fields.forEach((item) => {
      if (item.name == "Serial Number") {
        serialNumber = item.value;
      }
    });
  });
  return serialNumber;
}
</script>

<template>
  <v-row>
    <v-col cols="auto" :class="mobile ? '' : 'ml-2 mb-3'">
      <v-btn @click="$emit('back')" prepend-icon="mdi-arrow-left">Back</v-btn>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <h2 v-if="props.data.general.name.length < 18">
        {{ props.data.general.name }}
      </h2>
      <h4 v-else>
        {{ props.data.general.name }}
      </h4>
    </v-col>
  </v-row>

  <v-tabs v-model="selectedTab" color="#e7541e" class="mb-3">
    <v-tab value="General" :size="mobile ? 'small' : 'default'"
      >Information</v-tab
    >
    <v-tab value="Applications" :size="mobile ? 'small' : 'default'"
      >Applications</v-tab
    >
    <v-tab
      v-if="shouldShowTab('CMD24-Commands', 'commands')"
      value="Commands"
      :size="mobile ? 'small' : 'default'"
      >Commands</v-tab
    >
    <v-tab
      v-if="shouldShowTab('CMD24-PatchDetails', 'showPatchLogs')"
      value="PatchingLogs"
      >Patching Logs</v-tab
    >
    <v-tab
      v-if="shouldShowTab('CMD24-AgentCISData', 'agentCIS')"
      value="AgentData"
      >Agent Data</v-tab
    >
    <v-tab v-if="shouldShowTab('CMD24-AgentCISData', 'agentCIS')" value="CIS"
      >Security</v-tab
    >
  </v-tabs>

  <v-alert
    class="my-3"
    v-model="alertShow"
    type="error"
    closable
    density="compact"
    >{{ alertTxt }}
  </v-alert>
  <template v-if="loading">
    <v-row class="pt-6">
      <v-spacer></v-spacer>
      <v-col>
        <v-progress-circular indeterminate size="64" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>
  <v-window v-model="selectedTab">
    <v-window-item value="General">
      <template v-for="section in devicePage" :key="section">
        <BasicTable :data="section" />
      </template>
    </v-window-item>

    <v-window-item value="Applications">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field
                class="pb-2"
                label="Search..."
                v-model="applicationsSearchValue"
                variant="underlined"
                hide-details
                density="compact"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>

          <EasyDataTable
            class="pa-3"
            v-if="deviceDetail.applications"
            :items="deviceDetail.applications"
            :headers="applicationsHeaders"
            :search-value="applicationsSearchValue"
            :search-field="searchFields(applicationsHeaders)"
            theme-color="#e7541e"
          />
        </v-card-text>
      </v-card>
    </v-window-item>

    <v-window-item value="Commands">
      <ElaComputerCommands :data="data" :serialNumber="findSerialNumber()" />
    </v-window-item>

    <v-window-item value="PatchingLogs">
      <ELaComputerPatching :data="data" />
    </v-window-item>

    <v-window-item value="CIS">
      <ElaComputerCIS :data="data" />
    </v-window-item>

    <v-window-item value="AgentData">
      <ElaAgentData :data="data" />
    </v-window-item>
  </v-window>
</template>
