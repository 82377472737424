<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const expandedRole = ref("");
const loading = ref(false);
const editRole = ref(false);
const editRoleKey = ref(0);
const newRole = ref(false);
const newRoleContent = reactive({
  deleted: false,
  enabledPermissions: [],
  name: "",
  apiAction: "add",
});

async function deleteRole() {
  loading.value = true;
  roles[editRoleKey.value].deleted = true;
  await apiQuery("J24Auth", "roles", {
    apiAction: "update",
    ...roles[editRoleKey.value],
  });
  editRole.value = false;
  getRoles();
}

async function createNewRole() {
  await apiQuery("J24Auth", "roles", newRoleContent);
  newRoleContent.name = "";
  newRole.value = false;
  getRoles();
}

function deletePermission(key) {
  roles[editRoleKey.value].enabledPermissions.splice(key, 1);
}

var roles = reactive([]);
async function getRoles() {
  loading.value = true;
  try {
    const result = await apiQuery("J24Auth", "roles", { apiAction: "get" });
    roles.length = 0;
    roles.push(...result);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

const addPermission = ref(false);
var permissions = reactive([]);
var permissionsToAdd = reactive([]);
async function getPermissions() {
  addPermission.value = true;
  loading.value = true;
  try {
    const result = await apiQuery("J24Auth", "getpermission", "");
    if (permissions.lenth) {
      permissions.length = 0;
    }
    permissions.push(...result);
    for (const key in permissions) {
      var tempArray = permissions[key];
      //console.log(tempArray.actions);
      tempArray.actions2 = [];
      for (const actionKey in tempArray.actions) {
        tempArray.actions2.push({
          action: tempArray.actions[actionKey],
          enabled: false,
        });
      }
      delete tempArray.actions;
      tempArray.actions = tempArray.actions2;
      delete tempArray.actions2;
      permissionsToAdd.push(tempArray);
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

function savePermissionsToAdd() {
  permissionsToAdd.forEach(function (permission) {
    var adding = false;
    var tempObject = {};
    tempObject.permissionName = permission.permissionName;
    tempObject.app = permission.app;
    tempObject.actions = [];
    permission.actions.forEach(function (action) {
      if (action.enabled) {
        adding = true;
        tempObject.actions.push(action.action);
      }
    });
    if (adding) {
      roles[editRoleKey.value].enabledPermissions.push(tempObject);
    }
  });
  addPermission.value = false;
}

async function saveRoleChanges() {
  var tempArray = {};
  tempArray = roles[editRoleKey.value];
  tempArray.apiAction = "update";
  const result = await apiQuery("J24Auth", "roles", tempArray);
  editRole.value = false;
  getRoles();
}

onMounted(() => {
  getRoles();
});
</script>

<template>
  <v-dialog v-model="loading" hide-overlay persistent>
    <v-card color="grey">
      <v-card-text>
        Loading
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <h1>Roles Management</h1>
  <v-icon @click="newRole = true">mdi-plus-circle</v-icon>

  <div v-if="newRole">
    <h4>Create New Role</h4>
    <v-form>
      <v-text-field
        v-model="newRoleContent.name"
        label="Role Name"
      ></v-text-field>
      <v-btn @click="createNewRole">Save</v-btn>
    </v-form>
  </div>

  <div v-if="editRole">
    <h2>Edit Role</h2>
    <v-form>
      <v-text-field
        v-model="roles[editRoleKey].name"
        label="Role Name"
      ></v-text-field>
      <v-table v-if="addPermission">
        <thead class="text-left">
          <th>App</th>
          <th>Permission Name</th>
          <th>Actions</th>
        </thead>
        <tbody v-if="permissionsToAdd.length" class="text-left">
          <tr v-for="permission in permissionsToAdd" :key="permission._id">
            <td>{{ permission.app }}</td>
            <td>{{ permission.permissionName }}</td>
            <td>
              <template v-for="action in permission.actions" :key="action"
                ><input type="checkbox" v-model="action.enabled" />{{
                  action.action
                }}</template
              >
            </td>
          </tr>
        </tbody>
        <v-btn @click="savePermissionsToAdd()">Save</v-btn
        ><v-btn @click="addPermission.value = false">Cancel</v-btn>
        <br />
      </v-table>
      <v-table v-else>
        <thead class="text-left">
          <th><v-icon @click="getPermissions()">mdi-plus-circle</v-icon>App</th>
          <th>Permission</th>
          <th>Actions</th>
          <th></th>
        </thead>
        <tbody
          v-if="roles[editRoleKey].enabledPermissions.length"
          class="text-left"
        >
          <tr
            v-for="(enabledPermission, key) in roles[editRoleKey]
              .enabledPermissions"
            :key="enabledPermission"
            class="text-left"
          >
            <td>{{ enabledPermission.app }}</td>
            <td>{{ enabledPermission.permissionName }}</td>
            <td>{{ enabledPermission.actions }}</td>
            <td>
              <v-btn @click="deletePermission(key)">Delete</v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">No Permissions Attached</td>
          </tr>
        </tbody>
      </v-table>
    </v-form>
    <v-btn @click="saveRoleChanges()">Save</v-btn
    ><v-btn @click="deleteRole()">Delete</v-btn>
    <v-btn @click="editRole = false">Back</v-btn>
  </div>

  <div v-if="roles.length && !editRole">
    <v-table>
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(role, key) in roles" :key="role._id">
          <tr>
            <td>
              <div v-if="expandedRole == role._id">
                <div @click="expandedRole = null">
                  <v-icon>mdi-chevron-down</v-icon>{{ role.name }}
                </div>
              </div>
              <div v-else @click="expandedRole = role._id">
                <v-icon>mdi-chevron-right</v-icon>{{ role.name }}
              </div>
            </td>
            <td></td>
            <td>
              <v-icon
                @click="
                  editRoleKey = key;
                  editRole = true;
                "
                >mdi-pencil</v-icon
              >
            </td>
          </tr>
          <template v-if="expandedRole == role._id">
            <tr
              v-for="enabledPermission in role.enabledPermissions"
              :key="enabledPermission"
            >
              <td>{{ enabledPermission.app }}</td>
              <td>{{ enabledPermission.permissionName }}</td>
              <td>{{ enabledPermission.actions }}</td>
            </tr>
          </template>
        </template>
      </tbody>
    </v-table>
  </div>
</template>

<style></style>
