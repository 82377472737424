<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";
import * as pako from "pako";

const patchItems = reactive([]);
const loading = ref(false);
const query = ref("");
const report = reactive([]);

async function loadData() {
  patchItems.length = 0;
  loading.value = true;
  var apiresults = await apiQuery("ELA", "patching/patchitems", {
    apiAction: "GetPatchItem",
  });

  const charData = atob(apiresults)
    .split("")
    .map((x) => {
      return x.charCodeAt(0);
    });
  const binData = new Uint8Array(charData);
  var results = await JSON.parse(pako.inflate(binData, { to: "string" }));

  results.forEach((result) => {
    patchItems.push({
      ...result,
    });
  });

  let appIdentifiers = getAppIdentifiers(patchItems);
  console.log(appIdentifiers);
  query.value = generateQuery(appIdentifiers);

  loading.value = false;
}

function generateQuery(appIdentifiers) {
  //   var result =
  //     "_index=PatchingInventory | dedup by serial, CFBundleIdentifier | where CFBundleIdentifier matches /^(";
  var result =
    "_index=PatchingInventory | dedup by serial, CFBundleIdentifier | where path matches /^(";
  var first = true;
  appIdentifiers.forEach((identifier) => {
    if (first) {
      result += identifier;
      first = false;
    } else {
      result += "|" + identifier;
    }
  });
  result +=
    ")$/ | fields serial, CFBundleIdentifier, architecture, path | count by path, cfbundleidentifier, architecture | sort by _count";
  return result;
}

function getAppIdentifiers(data) {
  let results = [];
  data.forEach((title) => {
    if (title.installPath) {
      results.push(title.installPath.replaceAll("/", "\\/"));
    }
    // if (title.AppleSilicon) {
    //   if (title.AppleSilicon.bundleId) {
    //     if (!results.includes(title.AppleSilicon.bundleId)) {
    //       //results.push(title.AppleSilicon.bundleId);
    //       let parts = title.installPath.split("/");

    //       results.push(parts[parts.length -1]);
    //     }
    //   }
    // }
    // if (title.Intel) {
    //   if (title.Intel.bundleId) {
    //     if (!results.includes(title.Intel.bundleId)) {
    //       //results.push(title.Intel.bundleId);
    //       results.push(title.installPath.replaceAll("/", "//"));
    //     }
    //   }
    // }
  });
  return results;
}

async function getResults() {
  loading.value = true;
  const results = await apiQuery("ELA", "patching/patchusagereport", {
    query: query.value,
  });
  generateReport(results.records);
  console.log(results);
  loading.value = false;
}

// function reportResult(report, bundleId) {
//   console.log(report);
//   console.log("BundleID", bundleId);
//   if (!bundleId) {
//     return {};
//   }
//   var result = {};
//   report[0].forEach((a) => {
//     console.log(a);
//     if (a.map.cfbundleidentifier == bundleId) {
//       console.log("Found Match");
//       console.log(a.map["_count"]);
//       console.log(a.map.architecture);
//       result[a.map.architecture.replace("_", "")] = a.map["_count"];
//     }
//   });
//   console.log("Result", result);
//   return result;
// }

function reportResult(report, path) {
  var result = {};
  report[0].forEach((a) => {
    if (a.map.path == path) {
      result[a.map.architecture.replace("_", "")] = a.map["_count"];
    }
  });
  result.total = 0;
  if (result.arm64) {
    result.total += +result.arm64;
  }
  if (result.x8664) {
    result.total += +result.x8664;
  }
  return result;
}

function generateReport(reportResults) {
  report.length = 0;
  patchItems.forEach((title, index) => {
    patchItems[index].report = reportResult(reportResults, title.installPath);
    // console.log("Index", index);
    // try {
    //   patchItems[index].AppleSilicon.report = reportResult(
    //     reportResults,
    //     title.AppleSilicon.bundleId
    //   );
    // } catch (error) {
    //   console.log(error);
    //   console.log(title);
    // }
    // try {
    //   patchItems[index].Intel.report = reportResult(
    //     reportResults,
    //     title.Intel.bundleId
    //   );
    // } catch (error) {
    //   console.log(error);
    //   console.log(title);
    // }
  });
}

onMounted(() => {
  //loadData();
});

const headers = [
  { text: "Name", value: "name", sortable: "true" },
  { text: "Install Path", value: "installPath", sortable: "true" },
  //   { text: "AS BundleId", value: "AppleSilicon.bundleId", sortable: "true" },
  //   { text: "Intel BundleId", value: "Intel.bundleId", sortable: "true" },
  {
    text: "AS Installed",
    value: "report.arm64",
    sortable: "true",
  },
  {
    text: "Intel Installed",
    value: "report.x8664",
    sortable: "true",
  },
  { text: "Total", value: "report.total", sortable: "true" },
];
</script>

<template>
  <h1>Note: This page is under development</h1>
  <v-btn :loading="loading" @click="loadData()">Generate Query</v-btn>
  <template v-if="query">
    <v-textarea label="SumoLogic Query" :model-value="query"> </v-textarea>
    <v-btn :loading="loading" @click="getResults()">Get Data</v-btn>
    <EasyDataTable
      :headers="headers"
      :items="patchItems"
      show-index
    ></EasyDataTable>
  </template>
</template>
