<script setup>
import { defineProps } from "vue";
const props = defineProps({
  object: {},
  level: Number,
});

function hasChildItems(item) {
  if (typeof item == "object") {
    return true;
  }
}
</script>

<template>
  <v-card class="pl-9 pb-2">
    <template v-for="key in Object.keys(object)" :key="key">
      <template v-if="!hasChildItems(object[key])">
        <template v-if="key == 'script'">
          <tr>
            <td class="text-start">
              <strong>{{ key }}</strong>
            </td>
            <td class="pl-2" style="width: 100%">
              <v-textarea
                width="400"
                label="Click to view script contents"
                :value="object[key]"
              />
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td>
              <strong>{{ key }}</strong>
            </td>
            <td class="pl-2">{{ object[key] }}</td>
          </tr>
        </template>
      </template>
      <template v-else>
        <tr>
          <td>
            <strong>{{ key }}</strong>
          </td>
        </tr>
        <displayJSONRecursive :object="object[key]" />
      </template>
    </template>
  </v-card>
</template>
