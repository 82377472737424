<template>
  <h4 v-if="props.edit">Edit Record</h4>
  <h4 v-else>Add Record</h4>
  <template v-for="field in formFields" :key="field.value">
    <template v-if="field.type == 'select'">
      <v-select
        v-if="field.required"
        class="mt-2"
        :label="field.text + ' (Required)'"
        density="compact"
        variant="underlined"
        v-model="setFields[field.value]"
        :items="listItems(field.text, formData[0][field.value])"
      ></v-select>
      <v-select
        v-if="!field.required"
        class="mt-2"
        :label="field.text"
        density="compact"
        variant="underlined"
        v-model="searchFieldSelected"
        :items="listItems(field.text, formData[0][field.value])"
        hide-details
      ></v-select>
    </template>
    <template v-if="field.type == 'limit'">
      <v-text-field
        class="mt-2"
        :label="field.text + ' (Field limited to 30 Characters.)'"
        v-model="setFields[field.value]"
        density="compact"
        hide-details="auto"
        :maxLength="field.maxLength"
        @input="alertLimit(field.maxLength)"
      />
    </template>
    <template v-if="field.type == 'text'">
      <v-text-field
        class="mt-2"
        v-if="field.required && field.duplicateCheck != true"
        :label="field.text + ' (Required)'"
        v-model="setFields[field.value]"
        :rules="[rules.required]"
        density="compact"
        hide-details="auto"
      />
      <v-text-field
        v-if="!field.required"
        :label="field.text"
        v-model="setFields[field.value]"
        density="compact"
        class="mt-2"
      />
      <v-text-field
        class="mt-2"
        v-if="field.duplicateCheck"
        :label="field.text"
        v-model="setFields[field.value]"
        density="compact"
        hide-details="auto"
        :rules="[duplicateCheck(field.value)]"
      />
    </template>
  </template>
  <v-row class="mt-2">
    <v-col
      ><v-btn :disabled="saving" @click="save(formData)">Save</v-btn></v-col
    >
    <v-col><v-spacer></v-spacer></v-col>
    <v-col class="text-right"
      ><v-btn @click="$emit('cancel')">Cancel</v-btn></v-col
    >
  </v-row>
  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, defineEmits } from "vue";
import {
  assetsHeaders,
  contactsHeaders,
  deliveryHeaders,
  orderHeaders,
  addressesHeaders,
} from "./cat24Types";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const limitExceeded = ref(false);

const appBar = appBarStore();
const alert = ref("");

const props = defineProps({
  addType: String,
  edit: Boolean,
  item: {},
  currentData: Object,
  formData: Object,
});

const emit = defineEmits(["cancel", "updated"]);

const formFields = reactive([]);
const setFields = reactive({});
const rules = { required: (value) => !!value || "Required" };
const saving = ref(false);

function duplicateCheck(field) {
  return (value) => {
    var notDuplicate = true;
    props.currentData.forEach((current) => {
      if (current[field] == value) {
        notDuplicate = false;
      }
    });
    if (notDuplicate) {
      saving.value = false;
      return true;
    } else {
      saving.value = true;
      return "Error Duplicate Record";
    }
  };
}

function listItems(fieldName, formData) {
  var result = [];
  var data = formData;
  //console.log(data);
  data.forEach((data) => {
    //console.log(data);
    result.push(data.value);
  });
  return result;
}

function alertLimit(maxLength) {
  if (event.target.value.length == maxLength) {
    alert.value = "Please limit Address lines 1 and 2 to 30 characters only.";
  } else {
    alert.value = "";
  }
}

// const duplicateCheck = { duplicate: (value) => {
//   var notDuplicate = true;
//   props.currentData.forEach((current) => {
//     if ()
//   })
// }}

async function save(formData) {
  var payload;
  saving.value = true;
  alert.value = "";
  if (formData) {
    for (var field in setFields) {
      for (var object in formData[0]) {
        for (var item of formData[0][object]) {
          if (item.value == setFields[field]) {
            setFields[field] = item.id;
          }
        }
      }
    }
  }

  if (props.edit) {
    payload = {
      apiAction: "update",
      customer: appBar.selected,
      [props.addType]: setFields,
    };
  } else {
    payload = {
      apiAction: "add",
      customer: appBar.selected,
      [props.addType]: [setFields],
    };
  }
  try {
    console.log(payload);
    const result = await apiQuery("ELA", "cat24/" + props.addType, payload);
    emit("updated");
  } catch (error) {
    alert.value = "Error saving record";
    console.log(error);
    saving.value = false;
  }
}

onMounted(() => {
  switch (props.addType) {
    case "assets":
      assetsHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "contacts":
      contactsHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "deliveries":
      deliveryHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "neworder":
      orderHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "addresses":
      addressesHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    default:
      console.log("No addType Prop set");
  }

  if (props.item && props.edit) {
    Object.keys(props.item).forEach((key) => {
      console.log(key);
      setFields[key] = props.item[key];
    });
  }
});
</script>
