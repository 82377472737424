<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits, watch } from "vue";
import { apiQuery } from "@/api/j24api";
import { Bar } from "vue-chartjs";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  plugins,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler
);

const values = ref([]);

const props = defineProps({
  data: Object,
});

const chartData = ref({});

watch(
  () => props.data,
  (newvalue) => {
    getData();
  }
);

function checkSummarised() {
  const result = [];
  if (!props.data.summarisedData) {
    return values.value;
  }
  values.value.forEach((value) => {
    if (value.summarisedData) {
      result.push(value);
    }
  });
  return result;
}

function makeChartData() {
  const labels = [];
  const data = [];

  if (!props.data.summarisedData) {
    values.value.forEach((value) => {
      labels.push(value.date.split("T")[0]);
      data.push(value.count);
    });

    chartData.value = {
      labels: labels,
      datasets: [
        {
          data: data,
          label: props.data.name,
          borderColor: "rgb(231, 84, 30)",
          tension: 0.1,
        },
      ],
    };
    return;
  }
  const valuesSummarised = checkSummarised();
  valuesSummarised.forEach((value) => {
    labels.push(value.date.split("T")[0]);
  });

  const dataSets = [];
  valuesSummarised.forEach((value) => {
    Object.keys(value.summarisedData).forEach((key) => {
      if (!dataSets.includes(key)) {
        dataSets.push(key);
      }
    });
  });

  var chart = { labels: labels, datasets: [] };
  dataSets.forEach((label) => {
    const dataPoints = [];
    valuesSummarised.forEach((value) => {
      if (value.summarisedData[label]) {
        dataPoints.push(value.summarisedData[label]);
      } else {
        dataPoints.push(0);
      }
    });
    chart.datasets.push({
      data: dataPoints,
      label: label,
      fill: true,
      //backgroundColor: "rgb(231, 84, 30)",
    });
  });
  console.log(labels);
  console.log(chart);
  chartData.value = addChartColors(chart);
}

const chartOptions = {
  plugins: {
    legend: {
      display: true,
    },
  },
  responsive: true,
  scales: { yAxes: { suggestedMin: 0, stacked: true } },
  // scales: { xAxes: [{ ticks: { maxRotation: 90, minRotation: 90 } }] },
};

const height = 200;
const loading = ref(false);
var lastdataSourceID = "";

async function getData() {
  values.value.length = 0;
  loading.value = true;
  lastdataSourceID = props.data.id;
  const result = await apiQuery("ELA", "dashboard/data", {
    mdmServerId: props.data.mdmServerId,
    dataSourceId: props.data.id,
    name: props.data.name,
  });
  if (result) {
    values.value = result;
  }
  makeChartData();
  loading.value = false;
}

onMounted(() => {
  getData();
});

function addChartColors(chartData) {
  //var chartData = chartData;
  const colors = [
    "#e8dff5",
    "#fce1e4",
    "#fcf4dd",
    "#ddedea",
    "#daeaf6",
    "#d4afb9",
    "#d1cfe2",
    "#9cadce",
    "#7ec4cf",
    "#52b2cf",
  ];
  var counter = 0;
  var colourCounter = 0;
  const coloursRequired = chartData.datasets.length;
  while (counter < coloursRequired) {
    // if (!chartData.datasets[0].backgroundColor) {
    //   chartData.datasets[0].backgroundColor = [colors[colourCounter]];
    // } else {
    //   chartData.datasets[0].backgroundColor.push(colors[colourCounter]);
    // }
    //console.log(chartData.datasets[counter]);
    chartData.datasets[counter].backgroundColor = colors[colourCounter];
    if (colourCounter == colors.length) {
      colourCounter = 0;
    }
    colourCounter++;
    counter++;
  }
  return chartData;
}
</script>

<template>
  <v-card>
    <div class="text-right">
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <Line
      v-if="values.length"
      :chartData="chartData"
      :height="height"
      :chart-options="chartOptions"
    />
    <v-card-text v-if="loading" class="text-center">
      <v-progress-circular
        class="text-center"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>
