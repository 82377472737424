<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { apiQuery } from "@/api/j24api";
import UsersAdminEdit from "./UsersAdminEdit.vue";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AlertsList from "@/components/AlertsList.vue";
import VSelectSearch from "@/components/VSelectSearch.vue";
import { Auth } from "aws-amplify";

const users = reactive([]);
const customers = reactive([]);
const loading = ref(false);
const selectedUser = ref({});
const editUser = ref(false);
const alerts = reactive([]);

const addNewUser = ref(false);
const newEmail = ref("");
const newGivenName = ref("");
const newFamilyName = ref("");
const newCustomer = ref("");
const currentUsername = ref("");

function cancelAddNew() {
  addNewUser.value = false;
  newEmail.value = "";
  newGivenName.value = "";
  newFamilyName.value = "";
  newCustomer.value = "";
}

const headers = [
  { text: "Email Addresss", value: "Attributes.email", sortable: true },
  { text: "Name", value: "Name", sortable: true },
  { text: "Customer", value: "customerName", sortable: true },
  { text: "Enabled", value: "Enabled", sortable: true },
  { text: "Super User", value: "superUser", sortable: true },
  { text: "Status", value: "UserStatus", sortable: true },
  { text: "Actions", value: "actions" },
];

const searchValue = ref("");
function searchFields(headers) {
  const fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

async function deleteUser() {
  try {
    loading.value = true;
    await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "deleteUser",
      Username: selectedUser.value.Username,
    });
    selectedUser.value = {};
    getData();
    editUser.value = false;
  } catch (error) {
    console.log(error);
    alerts.push({
      type: "error",
      message: "Error Deleting User",
      closable: true,
    });
  }
  loading.value = false;
}

async function disableUser() {
  try {
    loading.value = true;
    await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "disableUser",
      Username: selectedUser.value.Username,
    });

    await getData();
    selectUser(
      users.find((user) => user.Username == selectedUser.value.Username)
    );
    //editUser.value = false;
  } catch (error) {
    console.log(error);
    alerts.push({
      type: "error",
      message: "Error Disabling User",
      closable: true,
    });
  }
  loading.value = false;
}

async function enableUser() {
  try {
    loading.value = true;
    await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "enableUser",
      Username: selectedUser.value.Username,
    });
    await getData();
    selectUser(
      users.find((user) => user.Username == selectedUser.value.Username)
    );
  } catch (error) {
    console.log(error);
    alerts.push({
      type: "error",
      message: "Error Enabling User",
      closable: true,
    });
  }
  loading.value = false;
}

async function saveNewUser() {
  console.log("Save");
  loading.value = true;
  try {
    const result = await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "createUser",
      email: newEmail.value,
      Attributes: {
        family_name: newFamilyName.value,
        given_name: newGivenName.value,
        "custom:Customer": newCustomer.value,
      },
    });
    console.log(result);
    if (result == "User already exists") {
      alerts.push({
        type: "error",
        message:
          "User with email address " + newEmail.value + " already exists.",
        closable: true,
      });
      throw new Error("User already exists");
    }
    if (result.Username) {
      alerts.push({
        type: "success",
        message: "User Created - Adding Permissions",
        closable: true,
      });

      for (const key in permissions) {
        if (permissions[key].enabled) {
          const groupUpdate = await apiQuery("J24Auth", "elaadmin/users", {
            apiAction: "editUserPermissions",
            Username: result.Username,
            permissions: [permissions[key]],
          });
        }
      }
      alerts.push({
        type: "success",
        message: "Permissions Added",
        closable: true,
      });

      permissions.length = 0;
      addNewUser.value = false;
      newEmail.value = "";
      newGivenName.value = "";
      newFamilyName.value = "";
      newCustomer.value = "";
      getData();
      addNewUser.value = false;
    }
  } catch (error) {
    console.log(error);
    alerts.push({
      type: "error",
      message: "Error Creating User",
      closable: true,
    });
  }
  loading.value = false;
}

const availablePermissions = reactive([]);

async function getData() {
  try {
    loading.value = true;
    const result = await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "getUsers",
      superUserStatus: true,
    });
    if (result.users) {
      users.length = 0;
      users.push(...result.users);
    }
    if (result.customers) {
      customers.length = 0;
      customers.push(...result.customers);
    }
  } catch (error) {
    console.log(error);
    alerts.push({
      type: "error",
      message: "Error Getting Users",
      closable: true,
    });
  }

  // Get Available Permissions to Add
  try {
    const result = await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "getPermissions",
    });
    if (result) {
      availablePermissions.length = 0;
      availablePermissions.push(...result);
    }
    console.log("Available Permissions", result);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

async function saveUser() {
  try {
    loading.value = true;
    const result = await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "updateUser",
      ...selectedUser.value,
    });

    if (permissionsChanged.value) {
      for (const key in permissions) {
        // If the enabled state has changed then update the group
        if (permissions[key].enabled != permissions[key].originalEnabledState) {
          const groupUpdate = await apiQuery("J24Auth", "elaadmin/users", {
            apiAction: "editUserPermissions",
            Username: selectedUser.value.Username,
            permissions: [permissions[key]],
          });
        }
      }

      // const groupUpdate = await apiQuery("J24Auth", "elaadmin/users", {
      //   apiAction: "editUserPermissions",
      //   Username: selectedUser.value.Username,
      //   permissions: permissions,
      // });
    }
  } catch (error) {
    console.log(error);
    alerts.push({
      type: "error",
      message: "Error Saving User",
      closable: true,
    });
  }
  loading.value = false;
}

const permissions = reactive([]);
const permissionsChanged = ref(false);

async function selectUser(item) {
  permissionsChanged.value = false;
  permissions.length = 0;
  selectedUser.value = item;
  editUser.value = true;
  loading.value = true;
  const selectedCustomerName = customers.find(
    (customer) =>
      customer._id == selectedUser.value.Attributes["custom:Customer"]
  ).name;
  try {
    const result = await apiQuery("J24Auth", "elaadmin/users", {
      apiAction: "getUserGroups",
      Username: item.Username,
    });
    if (result) {
      const groups = result;
      console.log("Groups", groups);
      console.log("Permissions", permissions);
      console.log("Groups 0", groups[0]);
      permissions.forEach((permission) => {
        console.log("Permission", permission);
      });

      console.log("Available Permissions", availablePermissions);
      groups.forEach((group) => {
        console.log("GROUP", group);
        const matchingPermission = availablePermissions.find((permission) => {
          console.log(permission.group, group.GroupName);
          return group.GroupName.startsWith(permission.groupPrefix);
        });

        console.log("Matching Permission", matchingPermission);
        if (matchingPermission) {
          permissions.push({
            permissionName: matchingPermission.permissionName,
            displayName:
              matchingPermission.permissionName +
              " - (" +
              group.GroupName.replace(matchingPermission.groupPrefix, "") +
              ")",
            group: group.GroupName,
            enabled: true,
          });
        }
      });
    }
    const customerNameWithoutSpaces = selectedCustomerName.replace(/ /g, "-");
    availablePermissions.forEach((availablePermission) => {
      if (
        !permissions.find((permission) => {
          return (
            permission.displayName ==
            availablePermission.permissionName +
              " - (" +
              customerNameWithoutSpaces +
              ")"
          );
        })
      ) {
        permissions.push({
          name:
            availablePermission.permissionName +
            "(" +
            selectedCustomerName +
            ")",
          permissionName: availablePermission.permissionName,
          displayName: availablePermission.permissionName,
          group:
            availablePermission.groupPrefix +
            selectedCustomerName.replace(/ /g, "-"),
          enabled: false,
        });
      }
    });

    // Loop through permissions and set the original enabled state
    permissions.forEach((permission) => {
      permission.originalEnabledState = permission.enabled;
    });

    console.log(result);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

onMounted(() => {
  getData();
  Auth.currentAuthenticatedUser().then((currentAuthenticatedUser) => {
    if (currentAuthenticatedUser) {
      console.log("Current User", currentAuthenticatedUser);
      currentUsername.value = currentAuthenticatedUser.username;
    }
  });
});

async function clickAddNewUser() {
  addNewUser.value = true;
}

// Watch for changes to newCustomer and update permissions
watch(newCustomer, () => {
  permissions.length = 0;
  if (!newCustomer.value) {
    return;
  }
  var customerNameWithoutSpaces = customers.find(
    (customer) => customer._id == newCustomer.value
  ).name;
  customerNameWithoutSpaces = customerNameWithoutSpaces.replace(/ /g, "-");
  availablePermissions.forEach((availablePermission) => {
    permissions.push({
      name: availablePermission.permissionName,
      permissionName: availablePermission.permissionName,
      displayName: availablePermission.permissionName,
      group: availablePermission.groupPrefix + customerNameWithoutSpaces,
      enabled: false,
    });
  });
});
</script>

<template>
  <h2 class="mb-4">User Accounts</h2>
  <AlertsList v-model="alerts"></AlertsList>
  <template v-if="!addNewUser && !editUser">
    <v-row>
      <v-col cols="auto"
        ><v-btn :loading="loading" @click="clickAddNewUser()"
          >Add New User</v-btn
        ></v-col
      >
      <v-col>
        <v-text-field
          class="mb-1"
          variant="underlined"
          v-model="searchValue"
          hide-details
          density="compact"
          label="Search"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-right"
        ><v-btn :loading="loading" prepend-icon="mdi-reload" @click="getData()"
          >refresh</v-btn
        ></v-col
      >
    </v-row>

    <EasyDataTable
      :headers="headers"
      :items="users"
      theme-color="#e7541e"
      alternating
      buttons-pagination
      class="mt-3"
      :loading="loading"
      :searchField="searchFields(headers)"
      :searchValue="searchValue"
    >
      <template #item-actions="item">
        <div>
          <v-icon @click="selectUser(item)">mdi-pencil</v-icon>
        </div>
      </template>
      <template #item-name="item">
        {{ item.Attributes.given_name }} {{ item.Attributes.family_name }}
      </template>
      <template #item-enabled="item">
        <v-icon>{{ item.Enabled ? "mdi-check" : "mdi-close" }}</v-icon>
      </template>
      <template #item-superUser="item">
        <v-icon v-if="item.superUser">mdi-check</v-icon>
      </template>
    </EasyDataTable>
  </template>

  <template v-if="editUser">
    <h3>User Detail</h3>

    <v-row>
      <v-col cols="7">
        <v-text-field
          label="User Id"
          v-model="selectedUser.Username"
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-text-field
          label="Email Address"
          v-model="selectedUser.Attributes.email"
          readonly
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-text-field
          label="First Name"
          v-model="selectedUser.Attributes.given_name"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-text-field
          label="Last Name"
          v-model="selectedUser.Attributes.family_name"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-select
          label="Customer"
          v-model="selectedUser.Attributes['custom:Customer']"
          hide-details
          :items="customers"
          item-title="name"
          item-value="_id"
        >
        </v-select>
      </v-col>
      <v-col cols="7" v-if="permissions.length">
        <h3>Permissions</h3>
        <template v-for="permission in permissions" :key="permission">
          <v-checkbox
            :label="permission.displayName"
            hide-details
            density="compact"
            v-model="permission.enabled"
            @click="permissionsChanged = true"
          >
          </v-checkbox>
        </template>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="auto" v-if="selectedUser.Username != currentUsername">
        <v-btn class="ma-2" :loading="loading" @click="saveUser()">
          Save
        </v-btn>
        <v-btn
          class="ma-2"
          :loading="loading"
          v-if="selectedUser.Enabled"
          @click="disableUser()"
          >Disable User</v-btn
        >
        <v-btn
          class="ma-2"
          v-if="!selectedUser.Enabled"
          :loading="loading"
          @click="enableUser()"
          >Enable User</v-btn
        >
        <v-btn
          class="ma-2"
          v-if="!selectedUser.Enabled"
          @click="deleteUser()"
          :loading="loading"
          >Delete User</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="ma-2"
          @click="
            editUser = false;
            selectedUser = {};
          "
          >Back</v-btn
        >
      </v-col>
    </v-row>
  </template>

  <template v-if="addNewUser">
    <h3>New User</h3>
    <v-text-field label="Email Address" v-model="newEmail"></v-text-field>
    <v-text-field label="Firstname" v-model="newGivenName"></v-text-field>
    <v-text-field label="Surname" v-model="newFamilyName"></v-text-field>
    <VSelectSearch
      label="Customer"
      v-model="newCustomer"
      :items="customers"
      itemTitle="name"
      itemValue="_id"
      activateSearch
      @change="console.log('Customer Changed')"
    ></VSelectSearch>
    <v-col cols="7" v-if="permissions.length">
      <h3>Permissions</h3>
      <template v-for="permission in permissions" :key="permission">
        <v-checkbox
          :label="permission.displayName"
          hide-details
          density="compact"
          v-model="permission.enabled"
          @click="permissionsChanged = true"
        >
        </v-checkbox>
      </template>
    </v-col>
    <v-btn
      class="ma-2"
      :disabled="
        newEmail && newGivenName && newFamilyName && newCustomer != ''
          ? false
          : true
      "
      @click="saveNewUser()"
      :loading="loading"
      >Save</v-btn
    >
    <v-btn class="ma-2" @click="cancelAddNew()">Back</v-btn>
  </template>
</template>
