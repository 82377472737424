<script setup>
import { onMounted, reactive, ref } from "vue";
import { apiQuery } from "@/api/j24api";
import DashboardPieChart from "@/views/dashboard/DashboardPieChart.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

//const report = ref("");

var licenceName = ref("");
const licences = reactive([]);

const dateFrom = new Date();
dateFrom.setDate(dateFrom.getDate() - 1);
const loading = ref(false);

const tiles = reactive({
  numberOfElevations: {
    query: "where !isnull(lengthOfElevation) | count",
    result: {},
    status: "",
    loading: true,
  },
  reasonBreakdown: {
    query: "where !isnull(lengthOfElevation) | count by reason",
    result: {},
    status: "",
    loading: true,
  },
  requestTimeBreakdown: {
    query: "where !isnull(lengthOfElevation) | count by lengthOfElevation",
    result: {},
    status: "",
    loading: true,
  },
  activityByUser: {
    query:
      "where !isnull(lengthOfElevation)| sort by licenseName, user | 1 as i|accum i as Elevations by user | sort by licenseName, user, Elevations | dedup consecutive by licenseName, user|reason as LastElevationReason | dateTimeRequested as LastDateTimeElevated | serialNumber as LastSerialNumber | lengthOfElevation as LastLengthOfElevation | count(user) group by user, Elevations, LastElevationReason, LastDateTimeElevated, LastSerialNumber, LastLengthOfElevation | sort by Elevations | fields - _count",
    result: {},
    status: "",
    loading: true,
  },
  last100Elevations: {
    query: "where !isnull(lengthOfElevation) | sort _messageTime| limit 100",
    result: {},
    status: "",
    loading: true,
  },
});

const headersActivity = [
  { text: "Username", value: "map.user", sortable: true },
  { text: "Elevations", value: "map.elevations", sortable: true },
  {
    text: "Last Elevation Reason",
    value: "map.lastelevationreason",
    sortable: true,
  },
  {
    text: "Last Time Elevated",
    value: "map.lastdatetimeelevated",
    sortable: true,
  },
  { text: "Last Serial Number", value: "map.lastserialnumber", sortable: true },
  {
    text: "Last Length Of Elevation",
    value: "map.lastlengthofelevation",
    sortable: true,
  },
];

const headersLast100 = [
  { text: "Username", value: "map.user", sortable: true },
  { text: "Serial Number", value: "map.serialnumber", sortable: true },
  {
    text: "Date Time Requested",
    value: "map.datetimerequested",
    sortable: true,
  },
  { text: "Reason", value: "map.reason", sortable: true },
  { text: "Reason User Text", value: "map.reasonusertext", sortable: true },
];

function preparePieChart(sumoData, keyName) {
  var pieData = { labels: [], datasets: [{ data: [] }] };
  sumoData.forEach((message) => {
    pieData.labels.push(message.map[keyName]);
    pieData.datasets[0].data.push(message.map._count);
  });
  return addChartColors(pieData);
}

const wholePageLoading = ref(true);

async function getLicences() {
  wholePageLoading.value = true;
  const result = await apiQuery("ELA", "elevatereports", {
    apiAction: "GetLicences",
  });
  if (result.length) {
    licences.length = 0;
    licences.push(...result);
  }
  if (licences.length == 1) {
    licenceName.value = licences[0].name;
    getData();
  }
  wholePageLoading.value = false;
}

async function getData() {
  loading.value = true;
  const keys = Object.keys(tiles);
  console.log("Keys", keys);
  for (const key in keys) {
    const result = await apiQuery("ELA", "elevatereports", {
      apiAction: "CreateRequest",
      licenceName: licenceName.value,
      from: selectedTimePeriod.value.from.toISOString().split(".")[0],
      to: selectedTimePeriod.value.to.toISOString().split(".")[0],
      //query: tiles[keys[key]].query,
      query: tiles[keys[key]].query,
    });
    if (result.reportId) {
      tiles[keys[key]].reportId = result.reportId;
      tiles[keys[key]].loading = true;
      tiles[keys[key]].status = "Waiting for report";
    }
    console.log(result);
  }

  var allDataRecieved = false;
  var maxRequests = 100;
  var count = 0;
  while (!allDataRecieved && count < maxRequests) {
    count++;

    var finished = true;
    for (const key in keys) {
      await sleep(1000);
      if (tiles[keys[key]].status == "Waiting for report") {
        const result = await apiQuery("ELA", "elevatereports", {
          apiAction: "GetStatus",
          reportID: tiles[keys[key]].reportId,
        });
        console.log(result);
        if (result.state == "DONE GATHERING RESULTS") {
          const messages = await apiQuery("ELA", "elevatereports", {
            apiAction: "GetMessages",
            reportID: tiles[keys[key]].reportId,
          });
          console.log(keys[key]);
          console.log("Messages Received", messages);
          tiles[keys[key]].result = messages;
          tiles[keys[key]].loading = false;
          tiles[keys[key]].status = "Complete";
        } else {
          finished = false;
        }
        //return;
      }
    }
    if (finished) {
      console.log("All data recieved");
      allDataRecieved = true;
    }
  }
  loading.value = false;
  // const fromDate = new Date();
  // fromDate.setDate(fromDate.getDate() - 1);
  // const now = new Date();
  // const result = await apiQuery("ELA", "elevatereports", {
  //   apiAction: "CreateRequest",
  //   licenceName: "Jigsaw24 Elevate Demo",
  //   from: fromDate.toISOString().split(".")[0],
  //   to: now.toISOString().split(".")[0],
  //   query: '"lengthOfElevation"',
  // });
  // console.log(result);
  // report.value = result;
}

// async function getStatus() {
//   const result = await apiQuery("ELA", "elevatereports", {
//     apiAction: "GetStatus",
//     reportID: report.value.reportId,
//   });
//   console.log(result);
// }

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

onMounted(() => {
  //getData();
  getLicences();
});

const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

function addChartColors(chartData) {
  const colors = [
    "#e8dff5",
    "#fce1e4",
    "#fcf4dd",
    "#ddedea",
    "#daeaf6",
    "#d4afb9",
    "#d1cfe2",
    "#9cadce",
    "#7ec4cf",
    "#52b2cf",
  ];
  var counter = 0;
  var colourCounter = 0;
  const coloursRequired = chartData.datasets[0].data.length;
  while (counter < coloursRequired) {
    counter++;
    if (!chartData.datasets[0].backgroundColor) {
      chartData.datasets[0].backgroundColor = [colors[colourCounter]];
    } else {
      chartData.datasets[0].backgroundColor.push(colors[colourCounter]);
    }
    if (colourCounter == colors.length) {
      colourCounter = 0;
    }
    colourCounter++;
  }
  return chartData;
}

function daysAgo(days) {
  var x = new Date();
  x.setDate(x.getDate() - days);
  return x;
}

const presetTimePeriods = [
  { title: "Last 24 Hours", from: daysAgo(1), to: new Date() },
  { title: "Last 3 Days", from: daysAgo(3), to: new Date() },
  { title: "Last 7 Days", from: daysAgo(7), to: new Date() },
  { title: "Last 14 Days", from: daysAgo(14), to: new Date() },
  { title: "Last 30 Days", from: daysAgo(30), to: new Date() },
];

const selectedTimePeriod = ref({
  title: "Last 24 Hours",
  from: daysAgo(1),
  to: new Date(),
});

function searchFields(headers) {
  var result = [];
  headers.forEach((header) => {
    result.push(header.value);
  });
  return result;
}

const last100ShowFilter = ref(false);
const last100Search = ref("");
const activityByUserShowFilter = ref(false);
const activityByUserSearch = ref("");

const chosenRange = ref();
const startTime = [
  { hours: 0, minutes: 0 },
  { hours: 0, minutes: 0 },
];

const loadingCustomReport = ref(false);
const customReport = ref(false);
const customReportMessages = reactive([]);
const customReportCardHeight = ref(420);

function cleanUpMessages(messages) {
  const newArray = [];
  messages.forEach((message) => {
    const newObject = {};
    const keys = Object.keys(message.map);
    console.log(keys);
    keys.forEach((key) => {
      if (key[0] != "_") {
        newObject[key] = message.map[key];
      }
    });
    newArray.push(newObject);
  });
  return newArray;
}

async function getCustomReport() {
  loadingCustomReport.value = true;
  try {
    const result = await apiQuery("ELA", "elevatereports", {
      apiAction: "CreateRequest",
      licenceName: licenceName.value,
      from: chosenRange.value[0].toISOString().split(".")[0],
      to: chosenRange.value[1].toISOString().split(".")[0],
      query: '"lengthOfElevation"',
    });
    var count = 0;
    const reportId = result.reportId;
    while (count < 100) {
      count++;
      await sleep(1000);
      const result = await apiQuery("ELA", "elevatereports", {
        apiAction: "GetStatus",
        reportID: reportId,
      });
      if (result.state == "DONE GATHERING RESULTS") {
        const data = await apiQuery("ELA", "elevatereports", {
          apiAction: "GetMessages",
          reportID: reportId,
        });

        customReportMessages.push(...cleanUpMessages(data.messages));
        count = 999999;
      }
    }
    if (customReportMessages.length > 0) {
      customReportCardHeight.value = undefined;
    }
  } catch (error) {
    console.log(error);
  }
  loadingCustomReport.value = false;
}

const customReportHeaders = [
  { text: "Username", value: "user" },
  { text: "Serial Number", value: "serialnumber" },
  { text: "Date Time Requested", value: "datetimerequested" },
  { text: "Reason", value: "reason" },
  { text: "Reason User Text", value: "reasonusertext" },
];
</script>

<template>
  <template v-if="wholePageLoading">
    <v-row>
      <v-spacer></v-spacer>
      <v-col class="text-center pt-11">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>
  <template v-else>
    <v-row v-if="customReport">
      <v-col cols="12">
        <v-card :height="customReportCardHeight">
          <v-card-title
            ><v-row>
              <v-col>Custom Report</v-col>
              <v-spacer></v-spacer>
              <v-icon
                class="ma-2"
                @click="customReport = false"
                icon="mdi-close"
              ></v-icon> </v-row
          ></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <VueDatePicker
                  range
                  auto-apply
                  v-model="chosenRange"
                  placeholder="Select Date Range"
                  :startTime="startTime"
                />
              </v-col>
              <v-col cols="4">
                <v-btn :loading="loadingCustomReport" @click="getCustomReport()"
                  >Get Report</v-btn
                >
              </v-col>
              <v-col v-if="customReportMessages.length > 0" class="text-right">
                <download-csv
                  :data="customReportMessages"
                  name="Elevate24 Report.csv"
                >
                  <v-btn prepend-icon="mdi-download">Download</v-btn>
                </download-csv>
              </v-col>
            </v-row>
            <v-row> </v-row>
            <template v-if="customReportMessages.length > 0">
              <v-row>
                <v-col cols="12">
                  <EasyDataTable
                    :headers="customReportHeaders"
                    :items="customReportMessages"
                    theme-color="#e7541e"
                  />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-if="licences.length > 1"
          label="Choose Elevate License"
          :items="licences"
          item-title="name"
          item-value="name"
          v-model="licenceName"
          variant="underlined"
          hide-details
          density="compact"
          @update:modelValue="getData()"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-btn v-if="licenceName" @click="customReport = true"
          >Custom Report</v-btn
        >
      </v-col>

      <v-spacer />
      <v-col class="text-right"
        ><v-select
          label="Report Time Period"
          :items="presetTimePeriods"
          return-object
          item-title="title"
          v-model="selectedTimePeriod"
          variant="underlined"
          hide-details
          density="compact"
        ></v-select>
      </v-col>
      <v-col cols="auto" class="text-right">
        <v-btn @click="getData()" :disabled="loading"
          ><v-icon>mdi-magnify</v-icon></v-btn
        ></v-col
      >
    </v-row>
    <template v-if="!licenceName">
      <h2>Select Elevate Licence create report</h2>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="4">
          <v-card :loading="tiles.numberOfElevations.loading" :height="250">
            <v-card-title>Number of Elevations</v-card-title>
            <v-card-text>
              <div
                v-if="
                  tiles.numberOfElevations.result.messages &&
                  tiles.numberOfElevations.result.messages.length
                "
                class="text-center text-h1 pt-10"
              >
                <template
                  v-if="tiles.numberOfElevations.result.messages.length == 0"
                >
                  0
                </template>
                <template v-else>
                  {{ tiles.numberOfElevations.result.messages[0].map._count }}
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card :loading="tiles.reasonBreakdown.loading" :height="250">
            <v-card-title>Reason Breakdown</v-card-title>
            <v-card-text>
              <DashboardPieChart
                v-if="
                  tiles.reasonBreakdown.result.messages &&
                  tiles.reasonBreakdown.result.messages.length != 0
                "
                :chartData="
                  preparePieChart(
                    tiles.reasonBreakdown.result.messages,
                    'reason'
                  )
                "
                :chartOptions="pieChartOptions"
                :height="200"
                :width="200"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card :loading="tiles.requestTimeBreakdown.loading" :height="250">
            <v-card-title>Reason Time Breakdown</v-card-title>
            <v-card-text>
              <DashboardPieChart
                v-if="
                  tiles.requestTimeBreakdown.result.messages &&
                  tiles.requestTimeBreakdown.result.messages.length != 0
                "
                :chartData="
                  preparePieChart(
                    tiles.requestTimeBreakdown.result.messages,
                    'lengthofelevation'
                  )
                "
                :chartOptions="pieChartOptions"
                :height="200"
                :width="200"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title
              ><v-row>
                <v-col>Activity By User</v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="text-right"
                  cols="2"
                  v-if="activityByUserShowFilter"
                >
                  <v-text-field
                    class="pa-0 ma-0"
                    density="compact"
                    variant="underlined"
                    hide-details
                    label="Search Text"
                    v-model="activityByUserSearch"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="text-right" cols="auto">
                  <v-icon
                    class="py-5"
                    @click="
                      activityByUserShowFilter = !activityByUserShowFilter
                    "
                  >
                    mdi-filter
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-title>

            <EasyDataTable
              class="mx-2 mb-2"
              v-if="tiles.activityByUser.loading"
              :loading="tiles.activityByUser.loading"
              :items="[]"
              :headers="headersActivity"
              theme-color="#e7541e"
            />
            <EasyDataTable
              v-else
              class="mx-2 mb-2"
              :loading="tiles.activityByUser.loading"
              :headers="headersActivity"
              :items="tiles.activityByUser.result.messages"
              theme-color="#e7541e"
              :search-field="searchFields(headersActivity)"
              :search-value="activityByUserSearch"
            />
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col>Last 100 Elevations</v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="text-right" v-if="last100ShowFilter">
                  <v-text-field
                    class="pa-0 ma-0"
                    density="compact"
                    variant="underlined"
                    hide-details
                    label="Search Text"
                    v-model="last100Search"
                  >
                  </v-text-field
                ></v-col>
                <v-col cols="auto" class="text-right"
                  ><v-icon
                    class="py-5"
                    @click="last100ShowFilter = !last100ShowFilter"
                    >mdi-filter</v-icon
                  ></v-col
                >
              </v-row>
            </v-card-title>

            <EasyDataTable
              v-if="tiles.last100Elevations.loading"
              class="mx-2 mb-2"
              :loading="tiles.last100Elevations.loading"
              :items="[]"
              :headers="headersActivity"
              theme-color="#e7541e"
            />
            <EasyDataTable
              v-else
              class="mx-2 mb-2"
              :loading="tiles.last100Elevations.loading"
              :headers="headersLast100"
              :items="tiles.last100Elevations.result.messages"
              :search-field="searchFields(headersLast100)"
              :search-value="last100Search"
              theme-color="#e7541e"
            />
          </v-card>
        </v-col>
      </v-row>
    </template>
  </template>
</template>
