/* eslint-disable */
<script setup>
import { reactive, ref, onMounted, defineProps } from "vue";
//import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";
import { Auth } from "aws-amplify";
import newApp from "./newApp.vue";
import patchInfo from "./patchingInfo.vue";
import * as pako from "pako";

//const appBar = appBarStore();
const loading = ref(false);
const patchItems = reactive([]);
const addApp = ref(false);
const dialog = ref({});
const showInfo = ref(false);
const patchData = reactive([]);
dialog.value.Show = ref(false);
dialog.value.Action = ref("");
dialog.value.Title = ref("");
dialog.value.Text = ref("");
dialog.value.Text2 = ref("");
dialog.value.TxtField = ref(false);
dialog.value.TxtFieldLabel = ref("");
dialog.value.TxtFieldValue = ref("");
dialog.value.TxtField2 = ref(false);
dialog.value.TxtFieldLabel2 = ref("");
dialog.value.TxtFieldValue2 = ref("");
dialog.value.Button = ref("");
dialog.value.ButtonDisabled = ref(false);
dialog.value.StatusTxt = ref("");
const email = ref("");
const lastname = ref("");
const firstname = ref("");

const headers = [
  { text: "Name", value: "name", sortable: true },
  // { text: "Built Using", value: "type", sortable: true },
  {
    text: "Latest Intel Version",
    value: "IntelVersion",
    sortable: true,
  },
  {
    text: "Latest AppleSilicon Version",
    value: "ASVersion",
    sortable: true,
  },
  {
    text: "Last Update Status",
    value: "jobList.0.status",
    sortable: true,
  },
  {
    text: "Last Update Date",
    value: "endDate", //"jobList.0.endDate",
    sortable: true,
  },
  {
    text: "",
    value: "info",
    sortable: false,
  },
];

async function loadData() {
  patchItems.length = 0;
  loading.value = true;
  var apiresults = await apiQuery("ELA", "patching/patchitems", {
    apiAction: "GetPatchItem",
  });

  const charData = atob(apiresults)
    .split("")
    .map((x) => {
      return x.charCodeAt(0);
    });
  const binData = new Uint8Array(charData);
  var results = await JSON.parse(pako.inflate(binData, { to: "string" }));

  results.forEach((result) => {
    patchItems.push({
      ...result,
    });
  });
  loading.value = false;
}

async function downloadGitItem(data) {
  loading.value = true;
  var downloadData = await apiQuery("ELA", "patching/patchitems", {
    apiAction: "getPatchRecipie",
    recipeName: dialog.value.data.recipeName,
  });
  const linkSource = `data:application/zip;base64,${downloadData}`;
  const link = document.createElement("a");
  link.href = linkSource;
  link.download = dialog.value.data.recipeName + ".zip";

  link.click();

  loading.value = false;
}

async function downloadPkg(data) {
  loading.value = true;
  var downloadURL = await apiQuery("ELA", "patching/patchitems", {
    apiAction: "downloadPkg",
    pkgName: data,
  });
  const link = document.createElement("a");
  link.href = downloadURL.url;
  link.download = data;
  link.click();
  loading.value = false;
}

function resetDialog() {
  dialog.value = {};
}

function showDialog(type, data) {
  resetDialog();
  dialog.value.Show = true;
  if (type == "sendTest") {
    dialog.value.Button = "Start Test";
    dialog.value.Title = "Start test build of " + data.name;
    dialog.value.Text =
      "This will start a test run. No updates will be made to Patching, the status will show passed or failed.";
    dialog.value.Action = "testBuild";
    dialog.value.data = data;
  }
  if (type == "approvedBox") {
    dialog.value.Button = "Approve";
    dialog.value.Title = "Patch Item Approval for " + data.name;
    dialog.value.Text =
      "Approving this item will add it to the customer patch list, you can download the recipe using this link:";
    dialog.value.Action = "approveItem";
    dialog.value.data = data;
  }
  if (type == "CantApprove") {
    dialog.value.Button = "Approve";
    dialog.value.Title = "Patch Item Approval for " + data.name;
    dialog.value.Text = "You cant approve this item as it was added by you.";
    dialog.value.Action = "cantapproveItem";
    dialog.value.ButtonDisabled = "disabled";
    dialog.value.data = data;
  }
}

async function showPatchData(data) {
  patchData.push(data);
  showInfo.value = true;
}

async function dialogButtonPressed() {
  try {
    switch (dialog.value.Action) {
      case "testBuild":
        dialog.value.data.apiAction = "patchTestRun";
        var results = await apiQuery(
          "ELA",
          "patching/patchitems",
          dialog.value.data
        );
        loadData();
        dialog.value.Show = false;

        break;
      case "approveItem": {
        var addedDate = new Date(Date.now()).toLocaleString();

        dialog.value.data.apiAction = "approveItem";
        dialog.value.data.approvedBy = email.value;
        dialog.value.data.approvedOn = addedDate;
        results = await apiQuery(
          "ELA",
          "patching/patchitems",
          dialog.value.data
        );
        loadData();
        dialog.value.Show = false;
        break;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function formatDate(date) {
  var d = new Date(date);
  if (isNaN(d)) {
    return "-";
  } else {
    return d.toLocaleDateString("en-GB");
  }
}

onMounted(() => {
  loadData();
  Auth.currentAuthenticatedUser().then((currentAuthenticatedUser) => {
    if (currentAuthenticatedUser) {
      email.value =
        currentAuthenticatedUser.signInUserSession.idToken.payload.email;
      lastname.value =
        currentAuthenticatedUser.signInUserSession.idToken.payload.family_name;
      firstname.value =
        currentAuthenticatedUser.signInUserSession.idToken.payload.given_name;
    }
  });
});
</script>

<template>
  <newApp
    v-if="addApp"
    @cancel="addApp = !addApp"
    @updated="(addApp = !addApp), loadData()"
    @loading="loading.value = true"
    @loadingDone="loading.value = false"
    :currentData="items"
    :email="email"
  />

  <patchInfo
    v-if="showInfo"
    :patchInfo="patchData"
    @cancel="showInfo = !showInfo"
  />

  <template v-if="!addApp && !showInfo">
    <v-row style="padding-bottom;: 5%">
      <h3 style="padding: 1%">Patching Apps List</h3>
      <v-col class="text-right">
        <v-btn
          height="33px"
          prepend-icon="mdi-plus"
          size="small"
          class="pa-2 ml-3"
          @click="addApp = true"
          >Add New</v-btn
        >
      </v-col>
    </v-row>
    <EasyDataTable
      style="text-align: center; list-style-position: inside"
      :headers="headers"
      :items="patchItems"
      alternating
      buttons-pagination
      :loading="loading"
      theme-color="#e7541e"
    >
      <template #item-IntelVersion="name">
        <v-icon
          v-if="name.Intel"
          icon="mdi-download-box"
          size="x-large"
          aria-hidden="false"
          @click="downloadPkg(name.Intel.patches.at(-1).packageName)"
        ></v-icon>
        {{ name.Intel ? name.Intel.currentVersion : "-" }}
      </template>
      <template #item-ASVersion="name">
        <v-icon
          v-if="name.AppleSilicon"
          icon="mdi-download-box"
          size="x-large"
          aria-hidden="false"
          @click="downloadPkg(name.AppleSilicon.patches.at(-1).packageName)"
        ></v-icon>
        {{ name.AppleSilicon ? name.AppleSilicon.currentVersion : "-" }}
      </template>

      <template #item-endDate="name">
        {{
          name.jobList.at(-1) ? formatDate(name.jobList.at(-1).endDate) : "-"
        }}
      </template>

      <template #item-info="name">
        <v-icon
          icon="mdi-information"
          size="x-large"
          @click="showPatchData(name)"
        ></v-icon>
        <v-icon
          v-if="name.approved == true"
          icon="mdi-test-tube"
          size="x-large"
          @click="showDialog('sendTest', name)"
        ></v-icon>
        <template v-if="name.approved != true">
          <v-icon
            v-if="email != name.addedBy"
            icon="mdi-map-check-outline"
            size="x-large"
            @click="showDialog('approvedBox', name)"
          ></v-icon>
          <v-icon
            v-if="email == name.addedBy"
            icon="mdi-map-check-outline"
            size="x-large"
            @click="showDialog('CantApprove', name)"
          ></v-icon>
        </template>
      </template>
    </EasyDataTable>

    <div class="text-center">
      <v-dialog v-model="dialog.Show" max-width="600">
        <v-card>
          <v-card-title>{{ dialog.Title }}</v-card-title>
          <v-card-text>
            {{ dialog.Text }}
            <v-text-field
              v-if="dialog.TxtField"
              :label="dialog.TxtFieldLabel"
              v-model="dialog.TxtFieldValue"
            ></v-text-field>
            <div v-html="dialog.Text2"></div>
            <v-text-field
              v-if="dialog.TxtField2"
              :label="dialog.TxtFieldLabel2"
              v-model="dialog.TxtFieldValue2"
            ></v-text-field>
            <v-btn
              v-if="dialog.Action == 'approveItem'"
              variant="tonal"
              @click="downloadGitItem(name)"
            >
              Download</v-btn
            >
          </v-card-text>
          <v-card-actions class="text-left">
            <v-row class="text-left">
              <v-col class="text-left"
                ><v-btn variant="tonal" @click="resetDialog()"> close </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-btn
                  variant="tonal"
                  @click="dialogButtonPressed()"
                  :disabled="dialog.ButtonDisabled"
                >
                  <template v-if="dialog.StatusTxt">
                    {{ dialog.StatusTxt }} </template
                  ><template v-else>{{ dialog.Button }}</template></v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
</template>
