<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";
import ElaDeviceDetail from "./ElaDeviceDetail.vue";

const appBar = appBarStore();
const complete = ref(false);
const headers = reactive([
  { text: "ID", value: "id", sortable: true, show: true },
  { text: "Device Name", value: "name", sortable: true, show: true },
]);

const defaultCols = [
  { text: "ID", value: "id", sortable: true, show: true },
  { text: "Device Name", value: "name", sortable: true, show: true },
  { text: "Username", value: "username", sortable: true, show: true },
];

const availableHeaders = reactive([
  { text: "ID", value: "id", sortable: true, show: true },
  { text: "Device Name", value: "name", sortable: true, show: true },
  { text: "Username", value: "username", sortable: true, show: true },
  { text: "Model", value: "model", sortable: true, show: false },
  {
    text: "Model Identifier",
    value: "modelIdentifier",
    sortable: true,
    show: false,
  },
  { text: "Device Type", value: "type", sortable: true, show: false },
  { text: "MAC Address", value: "wifiMacAddress", sortable: true, show: false },
  { text: "UDID", value: "udid", sortable: true, show: false },
]);

function resetCols() {
  headers.length = 0;
  headers.push(...defaultCols);
  headers.forEach((header) => {
    availableHeaders.forEach((aHeader) => {
      if (header.value == aHeader.value) {
        availableHeaders.show == header.show;
      }
    });
  });
  localStorage.setItem("cmd24DevicesCols", JSON.stringify(headers));
}

var doNotUpdateHeaders = false;
watch(availableHeaders, (currentValue) => {
  if (!doNotUpdateHeaders) {
    console.log("Available header changed", currentValue);
    currentValue.forEach((col) => {
      if (col.show) {
        if (!headers.find((h) => h.value == col.value)) {
          headers.push(col);
        }
      } else {
        headers.forEach((current, index) => {
          if (current.value == col.value) {
            headers.splice(index, 1);
          }
        });
      }
    });
    localStorage.setItem("cmd24DevicesCols", JSON.stringify(headers));
  }
});

const items = reactive([]);
const loading = ref(false);
const alertShow = ref(false);
const alertTxt = ref("");
const selectedDevice = ref(false);
const searchValue = ref("");

appBar.$subscribe(() => {
  if (appBar.selected != "") {
    getData();
  }
});

async function getData() {
  try {
    if (appBar.selected != "") {
      loading.value = true;
      const result = await apiQuery("ELA", "cmd24/query", {
        mdmServerId: appBar.selected,
        dataRequested: "devices",
        maxage: 86400,
      });

      items.length = 0;
      items.push(...result.result.results);
      //loading.value = false;
    }
  } catch (error) {
    console.log(error);
    alertShow.value = true;
    alertTxt.value = "Error - Unable to get Devices";
  }

  // const chunkSize = 100;
  // for (let i = 0; i < items.length; i += chunkSize) {
  //   const chunk = items.slice(i, i + chunkSize);
  //   const deviceIds = [];
  //   chunk.forEach((v) => {
  //     deviceIds.push(v.id);
  //   });
  //   const result = await apiQuery("ELA", "cmd24/query", {
  //     mdmServerId: appBar.selected,
  //     dataRequested: "devicesDetail",
  //     deviceIds: deviceIds,
  //     maxage: 86400,
  //   });
  //   console.log(result);
  //   if (result.length) {
  //     result.forEach((device) => {
  //       const key = items.findIndex((item) => item.id == device.result.id);
  //       items[key].detail = device;
  //     });
  //   }
  // }
  loading.value = false;
  complete.value = true;
}

function searchFields() {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

const selectDevice = (device) => {
  selectedDevice.value = device;
};

onMounted(() => {
  getData();
  var localHeaders = localStorage.getItem("cmd24DevicesCols");
  if (localHeaders) {
    doNotUpdateHeaders = true;
    try {
      localHeaders = JSON.parse(localHeaders);
      if (localHeaders.length) {
        headers.length = 0;
        headers.push(...localHeaders);
      }
      localHeaders.forEach((lHeader) => {
        availableHeaders.forEach((header, index) => {
          if (lHeader.value == header.value) {
            availableHeaders[index].show = lHeader.show;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
    doNotUpdateHeaders = false;
  }
});
</script>

<template>
  <v-alert
    class="my-3"
    v-model="alertShow"
    type="error"
    closable
    density="compact"
    >{{ alertTxt }}</v-alert
  >

  <template v-if="selectedDevice">
    <ela-device-detail :data="selectedDevice" @back="selectedDevice = false" />
  </template>

  <template v-if="!selectedDevice">
    <v-row>
      <v-col>
        <v-text-field
          class="pl-1"
          density="compact"
          label="Search"
          v-model="searchValue"
          variant="underlined"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <v-btn icon variant="plain"
          ><v-icon>mdi-format-columns</v-icon>
          <v-menu activator="parent">
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-btn
                    prepend-icon="mdi-refresh"
                    variant="text"
                    @click="resetCols()"
                    >Reset</v-btn
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-for="(col, index) in availableHeaders"
                :key="col.value"
              >
                <v-list-item-action>
                  <v-checkbox-btn
                    v-model="availableHeaders[index].show"
                    :label="col.text"
                  ></v-checkbox-btn>
                </v-list-item-action>

                <!-- <v-list-item-action-text
                  >{{ col.text }}{{ index }}</v-list-item-action-text
                > -->
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :search-field="searchFields()"
      :search-value="searchValue"
      alternating
      theme-color="#e7541e"
      @click-row="selectDevice"
    />
  </template>
</template>
