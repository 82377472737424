<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  data: Object,
  first: String,
});

function convertCamel(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  var converted = result.charAt(0).toUpperCase() + result.slice(1);
  converted = converted.replace("G B", "GB");
  converted = converted.replace("Cpu", "CPU");
  converted = converted.replace("Ad ", "AD ");
  converted = converted.replace("O S", "OS");
  return converted;
}

const expanded = ref(false);
</script>

<template>
  <tr>
    <th>
      <v-icon @click="expanded = !expanded">{{
        expanded ? "mdi-menu-down" : "mdi-menu-right"
      }}</v-icon>
    </th>
    <th>{{ convertCamel(props.first) }}</th>
    <td></td>
  </tr>
  <template v-if="expanded">
    <template
      v-if="props.first == 'cyberEssentials' || props.first == 'battery'"
    >
      <tr v-for="(line, index) in data" :key="index">
        <th></th>
        <th>{{ convertCamel(index) }}</th>
        <td>{{ line }}</td>
      </tr>
    </template>

    <template v-if="props.first == 'networkInterfaces'">
      <tr>
        <th></th>
        <td colspan="2">
          <v-table>
            <tr>
              <th>Interface</th>
              <th>Type</th>
              <th>Priority</th>
              <th>Name</th>
              <th>IP Address</th>
            </tr>
            <tr v-for="int in data" :key="int">
              <td>{{ int.interface }}</td>
              <td>{{ int.type }}</td>
              <td>{{ int.orderNo }}</td>
              <td>{{ int.name }}</td>
              <td>{{ int.ipaddr }}</td>
            </tr>
          </v-table>
        </td>
      </tr>
    </template>

    <template v-if="props.first == 'secureTokenData'">
      <tr>
        <th></th>
        <td colspan="2">
          <v-table>
            <tr>
              <th>Username</th>
              <th>Secure Token Status</th>
              <th>Adminstrator</th>
              <th>Disabled</th>
              <th>Hidden</th>
            </tr>

            <tr v-for="user in data" :key="user">
              <td>{{ user.user }}</td>
              <td>{{ user.status }}</td>
              <td>{{ user.isAdmin }}</td>
              <td>{{ user.isDisabled }}</td>
              <td>{{ user.isHidden }}</td>
            </tr>
          </v-table>
        </td>
      </tr>
    </template>
  </template>
</template>
