<template>
  <template v-if="!appBar.selected.length">
    <h3>Select Customer from Menu above</h3>
  </template>
  <template v-else>
    <cat24ImportCSV
      v-if="importCSV"
      @cancel="importCSV = !importCSV"
      @updated="(importCSV = !importCSV), getData()"
      importType="deliveries"
      :currentData="items"
      duplicateCheckField="slotDate"
    />
    <cat24AddRow
      v-if="addRow"
      addType="deliveries"
      @cancel="addRow = !addRow"
      @updated="(addRow = !addRow), getData()"
      :currentData="items"
    />
    <cat24AddRow
      v-if="editRow"
      edit
      :item="editItem"
      addType="deliveries"
      @cancel="editRow = !editRow"
      @updated="(editRow = !editRow), getData()"
    />
    <template v-if="!importCSV && !addRow && !editRow">
      <v-row>
        <v-col>
          <v-btn
            prepend-icon="mdi-upload"
            size="small"
            class="pa-2"
            @click="importCSV = true"
            >Import CSV</v-btn
          >
        </v-col>
        <v-col />
        <v-col class="text-right">
          <v-btn
            prepend-icon="mdi-plus"
            size="small"
            class="pa-2 ml-3"
            @click="addRow = true"
            >Add New</v-btn
          >
        </v-col>
      </v-row>

      <EasyDataTable
        :headers="headers"
        :items="items"
        theme-color="#e7541e"
        alternating
        buttons-pagination
        class="mt-3"
        :loading="loading"
      >
        <template #item-actions="item">
          <v-icon @click="openEdit(item)">mdi-pencil</v-icon>
          <v-icon class="ml-1" @click="deleteRow(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </template>
  </template>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
//import type { Header, Item } from "vue3-easy-data-table";
import cat24ImportCSV from "../cat24ImportCSV.vue";
import cat24AddRow from "../cat24AddRow.vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
const items = reactive([]);

appBar.$subscribe(() => {
  if (appBar.selected != "") {
    getData();
  }
});

const headers = [
  //  { text: "Delivery Date", value: "deliveryDate" },
  { text: "Slot Date", value: "slotDate", sortable: true },
  { text: "Slot Time", value: "slotTime" },
  { text: "Max Deliveries", value: "maxDeliveries" },
  {
    text: "Reschedule Allowance",
    value: "rescheduleAllowance",
  },

  { text: "Actions", value: "actions" },
];

const importCSV = ref(false);
const addRow = ref(false);

const editItem = ref({});
const editRow = ref(false);
const loading = ref(false);

async function deleteRow(item) {
  loading.value = true;
  await apiQuery("ELA", "cat24/deliveries", {
    apiAction: "delete",
    customer: appBar.selected,
    delivery: item,
  });
  getData();
}

function openEdit(item) {
  editItem.value = item;
  editRow.value = true;
}

async function getData() {
  loading.value = true;
  const response = await apiQuery("ELA", "cat24/deliveries", {
    apiAction: "get",
    customer: appBar.selected,
  });
  items.length = 0;
  response.forEach((element) => {
    //items.push(element);
    const date = new Date(element.deliveryDate).toISOString().split("T");
    items.push({
      ...element,
      slotDate: date[0],
      slotTime: date[1].split(".")[0],
    });
  });
  loading.value = false;
}

onMounted(async () => {
  if (appBar.selected != "") {
    getData();
  }
});
</script>
