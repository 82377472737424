<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const loading = ref(false);
const addNew = ref(false);
const editCustomerRecord = reactive({
  _id: "",
  name: "",
  deleted: false,
});
const editCustomer = ref(false);

const customers = reactive([]);

// function editCustomer(key) {
//   editCustomerRecord.name = customers[key].name;
// }

async function getCustomers() {
  loading.value = true;
  try {
    const result = await apiQuery("J24Auth", "customers", { apiAction: "get" });
    customers.length = 0;
    customers.push(...result);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

async function save() {
  try {
    if (editCustomer.value) {
      await apiQuery("J24Auth", "customers", {
        apiAction: "update",
        ...editCustomerRecord,
      });
    }
    if (addNew.value) {
      delete editCustomerRecord._id;
      await apiQuery("J24Auth", "customers", {
        apiAction: "add",
        ...editCustomerRecord,
      });
    }
    editCustomer.value = false;
    addNew.value = false;
    editCustomerRecord.name = "";
  } catch (error) {
    console.log(error);
  }
  getCustomers();
}

onMounted(() => {
  getCustomers();
});
</script>

<template>
  <h1>Customers Management</h1>
  <v-icon @click="addNew = true">mdi-plus-circle</v-icon>
  <!-- Edit/Add Customer -->
  <v-form v-if="addNew || editCustomer">
    <v-text-field
      v-model="editCustomerRecord.name"
      label="Customer Name"
    ></v-text-field>
    <v-btn @click="save()">Save/Add</v-btn
    ><v-btn
      @click="
        addNew = false;
        editCustomer = false;
      "
      >Cancel</v-btn
    >
    <v-btn
      @click="
        editCustomerRecord.deleted = true;
        save();
      "
      >Delete Customer</v-btn
    >
  </v-form>

  <!-- List Customers -->
  <v-table v-if="!addNew && !editCustomer">
    <thead class="text-left">
      <th>Customer Name</th>
      <th></th>
    </thead>
    <tbody v-if="customers.length" class="text-left">
      <tr v-for="(customer, key) in customers" :key="customer.name">
        <td>{{ customer.name }}</td>
        <td>
          <v-icon
            @click="
              editCustomerRecord.name = customers[key].name;
              editCustomerRecord._id = customers[key]._id;
              editCustomer = true;
            "
            >mdi-pencil</v-icon
          >
        </td>
      </tr>
    </tbody>
  </v-table>
  <!-- Loading Dialog -->
  <v-dialog v-model="loading" hide-overlay persistent>
    <v-card color="grey">
      <v-card-text>
        Loading
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
