<template>
  <v-navigation-drawer permanent width="190" theme="jigsawDark">
    <v-list>
      <div class="v-list-item">
        <v-icon class="pa-3" color="#e7541e">mdi-truck-fast</v-icon>
        <div class="text-h7 pa-3 cursor-default">
          <strong>CAT24 Admin</strong>
        </div>
      </div>
      <template v-for="item in items" :key="item.title">
        <v-list-item
          link
          :to="{ name: item.link }"
          @click="updateRandom()"
          active-color="#e7541e"
        >
          <template v-slot:prepend>
            <v-icon class="pa-2">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view :key="randomKey" />
    </v-container>
  </v-main>
</template>

<style scoped>
.v-navigation-drawer__content {
  background: white;
  border: none;
  border-style: none;
}
.v-navigation-drawer {
  border: none;
  border-style: none;
}
.cursor-default {
  cursor: default;
}
</style>

<script setup>
//import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();

const items = ref([]);

onMounted(async () => {
  // Setup of Customer Selection in navbar

  if (appBar.appRunning != "CAT24Admin") {
    appBar.$reset();
    appBar.$patch({
      type: "select",
      header: "Loading Please Wait...",
      appRunning: "CAT24Admin",
      items: [],
      selected: "",
    });

    const customers = await apiQuery("ELA", "cat24/getcustomers");
    appBar.$patch({
      type: "select",
      header: "Select Customer",
      appRunning: "CAT24Admin",
      items: customers,
      selected: "",
    });
  }
  buildSidebarItems();
});

permissions.$subscribe(() => {
  buildSidebarItems();
});

function buildSidebarItems() {
  items.value.length = 0;
  allItems.forEach((item) => {
    if (permissions.$state["Cat24Admin-" + item.privName]) {
      items.value.push(item);
    }
  });
}

const allItems = [
  // { title: "Assets", icon: "mdi-archive", link: "AssetsBulkUploader" },
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    link: "Cat24Dashboard",
    privName: "Dashboard",
    value: false,
  },
  {
    title: "Addresses",
    icon: "mdi-map-marker",
    link: "Cat24Addresses",
    privName: "Addresses",
    value: false,
  },
  {
    title: "Booking Slots",
    icon: "mdi-truck-fast",
    link: "BookingSlots",
    privName: "Deliveries",
    value: false,
  },
  {
    title: "Contacts",
    icon: "mdi-contacts",
    link: "ContactsBulkuploader",
    privName: "Contacts",
    value: false,
  },
  // {
  //   title: "Orders",
  //   icon: "mdi-file-document-multiple",
  //   link: "OrdersBulkUploader",
  //   value: false,
  // },
  {
    title: "Orders",
    icon: "mdi-file-document-multiple",
    link: "Cat24OrdersV2",
    privName: "Orders",
    value: false,
  },
  {
    title: "Customisations",
    icon: "mdi-content-save-edit-outline",
    link: "PortalCustomisations",
    privName: "Customisations",
    value: false,
  },
];

// Required to make the router-view reload when user clicks the link again
// See the key value set on <router-view> below.
const randomKey = ref("");
function updateRandom() {
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}
</script>
