import { watchEffect } from "vue";
import { RouteLocationNormalized } from "vue-router";
import router from "@/router";
import { Auth } from "aws-amplify";

async function useRouteGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      if (user.signInUserSession?.idToken?.payload?.identities) {
        return true;
      } else {
        if (to.name == "EnableMFA") {
          return true;
        }
        if (user.preferredMFA != "SOFTWARE_TOKEN_MFA") {
          //router.push("EnableMFA");
        }
      }
      return true;
    })
    .catch(() => {
      //router.push("signIn");
      Auth.federatedSignIn();
      return false;
    });
}

export { useRouteGuard };
