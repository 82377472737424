<script setup>
import { reactive, ref, onMounted, defineProps } from "vue";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import ModelChart from "./ModelChart.vue";
import ElaDashboardDrillDown from "../ElaDashboardDrillDown.vue";
import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();

let mdmType;

const allDevices = reactive([]);
const props = defineProps({
  allMacs: Object,
  allIOS: Object,
  allWindows: Object,
  allAndroid: Object,
  macModels: Object,
  iosModels: Object,
  windowsModels: Object,
  androidModels: Object,
});

const deviceTypes = reactive({
  macOS: [],
  iOS: [],
  iPadOS: [],
  tvOS: [],
  Windows: [],
  Android: [],
  Unknown: [],
});
const manufacturers = reactive({});

var deviceTypesChartData = {};
var manufacturersChartData = {};
var showChart = ref(false);
const macArchitecture = reactive({
  AppleSilicon: [],
  Intel: [],
});
var macArchitectureChart = {};
var vintageDevices = {};
var vintageDevicesChartData = reactive({
  show: false,
  options: {
    chart: { type: "pie" },
    labels: ["Other", "Vintage"],
    colors: ["#eb5d0b", "#7b264b", "#374f50", "#abb9c2", "#eeeeee"],
    dataLabels: {
      style: {
        colors: ["#111111"],
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
  },
  chartData: [],
});

function buildPieChart(chartData) {
  var series = [];
  var labels = [];
  Object.keys(chartData).forEach((key) => {
    //console.log("Key", key);
    labels.push(key);
    series.push(chartData[key].length);
  });

  return {
    options: {
      chart: { type: "pie" },
      // plotOptions: { pie: { customScale: 0.5 } },
      labels: labels,
      j24ChartData: chartData,
      colors: ["#eb5d0b", "#7b264b", "#374f50", "#abb9c2", "#eeeeee"],
      dataLabels: {
        style: {
          colors: ["#111111"],
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    chartData: series,
  };
}

function getMacArchitecture() {
  deviceTypes.macOS.forEach((device) => {
    if (device.Apple_Silicon == "Yes") {
      macArchitecture.AppleSilicon.push(device);
      return;
    }
    macArchitecture.Intel.push(device);
  });
  macArchitectureChart = buildPieChart(macArchitecture);
}

function getDeviceTypes() {
  allDevices.forEach((device) => {
    if (
      device.Operating_System_Name == "macOS" ||
      device.Operating_System_Name == "Mac OS X"
    ) {
      deviceTypes.macOS.push(device);
      return;
    } else {
      if (device.Model.includes("iPad")) {
        deviceTypes.iPadOS.push(device);
        return;
      }
      if (device.Model.includes("iPhone")) {
        deviceTypes.iOS.push(device);
        return;
      }
    }
    if (device.Model.includes("Apple TV")) {
      deviceTypes.tvOS.push(device);
      return;
    }
    if (device.Operating_System_Name == "Windows") {
      deviceTypes.Windows.push(device);
      return;
    }
    if (device.Operating_System_Name == "Android") {
      deviceTypes.Android.push(device);
      return;
    }

    deviceTypes.Unknown.push(device);
  });

  // Remove items that are empty.
  let deviceTypesFiltered = {};
  for (const key in deviceTypes) {
    if (deviceTypes[key].length > 0) {
      deviceTypesFiltered[key] = deviceTypes[key];
    }
  }

  deviceTypesChartData = buildPieChart(deviceTypesFiltered);
}

function getManufacturers() {
  allDevices.forEach((device) => {
    if (device.Manufacturer) {
      // Shorten names, as it increases the size of the chart.
      if (device.Manufacturer == "Microsoft Corporation") {
        device.Manufacturer = "Microsoft";
      }
      if (device.Manufacturer == "Microsoft Corporation") {
        device.Manufacturer = "Microsoft";
      }
      if (device.Manufacturer.includes("Parallels")) {
        device.Manufacturer = "Parallels VM";
      }

      if (manufacturers[device.Manufacturer]) {
        manufacturers[device.Manufacturer].push(device);
      } else {
        manufacturers[device.Manufacturer] = [device];
      }
    }
  });
  manufacturersChartData = buildPieChart(manufacturers);
}

function processVintageDevices(vintageDevices) {
  var result = {
    Vintage: [],
    Other: [],
  };
  allDevices.forEach((device) => {
    if (
      !vintageDevices.find((vintage) => {
        if (vintage.serial == device.Serial_Number) {
          result.Vintage.push(device);
          return true;
        }
        return false;
      })
    ) {
      result.Other.push(device);
    }
  });
  console.log(result);
  vintageDevicesChartData.options.j24ChartData = result;
  vintageDevicesChartData.chartData.push(result.Other.length);
  vintageDevicesChartData.chartData.push(result.Vintage.length);
  vintageDevicesChartData.show = true;
}

const loading = ref(true);

async function getData() {
  mdmType = mdmServer.$state.type;
  const allMacosResult = await apiQuery("ELA", "cmd24/query", {
    mdmServerId: appBar.selected,
    dataRequested: "dashboardDetail",
    id: props.allMacs.records,
  });
  if (allMacosResult) {
    allDevices.push(...allMacosResult.content);
  }

  const allIOSDevices = await apiQuery("ELA", "cmd24/query", {
    mdmServerId: appBar.selected,
    dataRequested: "dashboardDetail",
    id: props.allIOS.records,
  });
  if (allIOSDevices) {
    allDevices.push(...allIOSDevices.content);
  }

  if (mdmType == "Intune") {
    const allWindowsDevices = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "dashboardDetail",
      id: props.allWindows.records,
    });
    const allAndroidDevices = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "dashboardDetail",
      id: props.allAndroid.records,
    });
    if (allWindowsDevices) {
      allDevices.push(...allWindowsDevices.content);
    }
    if (allAndroidDevices) {
      allDevices.push(...allAndroidDevices.content);
    }
  }

  getDeviceTypes();
  getMacArchitecture();

  if (mdmType == "Intune") {
    getManufacturers();
  }

  showChart.value = true;

  try {
    const vintageResult = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "agentDataQuery",
      agentDataQuery: { "log.vintage": true },
    });
    if (vintageResult.length) {
      //vintageDevices.push(...vintageResult);
      processVintageDevices(vintageResult);
    }
  } catch (error) {
    console.log("Error fetching vintage devices:", error);
    // Handle the error gracefully, e.g., show an error message to the user
  }
  loading.value = false;
}

const drillDown = ref({});
const selectedTab = ref("hardware");

function clickDrilldown(model) {
  console.log(model);
  var dd = {
    mdmServerId: appBar.selected,
    reportId: [],
  };
  dd.reportId.push(model.records);
  console.log("DD", dd);
  drillDown.value = dd;
  selectedTab.value = "drillDown";
}

function clickHandler(event, chartContext, config) {
  drillDown.value = {};
  console.log("Click Handler Config", config);
  let series = config.w.config.series[config.seriesIndex];
  console.log("Series", series);
  if (series.devices) {
    drillDown.value = {
      mdmServerId: appBar.selected,
      manualMode: true,
      allDevices: series.devices,
      serialNumbers: series.devices.map((x) => x.Serial_Number),
    };
    selectedTab.value = "drillDown";
  }
  if (config.w.config.j24ChartData) {
    const key = config.w.config.labels[config.dataPointIndex];
    drillDown.value = {
      mdmServerId: appBar.selected,
      manualMode: true,
      allDevices: config.w.config.j24ChartData[key],
      serialNumbers: config.w.config.j24ChartData[key].map(
        (x) => x.Serial_Number
      ),
    };
    selectedTab.value = "drillDown";
  }
}

onMounted(() => {
  getData();
});

function convertObjToArray(obj) {
  var result = [];
  for (const key in obj) {
    result.push({ ...obj[key], name: key });
  }
  return result;
}

function calulateHeight(data) {
  const x = data.length;
  return `${x * 50}px`;
}
</script>

<template>
  <v-window v-model="selectedTab" class="pa-2">
    <v-window-item value="drillDown">
      <ElaDashboardDrillDown
        v-if="drillDown.mdmServerId"
        :mdmServerId="drillDown.mdmServerId"
        :reportId="drillDown.reportId"
        :allDevices="drillDown.allDevices"
        :serialNumbers="drillDown.serialNumbers"
        :manualMode="drillDown.manualMode"
        @close="
          drillDown = {};
          selectedTab = 'hardware';
        "
      ></ElaDashboardDrillDown>
    </v-window-item>

    <v-window-item value="hardware" class="pa-2">
      <v-row>
        <v-col cols="4">
          <v-card :height="220" :loading="loading" class="pa-0">
            <v-card-title class="pb-0">Device Types</v-card-title>
            <v-card-text v-if="showChart" class="pa-0">
              <apexchart
                class="pa-0"
                height="115%"
                :options="deviceTypesChartData.options"
                :series="deviceTypesChartData.chartData"
                @dataPointSelection="clickHandler"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" v-if="mdmType == 'Intune'">
          <v-card :height="220" :loading="loading" class="pa-0">
            <v-card-title class="pb-0">Manufacturers</v-card-title>
            <v-card-text v-if="showChart" class="pa-0">
              <apexchart
                class="pa-0"
                height="115%"
                :options="manufacturersChartData.options"
                :series="manufacturersChartData.chartData"
                @dataPointSelection="clickHandler"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" v-if="props.allMacs?.count > 0 && showChart">
          <v-card :height="220" :loading="loading" class="pa-0">
            <v-card-title class="pb-0">macOS Architecture</v-card-title>
            <v-card-text class="pa-0">
              <apexchart
                height="115%"
                :options="macArchitectureChart.options"
                :series="macArchitectureChart.chartData"
                @dataPointSelection="clickHandler"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" v-if="vintageDevicesChartData.show && showChart">
          <v-card :height="220" :loading="loading" class="pa-0">
            <v-card-title class="pb-0">Vintage Macs</v-card-title>
            <v-card-text class="pa-0">
              <apexchart
                height="100%"
                :options="vintageDevicesChartData.options"
                :series="vintageDevicesChartData.chartData"
                @dataPointSelection="clickHandler"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card :loading="loading" :minHeight="calulateHeight(deviceTypes)">
            <v-card-title>Type Breakdown</v-card-title>
            <v-card-text v-if="!loading && allDevices.length">
              <ModelChart
                class="ma-3"
                :allDevices="allDevices"
                @clickDataPoint="clickHandler"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" v-if="props.allWindows?.count > 0">
          <v-card max-height="500" :loading="loading">
            <v-card-title class=""> Windows Models </v-card-title>
            <v-card-text>
              <v-table hover density="compact" height="250" fixed-header>
                <thead>
                  <tr>
                    <th>Model</th>
                    <th>No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="model in convertObjToArray(
                      props.windowsModels.summarisedData
                    )"
                    :key="model"
                    @click="clickDrilldown(model)"
                  >
                    <td>{{ model.name }}</td>
                    <td>{{ model.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" v-if="props.allMacs?.count > 0">
          <v-card max-height="500" :loading="loading">
            <v-card-title class=""> Mac Models </v-card-title>
            <v-card-text>
              <v-table hover density="compact" height="250" fixed-header>
                <thead>
                  <tr>
                    <th>Model</th>
                    <th>No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="model in convertObjToArray(
                      props.macModels.summarisedData
                    )"
                    :key="model"
                    @click="clickDrilldown(model)"
                  >
                    <td>{{ model.name }}</td>
                    <td>{{ model.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" v-if="props.allIOS?.count > 0">
          <v-card max-height="500" :loading="loading">
            <v-card-title class=""> iOS/iPadOS Models </v-card-title>
            <v-card-text>
              <v-table hover density="compact" height="250" fixed-header>
                <thead>
                  <tr>
                    <th>Model</th>
                    <th>No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="model in convertObjToArray(
                      props.iosModels.summarisedData
                    )"
                    :key="model"
                    @click="clickDrilldown(model)"
                  >
                    <td>{{ model.name }}</td>
                    <td>{{ model.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" v-if="props.allAndroid?.count > 0">
          <v-card max-height="500" :loading="loading">
            <v-card-title class=""> Android Models </v-card-title>
            <v-card-text>
              <v-table hover density="compact" height="250" fixed-header>
                <thead>
                  <tr>
                    <th>Model</th>
                    <th>No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="model in convertObjToArray(
                      props.androidModels.summarisedData
                    )"
                    :key="model"
                    @click="clickDrilldown(model)"
                  >
                    <td>{{ model.name }}</td>
                    <td>{{ model.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-window-item>
  </v-window>
</template>

<style scoped>
.v-card-title {
  font-weight: 700;
}
</style>
