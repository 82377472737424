<script setup>
import { onMounted, ref, reactive, defineProps } from "vue";
import { apiQuery } from "@/api/j24api";
import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();

const notConfigured = ref(false);

const props = defineProps({
  licence: Object,
});
const users = reactive([]);

const loading = ref(false);
const headers = [
  { text: "Email Addresss", value: "Attributes.email" },
  { text: "Name", value: "Name" },
  { text: "Customer", value: "Attributes.custom:Customer" },
  { text: "Enabled", value: "Enabled" },
  { text: "Status", value: "UserStatus" },
  { text: "Actions", value: "actions" },
];

async function getGroup() {
  try {
    loading.value = true;
    const result = await apiQuery("J24Auth", "elevateLicences", {
      apiAction: "getGroup",
      licence: props.licence,
    });
    if (result == "Not Configured") {
      notConfigured.value = true;
      return;
    }
    notConfigured.value = false;
    if (result.length) {
      users.length = 0;
      result.forEach((user) => {
        var newAttributes = {};
        user.Attributes.forEach((attribute) => {
          newAttributes[attribute.Name] = attribute.Value;
        });
        users.push({
          ...user,
          Attributes: newAttributes,
          Name: newAttributes.given_name + " " + newAttributes.family_name,
        });
      });
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

async function configureCustomer() {
  try {
    loading.value = true;
    const result = await apiQuery("J24Auth", "elevateLicences", {
      apiAction: "configure",
      licence: props.licence,
    });
    console.log(result);
  } catch (error) {
    console.log(error);
  }
  await getGroup();
  loading.value = false;
}
onMounted(() => {
  getGroup();
});

const addingUser = ref(false);
const newUser = ref({});

async function AddNewUser() {
  loading.value = true;
  try {
    const result = await apiQuery("J24Auth", "elevateLicences", {
      apiAction: "addUser",
      user: {
        ...newUser.value,
        Username: newUser.value.email,
        Customer: props.licence.elaCustomerId,
      },
    });
    console.log(result);
  } catch (error) {
    alertTxt.value = "Error Creating user check user does not already exist";
  }
  getGroup();
  newUser.value = {};

  loading.value = false;
}

const alertTxt = ref("");

async function disableAccount(account) {
  console.log(account);
  loading.value = true;
  dialog.value = {
    title: "Disable User",
    checkboxLabel: "Confirm to disable user account.",
    show: true,
    action: "disableUser",
    account: account,
  };
}

async function enableAccount(account) {
  console.log(account);
  loading.value = true;
  dialog.value = {
    title: "Enable User",
    checkboxLabel: "Confirm to enable user account.",
    show: true,
    action: "enableUser",
    account: account,
  };
}

async function deleteAccount(account) {
  console.log(account);
  loading.value = true;
  dialog.value = {
    title: "Delete User",
    checkboxLabel: "Confirm to delete user account.",
    show: true,
    action: "deleteUser",
    account: account,
  };
}

async function dialogClicked() {
  if (dialog.value.checkbox) {
    try {
      dialog.value.loading = true;
      loading.value = true;
      await apiQuery("J24Auth", "elevateLicences", {
        apiAction: dialog.value.action,
        user: dialog.value.account,
      });
    } catch (error) {
      console.log(error);
      alertTxt.value = "Error Updating account";
    }
  }
  resetDialog();
  getGroup();
}

function resetDialog() {
  dialog.value = {};
}

const dialog = ref({});

function checkPermission(action) {
  switch (action) {
    case "enableUser":
    case "disableUser":
      if (permissions.$state["ElaAdmin-ElevateLicencesDisableUser"]) {
        return true;
      }
      break;
    case "deleteUser":
      if (permissions.$state["ElaAdmin-ElevateLicencesDeleteUser"]) {
        return true;
      }
      break;
    default:
      return false;
  }
}
</script>

<template>
  <v-dialog v-model="dialog.show" max-width="600">
    <v-card>
      <v-card-title>{{ dialog.title }}</v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="dialog.checkbox"
          :label="dialog.checkboxLabel"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="text-left">
            <v-btn variant="tonal" @click="resetDialog()">Close</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn variant="tonal" @click="dialogClicked()">save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-row v-if="alertTxt">
    <v-col>
      <v-alert class="pa-3" color="error">{{ alertTxt }}</v-alert>
    </v-col>
  </v-row>

  <template v-if="notConfigured">
    <h3>Not Configured</h3>
    <br />This customer has not been configured for external access. Click the
    button below to activate this. <br /><v-btn
      @click="configureCustomer()"
      class="ma-1"
      >Configure</v-btn
    >
  </template>
  <template v-else>
    <v-card v-if="addingUser">
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Email Address"
            v-model="newUser.email"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Firstname"
            v-model="newUser.givenName"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Surname"
            v-model="newUser.familyName"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="3"> <v-btn @click="AddNewUser()">Add User</v-btn></v-col>

        <v-col cols="3" class="text-right"
          ><v-btn @click="addingUser = false">Cancel</v-btn></v-col
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn @click="addingUser = true">Add User</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <EasyDataTable
            :headers="headers"
            :items="users"
            theme-color="#e7541e"
            alternating
            buttons-pagination
            :loading="loading"
          >
            <template #item-UserStatus="item">
              <div v-if="!item.Enabled">Disabled</div>
              <div v-else>
                {{ item.UserStatus }}
              </div>
            </template>

            <template #item-actions="item">
              <div>
                <v-btn
                  class="ma-2"
                  v-if="item.Enabled && checkPermission('disableUser')"
                  icon
                  size="x-small"
                  @click="disableAccount(item)"
                  ><v-icon>mdi-account-off</v-icon>
                  <v-tooltip activator="parent" location="end"
                    >Disable Account</v-tooltip
                  >
                </v-btn>
                <v-btn
                  class="ma-2"
                  v-if="!item.Enabled"
                  icon
                  size="x-small"
                  @click="enableAccount(item)"
                  ><v-icon>mdi-account-check</v-icon>
                  <v-tooltip activator="parent" location="end"
                    >Enable Account</v-tooltip
                  >
                </v-btn>
                <v-btn
                  v-if="!item.Enabled"
                  icon
                  size="x-small"
                  @click="deleteAccount(item)"
                  ><v-icon>mdi-account-remove</v-icon>
                  <v-tooltip activator="parent" location="end"
                    >Delete Account</v-tooltip
                  >
                </v-btn>
              </div>
            </template>
          </EasyDataTable>
        </v-col>
      </v-row>
    </v-card>
  </template>
</template>
