<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { Auth } from "aws-amplify";
import NavBarDynamic from "./NavBarDynamic.vue";
import { apiQuery } from "@/api/j24api";

import { permissionsStore } from "@/stores/permissions";
import { appsListStore } from "@/stores/appsList";
import { useDisplay } from "vuetify";

const { mobile } = useDisplay();

const permissions = permissionsStore();
const navApps = ref([]);
const appsList = appsListStore();

import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { appBarMobileStore } from "@/stores/appBarMobile";
const appBarMobile = appBarMobileStore();
const showMenuIcon = ref(false);
const showMenu = ref(false);

appBarMobile.$subscribe((mutation, state) => {
  showMenuIcon.value = appBarMobile.showMenuIcon;
  showMenu.value = appBarMobile.showMenu;
});

// Watch Mobile for changes
watch(mobile, (newValue, oldValue) => {
  console.log("Mobile Changed", newValue);
  if (newValue) {
    appBarMobile.showMenuIcon = true;
  } else {
    appBarMobile.showMenuIcon = false;
  }
});

const fullAppsList = [
  {
    appName: "CAT24 Admin",
    icon: "mdi-truck-fast",
    link: "/cat24/contactsUpload",
    color: "#e7541e",
    privAppName: "Cat24Admin",
  },
  {
    appName: "Management",
    icon: "mdi-cog",
    link: "/elaadmin",
    color: "black",
    privAppName: "ElaAdmin",
  },
  {
    appName: "Insights",
    icon: "custom:command24Icon",
    link: "/ela/dashboardoverview",
    color: "#e7541e",
    privAppName: "CMD24",
  },
  {
    appName: "User Admin",
    icon: "mdi-account-multiple",
    link: "/admin/Permissions",
    color: "black",
    privAppName: "AdminUsers",
  },
  {
    appName: "Dashboard",
    icon: "mdi-view-dashboard",
    link: "/dashboard/alerts",
    color: "#e7541e",
    privAppName: "Dashboard",
  },
  {
    appName: "Feature Request",
    icon: "mdi-account-details",
    link: "/feature/featurerequest",
    color: "#e7541e",
    privAppName: "Features",
  },
  {
    appName: "Elevate",
    icon: "custom:elevateIcon",
    //iconImage: "elevateIcon.png",
    link: "/elevate/licence",
    color: "#e7541e",
    privAppName: "Elevate",
  },
  {
    appName: "J24 Patching",
    icon: "mdi-account-details",
    link: "/patching",
    color: "#e7541e",
    privAppName: "Patching",
  },
];

function login() {
  Auth.federatedSignIn();
  console.log("Login");
}

function logoutAndRedirect() {
  Auth.signOut();
}

const isAuthenticated = ref(false);
var userObj = reactive({
  email: "",
  lastname: "",
  firstname: "",
});
const email = ref("");
const lastname = ref("");
const firstname = ref("");

onMounted(async () => {
  console.log("OnMounted Mobile", mobile.value);
  if (mobile.value) {
    console.log("Setting Menu Icon True");
    appBarMobile.showMenuIcon = true;
    showMenuIcon.value = true;
  } else {
    appBarMobile.showMenuIcon = false;
    showMenuIcon.value = false;
  }

  Auth.currentAuthenticatedUser()
    .then((currentAuthenticatedUser) => {
      if (currentAuthenticatedUser) {
        isAuthenticated.value = true;
        email.value =
          currentAuthenticatedUser.signInUserSession.idToken.payload.email;
        lastname.value =
          currentAuthenticatedUser.signInUserSession.idToken.payload.family_name;
        firstname.value =
          currentAuthenticatedUser.signInUserSession.idToken.payload.given_name;
      }

      // const userInfo = Auth.currentUserInfo();
      // userInfo.then((result) => {
      //   console.log("User Info");
      //   console.log(result);
      // });
    })
    .catch(() => {
      Auth.federatedSignIn();
    });
  await getAppsListFromLocal();

  const permissionsObject = await apiQuery("J24Auth", "getPermissionObject");
  permissions.$patch(permissionsObject);

  navApps.value = buildAppsObject(fullAppsList, permissionsObject);
  //console.log("NavApps Updated ", navApps.value);
  appsList.$reset();
  appsList.$patch(navApps.value);

  //console.log("apps list patched");
});

function getAppsListFromLocal() {
  const appsListLocal = localStorage.getItem("appsList");
  if (appsListLocal) {
    navApps.value = JSON.parse(appsListLocal);
    appsList.$patch(navApps.value);
  }
}

function buildAppsObject(apps, permissionsObject) {
  const list = [];
  apps.forEach((app) => {
    if (appHasPermission(permissionsObject, app.privAppName)) {
      list.push(app);
    }
  });
  //console.log(list);
  localStorage.setItem("appsList", JSON.stringify(list));
  appsList.$patch(list);
  return list;
}

function appHasPermission(permissionsObject, appName) {
  for (const key in permissionsObject) {
    if (permissionsObject[key].app == appName) {
      return true;
    }
  }
  return false;
}
</script>

<template>
  <v-app-bar app dense class="elevation-0" theme="jigsawDark">
    <v-row no-gutters>
      <v-col cols="auto">
        <v-icon
          class="pl-5 pt-3 pr-4"
          v-if="showMenuIcon"
          @click="appBarMobile.showMenu = !appBarMobile.showMenu"
        >
          mdi-menu
        </v-icon>
      </v-col>
      <v-col cols="auto">
        <v-menu>
          <template v-slot:activator="{ props }">
            <div v-bind="props" class="pointer">
              <v-icon class="pl-5 pt-3">mdi-dots-grid</v-icon>
            </div>
          </template>
          <v-card class="" theme="jigsawLight">
            <v-toolbar color="primary" height="dense">
              <v-toolbar-title class="ma-0 px-4 py-2">Apps</v-toolbar-title>
            </v-toolbar>

            <v-list color="secondary">
              <v-list-item v-if="!navApps.length">
                <v-list-item-title>Loading...</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="navApp in navApps"
                :key="navApp.appName"
                :to="navApp.link"
                variant="text"
              >
                <v-list-item-title />
                <tr>
                  <td class="pa-0 ma-0">
                    <v-img
                      class="pa-n6"
                      v-if="navApp.iconImage"
                      :src="navApp.iconImage"
                      width="24"
                    />
                    <v-icon
                      v-else
                      :icon="navApp.icon"
                      :color="navApp.color"
                      class="pr-2"
                      size="35px"
                    />
                  </td>
                  <td>
                    {{ navApp.appName }}
                  </td>
                </tr>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
      <v-col v-if="!mobile || !appBar.items" class="pl-5 pt-1" cols="auto">
        <v-img src="@/assets/jigsaw24_white_orange.svg" width="150"></v-img>
      </v-col>

      <NavBarDynamic :email="email" />

      <v-spacer></v-spacer>
      <v-col class="text-right pr-5 pt-2" cols="auto">
        <div v-if="isAuthenticated" class="pointer">
          <v-menu rounded="rounded" theme="jigsawLight">
            <template v-slot:activator="{ props }">
              <template v-if="mobile">
                <div v-bind="props">
                  <v-icon>mdi-account</v-icon>
                </div>
              </template>
              <template v-else>
                <div v-bind="props">
                  {{ firstname }} {{ lastname }}
                  <v-icon>mdi-account</v-icon>
                </div>
              </template>
            </template>
            <v-list color="white" rounded shaped>
              <!-- <v-list-item link>
                <v-list-item-title>
                  <router-link
                    to="userProfile"
                    style="text-decoration: none; color: inherit"
                    >Profile</router-link
                  >
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item link>
                <v-list-item-title @click="logoutAndRedirect">
                  Sign out
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn @click="login">
            <v-list-item-title class="text-h6">Sign-In </v-list-item-title>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style>
.v-toolbar__content {
  border-bottom: 0px solid lightgray;
  /*  background: #f9f9f9; */
}
.pointer {
  cursor: pointer;
}
</style>
