<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits } from "vue";
import { apiQuery } from "@/api/j24api";
import DashboardDataDetail from "./DashboardDataDetail.vue";
import DashboardDataDetailV2 from "./DashboardDataDetailV2.vue";
import DashboardEditDataSourceAlert from "./DashboardEditDataSourceAlert.vue";
import DashboardPieChart from "./DashboardPieChart.vue";

const mdmData = ref({});
const pieCharts = ref([]);
const props = defineProps({
  mdmServer: Object,
});

const showDetail = ref(false);
const showDetailPie = ref(false);
const detailItem = ref({});
const detailItemPie = ref({});
const editAlert = ref(false);
const editAlertDataId = ref("");
const dialog = ref(false);
const dialogContent = ref({});

function clickShowDetail(item) {
  showDetail.value = false;
  showDetail.value = true;
  console.log("][][]");
  console.log(item);
  console.log("][][]");
  detailItem.value = { ...item, mdmServerId: props.mdmServer.mdmserverId };
  console.log(detailItem.value);
}

function clickShowDetailPie(item) {
  showDetailPie.value = false;
  showDetailPie.value = true;
  detailItemPie.value = { ...item, mdmServerId: props.mdmServer.mdmserverId };
}

function clickADE() {
  dialogContent.value.title = "Automated Device Enrollment Accounts";
  dialogContent.value.data = [];
  mdmData.value.jamfInformation.data.enrollmentAccounts.forEach((value) => {
    dialogContent.value.data.push(
      value.name + " - Token Expires on " + value.tokenExpirationDate
    );
  });
  dialog.value = true;
}

function clickVPP() {
  dialogContent.value.title = "VPP / Apps and Books Accounts";
  dialogContent.value.data = [];
  mdmData.value.jamfInformation.data.vppAccounts.forEach((value) => {
    dialogContent.value.data.push(
      value.name + " - Token Expires on " + value.expirationDate
    );
  });
  dialog.value = true;
}

const showAlerts = ref(false);
const emits = defineEmits(["cancel"]);

const loading = ref(false);

function preparePieChart(value) {
  value.chartData = { labels: [], datasets: [{ data: [] }] };
  for (const key in value.summarisedData) {
    value.chartData.labels.push(key);
    value.chartData.datasets[0].data.push(value.summarisedData[key]);
  }
  value.chartData = addChartColors(value.chartData);
  pieCharts.value.push(value);
}

async function getData() {
  loading.value = true;

  const result = await apiQuery("ELA", "dashboard/data", {
    mdmServerId: props.mdmServer.mdmserverId,
  });
  if (result) {
    mdmData.value = result;
    mdmData.value.data.forEach((value, key) => {
      if (value.pieChart) {
        preparePieChart(value);
        console.log("Index is");
        console.log(key);
        mdmData.value.data.splice(key, 1);
      }
    });
  }
  if (props.mdmServer.type == "Intune") {
    // mdmData.value.alerts = props.mdmServer.alerts;
    //  mdmData.value.data = props.mdmServer.data;
  }
  loading.value = false;
}

function getDataItemClass(item) {
  if (isThisAlerting(item)) {
    return "bg-red-lighten-1 text-caption pa-1 pointer";
  }
  if (isThisWarning(item)) {
    return "bg-yellow-lighten-1 text-caption pa-1 pointer";
  }
  return "bg-grey-lighten-2 text-caption pa-1 pointer";
}

function isThisAlerting(item) {
  //console.log(item);
  //console.log(mdmData.value.alerts);
  for (const key in mdmData.value.alerts) {
    if (props.mdmServer.type == "Intune") {
      if (item.name == mdmData.value.alerts[key].name) {
        return true;
      }
    } else {
      if (item.id == mdmData.value.alerts[key].dataSourceId) {
        return true;
      }
    }
  }
  return false;
}

function isThisWarning(item) {
  var result = false;

  mdmData.value.warnings.forEach((warning) => {
    console.log(item.name);
    console.log(warning.name);
    if (props.mdmServer.type == "Intune") {
      if (item.name == warning.name) {
        result = true;
      }
    } else {
      if (item.id == warning.dataSourceId) {
        result = true;
      }
    }
  });
  return result;
}

onMounted(() => {
  getData();
});

function addChartColors(chartData) {
  const colors = [
    "#e8dff5",
    "#fce1e4",
    "#fcf4dd",
    "#ddedea",
    "#daeaf6",
    "#d4afb9",
    "#d1cfe2",
    "#9cadce",
    "#7ec4cf",
    "#52b2cf",
  ];
  var counter = 0;
  var colourCounter = 0;
  const coloursRequired = chartData.datasets[0].data.length;
  while (counter < coloursRequired) {
    counter++;
    if (!chartData.datasets[0].backgroundColor) {
      chartData.datasets[0].backgroundColor = [colors[colourCounter]];
    } else {
      chartData.datasets[0].backgroundColor.push(colors[colourCounter]);
    }
    if (colourCounter == colors.length) {
      colourCounter = 0;
    }
    colourCounter++;
  }
  return chartData;
}

var dummyData = {
  labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
  datasets: [
    {
      //backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
      data: [40, 20, 80, 10],
    },
  ],
};
dummyData = addChartColors(dummyData);

const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

function safeLength(value) {
  if (typeof value != "object") {
    return 0;
  }
  if (value.length) {
    return value.length;
  }
  return 0;
}
</script>

<template>
  <v-row class="px-3"
    ><v-col><v-icon @click="$emit('cancel')">mdi-arrow-left</v-icon></v-col
    ><v-col
      ><h2 class="text-right">{{ mdmServer.name }}</h2></v-col
    ></v-row
  >
  <v-row v-if="loading">
    <v-col class="text-center">
      <v-progress-circular
        class="text-center"
        indeterminate
        size="64"
      ></v-progress-circular> </v-col
  ></v-row>

  <template v-if="mdmData.jamfInformation">
    <v-dialog v-model="dialog" scrollable>
      <v-card>
        <v-card-title>{{ dialogContent.title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <template v-for="text in dialogContent.data" :key="text">
            {{ text }}<br />
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="10">
        <template v-if="props.mdmServer.type == 'Jamf'">
          <v-card>
            <v-card-title>Jamf Information</v-card-title>
            <v-card-text
              ><v-table class="text-left"
                ><tr>
                  <th>Jamf Version:</th>

                  <td>
                    {{ mdmData.jamfInformation.data.jamfVersion }}
                  </td>
                  <th>Computer Licenses</th>
                  <td>{{ mdmData.jamfInformation.data.computerLicenses }}</td>
                </tr>
                <tr>
                  <th>APNS Certificate Expires</th>
                  <td>{{ mdmData.jamfInformation.data.apnsExpiry }}</td>
                  <th>Managed Computers</th>
                  <td>{{ mdmData.jamfInformation.data.managedComputers }}</td>
                </tr>
                <tr>
                  <th>ADE Expiry</th>
                  <td @click="clickADE()" class="pointer">
                    <template
                      v-if="
                        safeLength(
                          mdmData.jamfInformation.data.enrollmentAccounts
                        ) > 3
                      "
                    >
                      Multiple
                    </template>
                    <template
                      v-else
                      v-for="enrollmentAccount in mdmData.jamfInformation.data
                        .enrollmentAccounts"
                      >{{
                        enrollmentAccount.tokenExpirationDate + " "
                      }}</template
                    >
                  </td>
                  <th>iOS Licenses</th>
                  <td>{{ mdmData.jamfInformation.data.deviceLicenses }}</td>
                </tr>
                <tr>
                  <th>VPP Expiry</th>
                  <td @click="clickVPP()" class="pointer">
                    <template
                      v-if="
                        safeLength(mdmData.jamfInformation.data.vppAccounts) > 3
                      "
                    >
                      Multiple
                    </template>

                    <template
                      v-else
                      v-for="vppAccount in mdmData.jamfInformation.data
                        .vppAccounts"
                      >{{ vppAccount.expirationDate + " " }}</template
                    >
                  </td>
                  <th>Managed iOS</th>
                  <td>{{ mdmData.jamfInformation.data.mangedDevices }}</td>
                </tr>
              </v-table></v-card-text
            ></v-card
          ></template
        >
        <template v-if="props.mdmServer.type == 'Intune'">
          <v-card>
            <v-card-title>Intune Information</v-card-title>
            <v-card-text
              ><v-table class="text-left"
                ><tr>
                  <th></th>

                  <td></td>
                  <th>Computer Licenses</th>
                  <td>{{ mdmData.jamfInformation.data.computerLicenses }}</td>
                </tr>
                <tr>
                  <th>APNS Certificate Expires</th>
                  <td>{{ mdmData.jamfInformation.data.apnsExpiry }}</td>
                  <th>Managed Computers</th>
                  <td>{{ mdmData.jamfInformation.data.managedComputers }}</td>
                </tr>
                <tr>
                  <th>ADE Expiry</th>
                  <td @click="clickADE()" class="pointer">
                    <template
                      v-if="
                        safeLength(
                          mdmData.jamfInformation.data.enrollmentAccounts
                        ) > 3
                      "
                    >
                      Multiple
                    </template>
                    <template
                      v-else
                      v-for="enrollmentAccount in mdmData.jamfInformation.data
                        .enrollmentAccounts"
                      >{{
                        enrollmentAccount.tokenExpirationDate + " "
                      }}</template
                    >
                  </td>
                  <th>iOS Licenses</th>
                  <td>{{ mdmData.jamfInformation.data.deviceLicenses }}</td>
                </tr>
                <tr>
                  <th>VPP Expiry</th>
                  <td @click="clickVPP()" class="pointer">
                    <template
                      v-if="
                        safeLength(mdmData.jamfInformation.data.vppAccounts) > 3
                      "
                    >
                      Multiple
                    </template>

                    <template
                      v-else
                      v-for="vppAccount in mdmData.jamfInformation.data
                        .vppAccounts"
                      >{{ vppAccount.expirationDate + " " }}</template
                    >
                  </td>
                  <th>Managed iOS</th>
                  <td>{{ mdmData.jamfInformation.data.mangedDevices }}</td>
                </tr>
              </v-table></v-card-text
            ></v-card
          ></template
        >
      </v-col>
      <v-col cols="2" class="fill-height"
        ><v-card @click="showAlerts = !showAlerts"
          ><v-card-title>Alerts</v-card-title
          ><v-card-text class="text-center text-h4">{{
            mdmData.alerts.length
          }}</v-card-text></v-card
        ></v-col
      >
    </v-row>

    <v-row v-if="showAlerts"
      ><v-col
        ><v-card
          ><v-card-text
            ><v-table density="compact"
              ><thead>
                <tr>
                  <th>Alert</th>
                  <th>Date Logged</th>
                  <th>Service Now ID</th>
                </tr>
                <tr v-for="alert in mdmData.alerts" :key="alert.name">
                  <td>{{ alert.name }}</td>
                  <td>{{ alert.dateCreated }}</td>
                  <td>{{ alert.serviceNowRecord }}</td>
                </tr>
              </thead></v-table
            ></v-card-text
          ></v-card
        ></v-col
      ></v-row
    >

    <v-row v-if="showDetailPie">
      <v-col
        ><DashboardDataDetailV2
          :data="detailItemPie"
          @close="showDetailPie = false" /></v-col
    ></v-row>

    <v-row
      ><v-col v-for="chart in pieCharts" :key="chart.id" cols="6"
        ><v-card>
          <v-card-title
            class="bg-grey-lighten-2 text-caption"
            @click="clickShowDetailPie(chart)"
            >{{ chart.name }}</v-card-title
          >
          <DashboardPieChart
            :chartData="chart.chartData"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card> </v-col
    ></v-row>

    <v-row v-if="showDetail">
      <v-col
        ><DashboardDataDetailV2
          :data="detailItem"
          @close="showDetail = false" /></v-col
    ></v-row>

    <v-row v-if="editAlert"
      ><v-col>
        <DashboardEditDataSourceAlert
          :mdmServerId="props.mdmServer.mdmserverId"
          :dataSourceId="editAlertDataId"
          @cancel="editAlert = false"
      /></v-col>
    </v-row>

    <v-row class="pa-2">
      <v-col cols="3" v-for="tile in mdmData.data" :key="tile.id" class="pa-1"
        ><v-card>
          <v-card-title
            @click="clickShowDetail(tile)"
            :class="getDataItemClass(tile)"
            >{{ tile.name.replace("J24 - ", "") }}</v-card-title
          >
          <v-card-text class="pa-1">
            <div class="text-center text-h4" @click="clickShowDetail(tile)">
              {{ tile.count }}
            </div>
            <div class="text-right pa-0">
              <v-icon
                small
                @click="
                  editAlert = true;
                  editAlertDataId = tile.id;
                "
              >
                mdi-cog-outline</v-icon
              >
            </div></v-card-text
          ></v-card
        >
      </v-col>
    </v-row>
  </template>
</template>
