<template>
  <v-main>
    <v-row class="ma-9">
      <template v-for="app in appsList2" :key="app">
        <v-col cols="12" sm="4">
          <v-card :prepend-icon="app.icon" :title="app.appName" :to="app.link">
            <template v-slot:prepend>
              <v-icon :color="app.color" size="x-large" border>
                {{ app.icon }}
              </v-icon>
            </template>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <!-- <button @click="apiTest()">Test API</button> |
    <button @click="configureMFA()">Configure MFA</button>|
    <button @click="setAttribute()">Set Attribute</button>|
    <router-link to="/signin">Sign In</router-link>|
    <router-link to="/signout">Sign out</router-link>|
    <router-link to="/enablemfa">EnableMFA</router-link>|
    <router-link to="/loginTest">Login Test</router-link> -->
  </v-main>
</template>

<script setup>
import { onMounted, reactive } from "vue";
import { apiQuery } from "@/api/j24api";
import { Auth } from "aws-amplify";
import { useRouter } from "vue-router";
import { appsListStore } from "@/stores/appsList";
import router from "@/router";

const appsList = appsListStore();
const appsList2 = reactive([]);

function checkSingleApp() {
  if (Object.keys(appsList.$state).length == 1) {
    router.push(appsList.$state[0].link);
  }
}

appsList.$subscribe((mutation, state) => {
  console.log("Apps List Updated", state);
  checkSingleApp();
  appsList2.length = 0;
  appsList2.push(...state);
});

onMounted(() => {
  checkSingleApp();
  appsList2.length = 0;
  appsList2.push(...appsList.$state);
});

// function apiTest() {
//   //const testing = apiQuery("J24", "", "");
//   const testing = apiQuery("J24Auth", "getPermissionObject", {});
//   testing.then((result) => {
//     console.log(result);
//   });
// }

// async function configureMFA() {
//   const user = await Auth.currentAuthenticatedUser();
//   console.log(user);
//   const totpCode = await Auth.setupTOTP(user);
//   console.log(totpCode);
// }

// async function setAttribute() {
//   const user = await Auth.currentAuthenticatedUser();
//   const result = await Auth.updateUserAttributes(user, {
//     email: "craig1212@mac.com",
//   });
//   console.log(result);
// }

//const otpCode = re

// export default defineComponent({
//   name: "HomeView",
// });
</script>
