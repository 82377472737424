import { defineStore } from "pinia";

export const permissionsStore = defineStore("permissions", {
  state: () => ({} as PermissionObject),
});

export interface PermissionObject {
  [key: string]: permission;
}

interface permission {
  app: string;
  permissionName: string;
  scope: string;
  actions: string[];
}
