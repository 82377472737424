<script setup>
import { reactive, ref, watch } from "vue";
import DashboardDataCollection from "./DashboardDataCollection.vue";
import DashboardDataCollectionIntune from "./DashboardDataCollectionIntune.vue";
const selectedTab = ref("");
</script>

<template>
  <v-tabs v-model="selectedTab" color="#e7541e">
    <v-tab value="dataSources">Jamf Data Sources</v-tab>
    <v-tab value="dataSourcesIntune">Intune Data Sources</v-tab>
    <v-tab value="Other">Other</v-tab>
  </v-tabs>

  <v-window v-model="selectedTab">
    <v-window-item value="dataSources">
      <DashboardDataCollection />
    </v-window-item>
    <v-window-item value="dataSourcesIntune">
      <DashboardDataCollectionIntune />
    </v-window-item>
  </v-window>
</template>
