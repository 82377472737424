function intune(data) {
  console.log("intune");
  console.log("Data", data);
  var page = [];
  page.push(generalFields(data));
  page.push(hardwareFields(data));
  page.push(deviceCompliance(data));
  return page;
}

function generalFields(data) {
  const section = {
    sectionTitle: "General",
    fields: [],
    expanded: true,
  };
  section.fields.push(addField("Operating System", data.operatingSystem));
  section.fields.push(addField("Operating System Version", data.osVersion));
  section.fields.push(addField("User", data.userDisplayName));
  const lastContact = TimeDifference(new Date(data.lastSyncDateTime));
  section.fields.push(addField("Last Sync", lastContact));
  const lastEnrollment = TimeDifference(new Date(data.enrolledDateTime));
  section.fields.push(addField("Enrollment Time", lastEnrollment));
  switch (data.autopilotEnrolled) {
    case true:
      section.fields.push(addField("Autopilot Enrolled", "Yes"));
      break;
    case false:
      if (data.operatingSystem === "Windows") {
        section.fields.push(addField("Autopilot Enrolled", "No"));
      }
      break;
  }
  if (data.enrollmentProfileName != "") {
    section.fields.push(
      addField("Deployment Profile", data.enrollmentProfileName)
    );
  }
  const mdmCert = formatDate(
    new Date(data.managementCertificateExpirationDate)
  );

  section.fields.push(addField("MDM Certificate Expiry", mdmCert));
  section.fields.push(
    addField("Enrollment Type", capitalizeFirstLetter(data.ownerType))
  );

  section.fields = removeEmpty(section.fields);
  return section;
}

function hardwareFields(data) {
  const section = {
    sectionTitle: "Hardware",
    fields: [],
    expanded: true,
  };

  var encryptionState;

  data.wiFiMacAddress = data.wiFiMacAddress
    .replace(/(..?)/g, "$1:")
    .slice(0, -1)
    .toUpperCase();

  section.fields.push(addField("Manufacturer", data.manufacturer));
  //section.fields.push(addField("Device Type", data.deviceType));
  section.fields.push(addField("Model", data.hardwareInformation.model));
  section.fields.push(
    addField("Model Identifier", data.hardwareInformation.productName)
  );
  section.fields.push(addField("Serial Number", data.serialNumber));
  if (data.udid != "") {
    section.fields.push(addField("UDID", data.udid));
  }
  section.fields.push(addField("Mac Address", data.wiFiMacAddress));
  if (data.hardwareInformation.batteryLevelPercentage !== null) {
    section.fields.push(
      addField("Battery", data.hardwareInformation.batteryLevelPercentage + "%")
    );
  }
  section.fields.push(
    addField(
      "Total Capacity",
      Math.round(data.totalStorageSpaceInBytes / 1073741824) + " GB"
    )
  );
  section.fields.push(
    addField(
      "Available Capacity",
      Math.round(data.freeStorageSpaceInBytes / 1073741824) + " GB"
    )
  );
  if (data.isEncrypted == true) {
    encryptionState = "Encrypted";
  } else {
    encryptionState = "Not Encrypted";
  }
  section.fields.push(addField("Device Encryption", encryptionState));
  section.fields = removeEmpty(section.fields);
  return section;
}

function deviceCompliance(data) {
  const section = {
    sectionTitle: "Device Compliance",
    fields: [],
    expanded: true,
  };

  section.fields.push(
    addField(
      "Intune Management State",
      capitalizeFirstLetter(data.managementState)
    )
  );
  section.fields.push(
    addField(
      "AAD Registration State",
      capitalizeFirstLetter(data.deviceRegistrationState)
    )
  );
  section.fields.push(
    addField("Compliance State", capitalizeFirstLetter(data.complianceState))
  );
  section.fields.push(
    addField("Jailbroken State", capitalizeFirstLetter(data.jailBroken))
  );

  section.fields = removeEmpty(section.fields);
  return section;
}

function addField(name, value, defaultValue = undefined, suffix = "") {
  if (value == undefined && defaultValue != undefined) {
    console.log("Using default value");
    return { name: name, defaultValue };
  }
  if (value != undefined) {
    return { name: name, value: value + suffix };
  }
  //If here not a valid value
}

function TimeDifference(dateThen) {
  const dateNow = new Date();
  //const dateThen = new Date(dateISO);

  console.log(dateThen);
  var diff = (dateNow.getTime() - dateThen.getTime()) / 1000;
  if (diff < 60) {
    if (Math.ceil(diff) == 1) {
      return "1 Second Ago";
    }
    return Math.ceil(diff) + " Seconds Ago";
  }
  if (diff < 3600) {
    if (Math.ceil(diff) == 60) {
      return "1 Minute Ago";
    }
    return Math.ceil(diff / 60) + " Minutes Ago";
  }
  if (diff < 86400) {
    if (Math.ceil(diff) == 3600) {
      return "1 Hour Ago";
    }
    return Math.ceil(diff / 60 / 60) + " Hours Ago";
  }
  return dateThen.toLocaleString("en-GB");
}

function removeEmpty(fields) {
  console.log(fields);
  return fields.filter((field) => field != undefined);
}

function formatDate(date) {
  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  return formattedDate;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

module.exports = intune;
