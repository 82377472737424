<script setup>
import { reactive, ref, onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";
import { Auth } from "aws-amplify";
import PortalCustomisationsPage from "./PortalCustomisationsPage.vue";

const appBar = appBarStore();
const loading = ref(false);
const showCustomer = ref(false);
const showDetail = ref(false);
const customisable = ref(true);
const indexOfArray = ref({});
const resultsObjToPass = ref({});
let resultsObj = ref({});
let itemKeys = reactive([]);
let itemValues = reactive([]);
let itemValuesArray = reactive([]);
const itemKeysToPass = ref({});
let itemEntries = reactive([]);
let index = 0;

appBar.$subscribe(() => {
  if (appBar.selected != "") {
    getData();
  }
});

async function getData() {
  itemValuesArray.length = 0;
  try {
    itemKeys.length = 0;
    loading.value = true;
    const results = await apiQuery("ELA", "cat24/customisations", {
      apiAction: "get",
      customer: appBar.selected,
    });
    if (!results) {
      customisable.value = false;
    } else {
      customisable.value = true;
    }
    for (var key of Object.keys(results)) {
      if (key != "_id") {
        itemKeys.push(key);
      }
    }
    itemValues = Object.values(results);
    itemValues.forEach((value, index) => {
      if (index != 0) {
        itemValuesArray.push(value);
      }
    });
    loading.value = false;
    resultsObj = results;
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

onMounted(() => {
  if (appBar.selected) {
    loading.value = true;
    getData();
  }
});

function clickCard(index, resultsObj) {
  showDetail.value = true;
  indexOfArray.value = index;
  resultsObjToPass.value = resultsObj;
}
</script>

<template>
  <template v-if="!appBar.selected.length">
    <h3>Select Customer from Menu above</h3>
  </template>
  <template v-else>
    <h1 style="color: rgb(231, 84, 30); padding-top: 1%; padding-bottom: 1%">
      Customisations
    </h1>
  </template>
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
      indeterminate
      size="64"
      color="#e7541e"
    ></v-progress-circular>
  </v-overlay>
  <PortalCustomisationsPage
    v-if="showDetail"
    :selectedPage="indexOfArray"
    :resultsPassed="resultsObjToPass"
    @cancel="showDetail = false"
  />

  <template v-else>
    <div v-if="!customisable">
      <v-card>
        <v-card-title style="color: rgb(231, 84, 30)"
          >No Customisable Content</v-card-title
        >
        <v-card-text>
          <p>
            This customer has no customisable content, please make a different
            selection. You can request a change through DevSecOps if you wish to
            allow the content from their portal to be customisable.
          </p>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-row :loading="loading">
        <template
          v-for="(itemValue, index) in itemValuesArray"
          :key="itemValue"
        >
          <v-card
            @click="clickCard(index, resultsObj, itemKeys)"
            style="margin: 1%; max-width: 20%"
          >
            <v-card-title>{{ itemValue.displayName }}</v-card-title>
            <v-img
              v-bind:src="
                require('/src/views/cat24/Assets/' + itemValue.imageName)
              "
            ></v-img>
          </v-card>
        </template>
      </v-row>
    </div>
  </template>
</template>
