function jamf(data) {
  console.log("jamf");
  console.log("Data", data);
  var page = [];
  page.push(generalFields(data));
  page.push(hardwareFields(data));
  page.push(diskEncryption(data));
  page.push(userAccounts(data));
  return page;
}

function userAccounts(data) {
  const section = {
    sectionTitle: "User Accounts",
    fields: [],
    expanded: false,
  };

  let users = {
    type: "subTable",
    cols: ["UID", "Username", "Full Name", "Admin", "Home Directory"],
    values: [],
  };
  if (data.localUserAccounts.length) {
    data.localUserAccounts.forEach((user) => {
      if (user.username[0] != "_") {
        users.values.push({
          UID: user.uid,
          Username: user.username,
          "Full Name": user.fullName,
          Admin: user.admin,
          "Home Directory": user.homeDirectory,
        });
      }
    });
  }
  section.fields.push(users);
  return section;
}

function diskEncryption(data) {
  const section = {
    sectionTitle: "Disk Encryption",
    fields: [],
    expanded: true,
  };
  section.fields.push(
    addField(
      "Filevault State",
      data.diskEncryption?.bootPartitionEncryptionDetails
        ?.partitionFileVault2State
    )
  );
  section.fields.push(
    addField(
      "Filevault Recovery Key Validity",
      data.diskEncryption?.individualRecoveryKeyValidityStatus
    )
  );
  section.fields = removeEmpty(section.fields);
  return section;
}

function generalFields(data) {
  const section = {
    sectionTitle: "General",
    fields: [],
    expanded: true,
  };
  section.fields.push(addField("Serial Number", data.hardware?.serialNumber));
  section.fields.push(addField("Model", data.hardware?.model));
  section.fields.push(addField("macOS Version", data.operatingSystem?.version));
  section.fields.push(addField("User", data.userAndLocation?.username));
  section.fields.push(addField("Asset Tag", data.general?.assetTag));
  const lastContact = TimeDifference(new Date(data.general.lastContactTime));
  section.fields.push(addField("Last Contact", lastContact));

  section.fields = removeEmpty(section.fields);
  console.log(section);
  section.panel = {
    model: data.hardware?.model,
    serial: data.hardware?.serialNumber,
    cpu: data.hardware?.processorType,
    ram: data.hardware?.totalRamMegabytes / 1024 + " GB",
  };
  return section;
}

function hardwareFields(data) {
  const section = {
    sectionTitle: "Hardware",
    fields: [],
    expanded: true,
  };
  section.fields.push(addField("Model", data.hardware?.modelIdentifier));
  section.fields.push(
    addField("Processor Architecture", data.hardware?.processorArchitecture)
  );
  section.fields.push(addField("Processor Type", data.hardware?.processorType));
  section.fields.push(addField("Processor Cores", data.hardware?.coreCount));
  if (data.hardware?.totalRamMegabytes) {
    const ram = data.hardware?.totalRamMegabytes / 1024 + " GB";
    section.fields.push(addField("Memory", ram));
  }
  section.fields.push(addField("MAC Address", data.hardware?.macAddress));
  section.fields.push(
    addField(
      "Battery Level",
      data.hardware?.batteryCapacityPercent,
      undefined,
      "%"
    )
  );
  section.fields = removeEmpty(section.fields);
  return section;
}

function removeEmpty(fields) {
  console.log(fields);
  return fields.filter((field) => field != undefined);
}

function addField(name, value, defaultValue = undefined, suffix = "") {
  if (value == undefined && defaultValue != undefined) {
    console.log("Using default value");
    return { name: name, defaultValue };
  }
  if (value != undefined) {
    return { name: name, value: value + suffix };
  }
  //If here not a valid value
}

function formatDate(date) {
  if (date == undefined) {
    return undefined;
  }
  let text = "";
  text = new Date(date).toLocaleString("en-GB");
  if (text == "Invalid Date") {
    return "";
  }
  return text;
}

function TimeDifference(dateThen) {
  const dateNow = new Date();
  //const dateThen = new Date(dateISO);

  console.log(dateThen);
  var diff = (dateNow.getTime() - dateThen.getTime()) / 1000;
  if (diff < 60) {
    if (Math.ceil(diff) == 1) {
      return "1 Second Ago";
    }
    return Math.ceil(diff) + " Seconds Ago";
  }
  if (diff < 3600) {
    if (Math.ceil(diff) == 60) {
      return "1 Minute Ago";
    }
    return Math.ceil(diff / 60) + " Minutes Ago";
  }
  if (diff < 86400) {
    if (Math.ceil(diff) == 3600) {
      return "1 Hour Ago";
    }
    return Math.ceil(diff / 60 / 60) + " Hours Ago";
  }
  return dateThen.toLocaleString("en-GB");
}
module.exports = jamf;
