<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  macOSVersions: Object,
  iOSVersions: Object,
  appleUpdates: Object,
  windowsVersions: Object,
  windowsUpdates: Object,
});

const emit = defineEmits(["clickDataPoint"]);

// Data is not reactive on purpose, it will break if it is :-)
var chartData = [];
const showchart = ref(false);

var subChartData = [];
const showSubChart = ref(false);

var chartDataIOS = [];
const showchartIOS = ref(false);

var chartDataWindows = [];
const showchartWindows = ref(false);

const versionsData = [];

function clickHandler(event, chartContext, config) {
  //console.log("Emit");
  emit("clickDataPoint", event, chartContext, config);
}

function clickMajorVersionBar(chart) {
  const data = versionsData.find((v) => v.versionName == chart.name);
  console.log("Data", data);
  subChartData.length = 0;
  subChartData.push(...createSubChartData(data.versions));
  console.log("Sub Chart Data", subChartData);
  if (subChartData.length) {
    showchart.value = false;
    showchartIOS.value = false;
    showchartWindows.value = false;
    showSubChart.value = true;
  }
}

function clickMajorVersion(chart) {
  let versionData = versionsData.find((v) => v.versionName == chart.name);
  console.log("versionData", versionData);
  let objectToSend = {
    seriesIndex: 0,
    w: {
      config: {
        series: [
          {
            records: versionData.versions.map((a) => a.records),
          },
        ],
      },
    },
  };
  console.log("Object To Send", objectToSend);
  emit("clickDataPoint", {}, {}, objectToSend);
}

onMounted(() => {
  if (props.macOSVersions) {
    const versionData = prepareVersionData(props.macOSVersions.summarisedData);
    chartData = createChartData(versionData.squashed);
    versionsData.push(...versionData.results);
    //chartData = setMinimumWidth(chartData);
    showchart.value = true;
  }

  if (props.windowsVersions) {
    const versionData = prepareVersionDataWindows(
      props.windowsVersions.summarisedData
    );
    chartDataWindows = createChartData(versionData.squashed);
    versionsData.push(...versionData.results);
    showchartWindows.value = true;
  }

  if (props.iOSVersions) {
    const versionData = prepareVersionDataIOS(props.iOSVersions.summarisedData);
    chartDataIOS = createChartData(versionData.squashed);
    versionsData.push(...versionData.results);
    //chartDataIOS = setMinimumWidth(chartDataIOS);
    showchartIOS.value = true;
  }
  setBarColours();
});

function setBarColours() {
  function colourToUse(name) {
    let colour1 = "#eb5d0b";
    let colour2 = "#eb5d0b";
    let colour3 = "#374f50";
    let colour4 = "#000000";
    let defaultColour = "#eeeeee";
    var primaryColour;
    switch (name) {
      case "Sonoma":
      case "Ventura":
        primaryColour = colour1;
        break;
      case "Monterey":
        primaryColour = colour2;
        break;
      case "Big Sur":
        primaryColour = colour3;
        break;
      case "17":
      case "16":
        primaryColour = colour1;
        break;
      case "15":
        primaryColour = colour2;
        break;
      case "14":
        primaryColour = colour3;
        break;
      case "Windows 11":
      case "Windows 10":
        primaryColour = colour1;
        break;
      default:
        primaryColour = colour4;
        break;
    }
    return { primary: primaryColour, default: defaultColour };
  }

  function setColours(chart) {
    const colour = colourToUse(chart.name);
    var coloursArray = [];
    for (let i = 1; i < chart.options.series.length; i++) {
      coloursArray.push(colour.default);
    }
    coloursArray.push(colour.primary);
    chart.options.colors = coloursArray;
    chart.options.dataLabels.style = { colors: [] };
    for (let i = 1; i < chart.options.series.length; i++) {
      chart.options.dataLabels.style.colors.push("#000000");
    }
    chart.options.dataLabels.style.colors.push("#ffffff");
    return chart;
  }

  chartData.forEach((chart, index) => {
    chartData[index] = setColours(chart);
  });
  chartDataIOS.forEach((chart, index) => {
    chartDataIOS[index] = setColours(chart);
  });
  chartDataWindows.forEach((chart, index) => {
    chartDataWindows[index] = setColours(chart);
  });
  subChartData.forEach((chart, index) => {
    subChartData[index] = setColours(chart);
  });
}

// This all needs more thought

// function setMinimumWidth(data) {
//   var xaxisMax = 0;
//   var minValue = 0;
//   data.forEach((chart, index) => {
//     console.log("Set Minimum Width");
//     let total = chart.options.xaxis.max;
//     chart.options.series.forEach((series) => {
//       series.data.forEach((value) => {
//         total += value;
//       });
//     });
//     console.log("Total", total);
//     var newTotal = 0;
//     chart.options.series.forEach((series, sIndex) => {
//       chart.options.series[sIndex].values = [];
//       series.data.forEach((value, vIndex) => {
//         chart.options.series[sIndex].values.push(value);
//         if (total / value > 24) {
//           chart.options.series[sIndex].data[vIndex] = Math.floor(total * 0.025);
//         }
//         newTotal += chart.options.series[sIndex].data[vIndex];
//       });
//     });
//     if (newTotal > xaxisMax) {
//       xaxisMax = newTotal;
//     }
//   });

//   data.forEach((chart) => {
//     chart.options.xaxis.max = xaxisMax;
//   });
//   return data;
// }

function versionSort(versions) {
  function extractVersions(version) {
    //console.log("Version", version);
    const split = version.split(".");
    const a = split[0] ? split[0] : 0;
    const b = split[1] ? split[1] : 0;
    const c = split[2] ? split[2] : 0;
    return [a, b, c];
  }
  function compare(a, b) {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }
  return versions.sort((a, b) => {
    const aVer = extractVersions(a.version);
    const bVer = extractVersions(b.version);
    if (compare(aVer[0], bVer[0]) != 0) {
      return compare(aVer[0], bVer[0]);
    }
    if (compare(aVer[1], bVer[1]) != 0) {
      return compare(aVer[1], bVer[1]);
    }
    if (compare(aVer[2], bVer[2]) != 0) {
      return compare(aVer[2], bVer[2]);
    }
    return 0;
  });
}

function getLatestVersion(version) {
  // Get Major version from version string 14.5.6 -> 14
  var majorVersion = version.split(".")[0];
  var latestVersion = "999";
  try {
    if (props.macOSVersions) {
      props.appleUpdates.PublicAssetSets.macOS.forEach((assetSet) => {
        if (assetSet.ProductVersion.split(".")[0] == majorVersion) {
          latestVersion = assetSet.ProductVersion;
        }
      });
    }
    if (props.iOSVersions) {
      props.appleUpdates.PublicAssetSets.iOS.forEach((assetSet) => {
        if (assetSet.ProductVersion.split(".")[0] == majorVersion) {
          if (
            assetSet.SupportedDevices.find((device) => device.includes("iPad"))
          ) {
            latestVersion = assetSet.ProductVersion;
          }
        }
      });
    }
    if (props.windowsVersions) {
      props.windowsUpdates.forEach((os) => {
        var majorVer = getWindowsVersion(version);
        if (os.os_major_version == majorVer) {
          latestVersion = os.build_number;
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  return latestVersion;
}

function compareVersion(version1, version2) {
  if (version1 == version2) {
    return 0;
  }
  // Compare version number strings in format 14.2.3 to determine which is newer can be in format 14.2 or 14.2.4
  var v1 = version1.split(".");
  var v2 = version2.split(".");
  var v1Length = v1.length;
  var v2Length = v2.length;
  var length = v1Length > v2Length ? v1Length : v2Length;
  for (var i = 0; i < length; i++) {
    var v1Num = Number(v1[i]) || 0;
    var v2Num = Number(v2[i]) || 0;
    if (v1Num > v2Num) {
      return 1;
    } else if (v1Num < v2Num) {
      return -1;
    }
  }
  return 0;
}

function isLatestVersion(version) {
  console.log("Version", version);
  let latestVersion = getLatestVersion(version);
  console.log("Latest Version", latestVersion);
  let comparison = compareVersion(version, latestVersion);
  console.log("Comparison", comparison);
  if (comparison == 0) {
    return " - Latest";
  }
  if (comparison == 1) {
    return " - Beta";
  }
  return;
}

function squashVersions(data) {
  var versions = [];
  data.forEach((item) => {
    var version;
    if (item.versionName == "Legacy" || item.versionName == "Unknown") {
      var versionTxt = "Requires Upgrade";
      if (item.versionName == "Unknown") {
        versionTxt = "Unknown Version";
      }
      version = [
        {
          count: 0,
          version: versionTxt,
        },
      ];
      item.versions.forEach((v) => {
        version[0].count += v.count;
      });
      versions.push({
        versionName: item.versionName,
        versions: version,
      });
      return;
    }

    version = [
      {
        count: 0,
        version: "Requires Update",
      },
      {
        count: 0,
        version: "Up-to-date",
      },
    ];
    if (item.versions.length) {
      let latestVersion = getLatestVersion(item.versions[0].version);
      item.versions.forEach((v) => {
        if (compareVersion(v.version, latestVersion) < 0) {
          version[0].count += v.count;
        } else {
          version[1].count += v.count;
        }
      });

      versions.push({
        versionName: item.versionName,
        versions: version,
      });
    }
  });
  console.log("Squashed Versions", versions);
  return versions;
}

function prepareVersionDataIOS(data) {
  var results = {};
  for (const key in data) {
    let majorVersion = key.split(".")[0] ? key.split(".")[0] : "Unknown";
    if (!results[majorVersion]) {
      results[majorVersion] = [];
    }
    results[majorVersion].push({ ...data[key], version: key });
  }
  // Sort the sub versions
  for (const key in results) {
    results[key] = versionSort(results[key]);
  }
  // Sort Major Versions and convert to array
  var newResults = [];
  for (const key in results) {
    newResults.push({ versions: results[key], versionName: key });
  }
  newResults = newResults.sort((a, b) => {
    if (
      a.versions[0].version.split(".")[0] > b.versions[0].version.split(".")[0]
    ) {
      return -1;
    }
    if (
      a.versions[0].version.split(".")[0] < b.versions[0].version.split(".")[0]
    ) {
      return 1;
    }
    return 0;
  });
  let squashed = squashVersions(newResults);
  return { results: newResults, squashed: squashed };
}

function prepareVersionData(data) {
  const versions = {
    10: "Legacy",
    11: "Legacy",
    12: "Monterey",
    13: "Ventura",
    14: "Sonoma",
  };
  var results = {};
  for (const key in data) {
    let majorVersion = key.split(".")[0];

    let versionName = versions[majorVersion]
      ? versions[majorVersion]
      : "Unknown";
    if (!results[versionName]) {
      results[versionName] = [];
    }
    results[versionName].push({ ...data[key], version: key });
    //console.log(majorVersion, versionName);
  }
  // Sort the sub versions
  for (const key in results) {
    results[key] = versionSort(results[key]);
  }
  // Sort Major Versions and convert to array
  var newResults = [];
  for (const key in results) {
    newResults.push({ versions: results[key], versionName: key });
  }
  newResults = newResults.sort((a, b) => {
    //console.log("A", a);
    if (
      a.versions[0].version.split(".")[0] > b.versions[0].version.split(".")[0]
    ) {
      return -1;
    }
    if (
      a.versions[0].version.split(".")[0] < b.versions[0].version.split(".")[0]
    ) {
      return 1;
    }
    return 0;
  });
  console.log("New Results", newResults);

  let squashed = squashVersions(newResults);

  return { results: newResults, squashed: squashed };
}

function getWindowsVersion(buildNumber) {
  if (buildNumber >= 22000) {
    return "Windows 11";
  } else if (buildNumber >= 10000 && buildNumber <= 20000) {
    return "Windows 10";
  } else if (buildNumber >= 2600 && buildNumber <= 9999) {
    return "Legacy";
  } else {
    return "Unknown";
  }
}

function prepareVersionDataWindows(importedData) {
  var results = {};

  /* This section removed the first two levels of the object key.
  i.e 10.0.1234.123 to 1234.123. 10.0 is the same Major version for both Windows 10 and 11. */
  let data = Object.entries(importedData).reduce((acc, [key, value]) => {
    const newKey = key.split(".").slice(2).join(".");
    acc[newKey] = value;
    return acc;
  }, {});

  for (const key in data) {
    let versionName = getWindowsVersion(key);

    if (!results[versionName]) {
      results[versionName] = [];
    }

    results[versionName].push({ ...data[key], version: key });
  }
  // Sort the sub versions
  for (const key in results) {
    results[key] = versionSort(results[key]);
  }
  // Sort Major Versions and convert to array
  var newResults = [];
  for (const key in results) {
    newResults.push({ versions: results[key], versionName: key });
  }
  newResults = newResults.sort((a, b) => {
    if (
      a.versions[0].version.split(".")[0] > b.versions[0].version.split(".")[0]
    ) {
      return -1;
    }
    if (
      a.versions[0].version.split(".")[0] < b.versions[0].version.split(".")[0]
    ) {
      return 1;
    }
    return 0;
  });
  console.log("New Results", newResults);

  let squashed = squashVersions(newResults);

  return { results: newResults, squashed: squashed };
}

function createChartData(data) {
  var chartData = [];
  var maxCount = 0;
  data.forEach((majorVersion) => {
    var count = 0;
    var chart = JSON.parse(JSON.stringify(templateChart));
    chart.options.series = [];

    // This provides version labels, now removed, left if we need to re-implement it later.

    // chart.options.dataLabels.formatter = function (val, opt) {
    //   if (opt.config.xaxis.max / val > 24) {
    //     return [" ", val];
    //   }
    //   return [opt.config.series[opt.seriesIndex].name, val];
    // };

    chart.options.dataLabels.formatter = function (val, opt) {
      return;
    };

    chart.options.plotOptions.bar.dataLabels.total.formatter = function (val) {
      return ["Total", val];
    };

    chart.options.xaxis.categories = [majorVersion.versionName];
    chart.name = majorVersion.versionName;
    majorVersion.versions.forEach((subVersion) => {
      //console.log("subVersion", subVersion);
      chart.options.series.push({
        name: subVersion.version,
        data: [subVersion.count],
        records: subVersion.records,
      });
      count += subVersion.count;
    });
    if (count > maxCount) {
      maxCount = count;
    }
    //console.log("New chart to add", chart);
    chartData.push(chart);
  });

  // Update Max Value
  chartData.forEach((chart, index) => {
    chartData[index].options.xaxis.max = maxCount + 20;
  });

  return chartData;
}

function createSubChartData(data) {
  var chartData = [];
  var maxCount = 0;
  data.forEach((subVersion) => {
    var count = subVersion.count;
    var chart = JSON.parse(JSON.stringify(templateChart));
    chart.options.series = [];

    // This provides version labels, now removed, left if we need to re-implement it later.

    // chart.options.dataLabels.formatter = function (val, opt) {
    //   if (opt.config.xaxis.max / val > 24) {
    //     return [" ", val];
    //   }
    //   return [opt.config.series[opt.seriesIndex].name, val];
    // };

    chart.options.dataLabels.formatter = function (val, opt) {
      return;
    };

    chart.options.plotOptions.bar.dataLabels.total.formatter = function (val) {
      return [val];
    };

    chart.options.xaxis.categories = [subVersion.version];
    chart.name = subVersion.version;
    chart.options.colors = ["#eeeeee"];
    chart.options.series.push({
      name: subVersion.version,
      data: [subVersion.count],
      records: subVersion.records,
    });
    chart.options.plotOptions.bar.dataLabels.total.offsetY = 0;
    if (count > maxCount) {
      maxCount = count;
    }
    //console.log("New chart to add", chart);
    chartData.push(chart);
  });

  // Update Max Value
  chartData.forEach((chart, index) => {
    chartData[index].options.xaxis.max = maxCount + 20;
  });

  return chartData;
}

const templateChart = {
  options: {
    // theme: {
    //   monochrome: {
    //     enabled: true,
    //     color: "#e7541e",
    //     shadeTo: "light",
    //     shadeIntensity: 0.55,
    //   },
    // },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
    colors: [],
    chart: {
      type: "bar",
      height: 150,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 5,
            offsetY: -10,
            style: {
              fontSize: "15px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["black"],
    },
    dataLabels: {
      formatter: function (val, opt) {
        //console.log("Val - " + val, "Opt -", opt);
        return ["13.4.1", val];
      },
      // Offset used when we were showing the version on the data label.
      //offsetY: -10,
    },
    grid: {
      padding: {
        top: -35,
        bottom: -20,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      min: 0,
      max: 100,
      categories: ["Monterey"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      position: "none",
      labels: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        formatter: function (val, opt) {
          return val + "";
        },
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
  },
};

function closeSubChart() {
  if (props.macOSVersions) {
    showchart.value = true;
  }
  if (props.iOSVersions) {
    showchartIOS.value = true;
  }
  if (props.windowsVersions) {
    showchartWindows.value = true;
  }
  showSubChart.value = false;
}
</script>

<template>
  <template v-if="showchart">
    <div v-for="chart in chartData" :key="chart">
      <v-sheet class="d-flex" height="60">
        <v-sheet
          align-self-center
          width="130"
          class="pointer font-weight-bold"
          @click="clickMajorVersion(chart)"
          >{{ chart.name }}</v-sheet
        >

        <v-sheet width="100%">
          <apexchart
            class="pa-0 ma-n5"
            :height="50"
            :options="chart.options"
            :series="chart.options.series"
            @dataPointSelection="clickMajorVersionBar(chart)"
          ></apexchart>
        </v-sheet>
      </v-sheet>
    </div>
    <div class="text-right font-italic">
      Hover over chart to view values. Click to see version breakdown.
    </div>
  </template>

  <template v-if="showSubChart">
    <div v-for="chart in subChartData" :key="chart">
      <v-sheet class="d-flex" height="60">
        <v-sheet align-self-center width="130" class="font-weight-bold pt-3"
          >{{ chart.name }}{{ isLatestVersion(chart.name) }}
          <p></p
        ></v-sheet>

        <v-sheet width="100%">
          <apexchart
            class="pa-0 xxma-n5"
            :height="50"
            :options="chart.options"
            :series="chart.options.series"
            @dataPointSelection="clickHandler"
          ></apexchart>
        </v-sheet>
      </v-sheet>
    </div>
    <v-row>
      <v-col cols="auto">
        <v-btn prepend-icon="mdi-arrow-left" @click="closeSubChart()"
          >Back</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <div class="text-right font-italic">Click to see devices.</div>
      </v-col>
    </v-row>
  </template>

  <template v-if="showchartWindows">
    <div v-for="chart in chartDataWindows" :key="chart">
      <v-sheet class="d-flex" height="60">
        <v-sheet
          align-self-center
          width="130"
          class="pointer font-weight-bold"
          @click="clickMajorVersion(chart)"
          >{{ chart.name }}</v-sheet
        >

        <v-sheet width="100%">
          <apexchart
            class="pa-0 ma-n5"
            :height="50"
            :options="chart.options"
            :series="chart.options.series"
            @dataPointSelection="clickMajorVersionBar(chart)"
          ></apexchart>
        </v-sheet>
      </v-sheet>
    </div>
    <div class="text-right font-italic">
      Hover over chart to view values. Click to see version breakdown.
    </div>
  </template>

  <template v-if="showchartIOS">
    <div v-for="chart in chartDataIOS" :key="chart">
      <v-sheet class="d-flex" height="60">
        <v-sheet
          align-self-center
          width="130"
          class="pointer font-weight-bold"
          @click="clickMajorVersion(chart)"
          >{{ chart.name }}</v-sheet
        >

        <v-sheet width="100%">
          <apexchart
            class="pa-0 ma-n5"
            :height="50"
            :options="chart.options"
            :series="chart.options.series"
            @dataPointSelection="clickMajorVersionBar(chart)"
          ></apexchart>
        </v-sheet>
      </v-sheet>
    </div>
    <div class="text-right font-italic">
      Hover over chart to view values. Click to see devices.
    </div>
  </template>
</template>
