<script setup>
import { apiQuery } from "@/api/j24api";
import { ref, reactive, defineProps, defineEmits, onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";

const appBar = appBarStore();
const selectedTab = ref("Information");
const loading = ref(false);
const dataCached = ref(false);
const cacheDate = ref("");

const props = defineProps({
  data: Object,
});

const data = ref({});
const emit = defineEmits(["back"]);

function formatDate(date) {
  if (date == undefined) {
    return undefined;
  }
  let text = "";
  text = new Date(date).toLocaleString("en-GB");
  if (text == "Invalid Date") {
    return "";
  }
  return text;
}

const commands = [
  {
    title: "Erase Device",
    value: "erase",
    icon: "mdi-eraser",
  },
  {
    title: "Lock Computer",
    value: "lock",
    icon: "mdi-lock",
  },
  {
    title: "Send Blank Push",
    value: "blankpush",
    icon: "mdi-radiobox-blank",
  },
];

const generalFields = reactive([]);
function populateGeneral() {
  generalFields.push(
    ...[
      {
        label: "ID",
        value: data.value.id,
      },
      {
        label: "Last Contact Time",
        value: formatDate(data.value.lastInventoryUpdateTimestamp),
      },
      {
        label: "Last Enrolment",
        value: formatDate(data.value.lastEnrollmentTimestamp),
      },
      {
        label: "Enrollment Method",
        value: data.value.enrollmentMethod,
      },
      {
        label: "Model Identifier",
        value: data.value.ios?.modelIdentifier,
      },
      {
        label: "Model Name",
        value: data.value.ios?.model,
      },
      {
        label: "Model Number",
        value: data.value.ios?.modelNumber,
      },
      {
        label: "UDID",
        value: data.value.udid,
      },
      {
        label: "MAC Address",
        value: data.value.wifiMacAddress,
      },
    ]
  );
}

onMounted(async () => {
  await getDetail();
});

async function getDetail() {
  loading.value = true;
  try {
    const result = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "devicesDetail",
      deviceIds: [props.data.id],
      maxage: 86400,
    });
    console.log(result);
    if (result.length) {
      data.value = result[0].result;
      dataCached.value = result[0].cached;
      cacheDate.value = result[0].date || "";
      populateGeneral();
    }
  } catch (error) {
    console.log(error);
  }
}

const appsHeaders = [
  {
    text: "App Name",
    value: "name",
    sortable: true,
  },
  {
    text: "Version (short)",
    value: "shortVersion",
    sortable: true,
  },
  {
    text: "Identifier",
    value: "identifier",
    sortable: true,
  },
  {
    text: "Version",
    value: "version",
    sortable: true,
  },
];
</script>

<template>
  <h2>
    <v-icon @click="$emit('back')" class="px-4">mdi-arrow-left</v-icon
    >{{ props.data.name }}
  </h2>
  <template v-if="data">
    <v-tabs v-model="selectedTab" color="#e7541e">
      <v-tab value="Information">Information</v-tab>
      <v-tab value="Apps">Apps</v-tab>
      <v-tab value="Commands">Commands</v-tab>
    </v-tabs>

    <v-window v-model="selectedTab">
      <v-window-item value="Information">
        <v-row>
          <v-card>
            <v-col class="d-flex flex-row" cols="12">
              <div class="font-weight-bold pa-2">
                <p>Serial Number:</p>
                <p>Model:</p>
                <p>iOS Version:</p>
              </div>
              <div class="pa-2">
                <p>{{ data.serialNumber }}</p>
                <p>{{ data.ios?.model }}</p>
                <p>{{ data.osVersion }}</p>
              </div>
              <div class="font-weight-bold pa-2">
                <p>Assigned User:</p>
                <p>Asset Tag:</p>
                <p>Last Contact:</p>
              </div>
              <div class="pa-2">
                <p>{{ data.location?.username || "&nbsp;" }}</p>
                <p>{{ data.assetTag || "&nbsp;" }}</p>
                <p>{{ formatDate(data.lastInventoryUpdateTimestamp) }}</p>
              </div>
            </v-col>
          </v-card>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="ma-1">
              <h2 class="pl-4">General</h2>
              <v-table>
                <tbody>
                  <tr v-for="field in generalFields" :key="field.id">
                    <th width="350" class="font-weight-bold">
                      {{ field.label }}
                    </th>
                    <td>{{ field.value }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>

      <v-window-item value="Apps">
        <EasyDataTable
          v-if="data.ios.applications"
          :items="data.ios.applications"
          :headers="appsHeaders"
          theme-color="#e7541e"
        />
      </v-window-item>
    </v-window>
  </template>
</template>
