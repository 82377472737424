<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits, watch } from "vue";

const props = defineProps({
  data: Object,
  mandatoryHeaders: Array,
  loading: Boolean,
  hideBackButton: Boolean,
});

const emit = defineEmits(["clickRow", "clickBack"]);
const columnsMenu = ref(false);
import { useDisplay } from "vuetify";
const { mobile } = useDisplay();

function clickRow(item) {
  emit("clickRow", item);
}

const activeHeaders = reactive([]);
const defaultHeaders = [
  { text: "Device Name", value: "name", sortable: true, show: true },
  {
    text: "Last Contact Time",
    value: "Last_Check_in",
    sortable: true,
    show: true,
  },
];
const allHeaders = reactive([
  { text: "Device Name", value: "name", sortable: true, show: false },
  {
    text: "Operating System",
    value: "Operating_System_Name",
    sortable: true,
    show: false,
  },
  { text: "Total Memory", value: "RAM", sortable: true, show: false },
  { text: "Model", value: "Model", sortable: true, show: false },
  {
    text: "Last Check In",
    value: "Last_Check_in",
    sortable: true,
    show: false,
  },
  {
    text: "Last Enrollment",
    value: "Last_Enrollment",
    sortable: true,
    show: false,
  },
  {
    text: "Last Inventory Update",
    value: "Last_Inventory_Update",
    sortable: true,
    show: false,
  },
  {
    text: "Operating System Version",
    value: "Operating_System_Version",
    sortable: true,
    show: false,
  },
  {
    text: "Serial Number",
    value: "Serial_Number",
    sortable: true,
    show: false,
  },
  { text: "Username", value: "Username", sortable: true, show: false },
  { text: "Full Name", value: "Full_Name", sortable: true, show: false },
  {
    text: "Email Address",
    value: "Email_Address",
    sortable: true,
    show: false,
  },
  { text: "Department", value: "Department", sortable: true, show: false },
  //   { text: "SerialNumber", value: "SerialNumber", sortable: true, show: false },
  //   { text: "SerialNumber", value: "SerialNumber", sortable: true, show: false },
]);

var doNotUpdateHeaders = false;
watch(allHeaders, (currentValue) => {
  if (doNotUpdateHeaders) {
    return;
  }
  console.log("Column Headers Updated");
  currentValue.forEach((col) => {
    if (col.show) {
      if (!activeHeaders.find((h) => h.value == col.value)) {
        activeHeaders.push(col);
      }
    } else {
      activeHeaders.forEach((current, index) => {
        if (current.value == col.value) {
          activeHeaders.splice(index, 1);
        }
      });
    }
  });
  localStorage.setItem("cmd24DrillDownCols", JSON.stringify(activeHeaders));
});

function syncHeaders() {
  allHeaders.forEach((header, index) => {
    console.log("header", header);
    const match = activeHeaders.find((a) => a.value == header.value);
    if (match) {
      console.log("Match", match);
      allHeaders[index].show = match.show;
    }
  });
}

onMounted(() => {
  doNotUpdateHeaders = true;
  try {
    var setHeaders = localStorage.getItem("cmd24DrillDownCols");
    setHeaders = JSON.parse(setHeaders);
    if (setHeaders.length) {
      activeHeaders.length = 0;
      activeHeaders.push(...setHeaders);
    }
  } catch (error) {
    console.log(error);
  }
  if (activeHeaders.length == 0) {
    activeHeaders.push(...defaultHeaders);
  }
  syncHeaders();
  doNotUpdateHeaders = false;
});

const searchValue = ref("");

function searchFields() {
  var fields = [];
  activeHeaders.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

function resetCols() {
  activeHeaders.length = 0;
  activeHeaders.push(...defaultHeaders);
  allHeaders.forEach((header) => {
    if (defaultHeaders.find((a) => a.value == header.value)) {
      header.show = true;
    } else {
      header.show = false;
    }
  });
  syncHeaders();
  localStorage.setItem("cmd24DrillDownCols", JSON.stringify(activeHeaders));
}

function returnActiveHeaders() {
  var headers = [];
  headers.push(...activeHeaders);
  if (props.mandatoryHeaders?.length) {
    headers.push(...props.mandatoryHeaders);
  }
  console.log("Headers", headers);
  return headers;
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex flex-row justify-space-between">
        <v-sheet v-if="!props.hideBackButton">
          <v-btn
            prepend-icon="mdi-arrow-left"
            @click="emit('clickBack')"
            class="mr-3"
          >
            Back
          </v-btn>
        </v-sheet>
        <v-sheet :min-width="mobile ? 200 : 400" class="me-auto">
          <v-text-field
            class="pl-1"
            density="compact"
            label="Search"
            v-model="searchValue"
            variant="underlined"
            min-width="300"
          ></v-text-field>
        </v-sheet>

        <v-sheet class="justify-space-between">
          <download-csv :data="props.data" name="report.csv">
            <v-btn
              v-if="mobile"
              class="mr-0"
              icon="mdi-download"
              variant="text"
            ></v-btn>
            <v-btn v-else prepend-icon="mdi-download"> Download CSV </v-btn>
          </download-csv>
        </v-sheet>
        <v-sheet v-if="mobile">
          <v-btn class="ml-0" icon="mdi-download" variant="text">
            <v-icon>mdi-format-columns</v-icon>
            <v-menu
              activator="parent"
              v-model="columnsMenu"
              :close-on-content-click="false"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn
                      prepend-icon="mdi-close"
                      variant="text"
                      @click="columnsMenu = !columnsMenu"
                      >Close</v-btn
                    >
                    <v-btn
                      prepend-icon="mdi-refresh"
                      variant="text"
                      @click="resetCols()"
                      >Reset</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-for="(col, index) in allHeaders"
                  :key="col.value"
                >
                  <v-list-item-action>
                    <v-checkbox-btn
                      v-model="allHeaders[index].show"
                      :label="col.text"
                    ></v-checkbox-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
        </v-sheet>
        <v-sheet v-else>
          <v-btn class="ml-2" prepend-icon="mdi-format-columns">
            {{ mobile ? "" : columnsMenu ? "Close" : "Columns" }}
            <v-menu
              activator="parent"
              v-model="columnsMenu"
              :close-on-content-click="false"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn
                      prepend-icon="mdi-close"
                      variant="text"
                      @click="columnsMenu = !columnsMenu"
                      >Close</v-btn
                    >
                    <v-btn
                      prepend-icon="mdi-refresh"
                      variant="text"
                      @click="resetCols()"
                      >Reset</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-for="(col, index) in allHeaders"
                  :key="col.value"
                >
                  <v-list-item-action>
                    <v-checkbox-btn
                      v-model="allHeaders[index].show"
                      :label="col.text"
                    ></v-checkbox-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
        </v-sheet>
      </div>
    </v-col>
  </v-row>
  <EasyDataTable
    table-class-name="customize-table"
    :search-field="searchFields()"
    :search-value="searchValue"
    :headers="returnActiveHeaders()"
    :items="props.data"
    :loading="props.loading"
    theme-color="#e7541e"
    alternating
    @click-row="clickRow"
    :table-height="500"
  ></EasyDataTable>
</template>

<style scoped>
.customize-table {
  --easy-table-header-font-color: #000000;
  --easy-table-body-row-font-color: #000000;
  --easy-table-body-even-row-font-color: #000000;
}
</style>
