const commands = [
  {
    title: "Erase Device",
    value: "eraseDevice",
    icon: "mdi-eraser",
    permission: "SendEraseComputer",
  },
  {
    title: "Lock Device",
    value: "lockDevice",
    icon: "mdi-lock",
    permission: "SendDeviceLock",
  },
  {
    title: "Get FileVault Key",
    value: "GetFileVaultKey",
    icon: "mdi-key-variant",
    permission: "GetFileVaultKey",
  },
];

const mobileCommands = [
  {
    title: "Erase Device",
    value: "eraseDevice",
    icon: "mdi-eraser",
    permission: "SendEraseDevice",
  },
  {
    title: "Enable Lost Mode",
    value: "lostMode",
    icon: "mdi-radar",
    permission: "SendEnableLostModeDevice",
  },
  {
    titile: "Disable Lost Mode",
    value: "disableLostMode",
    icon: "mdi-radar-off",
    permission: "SendEnableLostModeDevice",
  },
];

function setupDialogMobile(value, dialog) {
  switch (value) {
    case "disableLostMode":
      dialog.Title = "Disable Lost Mode";
      dialog.Text = "Request the device to disable lost mode.";
      dialog.Action = "disableLostMode";
      break;
    case "lostMode":
      dialog.Title = "Enable Lost Mode";
      dialog.v2 = {
        message: {
          type: "textfield",
          label: "Message",
          preText:
            "Specify a message to be displayed on the device. (The message or phone field must be set)",
          value: "",
        },
        phone: {
          type: "textfield",
          label: "Phone Number",
          preText:
            "Phone number to be displayed on the device whilst in lost mode.",
          value: "",
        },
        footnote: {
          type: "textfield",
          label: "Footnote",
          preText: "Footnote displayed on the device whilst in lost mode.",
          value: "",
        },
        sound: {
          type: "checkbox",
          label: "Play Lost Sound",
          value: false,
        },
      };
      dialog.Action = "lostMode";
      break;
  }
}

function setupDialog(value, dialog) {
  switch (value) {
    case "GetFileVaultKey":
      dialog.Title = "Get FileVault Key";
      dialog.Text = "Requests the FileVault key for the device.";
      dialog.Action = "GetFileVaultKey";
      break;
    case "eraseDevice":
      dialog.Title = "Erase Device";
      dialog.v2 = {
        passcode: {
          type: "textfield",
          label: "6 Digit Passcode",
          preText:
            "Please specify a passcode to use to wipe the device. This must be a 6-digit passcode",
          value: "",
        },
        confirm: {
          type: "textfield",
          label: "ERASE",
          preText:
            "WARNING - this action will erase the device which will result in data loss. Please type ERASE in the textbox below to confirm",
          value: "",
        },
      };
      dialog.Action = "EraseDevice";
      break;
    case "lockDevice":
      (dialog.Title = "Lock Device"),
        (dialog.v2 = {
          confirm: {
            type: "textfield",
            label: "LOCK",
            preText: "Please type LOCK to confirm you wish to lock the device.",
            value: "",
          },
        });
      dialog.Action = "LockDevice";
      break;
    default:
      console.log("No dialog setup for " + value);
  }
  return dialog;
}

function dialogButtonPressedMobile(dialog, mdmServerId, id) {
  try {
    switch (dialog.Action) {
      case "lostMode":
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          mobileDeviceId: id,
          lostModeMessage: dialog.v2.message.value,
          lostModePhone: dialog.v2.phone.value,
          lostModeFootnote: dialog.v2.footnote.value,
          lostModeWithSound: dialog.v2.sound.value,
        };
        return dialog;
      case "disableLostMode":
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          mobileDeviceId: id,
        };
        return dialog;
    }
  } catch (error) {
    console.log(error);
  }
}

function dialogButtonPressed(dialog, mdmServerId, id) {
  try {
    switch (dialog.Action) {
      case "GetFileVaultKey": {
        console.log("Get FileVault Key");
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Getting Key...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "GetFileVaultKey",
          deviceId: id,
        };
        return dialog;
      }
      case "EraseDevice": {
        console.log("Erase Device");
        console.log("Dialog Object", dialog);
        console.log(dialog.TxtFieldValue);
        const regexTest = new RegExp("\\d{6}");
        if (!regexTest.test(dialog.v2.passcode.value)) {
          dialog.StatusTxt = "PIN must be a 6 digits";
          return dialog;
        }
        if (dialog.v2.confirm.value != "ERASE") {
          dialog.StatusTxt = "Please confirm by typing ERASE";
          return dialog;
        }

        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "erase",
          deviceId: id,
          passcode: dialog.v2.passcode.value,
        };
        return dialog;
      }
      case "LockDevice": {
        console.log("Lock Device");
        if (dialog.v2.confirm.value != "LOCK") {
          dialog.StatusTxt = "Please confirm by typing LOCK";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "lock",
          deviceId: id,
        };
        return dialog;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

module.exports = {
  commands,
  mobileCommands,
  setupDialog,
  setupDialogMobile,
  dialogButtonPressed,
  dialogButtonPressedMobile,
};
