import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useRouteGuard } from "@/auth/useRouterGuard";

import UserProfile from "../views/UserProfile.vue";
import cat24SideBar from "../views/cat24/cat24SideBar.vue";
import AssetsBulkUploader from "../views/cat24/Assets/AssetsBulkUploader.vue";
import ContactsBulkUploader from "../views/cat24/Contacts/ContactsBulkUploader.vue";
import BookingSlots from "../views/cat24/BookingSlots/BookingSlots.vue";
import OrdersBulkUploader from "../views/cat24/Orders/OrdersBulkUploader.vue";
import Cat24OrdersV2 from "../views/cat24/Orders/OrdersV2.vue";
import Cat24Dashboard from "../views/cat24/Dashboard/Cat24Dashboard.vue";
import Cat24Addresses from "../views/cat24/Addresses/Cat24Addresses.vue";
import PortalCustomisations from "../views/cat24/Customisations/PortalCustomisations.vue";

import SignOut from "../views/SignOut.vue";
import SignIn from "../views/SignIn.vue";
import EnableMFA from "../views/EnableMFA.vue";
import adminSideBar from "../views/admin/adminSideBar.vue";
import adminPermissions from "../views/admin/adminPermissions.vue";
import adminRoles from "../views/admin/adminRoles.vue";
import adminCustomers from "../views/admin/adminCustomers.vue";
import adminGroups from "../views/admin/adminGroups.vue";
import adminUsers from "../views/admin/adminUsers.vue";
import loginTest from "../views/LoginTest.vue";
import ElaAdminSidebar from "../views/elaadmin/ElaAdminSidebar.vue";
import ElaAdminMdmServers from "../views/elaadmin/MdmServers.vue";
import ElaAdminUsers from "../views/elaadmin/UsersAdmin.vue";
import ElaAdminElevateLicences from "../views/elaadmin/ElevateLicences.vue";
import ElaAdminAgentLicences from "../views/elaadmin/AgentLicences.vue";
import ElaAdminIntegrationsManagement from "../views/elaadmin/IntegrationsManagment.vue";

import ElaSidebar from "../views/ela/ElaSidebar.vue";
import ElaComputers from "../views/ela/ElaComputers.vue";
import ElaDevices from "../views/ela/ElaDevices.vue";
import ElaDashboard from "../views/ela/ElaDashboard.vue";
import ElaDashboardOverview from "../views/ela/ElaDashboardOverview.vue";

import DashboardSidebar from "../views/dashboard/DashboardSidebar.vue";
import DashboardOverview from "../views/dashboard/DashboardOverview.vue";
import DashboardSettings from "../views/dashboard/DashboardSettings.vue";
import DashboardAlerts from "../views/dashboard/DashboardAlerts.vue";
import DashboardLogs from "../views/dashboard/DashboardLogs.vue";
import DashboardPolicyUpdates from "../views/dashboard/DashboardPolicyUpdates.vue";
import DashboardPatchingReport from "../views/dashboard/DashboardPatchingReport.vue";
import DashboardAccounts from "../views/dashboard/DashboardAccounts.vue";
import DashboardJamfSearch from "../views/dashboard/DashboardJamfSearch.vue";

import FeatureRequest from "../views/feature/FeatureRequest.vue";
import FeatureRequestSidebar from "../views/feature/FeatureRequestSidebar.vue";

import ElevateSidebar from "../views/elevate/ElevateSidebar.vue";
import ElevateReport from "../views/elevate/ElevateReport.vue";
import ElevateLicense from "../views/elevate/ElevateLicence.vue";

import PatchngPipleline from "../views/patching/PatchngPipleline.vue";
import PatchingSidebar from "../views/patching/PatchingSidebar.vue";
import PatchingUsageReport from "../views/patching/patchingUsageReport.vue";
import PatchingApprovals from "../views/patching/patchingApprovals.vue";
import PatchingPatches from "../views/patching/patchingPatches.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/logintest",
    name: "loginTest",
    component: loginTest,
  },
  {
    path: "/signin",
    name: "signIn",
    component: SignIn,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/signout",
    name: "SignOut",
    component: SignOut,
  },
  {
    path: "/userProfile",
    name: "UserProfile",
    component: UserProfile,
    // beforeEnter: useRouteGuard,
  },
  {
    path: "/enablemfa",
    name: "EnableMFA",
    component: EnableMFA,
    beforeEnter: useRouteGuard,
  },
  {
    path: "/cat24",
    component: cat24SideBar,
    children: [
      {
        path: "assetsUpload",
        name: "AssetsBulkUploader",
        component: AssetsBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "contactsUpload",
        name: "ContactsBulkuploader",
        component: ContactsBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "contactsUpload",
        name: "ContactsBulkuploader",
        component: ContactsBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "BookingSlots",
        name: "BookingSlots",
        component: BookingSlots,
        beforeEnter: useRouteGuard,
      },
      {
        path: "OrdersBulkUploader",
        name: "OrdersBulkUploader",
        component: OrdersBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "OrdersV2",
        name: "Cat24OrdersV2",
        component: Cat24OrdersV2,
        beforeEnter: useRouteGuard,
      },
      {
        path: "dashboard",
        name: "Cat24Dashboard",
        component: Cat24Dashboard,
        beforeEnter: useRouteGuard,
      },
      {
        path: "addresses",
        name: "Cat24Addresses",
        component: Cat24Addresses,
        beforeEnter: useRouteGuard,
      },
      {
        path: "customisations",
        name: "PortalCustomisations",
        component: PortalCustomisations,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/admin",
    component: adminSideBar,
    children: [
      {
        path: "Permissions",
        name: "AdminPermissions",
        component: adminPermissions,
        beforeEnter: useRouteGuard,
      },
      {
        path: "Roles",
        name: "AdminRoles",
        component: adminRoles,
        beforeEnter: useRouteGuard,
      },
      {
        path: "Customers",
        name: "AdminCustomers",
        component: adminCustomers,
        beforeEnter: useRouteGuard,
      },
      {
        path: "Groups",
        name: "AdminGroups",
        component: adminGroups,
        beforeEnter: useRouteGuard,
      },
      {
        path: "Users",
        name: "AdminUsers",
        component: adminUsers,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/elaadmin",
    component: ElaAdminSidebar,
    children: [
      {
        path: "mdmservers",
        name: "ElaAdminMdmServers",
        component: ElaAdminMdmServers,
        beforeEnter: useRouteGuard,
      },
      {
        path: "users",
        name: "ElaAdminUsers",
        component: ElaAdminUsers,
        beforeEnter: useRouteGuard,
      },
      {
        path: "elevatelicences",
        name: "ElaAdminElevateLicences",
        component: ElaAdminElevateLicences,
        beforeEnter: useRouteGuard,
      },
      {
        path: "agentlicences",
        name: "ElaAdminAgentLicences",
        component: ElaAdminAgentLicences,
        beforeEnter: useRouteGuard,
      },
      {
        path: "integrationsmanagement",
        name: "ElaAdminIntegrationsManagement",
        component: ElaAdminIntegrationsManagement,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/ela",
    component: ElaSidebar,
    children: [
      {
        path: "dashboard",
        name: "ElaDashboard",
        component: ElaDashboard,
        beforeEnter: useRouteGuard,
      },
      {
        path: "computers",
        name: "ElaComputers",
        component: ElaComputers,
        beforeEnter: useRouteGuard,
      },
      {
        path: "devices",
        name: "ElaDevices",
        component: ElaDevices,
        beforeEnter: useRouteGuard,
      },
      {
        path: "dashboardoverview",
        name: "ElaDashboardOverview",
        component: ElaDashboardOverview,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardSidebar,
    children: [
      {
        path: "overview",
        name: "DashboardOverview",
        component: DashboardOverview,
        beforeEnter: useRouteGuard,
      },
      {
        path: "alerts",
        name: "DashboardAlerts",
        component: DashboardAlerts,
        beforeEnter: useRouteGuard,
      },
      {
        path: "settings",
        name: "DashboardSettings",
        component: DashboardSettings,
        beforeEnter: useRouteGuard,
      },
      {
        path: "logs",
        name: "DashboardLogs",
        component: DashboardLogs,
        beforeEnter: useRouteGuard,
      },
      {
        path: "policy",
        name: "DashboardPolicyUpdates",
        component: DashboardPolicyUpdates,
        beforeEnter: useRouteGuard,
      },
      {
        path: "patchingReport",
        name: "DashboardPatchingReport",
        component: DashboardPatchingReport,
        beforeEnter: useRouteGuard,
      },
      {
        path: "accounts",
        name: "DashboardAccounts",
        component: DashboardAccounts,
        beforeEnter: useRouteGuard,
      },
      {
        path: "search",
        name: "DashboardJamfSearch",
        component: DashboardJamfSearch,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/feature",
    component: FeatureRequestSidebar,
    children: [
      {
        path: "featurerequest",
        name: "FeatureRequest",
        component: FeatureRequest,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/elevate",
    component: ElevateSidebar,
    children: [
      {
        path: "report",
        name: "ElevateReport",
        component: ElevateReport,
        beforeEnter: useRouteGuard,
      },
      {
        path: "licence",
        name: "ElevateLicence",
        component: ElevateLicense,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/patching",
    component: PatchingSidebar,
    children: [
      {
        path: "PatchngPipleline",
        name: "PatchngPipleline",
        component: PatchngPipleline,
        beforeEnter: useRouteGuard,
      },
      {
        path: "PatchingUsageReport",
        name: "PatchingUsageReport",
        component: PatchingUsageReport,
        beforeEnter: useRouteGuard,
      },
      {
        path: "PatchingApprovals",
        name: "PatchingApprovals",
        component: PatchingApprovals,
        beforeEnter: useRouteGuard,
      },
      {
        path: "PatchingPatches",
        name: "PatchingPatches",
        component: PatchingPatches,
        beforeEnter: useRouteGuard,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
