<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits } from "vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";
import { Auth } from "aws-amplify";
import PortalEditor from "./Editor.vue";

const appBar = appBarStore();
const loading = ref(false);
const selectedHTMLEdit = ref(false);
const indexOfSelectedCustomisation = ref({});
const indexSelectedPage = ref({});
const updatedData = ref(false);
const emits = defineEmits(["cancel"]);
const props = defineProps({
  selectedPage: Number,
  resultsPassed: Object,
});
let itemValues = reactive([]);
let itemValuesArray = reactive([]);
let itemEntries = reactive([]);
let itemEntriesArray = reactive([]);
let displayNameArray = reactive([]);
let selectedItemEntry = reactive([]);
let itemEntriesOriginal = reactive([]);
let cancelled = reactive([]);
let itemKeys = reactive([]);

async function getData(resultsPassed) {
  if (cancelled == true) {
    try {
      itemKeys.length = 0;
      loading.value = true;
      const results = await apiQuery("ELA", "cat24/customisations", {
        apiAction: "get",
        customer: appBar.selected,
      });
      if (results) {
        for (var rkey of Object.keys(results)) {
          if (rkey != "_id") {
            itemKeys.push(rkey);
          }
        }
        itemValues = Object.values(results);
        itemValues.forEach((value, index) => {
          if (index != 0) {
            itemValuesArray.push(value);
          }
        });
        loading.value = false;
        resultsPassed = results;
      }
    } catch (error) {
      console.log(error);
    }
  }
  itemValues.length = 0;
  itemValuesArray.length = 0;
  itemEntries.length = 0;
  itemEntriesArray.length = 0;
  indexSelectedPage.value = props.selectedPage;
  loading.value = true;
  if (updatedData.value == false) {
    resultsPassed = props.resultsPassed;
    updatedData.value == true;
  }
  itemValues = Object.values(resultsPassed);
  itemValues.forEach((value, index) => {
    if (index != 0) {
      itemValuesArray.push(value);
    }
  });
  for (var key of Object.keys(resultsPassed)) {
    itemKeys.push(key);
  }
  itemValuesArray.forEach((itemValue, index) => {
    if (index == indexSelectedPage.value) {
      itemEntries = Object.entries(itemValue);
      itemEntriesOriginal = Object.entries(itemValue);
    }
  });
  itemEntries.forEach((itemEntry, index) => {
    if (itemEntry[0] != "displayName" && itemEntry[0] != "imageName") {
      itemEntriesArray.push(itemEntry);
    }
  });
  itemEntriesArray.forEach((itemEntryArr, index) => {
    let displayNameValue = itemEntryArr[0];
    toCamelCase(displayNameValue);
  });
  if (displayNameArray) {
    displayNameSplice(displayNameArray);
  }
  loading.value = false;
}

function selectCustomisation(itemEntry, index) {
  indexOfSelectedCustomisation.value = index;
  selectedHTMLEdit.value = true;
  selectedItemEntry = itemEntry;
}

function toCamelCase(displayNameValue) {
  let displayNameCamel = displayNameValue.replace(/([A-Z]+)/g, " $1");
  const words = displayNameCamel.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  displayNameCamel = words.join(" ");
  displayNameArray.push(displayNameCamel);
}

function displayNameSplice() {
  itemEntriesArray.forEach((itemEntry, index) => {
    itemEntry[0] = displayNameArray[index];
  });
}

function mergeCustomisation(
  selectedItemEntry,
  itemValues,
  itemEntries,
  itemKeys
) {
  loading.value = true;
  let selectedItemEntryLow = selectedItemEntry[0].toLowerCase();
  selectedItemEntry.splice(0, 1, selectedItemEntryLow);
  itemEntriesOriginal.splice(
    indexOfSelectedCustomisation.value,
    1,
    selectedItemEntry
  );

  let itemEntriesObj = Object.fromEntries(itemEntriesOriginal);
  itemValues.splice(indexSelectedPage.value + 1, 1, itemEntriesObj);

  var merged = {};
  for (var i = 0; i < itemKeys.length; i++) merged[itemKeys[i]] = itemValues[i];
  selectedHTMLEdit.value = false;
  saveCustomisation(merged);
  return merged;
}

async function saveCustomisation(merged, resultsPassed) {
  try {
    itemKeys.length = 0;
    loading.value = true;
    const results = await apiQuery("ELA", "cat24/customisations", {
      apiAction: "update",
      customer: appBar.selected,
      payload: merged,
    });
  } catch (error) {
    console.log(error);
  }
  resultsPassed = merged;
  loading.value = false;
  itemEntriesArray.length = 0;
  updatedData.value = true;
  getData(resultsPassed);
}

onMounted(() => {
  if (appBar.selected) {
    loading.value = true;
    getData();
  }
});
</script>

<template v-html="data">
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
      indeterminate
      size="64"
      color="#e7541e"
    ></v-progress-circular>
  </v-overlay>

  <v-overlay
    persistent
    no-click-animation
    :model-value="selectedHTMLEdit"
    contained
    class="align-center justify-center"
    style="z-index: 1100"
  >
    <v-card style="padding: 3%">
      <PortalEditor
        id="editorcontent"
        v-if="selectedHTMLEdit"
        :initial-value="selectedItemEntry[1]"
        v-model="selectedItemEntry[1]"
        :init="{
          plugins: 'link lists preview',
          menubar: false,
          branding: false,
          toolbar_mode: 'wrap',
          toolbar:
            'blocks | bold italic underline | \
           alignleft aligncenter alignright |  bullist numlist outdent indent | link unlink |\
           undo redo preview ',
        }"
        api-key="no-api-key"
      />

      <v-row style="padding-top: 3%">
        <v-col>
          <v-btn
            type="button"
            @click="
              mergeCustomisation(
                selectedItemEntry,
                itemValues,
                itemEntries,
                itemKeys
              )
            "
          >
            Save Customisation
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn
            @click="
              selectedHTMLEdit = false;
              cancelled = true;
              getData();
            "
            class="align-right"
            style="margin: 1%"
            >Cancel</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
  <v-row class="px-3"
    ><v-col><v-icon @click="$emit('cancel')">mdi-arrow-left</v-icon></v-col
    ><v-col><h2 class="text-right">HMTL Editor</h2></v-col></v-row
  >
  <v-card
    @click="selectCustomisation(itemEntry, index)"
    v-for="(itemEntry, index) in itemEntriesArray"
    :key="itemEntry"
    style="margin: 1%"
  >
    <v-card-title>
      <u>{{ itemEntry[0] }}</u>
    </v-card-title>
    <v-card-text>
      <span v-html="itemEntry[1]"></span>
    </v-card-text>
  </v-card>
</template>
