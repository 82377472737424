<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  ref,
  watch,
  onMounted,
  reactive,
} from "vue";

const props = defineProps({
  items: Array,
  modelValue: String,
  itemTitle: String,
  itemValue: String,
  label: String,
  noDataText: String,
  activateSearch: Boolean,
  variant: { type: String, required: false, default: "filled" },
  density: { type: String, required: false, default: "default" },
  class: { type: String, required: false, default: "" },
  hideDetails: String,
});
const emit = defineEmits(["update:modelValue"]);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    return emit("update:modelValue", value);
  },
});

function updateValue(value) {
  emit("update:modelValue", value);
}
const searchField = ref("");

const filtered = computed(() => {
  if (!searchField.value) {
    return props.items;
  }
  let searchValue = searchField.value.toLowerCase();
  return props.items.filter((item) => {
    if (typeof item == "string") {
      let value = item.toLowerCase();
      return value.includes(searchValue);
    }
    if (props.itemTitle) {
      let value = item[props.itemTitle].toLowerCase();
      if (value.includes(searchValue)) {
        return true;
      }
    } else {
      let value = item.title.toLowerCase();
      if (value.includes(searchValue)) {
        return true;
      }
    }
  });
});

function noDataText() {
  if (props.items.length) {
    return "None found";
  }
  if (!props.noDataText) {
    return undefined;
  }
  return undefined;
}

function hideDetails() {
  if (!props.hideDetails) {
    return false;
  }
  return props.hideDetails;
}
</script>

<template>
  <v-select
    asclass="ma-10"
    :class="props.class"
    :variant="props.variant"
    :items="filtered"
    v-model="value"
    :item-title="props.itemTitle"
    :item-value="props.itemValue"
    :label="props.label"
    :no-data-text="noDataText()"
    :density="props.density"
    :hide-details="hideDetails()"
    placeholder="No MDM Selected"
  >
    <template v-slot:prepend-item v-if="props.activateSearch && items.length">
      <v-list-item>
        <v-text-field
          clearable
          class="pbn-7"
          variant="underlined"
          placeholder="Search"
          density="compact"
          v-model="searchField"
        ></v-text-field>
      </v-list-item>
    </template>
  </v-select>
</template>
