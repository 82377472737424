const windowsCommands = [
  {
    title: "Wipe Device",
    value: "eraseWindows",
    icon: "mdi-eraser",
    permission: "SendEraseDevice",
  },
  {
    title: "Sync Device",
    value: "sync",
    icon: "mdi-sync",
    permission: "SendUpdateInventory",
  },
  {
    title: "Restart Device",
    value: "restart",
    icon: "mdi-restart",
    permission: "SendRestartDevice",
  },
  {
    title: "Shutdown Device",
    value: "shutdown",
    icon: "mdi-power",
    permission: "SendShutdownDevice",
  },
  {
    title: "Rename Device",
    value: "rename",
    icon: "mdi-rename",
    permission: "SendRenameDevice",
  },
  {
    title: "Defender Quick Scan",
    value: "defenderQuickScan",
    icon: "mdi-shield-half-full",
    permission: "SendDefenderDevice",
  },
  {
    title: "Defender Full Scan",
    value: "defenderFullScan",
    icon: "mdi-shield",
    permission: "SendDefenderDevice",
  },
  {
    title: "Update Defender",
    value: "updateDefender",
    icon: "mdi-shield-sync-outline",
    permission: "SendDefenderDevice",
  },
  {
    title: "BitLocker Key Rotation",
    value: "keyRotationBL",
    icon: "mdi-key-variant",
    permission: "SendKeyRotationDevice",
  },
  {
    title: "Fresh Start",
    value: "freshStart",
    icon: "mdi-cog-refresh-outline",
    permission: "SendFreshStartDevice",
  },
  // {
  //   title: "Autopilot Reset",
  //   value: "autopilotReset",
  //   icon: "mdi-airplane-cog",
  //   permission: "SendAutopilotResetDevice",
  // },
  {
    title: "Retire Device",
    value: "retire",
    icon: "mdi-close-octagon-outline",
    permission: "SendRetireDevice",
  },
  {
    title: "Delete Device",
    value: "delete",
    icon: "mdi-delete",
    permission: "SendDeleteDevice",
  },
];

const macosCommands = [
  {
    title: "Erase Device",
    value: "eraseMacos",
    icon: "mdi-eraser",
    permission: "SendEraseDevice",
  },
  {
    title: "Sync Device",
    value: "sync",
    icon: "mdi-sync",
    permission: "SendUpdateInventory",
  },
  {
    title: "Restart Device",
    value: "restart",
    icon: "mdi-restart",
    permission: "SendRestartDevice",
  },
  {
    title: "Shutdown Device",
    value: "shutdown",
    icon: "mdi-power",
    permission: "SendShutdownDevice",
  },
  {
    title: "Rename Device",
    value: "rename",
    icon: "mdi-rename",
    permission: "SendRenameDevice",
  },
  {
    title: "Get FileVault Key",
    value: "getFileVaultKey",
    icon: "mdi-key-variant",
    permission: "GetFileVaultKey",
  },
  {
    title: "FileVault Key Rotation",
    value: "keyRotationFV",
    icon: "mdi-key-variant",
    permission: "SendKeyRotationDevice",
  },
  {
    title: "Remote Lock",
    value: "remoteLock-macOS",
    icon: "mdi-lock",
    permission: "SendDeviceLock",
  },
  {
    title: "Bypass Activation Lock",
    value: "bypassActivationLock",
    icon: "mdi-lock-open-variant-outline",
    permission: "SendDisableActivationLockDevice",
  },
  {
    title: "Retire Device",
    value: "retire",
    icon: "mdi-close-octagon-outline",
    permission: "SendRetireDevice",
  },
  {
    title: "Delete Device",
    value: "delete",
    icon: "mdi-delete",
    permission: "SendDeleteDevice",
  },
];

const iosCommands = [
  {
    title: "Sync Device",
    value: "sync",
    icon: "mdi-sync",
    permission: "SendUpdateInventory",
  },
  {
    title: "Restart Device",
    value: "restart",
    icon: "mdi-restart",
    permission: "SendRestartDevice",
  },
  {
    title: "Shutdown Device",
    value: "shutdown",
    icon: "mdi-power",
    permission: "SendShutdownDevice",
  },
  {
    title: "Remote Lock",
    value: "remoteLock-ios",
    icon: "mdi-lock",
    permission: "SendDeviceLock",
  },
  {
    title: "Rename Device",
    value: "rename",
    icon: "mdi-rename",
    permission: "SendRenameDevice",
  },
  {
    title: "Clear Passcode",
    value: "clearPasscode",
    icon: "mdi-lock-off-outline",
    permission: "SendClearPasscode",
  },
  {
    title: "Bypass Activation Lock",
    value: "BypassActivationLock",
    icon: "mdi-lock-open-variant-outline",
    permission: "SendDisableActivationLockDevice",
  },
  {
    title: "Enable Lost Mode",
    value: "enableLostMode",
    icon: "mdi-earth",
    permission: "SendEnableLostModeDevice",
  },
  {
    title: "Disable Lost Mode",
    value: "disableLostMode",
    icon: "mdi-earth-off",
    permission: "SendEnableLostModeDevice",
  },
  // {
  //   title: "Logout Current User",
  //   value: "logout",
  //   icon: "mdi-logout",
  //   permission: "SendLogoutDevice",
  // },
  {
    title: "Erase Device",
    value: "eraseMobile",
    icon: "mdi-eraser",
    permission: "SendEraseDevice",
  },
  {
    title: "Retire Device",
    value: "retire",
    icon: "mdi-trash-can-outline",
    permission: "SendRetireDevice",
  },
  {
    title: "Delete Device",
    value: "delete",
    icon: "mdi-delete",
    permission: "SendDeleteDevice",
  },
];

function setupDialog(value, dialog) {
  switch (value) {
    case "eraseMobile":
      dialog.v2 = {
        confirm: {
          type: "textfield",
          label: "ERASE",
          preText:
            "WARNING - This action will erase the device which will result in data loss. Please type ERASE in the field below to confirm.",
          value: "",
        },
      };
      dialog.Title = "Erase Device";
      dialog.Action = "eraseMobile";
      break;
    case "eraseMacos":
      dialog.v2 = {
        code: {
          type: "textfield",
          label: "Unlock Code",
          preText: "Please enter a six digit unlock code.",
          value: "",
        },
        confirm: {
          type: "textfield",
          label: "ERASE",
          preText:
            "WARNING - This action will erase the device which will result in data loss. Please type ERASE in the field below to confirm.",
          value: "",
        },
      };
      dialog.Title = "Erase Device";
      dialog.Action = "eraseMacos";
      break;
    case "eraseWindows":
      dialog.v2 = {
        keepUserData: {
          type: "checkbox",
          label: "Yes",
          preText:
            "Optional - Wipe device, but keep enrollment state and associated user account.",
          value: "false",
        },
        confirm: {
          type: "textfield",
          label: "ERASE",
          preText:
            "WARNING - This action will wipe the device which will result in data loss. Please type ERASE in the field below to confirm.",
          value: "",
        },
      };
      dialog.Title = "Wipe Device";
      dialog.Action = "eraseWindows";
      break;
    case "delete":
      dialog.v2 = {
        confirm: {
          type: "textfield",
          label: "DELETE",
          preText:
            "WARNING - This action will IMMEDIATELY remove the device from MDM, all settings and configuration will remain on the device. Please type DELETE in the field below to confirm.",
          value: "",
        },
      };
      dialog.Title = "Delete Device";
      dialog.Action = "delete";
      break;
    case "rename":
      dialog.v2 = {
        name: {
          type: "textfield",
          label: "New name",
          preText:
            "Beta - Please enter your new device name, it cannot contain special characters or spaces.",
          value: "",
        },
      };
      dialog.Title = "Rename Device";
      dialog.Action = "rename";
      break;
    case "retire":
      dialog.v2 = {
        confirm: {
          type: "textfield",
          label: "RETIRE",
          preText:
            "WARNING - This action removes managed app data (where applicable), settings, and email profiles that were assigned by using Intune. The device is then removed from Intune management. Please type RETIRE in the field below to confirm.",
          value: "",
        },
      };
      dialog.Title = "Retire Device";
      dialog.Action = "retire";
      break;
    case "enableLostMode":
      dialog.v2 = {
        message: {
          type: "textfield",
          label: "Message Text",
          preText: "Message Body",
          value: "",
        },
        number: {
          type: "textfield",
          label: "Phone Number",
          preText: "Contact Phone Number",
          value: "",
        },
        footer: {
          type: "textfield",
          label: "Footer Text",
          preText: "Footer Text",
          value: "",
        },
      };
      dialog.Title = "Enable Lost Mode";
      dialog.Action = "enableLostMode";
      break;
    case "freshStart":
      dialog.Title = "Fresh Start";
      dialog.Text =
        "WARNING - This action will reset the device to the only built-in applications included with the default Windows install, this will cause disruption and potential data loss. Please type RESET in the field below to confirm.";
      dialog.TxtField = true;
      dialog.v2 = {
        keepUserData: {
          type: "checkbox",
          label: "Yes",
          preText: "Optional - Retain user data on this device.",
          value: "false",
        },
        confirm: {
          type: "textfield",
          label: "RESET",
          preText:
            "WARNING - Cleaning this device will remove all preloaded Win32 apps, this will cause disruption and potential data loss. Please type RESET in the field below to confirm.",
          value: "",
        },
      };
      dialog.TxtFieldLabel = "RESET";
      dialog.Action = "freshStart";
      break;
    case "keyRotationFV":
      dialog.Title = "FileVault Key Rotation";
      dialog.Text =
        "This action will generate a new FileVault encryption key for this device.";
      dialog.Action = "keyRotationFV";
      break;
    case "disableLostMode":
      dialog.Title = "Disable Lost Mode";
      dialog.Text =
        "If already enabled, this action will disable lost mode on this device.";
      dialog.Action = "disableLostMode";
      break;
    case "shutdown":
      dialog.Title = "Shutdown Device";
      dialog.Text =
        "WARNING - This action may cause disruption to any logged in users. Please take care when running this command.";
      dialog.Action = "shutdown";
      break;
    case "restart":
      dialog.Title = "Restart Device";
      dialog.Text =
        "WARNING - This action may cause disruption to any logged in users. Please take care when running this command.";
      dialog.Action = "restart";
      break;
    case "sync":
      dialog.Title = "Sync Device";
      dialog.Text =
        "This will request the device to check in with the MDM as soon as it is able.";
      dialog.Action = "sync";
      break;
    case "clearPasscode":
      dialog.Title = "Clear Passcode";
      dialog.Text =
        "WARNING - This will remove the devices passcode and clear encryption, allowing it to be accessed by any user. Please consider before running this command.";
      dialog.Action = "clearPasscode";
      break;
    case "BypassActivationLock":
      dialog.Title = "Bypass Activation Lock";
      dialog.Text =
        "Beta - This action will allow the bypassing of activation lock on this device. This command will fail if a code has already been generated.";
      dialog.Action = "bypassActivationLock";
      break;
    case "logout":
      dialog.Title = "Logout User";
      dialog.Text =
        "This action will logout the logged in user of a device in Shared iPad Mode. This is not applicable to iOS.";
      dialog.Action = "logout";
      break;
    case "remoteLock-ios":
      dialog.Title = "Remote Lock";
      dialog.Text =
        "This action will force the user to use their passcode to unlock the device. This does not affect devices with no passcode.";
      dialog.Action = "remoteLock";
      break;
    case "remoteLock-macOS":
      dialog.Title = "Remote Lock";
      dialog.Text =
        "This device will reboot and require a PIN code to unlock. This PIN can be viewed in the 'Completed' commands tab.";
      dialog.Action = "remoteLock";
      break;
    case "autopilotReset":
      dialog.Title = "AutoPilot Reset";
      dialog.Text =
        "WARNING - This action will remove all the files, apps, and settings on a device (including the user profile) but retains the connection to Azure AD and Intune, this will cause disruption and data loss. Please type ERASE in the field below to confirm.";
      dialog.TxtField = true;
      dialog.TxtFieldLabel = "ERASE";
      dialog.Action = "autopilotReset";
      break;
    case "defenderQuickScan":
      dialog.Title = "Defender Quick Scan";
      dialog.Text =
        "This will request the device to perform a quick scan using Microsoft Defender. Please Note - This will have no effect if an alternative AV solution is in use.";
      dialog.Action = "defenderQuickScan";
      break;
    case "defenderFullScan":
      dialog.Title = "Defender Full Scan";
      dialog.Text =
        "This will request the device to perform a full scan using Microsoft Defender, this may take a while to complete. Please Note - This will have no effect if an alternative AV solution is in use.";
      dialog.Action = "defenderFullScan";
      break;
    case "updateDefender":
      dialog.Title = "Update Defender";
      dialog.Text =
        "This will request the device to update its Defender definitions database.";
      dialog.Action = "updateDefender";
      break;
    case "keyRotationBL":
      dialog.Title = "BitLocker Key Rotation";
      dialog.Text =
        "This will request the device to update its BitLocker encryption key and forward it to the MDM.";
      dialog.Action = "keyRotationBL";
      break;
    case "getFileVaultKey":
      dialog.Title = "Get FileVault Key";
      dialog.Text = "Requests the FileVault key for the device.";
      dialog.Action = "getFileVaultKey";
      break;
    default:
      console.log("No Dialog Configuration");
  }
  return dialog;
}

function dialogButtonPressed(dialog, mdmServerId, id) {
  console.log("Dialog - " + JSON.stringify(dialog));
  try {
    console.log(dialog.Action);
    switch (dialog.Action) {
      case "bypassActivationLock":
      case "clearPasscode":
      case "logout":
      case "remoteLock":
      case "restart":
      case "sync":
      case "keyRotationFV":
      case "keyRotationBL":
      case "defenderQuickScan":
      case "defenderFullScan":
      case "updateDefender":
      case "disableLostMode":
      case "shutdown":
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          deviceId: id,
        };
        return dialog;
      case "rename": {
        const regexTest = new RegExp(/^(?=.{1,63}$)(?![0-9]*$)[a-zA-Z0-9-]+$/u);
        if (!regexTest.test(dialog.v2.name.value)) {
          dialog.StatusTxt = "Please remove illegal characters";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "rename",
          deviceId: id,
          name: dialog.v2.name.value,
        };
        return dialog;
      }
      case "delete": {
        if (dialog.v2.confirm.value != "DELETE") {
          dialog.StatusTxt = "Please confirm by typing DELETE";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          //// Disabled for safety until needed.
          command: "delete-DISABLED",
          deviceId: id,
          name: dialog.v2.confirm.value,
        };
        return dialog;
      }
      case "retire": {
        if (dialog.v2.confirm.value != "RETIRE") {
          dialog.StatusTxt = "Please confirm by typing RETIRE";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          //// Disabled for safety until needed.
          command: "retire-DISABLED",
          deviceId: id,
          name: dialog.v2.confirm.value,
        };
        return dialog;
      }
      case "eraseMobile": {
        if (dialog.v2.confirm.value != "ERASE") {
          dialog.StatusTxt = "Please confirm by typing ERASE";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          //// Disabled for safety until needed.
          command: "eraseMobile-DISABLED",
          deviceId: id,
          name: dialog.v2.confirm.value,
        };
        return dialog;
      }
      case "eraseMacos": {
        const regexTest = new RegExp("\\d{6}");
        if (!regexTest.test(dialog.v2.code.value)) {
          dialog.StatusTxt = "Code must be a 6 digits";
          return dialog;
        }
        if (dialog.v2.confirm.value != "ERASE") {
          dialog.StatusTxt = "Please confirm by typing ERASE";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          //// Disabled for safety until needed.
          command: "eraseMacos-DISABLED",
          deviceId: id,
          code: dialog.v2.code.value,
        };
        return dialog;
      }
      case "eraseWindows": {
        if (dialog.v2.confirm.value != "ERASE") {
          dialog.StatusTxt = "Please confirm by typing ERASE";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          //// Disabled for safety until needed.
          command: "eraseWindows-DISABLED",
          deviceId: id,
          name: dialog.v2.confirm.value,
          keepUserData: dialog.v2.keepUserData.value,
        };
        return dialog;
      }
      case "freshStart": {
        if (dialog.v2.confirm.value != "RESET") {
          dialog.StatusTxt = "Please confirm by typing RESET";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          //// Disabled for safety until needed.
          command: "freshStart-DISABLED",
          deviceId: id,
          name: dialog.v2.confirm.value,
          keepUserData: dialog.v2.keepUserData.value,
        };
        return dialog;
      }
      case "enableLostMode": {
        if (dialog.v2.message.value == "") {
          dialog.StatusTxt = "Please enter a message";
          return dialog;
        }
        if (dialog.v2.number.value == "") {
          dialog.StatusTxt = "Please enter a phone number";
          return dialog;
        }
        if (dialog.v2.footer.value == "") {
          dialog.StatusTxt = "Please enter a footer message";
          return dialog;
        }
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Sending Command...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: "enableLostMode",
          deviceId: id,
          message: dialog.v2.message.value,
          number: dialog.v2.number.value,
          footer: dialog.v2.footer.value,
        };
        return dialog;
      }
      case "getFileVaultKey": {
        console.log("Get FileVault Key");
        dialog.ButtonDisabled = true;
        dialog.StatusTxt = "Getting Key...";
        dialog.command = {
          mdmServerId: mdmServerId,
          command: dialog.Action,
          deviceId: id,
        };
        return dialog;
      }
      default:
        console.log("Unknown Command");
        return dialog;
    }
  } catch (error) {
    console.log(error);
  }
}

module.exports = {
  windowsCommands,
  macosCommands,
  iosCommands,
  setupDialog,
  dialogButtonPressed,
};
