<script setup>
import { onMounted, ref, defineProps } from "vue";
import DevicePanel from "@/views/ela/components/DevicePanel.vue";
const props = defineProps({
  data: Object,
});

const expanded = ref(false);
onMounted(() => {
  expanded.value = props.data.expanded;
});

import { useDisplay } from "vuetify";

const { mobile } = useDisplay();
</script>

<template>
  <v-row class="pa-1 pt-2">
    <v-col cols="12" sm="9">
      <v-card>
        <template v-if="!expanded">
          <v-card-title @click="expanded = !expanded"
            ><v-icon>mdi-chevron-right</v-icon
            >{{ data.sectionTitle }}</v-card-title
          >
        </template>
        <template v-else>
          <v-card-title @click="expanded = !expanded"
            ><v-icon>mdi-chevron-down</v-icon
            >{{ data.sectionTitle }}</v-card-title
          >
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" v-if="data.panel && mobile" align="center">
                <DevicePanel :data="data"></DevicePanel>
              </v-col>
              <v-col>
                <v-table>
                  <tbody>
                    <tr v-for="field in data.fields" :key="field">
                      <template v-if="!field.type">
                        <th :width="350">{{ field.name }}</th>
                        <td>{{ field.value }}</td>
                      </template>
                      <template v-if="field.type == 'subTable'">
                        <td colspan="2">
                          <v-table>
                            <thead>
                              <tr>
                                <th v-for="header in field.cols" :key="header">
                                  {{ header }}
                                </th>
                              </tr>
                              <tr v-for="value in field.values" :key="value">
                                <td v-for="header in field.cols" :key="header">
                                  {{ value[header] }}
                                </td>
                              </tr>
                            </thead>
                          </v-table>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
              <v-col cols="auto" v-if="data.panel && !mobile">
                <DevicePanel :data="data"></DevicePanel>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
