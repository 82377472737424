<template>
  <template v-if="!appBar.selected.length">
    <h3>Select Customer from Menu above</h3>
  </template>
  <template v-else>
    <cat24ImportCSV
      v-if="importCSV"
      @cancel="importCSV = !importCSV"
      importType="orders"
    />
    <cat24AddRow
      v-if="addRow"
      addType="neworder"
      :formData="customerConfig"
      @cancel="addRow = !addRow"
      @updated="(addRow = !addRow), getData()"
    />
    <cat24AddRow
      v-if="editRow"
      edit
      :item="editItem"
      addType="orders"
      @cancel="editRow = !editRow"
      @updated="(editRow = !editRow), getData()"
    />
    <template v-if="!selectedOrder && addRow == false">
      <v-row no-gutters style="height: 45px">
        <!-- <v-col class="flex-grow-0 flex-shrink 0" cols="2"> </v-col> -->
        <v-col class="pa-2">
          <v-select
            label="Search Field"
            density="compact"
            variant="underlined"
            v-model="searchFieldSelected"
            :items="searchFields()"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="2" class="">
          <v-text-field
            variant="underlined"
            height="25px"
            class="pl-2"
            density="compact"
            label="Search Value"
            v-model="searchValue"
            hide-details="auto"
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn
            height="33px"
            prepend-icon="mdi-plus"
            size="small"
            class="pa-2 ml-3"
            @click="addNew()"
            >Add New</v-btn
          >
        </v-col>
      </v-row>

      <EasyDataTable
        :headers="headers"
        :items="items"
        theme-color="#e7541e"
        alternating
        buttons-pagination
        class="mt-3"
        :loading="loading"
        :search-field="searchField"
        :search-value="searchValue"
        @click-row="selectOrder"
      >
        <template #item-actions="item">
          <v-icon @click="openEdit(item)">mdi-pencil</v-icon>
        </template>
      </EasyDataTable>
      <v-row class="pt-2">
        <v-col cols="9">
          <v-btn @click="createReport()" :disabled="disableReportButton"
            >Get Order Report</v-btn
          >
          {{ reportStatus }}{{ reportStatusError }}
        </v-col>
        <v-spacer />

        <v-col cols="3" class="text-right">
          <download-csv
            :data="report"
            v-if="reportComplete"
            :name="ordersCsvName()"
            ><v-btn prepend-icon="mdi-download">Download</v-btn>
          </download-csv>
        </v-col>
      </v-row>
    </template>
    <template v-if="selectedOrder">
      <v-row>
        <v-col>
          <v-btn
            @click="selectedOrder = false"
            height="33px"
            size="small"
            prepend-icon="mdi-arrow-left"
          >
            Back
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right"
          ><v-btn
            height="33px"
            size="small"
            prepend-icon="mdi-delete"
            @click="displayConfirmDelete = true"
            >Delete Order</v-btn
          ></v-col
        >
      </v-row>
      <v-table>
        <!-- <t-head>
          <th colspan="2" class="text-center">Order Details</th>
        </t-head> -->
        <tbody>
          <template v-for="item in selectedOrderDetails" :key="item">
            <tr>
              <th>{{ item.label }}</th>
              <td>{{ item.value }}</td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </template>
    <v-dialog v-model="displayConfirmDelete" persistent
      ><v-card>
        <v-card-title>Confirm Delete Order</v-card-title>
        <v-card-text
          >Please confirm that you wish to delete this order, this action cannot
          be undone.</v-card-text
        >
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="displayConfirmDelete = false">Cancel</v-btn
          ><v-btn @click="confirmDelete()">Delete</v-btn></v-card-actions
        >
      </v-card></v-dialog
    >
  </template>

  <!-- <h4>{{ token }}</h4>
  <div v-if="token">
    <h4>{{ token }}</h4>
  </div> -->
</template>

<style>
.v-select__selection-text {
  font-size: 0.9em;
}
</style>
<script setup>
import { reactive, ref, onMounted, watch } from "vue";
//import type { Header, Item } from "vue3-easy-data-table";
import cat24ImportCSV from "../cat24ImportCSV.vue";
import cat24AddRow from "../cat24AddRow.vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
const items = reactive([]);
const selectedOrder = ref(false);
const customerConfig = reactive([]);

appBar.$subscribe(() => {
  if (appBar.selected != "") {
    getData();
  }
});

const headers = [
  { text: "Order ID", value: "_id" },
  { text: "Email", value: "email" },
  { text: "Order Date", value: "_created_at" },
  { text: "Delivery Slot Date", value: "deliveryDate" },
  { text: "Order Notes", value: "orderNotes" },
];

const searchField = ref("");
const searchValue = ref("");
const searchFieldSelected = ref("");

const startDate = ref(new Date());
const endDate = ref(new Date());

function searchFields() {
  var result = [];
  headers.forEach((header) => {
    if (header.text != "Actions") result.push(header.text);
  });
  return result;
}

function ordersCsvName() {
  return appBar.selected + "Orders";
}

async function addNew() {
  loading.value = true;
  customerConfig.length = 0;
  customerConfig.push(
    await apiQuery("ELA", "cat24/neworder", {
      apiAction: "getConfig",
      customer: appBar.selected,
    })
  );

  loading.value = false;
  addRow.value = true;
}

watch(searchFieldSelected, (currentValue) => {
  headers.forEach((header) => {
    if (header.text == currentValue) {
      searchField.value = header.value;
    }
  });
});
const importCSV = ref(false);
const addRow = ref(false);
const editItem = ref({});
const editRow = ref(false);
const loading = ref(false);

const report = ref([]);
const reportComplete = ref(false);
const reportStatus = ref("");
const reportStatusError = ref("");
const disableReportButton = ref(false);

async function createReport() {
  // const tempArray = [];
  // for (const key in items) {
  //   await selectedOrder(items[key]);
  //   tempArray.push(selectedOrderDetails);
  // }
  // console.log(tempArray);

  // Create Array of Order IDs
  reportComplete.value = false;
  reportStatusError.value = "";
  disableReportButton.value = true;
  const orderIDs = [];
  items.forEach((item) => {
    orderIDs.push(item._id);
  });
  console.log(orderIDs);
  report.value.length = 0;
  const chunkSize = 100;
  for (let i = 0; i < orderIDs.length; i += chunkSize) {
    const chunk = orderIDs.slice(i, i + chunkSize);
    var toValue = i + chunkSize;
    if (toValue > orderIDs.length) {
      toValue = orderIDs.length;
    }
    reportStatus.value = "Getting records " + i + " to " + toValue;
    console.log(chunk);
    const response = await apiQuery("ELA", "cat24/orders", {
      apiAction: "getReport",
      orderIDs: chunk,
      customer: appBar.selected,
    });
    if (response) {
      report.value.push(...response);
    } else {
      reportStatusError.value = "An Error occured getting data";
    }
  }
  if (reportStatusError.value == "") {
    reportComplete.value = true;
    reportStatus.value = "Complete - Click Download";
  }
  disableReportButton.value = false;
  //console.log(reportStatusError.value);
  //console.log(report.value);
}

const selectedOrderDetails = reactive([]);
const selectedOrderId = ref("");
const selectOrder = async (item) => {
  loading.value = true;

  selectedOrderDetails.length = 0;

  const response = await apiQuery("ELA", "cat24/orders", {
    apiAction: "getOne",
    customer: appBar.selected,
    order: {
      _id: item._id,
    },
  });
  if (response._id) {
    selectedOrderId.value = item._id;
    var details = [];
    details.push({ label: "Order ID", value: response._id });
    details.push(
      checkForValue(response, "readyForDelivery", "Ready for Delivery")
    );
    details.push(checkForValue(response, "_created_at", "Created At"));
    details.push(checkForValue(response, "_updated_at", "Updated At"));
    details.push(checkForValue(response, "quantity", "Quantitiy"));
    details.push(checkForValue(response, "orderNotes", "Order Notes"));
    if (response.deliverySlot) {
      details.push(
        checkForValue(response.deliverySlot, "deliveryDate", "Delivery Date")
      );
    }
    if (response.contact) {
      details.push({ label: "Contact Details", value: "" });
      details.push(checkForValue(response.contact, "email", "Email"));
      details.push(checkForValue(response.contact, "firstName", "First Name"));
      details.push(checkForValue(response.contact, "lastName", "Last Name"));
      details.push(checkForValue(response.contact, "keyWords", "Keywords"));
    }
    if (response.orderingChoice) {
      details.push({ label: "Order Choice", value: "" });
      details.push(checkForValue(response.orderingChoice, "name", "Name"));
      details.push(
        checkForValue(
          response.orderingChoice,
          "requiresDelivery",
          "Requires Delivery"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "placeOrderOnSid",
          "Place Order on SID"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "requiresBooking",
          "Requires Booking"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "requiresCollection",
          "Requires Collection"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "returnExistingDevice",
          "Return Existing Device"
        )
      );
    }
    if (response.deliveryAddress) {
      details.push({ label: "Delivery Address", value: "" });
      details.push(checkForValue(response.deliveryAddress, "line1", "Line 1"));
      details.push(checkForValue(response.deliveryAddress, "line2", "Line 2"));
      details.push(checkForValue(response.deliveryAddress, "city", "City"));
      details.push(
        checkForValue(response.deliveryAddress, "postcode", "Post Code")
      );
      details.push(
        checkForValue(response.deliveryAddress, "driverNotes", "Driver Notes")
      );
      details.push(
        checkForValue(
          response.deliveryAddress,
          "contactNumber",
          "Contact Number"
        )
      );
    }

    // Remove any null entries from temp array and push into reactive array
    details = details.filter((element) => {
      return element != null;
    });
    selectedOrderDetails.push(...details);
  }
  loading.value = false;
  selectedOrder.value = true;
};

function checkForValue(object, property, label) {
  if (Object.prototype.hasOwnProperty.call(object, property)) {
    return { label: label, value: object[property] };
  }
}

const displayConfirmDelete = ref(false);

async function confirmDelete() {
  console.log("confirmedDeleted");
  selectedOrder.value = false;
  loading.value = true;
  displayConfirmDelete.value = false;
  await apiQuery("ELA", "cat24/orders", {
    apiAction: "delete",
    customer: appBar.selected,
    order: {
      _id: selectedOrderId.value,
    },
  });

  getData();
}

function openEdit(item) {
  editItem.value = item;
  editRow.value = true;
}

async function getData() {
  loading.value = true;
  reportComplete.value = false;
  reportStatus.value = "";
  reportStatusError.value = "";
  disableReportButton.value = false;
  // const response = await apiQuery("ELA", "cat24/orders", {
  //   apiAction: "getV2",
  //   customer: appBar.selected,
  // });

  const [response, deliverySlots] = await Promise.all([
    apiQuery("ELA", "cat24/orders", {
      apiAction: "getV2",
      customer: appBar.selected,
    }),
    apiQuery("ELA", "cat24/deliveries", {
      apiAction: "get",
      customer: appBar.selected,
    }),
  ]);

  // Make delivery slots into an object for easier use
  // console.log(deliverySlots);
  var deliverySlotsObj = {};
  deliverySlots.forEach((slot) => {
    deliverySlotsObj[slot._id] = slot;
  });
  // console.log(deliverySlotsObj);

  const contacts = {};
  if (response.Contacts.length) {
    response.Contacts.forEach((contact) => {
      contacts[contact._id] = contact.email.toLowerCase();
    });
  }
  items.length = 0;
  response.Orders.forEach((order) => {
    order.email = contacts[order._p_contact.replace("Contact$", "")];

    if (order._p_deliverySlot) {
      order.deliveryDate =
        deliverySlotsObj[
          order._p_deliverySlot.replace("DeliverySlot$", "")
        ]?.deliveryDate;
      //console.log(order.deliveryDate?.deliveryDate);
    }
    items.push(order);
  });

  loading.value = false;
}

onMounted(async () => {
  if (appBar.selected != "") {
    getData();
  }
});
</script>
