import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Auth, API } from "aws-amplify";
import { createPinia } from "pinia";
//import { library } from "@fortawesome/fontawesome-svg-core";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { VueCsvImportPlugin } from "vue-csv-import";
import JsonCSV from "vue-json-csv";
import VuePapaParse from "vue-papa-parse";
import VueApexCharts from "vue3-apexcharts";

// Icons
import { faLaptop } from "@fortawesome/free-solid-svg-icons";

//library.add(faLaptop);

loadFonts();

Auth.configure({
  region: process.env.VUE_APP_AWS_REGION as string,
  userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID as string,
  userPoolWebClientId: process.env
    .VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID as string,
  oauth: {
    domain: process.env.VUE_APP_AWS_OAUTH_DOMAIN as string,
    scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    // scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.VUE_APP_AWS_OAUTH_REDIRECT_SIGN_IN as string,
    redirectSignOut: process.env.VUE_APP_AWS_OAUTH_REDIRECT_SIGN_OUT as string,
    responseType: "code",
  },
});

API.configure({
  endpoints: [
    {
      name: "J24",
      endpoint:
        "https://8wbfq62cch.execute-api.eu-west-1.amazonaws.com/default/TestFunctionCognito",
    },
    {
      name: "J24Auth",
      endpoint: process.env.VUE_APP_AUTH_API as string,
    },
    {
      name: "ELA",
      endpoint: process.env.VUE_APP_ELA_API as string,
    },
  ],
});

const pinia = createPinia();

createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(VueCsvImportPlugin)
  .use(VuePapaParse)
  .use(VueApexCharts)
  // .component("font-awesome-icon", FontAwesomeIcon)
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("downloadCsv", JsonCSV)
  .component("apexchart", VueApexCharts)
  .mount("#app");
