<script setup>
import { ref, defineProps, onMounted, defineEmits } from "vue";
import { apiQuery } from "@/api/j24api";

const props = defineProps({
  dataSourceId: String,
  mdmServerId: String,
});

const alertSettings = ref({});
const warnSettings = ref({});
const dataSources = ref([]);
const loading = ref(false);
const dataSource = ref({});

const emit = defineEmits(["cancel"]);

async function getData() {
  console.log("Get Data");
  loading.value = true;
  const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
    apiAction: "get",
    dataSourceId: props.dataSourceId,
  });
  dataSources.value = await apiQuery("ELA", "dashboard/jamfdatasources", {
    apiAction: "get",
  });
  dataSource.value = result[0];
  loading.value = false;
  alertSettings.value = { global: true };
  warnSettings.value = { global: true };
  if (!result[0].customAlerts) {
    alertSettings.value = { global: true };
  } else {
    if (result[0].customAlerts[props.mdmServerId]) {
      alertSettings.value = result[0].customAlerts[props.mdmServerId];
    }
  }
  if (!result[0].customWarn) {
    warnSettings.value.global = true;
  } else {
    if (result[0].customWarn[props.mdmServerId]) {
      warnSettings.value = result[0].customWarn[props.mdmServerId];
    }
  }
}

async function saveChanges() {
  loading.value = true;
  if (!dataSource.value.customAlerts) {
    dataSource.value.customAlerts = {};
  }
  dataSource.value.customAlerts[props.mdmServerId] = alertSettings.value;
  if (alertSettings.value.global) {
    delete dataSource.value.customAlerts[props.mdmServerId];
  }

  if (!dataSource.value.customWarn) {
    dataSource.value.customWarn = {};
  }
  dataSource.value.customWarn[props.mdmServerId] = warnSettings.value;
  if (warnSettings.value.global) {
    delete dataSource.value.customWarn[props.mdmServerId];
  }

  try {
    const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
      apiAction: "update",
      JamfDataSource: dataSource.value,
    });
    emit("cancel");
  } catch (error) {
    console.log(error);
  }
  getData();
}

onMounted(() => {
  getData();
});
const alertOperators = ["Less Than", "Equals", "Greater Than"];
</script>

<template>
  <v-card>
    <v-card-text class="text-center" v-if="loading"
      ><v-progress-circular indeterminate size="64"></v-progress-circular
    ></v-card-text>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col>
          <h3>Alert Settings</h3>
          <v-checkbox
            density="compact"
            v-model="alertSettings.global"
            label="Use Global Settings"
          />
          <template v-if="!alertSettings.global">
            <v-select
              label="Alert when value is"
              density="compact"
              :items="alertOperators"
              v-model="alertSettings.AlertOperator"
            ></v-select>
            <v-text-field
              v-if="!alertSettings.AlertUseAnotherSource"
              density="compact"
              v-model="alertSettings.AlertValue"
              label="Static Value"
            />
            <v-checkbox
              density="compact"
              v-model="alertSettings.AlertUseAnotherSource"
              label="Use Another Source"
            />
            <v-select
              v-if="alertSettings.AlertUseAnotherSource"
              label="Choose Other Data Source"
              v-model="alertSettings.AlertOtherSource"
              density="compact"
              :items="dataSources"
              item-title="name"
              item-value="_id"
            ></v-select>
            <v-checkbox
              v-if="alertSettings.AlertUseAnotherSource"
              density="compact"
              v-model="alertSettings.AlertUsePercentage"
              label="Use Percentage"
            />
            <v-slider
              v-if="
                alertSettings.AlertUsePercentage &&
                alertSettings.AlertUseAnotherSource
              "
              v-model="alertSettings.AlertPercentage"
              density="compact"
              label="Choose Percentage"
              thumb-label="always"
              max="100"
              step="1"
            >
            </v-slider>
          </template>
        </v-col>
        <v-col>
          <h3>Warning Settings</h3>
          <v-checkbox
            density="compact"
            v-model="warnSettings.global"
            label="Use Global Settings"
          />
          <template v-if="!warnSettings.global">
            <v-select
              label="Warn when value is"
              density="compact"
              :items="alertOperators"
              v-model="warnSettings.WarnOperator"
            ></v-select>
            <v-text-field
              v-if="!warnSettings.WarnUseAnotherSource"
              density="compact"
              v-model="warnSettings.WarnValue"
              label="Static Value"
            />
            <v-checkbox
              density="compact"
              v-model="warnSettings.WarnUseAnotherSource"
              label="Use Another Source"
            />
            <v-select
              v-if="warnSettings.WarnUseAnotherSource"
              label="Choose Other Data Source"
              v-model="warnSettings.WarnOtherSource"
              density="compact"
              :items="dataSources"
              item-title="name"
              item-value="_id"
            ></v-select>
            <v-checkbox
              v-if="warnSettings.WarnUseAnotherSource"
              density="compact"
              v-model="warnSettings.WarnUsePercentage"
              label="Use Percentage"
            />
            <v-slider
              v-if="
                warnSettings.WarnUsePercentage &&
                warnSettings.WarnUseAnotherSource
              "
              v-model="warnSettings.WarnPercentage"
              density="compact"
              label="Choose Percentage"
              thumb-label="always"
              max="100"
              step="1"
            ></v-slider>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"><v-btn @click="saveChanges()">Save</v-btn> </v-col>
        <v-col cols="2"><v-btn @click="$emit('cancel')">Cancel</v-btn></v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>
