<script setup>
import { onMounted, ref } from "vue";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { permissionsStore } from "@/stores/permissions";
const permissions = permissionsStore();

const items = ref([]);

permissions.$subscribe(() => {
  buildSidebarItems();
});

onMounted(async () => {
  if (appBar.appRunning != "Dashboard") {
    appBar.$patch({
      appRunning: "Dashboard",
      type: "None",
      items: [],
      selected: "",
    });
  }
  buildSidebarItems();
});

function buildSidebarItems() {
  items.value.length = 0;
  allItems.forEach((item) => {
    if (permissions.$state["Dashboard-" + item.privName]) {
      items.value.push(item);
    }
  });
}

const allItems = [
  {
    title: "Alerts",
    icon: "mdi-alert",
    link: "DashboardAlerts",
    privName: "Overview",
  },
  {
    title: "Overview",
    icon: "mdi-view-dashboard",
    link: "DashboardOverview",
    privName: "Overview",
  },
  {
    title: "Settings",
    icon: "mdi-cog-outline",
    link: "DashboardSettings",
    privName: "JamfDataSources",
  },
  {
    title: "Logs",
    icon: "mdi-view-list",
    link: "DashboardLogs",
    privName: "MSPLogs",
  },
  {
    title: "Policies",
    icon: "mdi-archive-arrow-up",
    link: "DashboardPolicyUpdates",
    privName: "PolicyUpdates",
  },
  {
    title: "Reports",
    icon: "mdi-chart-box",
    link: "DashboardPatchingReport",
    privName: "PatchReports",
  },
  {
    title: "Accounts",
    icon: "mdi-account-multiple",
    link: "DashboardAccounts",
    privName: "TempJamfAccountReadOnly",
  },
  {
    title: "Search",
    icon: "mdi-magnify",
    link: "DashboardJamfSearch",
    privName: "JamfSearch",
  },
];

// Required to make the router-view reload when user clicks the link again
// See the key value set on <router-view> below.
const randomKey = ref("");
function updateRandom() {
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}
</script>

<template>
  <v-navigation-drawer permanent width="190" theme="jigsawDark">
    <v-list>
      <div class="v-list-item">
        <v-icon class="pa-3" color="#e7541e">mdi-view-dashboard</v-icon>
        <div class="text-h7 pa-3 cursor-default">
          <strong>Dashboard</strong>
        </div>
      </div>
      <template v-for="item in items" :key="item.title">
        <v-list-item
          link
          :to="{ name: item.link }"
          active-color="#e7541e"
          @click="updateRandom()"
        >
          <template v-slot:prepend>
            <v-icon class="">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title class="pl-0">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view :key="randomKey" />
    </v-container>
  </v-main>
</template>
