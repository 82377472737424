<script setup>
import { ref, reactive, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";
import VSelectSearch from "@/components/VSelectSearch.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

const loading = ref(false);
const licenses = reactive([]);
const customers = reactive([]);

const headers = [
  { text: "License Name", value: "instancename", sortable: true },
  { text: "Creation Date", value: "creationDate", sortable: true },
  { text: "Expiry Date", value: "enddate", sortable: true },
  { text: "Customer", value: "customerName", sortable: true },
  { text: "Actions", value: "actions" },
];

const searchValue = ref("");

function formatDate(date) {
  if (date) {
    const d = new Date(Number(date));
    if (d instanceof Date && !isNaN(d)) {
      //return d.toLocaleDateString();
      return d.toLocaleString();
    }
  }
  return "";
}

function formatDate2(date) {
  if (date == undefined) {
    return undefined;
  }
  let text = "";
  text = new Date(date).toLocaleString("en-GB");
  if (text == "Invalid Date") {
    return "";
  }
  return text;
}

function searchFields(headers) {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

headers.forEach((header) => {
  header.align = "start";
  header.sortable = false;
});

async function getLicences() {
  try {
    loading.value = true;
    const result = await apiQuery("ELA", "licences", {
      type: "Agent",
      apiAction: "GetLicences",
    });
    if (result.licences) {
      licenses.length = 0;
      // Convert epoch time to date object
      result.licences.forEach((licence) => {
        if (licence.enddate) {
          licence.enddate = new Date(Number(licence.enddate));
        }
      });

      licenses.push(...result.licences);
    }
    if (result.customers) {
      customers.length = 0;
      customers.push(...result.customers);
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

onMounted(() => {
  getLicences();
});

const editing = ref(false);
const addingNew = ref(false);
const editItem = ref({});

function addNew() {
  addingNew.value = true;
  editItem.value = {
    enddate: new Date().toISOString(),
  };
}

function edit(item) {
  editing.value = true;
  editItem.value = item;
}

function cancel() {
  addingNew.value = false;
  editing.value = false;
  editItem.value = {};
}

async function save() {
  loading.value = true;
  try {
    var requestBody = {
      type: "Agent",
      ...editItem.value,
    };
    // If New Customer is set create new customer
    if (editItem.value.newCustomer) {
      const newCustomer = await apiQuery("J24Auth", "elaadmin/users", {
        apiAction: "createCustomer",
        name: editItem.value.newCustomer,
      });

      requestBody.customerid = newCustomer._id;
    }

    // Get EPOCH time from editItem.enddate
    console.log(editItem.value.enddate);
    console.log(new Date(editItem.value.enddate));
    requestBody.enddate = new Date(editItem.value.enddate).getTime();

    if (addingNew.value) {
      requestBody.apiAction = "CreateNewLicence";
    } else {
      requestBody.apiAction = "UpdateLicence";
    }
    const result = await apiQuery("ELA", "licences", requestBody);
    console.log(result);
    if (result.instancename) {
      await getLicences();
      editItem.value = result;
      editItem.value.enddate = formatDate(result.enddate);
      addingNew.value = false;
      editing.value = true;
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

async function newLicenceKey() {
  loading.value = true;
  try {
    const result = await apiQuery("ELA", "licences", {
      type: "Agent",
      apiAction: "CreateNewLicence",
      ...editItem.value,
    });
    console.log(result);
    if (result.license) {
      editItem.value = result;
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}
</script>

<template>
  <v-card v-if="!editing && !addingNew">
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn @click="addNew()">Add New Licence</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-text-field
            density="compact"
            label="Search"
            v-model="searchValue"
            variant="underlined"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <EasyDataTable
            :items="licenses"
            :headers="headers"
            :search="searchValue"
            :searchFields="searchFields(headers)"
            theme-color="#e7541e"
            alternating
            buttons-pagination
            :loading="loading"
          >
            <template #item-creationDate="item">
              {{ formatDate2(item.creationDate) }}
            </template>
            <template #item-enddate="item">
              {{ formatDate(item.enddate) }}
            </template>
            <template #item-actions="item">
              <v-icon @click="edit(item)">mdi-pencil</v-icon>
            </template>
          </EasyDataTable>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card v-if="editing || addingNew">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="editItem.instancename"
            label="Licence Name"
            density="compact"
            :readonly="editing"
          >
          </v-text-field>
          <!-- v-select to select customer-->
          <VSelectSearch
            v-model="editItem.customerid"
            :items="customers"
            itemTitle="name"
            itemValue="_id"
            label="Customer"
            density="compact"
            :activateSearch="true"
          />
          <div>If Customer is not listed createa a new customer here</div>
          <v-text-field
            v-model="editItem.newCustomer"
            label="Customer Name"
            density="compact"
          />
          Expiry Date
          <VueDatePicker
            class="py-3"
            v-model="editItem.enddate"
            label="Expiry Date"
            inline
            auto-apply
            :enable-time-picker="false"
            :month-change-on-scroll="false"
          />
          <!-- add vuetify text area for editItem.license-->
          <v-textarea
            v-if="editItem.license"
            v-model="editItem.license"
            label="Licence Key"
            readonly
            rows="3"
          />
          <v-textarea
            v-if="editItem.apiKey"
            v-model="editItem.apiKey"
            label="API Key"
            readonly
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <v-btn :loading="loading" @click="save()">Save</v-btn>
        </v-col>
        <v-col v-if="editing" cols="auto">
          <v-btn @click="newLicenceKey()">Create New Licence</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn @click="cancel()">Cancel</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card-title {
  font-weight: 700;
}
</style>
