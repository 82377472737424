<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  allDevices: Object,
});

const emit = defineEmits(["clickDataPoint"]);

function clickHandler(event, chartContext, config) {
  emit("clickDataPoint", event, chartContext, config);
}

var macbook = {
  macbookPro: {
    name: "MacBook Pro",
    devices: [],
  },
  macbookAir: {
    name: "MacBook Air",
    devices: [],
  },
};
var desktop = {
  imac: {
    name: "iMac",
    devices: [],
  },
  macMini: {
    name: "Mac Mini",
    devices: [],
  },
  studio: {
    name: "Mac Studio",
    devices: [],
  },
};
var ipad = {
  ipadPro: {
    name: "iPad Pro",
    devices: [],
  },
  ipadAir: {
    name: "iPad Air",
    devices: [],
  },
  ipad: {
    name: "iPad",
    devices: [],
  },
};

var windows = {};
var android = {};

var iphone = {};
var tv = {
  appleTv4K: {
    name: "Apple TV 4K",
    devices: [],
  },
  appleTv4th: {
    name: "Apple TV 4th Generation",
    devices: [],
  },
};

onMounted(() => {
  sortDevices();
  generateChartData();
  setMinimumWidth();
  showChart.value = true;
});

var chartData = [];
const showChart = ref(false);

function generateChartData() {
  function makeChart(data, name) {
    var series = [];
    for (const key in data) {
      if (data[key].devices.length) {
        series.push({
          name: data[key].name,
          data: [data[key].devices.length],
          devices: data[key].devices,
          value: data[key].devices.length,
        });
      }
    }

    series.sort((a, b) => {
      if (a.data[0] > b.data[0]) {
        return 1;
      }
      if (a.data[0] < b.data[0]) {
        return -1;
      }
      return 0;
    });
    var total = 0;
    series.forEach((a) => {
      total += a.devices.length;
    });

    return {
      colors: ["#eeeeee"],
      fill: {
        opacity: 1,
      },
      chart: {
        type: "bar",
        //height: 150,
        stacked: true,
        toolbar: { show: false },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value, opt) {
            //console.log(value, opt);
            //return "x";
            return opt.w.config.series[opt.seriesIndex].value;
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: false, // Disabled as this doesn't correctly tally up the total.
              offsetX: 5,
              offsetY: 0,
              style: { fontSize: "15px", fontWeight: 900 },
            },
          },
        },
      },
      series: series,
      stroke: {
        width: 1,
        colors: ["black"],
      },
      dataLabels: {
        formatter: function (value, opt) {
          //console.log(value, opt);
          return opt.config.series[opt.seriesIndex].value;
        },
        style: {
          colors: ["black"],
        },
      },
      grid: {
        padding: { top: -35, bottom: -20, right: 50 },
        yaxis: { lines: { show: false } },
      },
      xaxis: {
        min: 0,
        max: total,
        categories: [name],
        axisBorder: { show: false },
        axisTicks: { show: false },
        position: "none",
        labels: {
          show: false,
        },
        convertedCatToNumeric: false,
      },
      yaxis: {
        title: {},
        labels: {
          show: false,
        },
        axisBorder: { show: false },
      },
      legend: { show: false },
    };
  }

  // Filter out platforms with zero devices.
  chartData.push(
    ...[
      makeChart(macbook, "MacBook"),
      makeChart(desktop, "Mac Desktop"),
      makeChart(ipad, "iPad"),
      makeChart(iphone, "iPhone"),
      makeChart(tv, "Apple TV"),
      makeChart(windows, "Windows"),
      makeChart(android, "Android"),
    ].filter((chart) => chart.series.length > 0)
  );
}

function setMinimumWidth() {
  chartData.forEach((chart, index) => {
    var total = 0;
    chart.series.forEach((series) => {
      series.data.forEach((value) => {
        total += value;
      });
    });

    var newTotal = 0;
    chart.series.forEach((series, sIndex) => {
      chart.series[sIndex].values = [];
      series.data.forEach((value, vIndex) => {
        chart.series[sIndex].values.push(value);
        if (total / value > 24) {
          let newValue = Math.floor(total * 0.025);
          chart.series[sIndex].data[vIndex] = newValue;
        }
        newTotal += chart.series[sIndex].data[vIndex];
      });
    });
    chart.xaxis.max = newTotal;

    // if (opt.config.xaxis.max / val > 24) {
    //     return [" ", val];
    //   }
  });
}

function sortDevices() {
  props.allDevices.forEach((device) => {
    if (device.Model.includes("MacBook")) {
      macbookSort(device);
      return;
    }

    if (
      device.Model.includes("iMac") ||
      device.Model.includes("Mac mini") ||
      device.Model.includes("Studio")
    ) {
      desktopSort(device);
      return;
    }

    if (device.Model.includes("TV")) {
      tvSort(device);
      return;
    }

    if (device.Model.includes("iPad")) {
      iPadSort(device);
      return;
    }

    if (device.Model.includes("iPhone")) {
      iPhoneSort(device);
      return;
    }

    if (device.Operating_System_Name?.includes("Windows")) {
      windowsSort(device);
      return;
    }

    if (device.Operating_System_Name?.includes("Android")) {
      androidSort(device);
      return;
    }
  });
}

function iPhoneSort(device) {
  if (!iphone[device.Model]) {
    iphone[device.Model] = {
      name: device.Model,
      devices: [device],
    };
    return;
  }
  iphone[device.Model].devices.push(device);
}

function windowsSort(device) {
  if (!windows[device.Model]) {
    windows[device.Model] = {
      name: device.Model,
      devices: [device],
    };
    return;
  }
  windows[device.Model].devices.push(device);
}

function androidSort(device) {
  if (!android[device.Model]) {
    android[device.Model] = {
      name: device.Model,
      devices: [device],
    };
    return;
  }
  android[device.Model].devices.push(device);
}

function iPadSort(device) {
  if (device.Model.includes("iPad Pro")) {
    ipad.ipadPro.devices.push(device);
    return;
  }
  if (device.Model.includes("iPad Air")) {
    ipad.ipadAir.devices.push(device);
    return;
  }
  if (device.Model.includes("iPad")) {
    ipad.ipad.devices.push(device);
    return;
  }
  console.log("Unknown Device iPad", device);
  return;
}

function tvSort(device) {
  if (device.Model.includes("Apple TV 4K")) {
    tv.appleTv4K.devices.push(device);
    return;
  }
  if (device.Model.includes("Apple TV 4th Generation")) {
    tv.appleTv4th.devices.push(device);
    return;
  }
  console.log("Unknown Device TV", device);
  return;
}

function desktopSort(device) {
  if (device.Model.includes("iMac")) {
    desktop.imac.devices.push(device);
    return;
  }
  if (device.Model.includes("Mac mini")) {
    desktop.imac.devices.push(device);
    return;
  }
  if (device.Model.includes("Studio")) {
    desktop.studio.devices.push(device);
    return;
  }
  console.log("Unknown Device Desktop", device);
  return;
}

function macbookSort(device) {
  if (device.Model.includes("MacBook Pro")) {
    macbook.macbookPro.devices.push(device);
    return;
  }
  if (device.Model.includes("MacBook Air")) {
    macbook.macbookAir.devices.push(device);
    return;
  }

  console.log("Unknown Device", device);
  return;
}
</script>

<template>
  <template v-if="showChart">
    <template v-for="chart in chartData" :key="chart">
      <v-sheet class="d-flex" height="60">
        <v-sheet align-self-center width="130" class="font-weight-bold">
          <div class="pt-4">
            {{ chart.xaxis.categories[0] }}
          </div>
        </v-sheet>
        <v-sheet width="100%">
          <apexchart
            class=""
            :options="chart"
            :series="chart.series"
            :height="50"
            @dataPointSelection="clickHandler"
          >
          </apexchart>
        </v-sheet>
      </v-sheet>
    </template>
  </template>
</template>
