<script setup>
import { ref, onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";
import router from "@/router";

function goTo(link) {
  router.push({ name: link });
}

onMounted(() => {
  appBarStore().$reset();
  appBarStore().$patch({
    appRunning: "ELA Admin",
    type: "None",
    items: [],
    selected: "",
  });
});

const items = ref([
  { title: "Permissions", icon: "mdi-account-key", link: "AdminPermissions" },
  { title: "Roles", icon: "mdi-account-key", link: "AdminRoles" },
  { title: "Customers", icon: "mdi-account-key", link: "AdminCustomers" },
  { title: "Groups", icon: "mdi-account-key", link: "AdminGroups" },
  { title: "Users", icon: "mdi-account-key", link: "AdminUsers" },
]);
</script>

<template>
  <v-navigation-drawer permanent width="190" theme="jigsawDark">
    <v-list density="compact">
      <template v-for="item in items" :key="item">
        <v-list-item link :to="{ name: item.link }" active-color="#e7541e">
          <v-list-item-title @click="goTo(item.link)"
            ><v-icon>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
</template>
