<script setup>
import { onMounted, ref, defineProps } from "vue";
const props = defineProps({
  data: Object,
});
import { useDisplay } from "vuetify";
const { mobile } = useDisplay();

import generic from "@/assets/generic.png";
import ipad from "@/assets/ipad.png";
import imac from "@/assets/imac.png";
import iphone from "@/assets/iphone.png";
import macbookAir from "@/assets/macbookAir.png";
import macbookPro from "@/assets/macbookPro.png";
import macmini from "@/assets/macmini.png";

function setDeviceImage(model) {
  if (model.includes("iPad")) {
    return ipad;
  }
  if (model.includes("iMac")) {
    return imac;
  }
  if (model.includes("iPhone")) {
    return iphone;
  }
  if (model.includes("MacBook Air")) {
    return macbookAir;
  }
  if (model.includes("MacBook Pro")) {
    return macbookPro;
  }
  if (model.includes("Mac mini")) {
    return macmini;
  }
  return generic;
}
</script>

<template>
  <v-row :class="mobile ? '' : 'mt-3'">
    <v-spacer></v-spacer>
    <v-col class="text-center">
      <v-img :src="setDeviceImage(data.panel.model)" :width="150"></v-img>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <v-row>
    <v-col class="text-center">
      <strong>Serial: </strong>{{ data.panel.serial }}<br />
      <strong>Model: </strong>{{ data.panel.model }}<br />
      <template v-if="data.panel.cpu"
        ><strong>CPU: </strong>{{ data.panel.cpu }}<br
      /></template>
      <template v-if="data.panel.ram"
        ><strong>RAM: </strong>{{ data.panel.ram }}<br
      /></template>
      <template v-if="data.panel.storage"
        ><strong>Storage:</strong>{{ data.panel.storage }}</template
      >
    </v-col>
  </v-row>
</template>
