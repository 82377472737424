<script setup>
import { defineProps, defineEmits, computed } from "vue";
const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    return emit("update:modelValue", value);
  },
});
</script>

<template>
  <v-sheet class="d-flex">
    <v-sheet class="pa-1">30 Days</v-sheet>
    <v-sheet align-self-center
      ><v-switch density="compact" v-model="value"></v-switch
    ></v-sheet>
    <v-sheet class="pa-1" align-self-center>All</v-sheet>
    <v-sheet class="px-1">
      <v-btn icon size="small" variant="outline" density="compact">
        <v-icon>mdi-help-circle-outline</v-icon>
        <v-tooltip
          activator="parent"
          location="start"
          text="Choose 30 days to only show devices that have been online in the last 30 days."
        >
        </v-tooltip
      ></v-btn>
    </v-sheet>
  </v-sheet>
</template>
