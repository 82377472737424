<script setup>
import { apiQuery } from "@/api/j24api";
import { onMounted, reactive, ref } from "vue";

const licenseConfig =
  '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd"><plist version="1"><dict><key>PayloadUUID</key><string>1E53984D-1888-4AD5-A8C1-5B2DAB5DFEF6</string><key>PayloadType</key><string>Configuration</string><key>PayloadOrganization</key><string>Jigsaw24</string><key>PayloadIdentifier</key><string>1E53984D-1888-4AD5-A8C1-5B2DAB5DFEF6</string><key>PayloadDisplayName</key><string>Elevate24 License</string><key>PayloadDescription</key><string/><key>PayloadVersion</key><integer>1</integer><key>PayloadEnabled</key><true/><key>PayloadRemovalDisallowed</key><true/><key>PayloadScope</key><string>System</string><key>PayloadContent</key><array><dict><key>PayloadDisplayName</key><string>Custom Settings</string><key>PayloadIdentifier</key><string>80AAF18C-E638-4833-A387-7B9EC50888B1</string><key>PayloadOrganization</key><string>Jigsaw24</string><key>PayloadType</key><string>com.apple.ManagedClient.preferences</string><key>PayloadUUID</key><string>80AAF18C-E638-4833-A387-7B9EC50888B1</string><key>PayloadVersion</key><integer>1</integer><key>PayloadContent</key><dict><key>com.jigsaw24.Elevate24</key><dict><key>Forced</key><array><dict><key>mcx_preference_settings</key><dict><key>LicenseAPIKey</key><string>**KEY**</string><key>LicenseKey</key><string>**LICENSE**</string></dict></dict></array></dict></dict></dict></array></dict></plist>';
const notificationsConfig =
  '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd"><plist version="1"><dict><key>PayloadUUID</key><string>E4B7EA8C-C9A8-4D8E-8B01-3A2E6FB87AF6</string><key>PayloadType</key><string>Configuration</string><key>PayloadOrganization</key><string>Jigsaw24</string><key>PayloadIdentifier</key><string>E4B7EA8C-C9A8-4D8E-8B01-3A2E6FB87AF6</string><key>PayloadDisplayName</key><string>Elevate24v3 Notifications</string><key>PayloadDescription</key><string/><key>PayloadVersion</key><integer>1</integer><key>PayloadEnabled</key><true/><key>PayloadRemovalDisallowed</key><true/><key>PayloadScope</key><string>System</string><key>PayloadContent</key><array><dict><key>PayloadDisplayName</key><string>Notifications Payload</string><key>PayloadIdentifier</key><string>13A30032-CF0B-455D-AFA5-0CF4EB69D4FB</string><key>PayloadOrganization</key><string>Jigsaw24</string><key>PayloadType</key><string>com.apple.notificationsettings</string><key>PayloadUUID</key><string>1459E263-3331-4FC5-A536-0648F5C319B9</string><key>PayloadVersion</key><integer>1</integer><key>NotificationSettings</key><array><dict><key>AlertType</key><integer>1</integer><key>BundleIdentifier</key><string>com.jigsaw24.Elevate24</string><key>NotificationsEnabled</key><true/><key>SoundsEnabled</key><true/></dict></array></dict></array></dict></plist>';
const loading = ref(true);
const alertTxt = ref("");
const licences = reactive([]);

function downloadNotificationConfig() {
  downloadFile("Elevate24 Notifications.mobileconfig", notificationsConfig);
}

function downloadLicenseConfig(license) {
  var mobileconfig = licenseConfig.replace("**KEY**", license.key);
  mobileconfig = mobileconfig.replace("**LICENSE**", license.licence);
  downloadFile("Elevate24 License.mobileconfig", mobileconfig);
}

function downloadFile(filename, content) {
  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
}

async function getLicence() {
  loading.value = true;
  try {
    const result = await apiQuery("ELA", "elevatereports", {
      apiAction: "GetLicenceKeys",
    });
    if (!result) {
      alertTxt.value = "No Licences Found";
    }
    if (result.length) {
      licences.length = 0;
      licences.push(...result);
    }
  } catch (error) {
    alertTxt.value = "Error Loading Licences";
    console.log(error);
  }
  loading.value = false;
}

const gotDownloadLink = ref(false);
async function getDownloadLink() {
  try {
    gotDownloadLink.value = false;
    const result = await apiQuery("ELA", "elevatereports", {
      apiAction: "GetDownloadLink",
    });
    softwareDownloadLink.value = result.url;
    softwareVersion.value = result.version;
    gotDownloadLink.value = true;
  } catch (error) {
    console.log(error);
    alertTxt.value = "Unable to get download link";
  }
}

onMounted(() => {
  //getDownloadLink();
  getLicence();
});

const softwareDownloadLink = ref("");
const softwareVersion = ref("");
const acceptEULA = ref(false);
async function acceptedEULA() {
  try {
    if (acceptEULA.value) {
      console.log("Accepted EULA");
      const result = await apiQuery("ELA", "elevatereports", {
        apiAction: "AcceptEULA",
      });
      getDownloadLink();
    }
  } catch (error) {
    console.log(error);
    alertTxt.value = "Unable to get download link";
  }
}
</script>

<template>
  <v-alert color="error" v-if="alertTxt">{{ alertTxt }}</v-alert>
  <template v-if="loading">
    <v-row>
      <v-spacer></v-spacer>
      <v-col class="text-center pt-11">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>
  <template v-else>
    <v-card class="mb-3">
      <v-card-title class="bg-grey-lighten-2"
        >Elevate24 Software Download</v-card-title
      >
      <v-card-text class="pt-3">
        <div v-if="!acceptEULA">
          In order to download this software you must accept the terms of the
          End User License Agreement (EULA). This can be viewed
          <a
            href="/END USER LICENSE AGREEMENT FOR PRIVILEGE ACCESS MANAGEMENT SOFTWARE FOR MACOS (May-23).pdf"
            target="_blank"
            >here</a
          >
          <br />
          <v-checkbox
            @update:focused="acceptedEULA()"
            label="Accept EULA"
            v-model="acceptEULA"
            hide-details
          ></v-checkbox>
        </div>
        <div v-if="acceptEULA && gotDownloadLink">
          The latest version of the Elevate software can be downloaded
          <a :href="softwareDownloadLink">here</a>. The latest version is
          {{ softwareVersion }}.
        </div>
        <div v-if="acceptEULA && !gotDownloadLink">
          <v-progress-circular indeterminate></v-progress-circular> Getting
          Download Link
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mb-3">
      <v-card-title class="bg-grey-lighten-2"> Documentation </v-card-title>
      <v-card-text class="pt-3">
        <div>
          A deployment and configuration guide can be downloaded from
          <a
            href="/Elevate24 Deployment and Configuration Guide.pdf"
            target="_blank"
            >here</a
          >.
        </div>
      </v-card-text>
    </v-card>
    <template v-for="licence in licences" :key="licence._id">
      <v-card class="mb-3">
        <v-card-title class="bg-grey-lighten-2">{{
          licence.name
        }}</v-card-title>
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Configuration File</th>
              <td class="py-3">
                <v-btn @click="downloadLicenseConfig(licence)"
                  >Download License Configuration Profile</v-btn
                >
                <br />
                <v-btn class="mt-3" @click="downloadNotificationConfig()"
                  >Download Notifications Configuration Profile</v-btn
                >
              </td>
            </tr>
            <tr>
              <th>Key</th>
              <td>
                <v-text-field v-model="licence.key" readonly></v-text-field>
              </td>
            </tr>
            <tr>
              <th>Licence</th>
              <td>
                <v-textarea v-model="licence.licence" readonly></v-textarea>
              </td>
            </tr>
            <tr>
              <th>Licenced Devices</th>
              <td>
                <v-text-field v-model="licence.count" readonly></v-text-field>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <th>Licence Type</th>
              <td>
                <v-text-field v-model="licence.type" readonly></v-text-field>
              </td>
            </tr>
            <tr>
              <th>Start Date</th>
              <td>
                <v-text-field
                  v-model="licence.startdate"
                  readonly
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <th>End Date</th>
              <td>
                <v-text-field v-model="licence.enddate" readonly></v-text-field>
              </td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </template>
  </template>
</template>
