<script setup>
import { reactive, ref, watch, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const loading = ref(false);
const alert = ref("");
const saving = ref(false);
const displayConfirmDelete = ref(false);

const dataSources = reactive([]);
const dataSource = ref({});
const addDataSource = ref(false);
const editDataSource = ref(false);
const searchValue = ref("");
const serviceNowTypes = ["None", "Case", "Request"];
const ruleLogic = ["and", "or"];
const chooseMDMServers = ref(false);
const mdmServers = reactive([]);
var ruleData = ref({});
ruleData.value.criteria = [{ type: "", field: "", is: "", value: "" }];

const deviceFields = [
  "general.name",
  "general.assetTag",
  "general.lastEnrolledDate",
  "general.lastContactTime",
  "operatingSystem.version",
  "operatingSystem.name",
  "hardware.modelIdentifier",
  "hardware.serialNumber",
  "hardware.model",
  "userAndLocation.username",
  "userAndLocation.realname",
  "userAndLocation.email",
  "userAndLocation.position",
  "defender.DeviceId",
  "defender.DeviceName",
  "defender.DeviceState",
  "defender.DeviceState_loc",
  "defender.MalwareProtectionEnabled",
  "defender.RealTimeProtectionEnabled",
  "defender.NetworkInspectionSystemEnabled",
  "defender.SignatureUpdateOverdue",
  "defender.LastReportedDateTime",
  "defender.UserEmail",
  "defender.UserName",
  "defender.ProductStatus",
  "defender.IsVirtualMachine",
  "defender.TamperProtectionEnabled",
  "defender._ManagedBy",
  "defender._ManagedBy_loc",
  "detail.id",
  "detail.userId",
  "detail.deviceName",
  "detail.ownerType",
  "detail.managedDeviceOwnerType",
  "detail.managementState",
  "detail.enrolledDateTime",
  "detail.lastSyncDateTime",
  "detail.chassisType",
  "detail.operatingSystem",
  "detail.deviceType",
  "detail.complianceState",
  "detail.jailBroken",
  "detail.managementAgent",
  "detail.osVersion",
  "detail.easActivated",
  "detail.easDeviceId",
  "detail.easActivationDateTime",
  "detail.aadRegistered",
  "detail.azureADRegistered",
  "detail.deviceEnrollmentType",
  "detail.lostModeState",
  "detail.activationLockBypassCode",
  "detail.emailAddress",
  "detail.azureActiveDirectoryDeviceId",
  "detail.azureADDeviceId",
  "detail.deviceRegistrationState",
  "detail.deviceCategoryDisplayName",
  "detail.isSupervised",
  "detail.exchangeLastSuccessfulSyncDateTime",
  "detail.exchangeAccessState",
  "detail.exchangeAccessStateReason",
  "detail.remoteAssistanceSessionUrl",
  "detail.remoteAssistanceSessionErrorDetails",
  "detail.isEncrypted",
  "detail.userPrincipalName",
  "detail.model",
  "detail.manufacturer",
  "detail.imei",
  "detail.complianceGracePeriodExpirationDateTime",
  "detail.serialNumber",
  "detail.phoneNumber",
  "detail.androidSecurityPatchLevel",
  "detail.userDisplayName",
  "detail.configurationManagerClientEnabledFeatures",
  "detail.wiFiMacAddress",
  "detail.deviceHealthAttestationState",
  "detail.subscriberCarrier",
  "detail.meid",
  "detail.totalStorageSpaceInBytes",
  "detail.freeStorageSpaceInBytes",
  "detail.managedDeviceName",
  "detail.partnerReportedThreatState",
  "detail.retireAfterDateTime",
  "detail.preferMdmOverGroupPolicyAppliedDateTime",
  "detail.autopilotEnrolled",
  "detail.requireUserEnrollmentApproval",
  "detail.managementCertificateExpirationDate",
  "detail.iccid",
  "detail.udid",
  "detail.windowsActiveMalwareCount",
  "detail.windowsRemediatedMalwareCount",
  "detail.notes",
  "detail.configurationManagerClientHealthState",
  "detail.configurationManagerClientInformation",
  "detail.ethernetMacAddress",
  "detail.physicalMemoryInBytes",
  "detail.processorArchitecture",
  "detail.specificationVersion",
  "detail.joinType",
  "detail.skuFamily",
  "detail.skuNumber",
  "detail.managementFeatures",
  "detail.enrollmentProfileName",
  "detail.bootstrapTokenEscrowed",
  "detail.deviceFirmwareConfigurationInterfaceManaged",
  "detail.hardwareInformation.serialNumber",
  "detail.hardwareInformation.totalStorageSpace",
  "detail.hardwareInformation.freeStorageSpace",
  "detail.hardwareInformation.imei",
  "detail.hardwareInformation.meid",
  "detail.hardwareInformation.manufacturer",
  "detail.hardwareInformation.model",
  "detail.hardwareInformation.phoneNumber",
  "detail.hardwareInformation.subscriberCarrier",
  "detail.hardwareInformation.cellularTechnology",
  "detail.hardwareInformation.wifiMac",
  "detail.hardwareInformation.operatingSystemLanguage",
  "detail.hardwareInformation.isSupervised",
  "detail.hardwareInformation.isEncrypted",
  "detail.hardwareInformation.batterySerialNumber",
  "detail.hardwareInformation.batteryHealthPercentage",
  "detail.hardwareInformation.batteryChargeCycles",
  "detail.hardwareInformation.isSharedDevice",
  "detail.hardwareInformation.tpmSpecificationVersion",
  "detail.hardwareInformation.operatingSystemEdition",
  "detail.hardwareInformation.deviceFullQualifiedDomainName",
  "detail.hardwareInformation.deviceGuardVirtualizationBasedSecurityHardwareRequirementState",
  "detail.hardwareInformation.deviceGuardVirtualizationBasedSecurityState",
  "detail.hardwareInformation.deviceGuardLocalSystemAuthorityCredentialGuardState",
  "detail.hardwareInformation.osBuildNumber",
  "detail.hardwareInformation.operatingSystemProductType",
  "detail.hardwareInformation.ipAddressV4",
  "detail.hardwareInformation.subnetAddress",
  "detail.hardwareInformation.esimIdentifier",
  "detail.hardwareInformation.systemManagementBIOSVersion",
  "detail.hardwareInformation.tpmManufacturer",
  "detail.hardwareInformation.tpmVersion",
  "detail.hardwareInformation.batteryLevelPercentage",
  "detail.hardwareInformation.residentUsersCount",
  "detail.hardwareInformation.productName",
  "detail.hardwareInformation.deviceLicensingStatus",
  "detail.hardwareInformation.deviceLicensingLastErrorCode",
  "detail.hardwareInformation.deviceLicensingLastErrorDescription",
  "detail.hardwareInformation.sharedDeviceCachedUsers",
  "detail.usersLoggedOn.userId",
  "detail.usersLoggedOn.lastLogOnDateTime",
  "detail.applications.name",
  "detail.applications.version",
  "detail.applications.shortVersion",
  "detail.applications.identifier",
  "detail.applications.path",
];

const intuneSearchFieldsFull = [
  { title: "Name", value: "general.name" },
  { title: "Asset Tag", value: "general.assetTag" },
  { title: "Last Enrolled Date", value: "general.lastEnrolledDate" },
  { title: "Last Contact Time", value: "general.lastContactTime" },
  { title: "Version", value: "operatingSystem.version" },
  { title: "Name", value: "operatingSystem.name" },
  { title: "Model Identifier", value: "hardware.modelIdentifier" },
  { title: "Serial Number", value: "hardware.serialNumber" },
  { title: "Model", value: "hardware.model" },
  { title: "Username", value: "userAndLocation.username" },
  { title: "Realname", value: "userAndLocation.realname" },
  { title: "Email", value: "userAndLocation.email" },
  { title: "Position", value: "userAndLocation.position" },
  { title: "Id", value: "detail.id" },
  { title: "User Id", value: "detail.userId" },
  { title: "Device Name", value: "detail.deviceName" },
  { title: "Owner Type", value: "detail.ownerType" },
  {
    title: "Managed Device Owner Type",
    value: "detail.managedDeviceOwnerType",
  },
  { title: "Management State", value: "detail.managementState" },
  { title: "Enrolled Date Time", value: "detail.enrolledDateTime" },
  { title: "Last Sync Date Time", value: "detail.lastSyncDateTime" },
  { title: "Chassis Type", value: "detail.chassisType" },
  { title: "Operating System", value: "detail.operatingSystem" },
  { title: "Device Type", value: "detail.deviceType" },
  { title: "Compliance State", value: "detail.complianceState" },
  { title: "Jail Broken", value: "detail.jailBroken" },
  { title: "Management Agent", value: "detail.managementAgent" },
  { title: "Os Version", value: "detail.osVersion" },
  { title: "Eas Activated", value: "detail.easActivated" },
  { title: "Eas Device Id", value: "detail.easDeviceId" },
  { title: "Eas Activation Date Time", value: "detail.easActivationDateTime" },
  { title: "Aad Registered", value: "detail.aadRegistered" },
  { title: "Azure AD Registered", value: "detail.azureADRegistered" },
  { title: "Device Enrollment Type", value: "detail.deviceEnrollmentType" },
  { title: "Lost Mode State", value: "detail.lostModeState" },
  {
    title: "Activation Lock Bypass Code",
    value: "detail.activationLockBypassCode",
  },
  { title: "Email Address", value: "detail.emailAddress" },
  {
    title: "Azure Active Directory Device Id",
    value: "detail.azureActiveDirectoryDeviceId",
  },
  { title: "Azure AD Device Id", value: "detail.azureADDeviceId" },
  {
    title: "Device Registration State",
    value: "detail.deviceRegistrationState",
  },
  {
    title: "Device Category Display Name",
    value: "detail.deviceCategoryDisplayName",
  },
  { title: "Is Supervised", value: "detail.isSupervised" },
  {
    title: "Exchange Last Successful Sync Date Time",
    value: "detail.exchangeLastSuccessfulSyncDateTime",
  },
  { title: "Exchange Access State", value: "detail.exchangeAccessState" },
  {
    title: "Exchange Access State Reason",
    value: "detail.exchangeAccessStateReason",
  },
  {
    title: "Remote Assistance Session Url",
    value: "detail.remoteAssistanceSessionUrl",
  },
  {
    title: "Remote Assistance Session Error Details",
    value: "detail.remoteAssistanceSessionErrorDetails",
  },
  { title: "Is Encrypted", value: "detail.isEncrypted" },
  { title: "User Principal Name", value: "detail.userPrincipalName" },
  { title: "Model", value: "detail.model" },
  { title: "Manufacturer", value: "detail.manufacturer" },
  { title: "Imei", value: "detail.imei" },
  {
    title: "Compliance Grace Period Expiration Date Time",
    value: "detail.complianceGracePeriodExpirationDateTime",
  },
  { title: "Serial Number", value: "detail.serialNumber" },
  { title: "Phone Number", value: "detail.phoneNumber" },
  {
    title: "Android Security Patch Level",
    value: "detail.androidSecurityPatchLevel",
  },
  { title: "User Display Name", value: "detail.userDisplayName" },
  {
    title: "Configuration Manager Client Enabled Features",
    value: "detail.configurationManagerClientEnabledFeatures",
  },
  { title: "Wi Fi Mac Address", value: "detail.wiFiMacAddress" },
  {
    title: "Device Health Attestation State",
    value: "detail.deviceHealthAttestationState",
  },
  { title: "Subscriber Carrier", value: "detail.subscriberCarrier" },
  { title: "Meid", value: "detail.meid" },
  {
    title: "Total Storage Space In Bytes",
    value: "detail.totalStorageSpaceInBytes",
  },
  {
    title: "Free Storage Space In Bytes",
    value: "detail.freeStorageSpaceInBytes",
  },
  { title: "Managed Device Name", value: "detail.managedDeviceName" },
  {
    title: "Partner Reported Threat State",
    value: "detail.partnerReportedThreatState",
  },
  { title: "Retire After Date Time", value: "detail.retireAfterDateTime" },
  {
    title: "Prefer Mdm Over Group Policy Applied Date Time",
    value: "detail.preferMdmOverGroupPolicyAppliedDateTime",
  },
  { title: "Autopilot Enrolled", value: "detail.autopilotEnrolled" },
  {
    title: "Require User Enrollment Approval",
    value: "detail.requireUserEnrollmentApproval",
  },
  {
    title: "Management Certificate Expiration Date",
    value: "detail.managementCertificateExpirationDate",
  },
  { title: "Iccid", value: "detail.iccid" },
  { title: "Udid", value: "detail.udid" },
  {
    title: "Windows Active Malware Count",
    value: "detail.windowsActiveMalwareCount",
  },
  {
    title: "Windows Remediated Malware Count",
    value: "detail.windowsRemediatedMalwareCount",
  },
  { title: "Notes", value: "detail.notes" },
  {
    title: "Configuration Manager Client Health State",
    value: "detail.configurationManagerClientHealthState",
  },
  {
    title: "Configuration Manager Client Information",
    value: "detail.configurationManagerClientInformation",
  },
  { title: "Ethernet Mac Address", value: "detail.ethernetMacAddress" },
  { title: "Physical Memory In Bytes", value: "detail.physicalMemoryInBytes" },
  { title: "Processor Architecture", value: "detail.processorArchitecture" },
  { title: "Specification Version", value: "detail.specificationVersion" },
  { title: "Join Type", value: "detail.joinType" },
  { title: "Sku Family", value: "detail.skuFamily" },
  { title: "Sku Number", value: "detail.skuNumber" },
  { title: "Management Features", value: "detail.managementFeatures" },
  { title: "Enrollment Profile Name", value: "detail.enrollmentProfileName" },
  { title: "Bootstrap Token Escrowed", value: "detail.bootstrapTokenEscrowed" },
  {
    title: "Device Firmware Configuration Interface Managed",
    value: "detail.deviceFirmwareConfigurationInterfaceManaged",
  },
  { title: "Serial Number", value: "detail.hardwareInformation.serialNumber" },
  {
    title: "Total Storage Space",
    value: "detail.hardwareInformation.totalStorageSpace",
  },
  {
    title: "Free Storage Space",
    value: "detail.hardwareInformation.freeStorageSpace",
  },
  { title: "Imei", value: "detail.hardwareInformation.imei" },
  { title: "Meid", value: "detail.hardwareInformation.meid" },
  { title: "Manufacturer", value: "detail.hardwareInformation.manufacturer" },
  { title: "Model", value: "detail.hardwareInformation.model" },
  { title: "Phone Number", value: "detail.hardwareInformation.phoneNumber" },
  {
    title: "Subscriber Carrier",
    value: "detail.hardwareInformation.subscriberCarrier",
  },
  {
    title: "Cellular Technology",
    value: "detail.hardwareInformation.cellularTechnology",
  },
  { title: "Wifi Mac", value: "detail.hardwareInformation.wifiMac" },
  {
    title: "Operating System Language",
    value: "detail.hardwareInformation.operatingSystemLanguage",
  },
  { title: "Is Supervised", value: "detail.hardwareInformation.isSupervised" },
  { title: "Is Encrypted", value: "detail.hardwareInformation.isEncrypted" },
  {
    title: "Battery Serial Number",
    value: "detail.hardwareInformation.batterySerialNumber",
  },
  {
    title: "Battery Health Percentage",
    value: "detail.hardwareInformation.batteryHealthPercentage",
  },
  {
    title: "Battery Charge Cycles",
    value: "detail.hardwareInformation.batteryChargeCycles",
  },
  {
    title: "Is Shared Device",
    value: "detail.hardwareInformation.isSharedDevice",
  },
  {
    title: "Tpm Specification Version",
    value: "detail.hardwareInformation.tpmSpecificationVersion",
  },
  {
    title: "Operating System Edition",
    value: "detail.hardwareInformation.operatingSystemEdition",
  },
  {
    title: "Device Full Qualified Domain Name",
    value: "detail.hardwareInformation.deviceFullQualifiedDomainName",
  },
  {
    title:
      "Device Guard Virtualization Based Security Hardware Requirement State",
    value:
      "detail.hardwareInformation.deviceGuardVirtualizationBasedSecurityHardwareRequirementState",
  },
  {
    title: "Device Guard Virtualization Based Security State",
    value:
      "detail.hardwareInformation.deviceGuardVirtualizationBasedSecurityState",
  },
  {
    title: "Device Guard Local System Authority Credential Guard State",
    value:
      "detail.hardwareInformation.deviceGuardLocalSystemAuthorityCredentialGuardState",
  },
  {
    title: "Os Build Number",
    value: "detail.hardwareInformation.osBuildNumber",
  },
  {
    title: "Operating System Product Type",
    value: "detail.hardwareInformation.operatingSystemProductType",
  },
  { title: "IP Address V4", value: "detail.hardwareInformation.ipAddressV4" },
  {
    title: "Subnet Address",
    value: "detail.hardwareInformation.subnetAddress",
  },
  {
    title: "Esim Identifier",
    value: "detail.hardwareInformation.esimIdentifier",
  },
  {
    title: "System Management BIOS Version",
    value: "detail.hardwareInformation.systemManagementBIOSVersion",
  },
  {
    title: "Tpm Manufacturer",
    value: "detail.hardwareInformation.tpmManufacturer",
  },
  { title: "Tpm Version", value: "detail.hardwareInformation.tpmVersion" },
  {
    title: "Battery Level Percentage",
    value: "detail.hardwareInformation.batteryLevelPercentage",
  },
  {
    title: "Resident Users Count",
    value: "detail.hardwareInformation.residentUsersCount",
  },
  { title: "Product Name", value: "detail.hardwareInformation.productName" },
  {
    title: "Device Licensing Status",
    value: "detail.hardwareInformation.deviceLicensingStatus",
  },
  {
    title: "Device Licensing Last Error Code",
    value: "detail.hardwareInformation.deviceLicensingLastErrorCode",
  },
  {
    title: "Device Licensing Last Error Description",
    value: "detail.hardwareInformation.deviceLicensingLastErrorDescription",
  },
  {
    title: "Shared Device Cached Users",
    value: "detail.hardwareInformation.sharedDeviceCachedUsers",
  },
  { title: "User Id", value: "detail.usersLoggedOn.userId" },
  {
    title: "Last Log On Date Time",
    value: "detail.usersLoggedOn.lastLogOnDateTime",
  },
  { title: "Name", value: "detail.applications.name" },
  { title: "Version", value: "detail.applications.version" },
  { title: "Short Version", value: "detail.applications.shortVersion" },
  { title: "Identifier", value: "detail.applications.identifier" },
  { title: "Path", value: "detail.applications.path" },
];

var rowInfo = reactive([{ field: "", is: "", value: "" }]);

const intuneSearchFields1 = [
  {
    title: "All Computers",
    value: "Computers",
  },
  {
    title: "Windows Devices",
    value: "Windows",
  },
  { title: "macOS Devices", value: "Apple" },
  { title: "All Devices ", value: "Devices" },
  { title: "IOS Devices ", value: "ios" },
  { title: "Android Devices ", value: "android" },
];

const datasourceTypes = [
  {
    title: "All Computers",
    value: "Computers",
  },
  {
    title: "Windows Devices",
    value: "Windows",
  },
  { title: "macOS Devices", value: "Apple" },
  { title: "All Devices ", value: "Devices" },
  { title: "IOS Devices ", value: "ios" },
  { title: "Android Devices ", value: "android" },
];

const headers = [
  { text: "Data Type", value: "type", sortable: true },
  { text: "Data Name", value: "name", sortable: true },
  { text: "Global", value: "global", sortable: true },
  { text: "Actions", value: "actions" },
];

function addNewPressed() {
  addDataSource.value = true;
  dataSource.value.global = true;
  ruleData.value.criteria = [{ type: "", field: "", is: "", value: "" }];
  dataSource.value.alert = {};
  dataSource.value.alert.enabled = false;
  dataSource.value.alert.operator = "";
  dataSource.value.alert.value = "";
  dataSource.value.warn = {};
  dataSource.value.warn.enabled = false;
  dataSource.value.warn.operator = "";
  dataSource.value.warn.value = "";
}

async function getMDMServers() {
  if (mdmServers.length == 0) {
    const result = await apiQuery("J24Auth", "elaadmin/mdmservers", {
      apiAction: "get",
    });
    if (result) {
      result.forEach((mdmServer) => {
        if (mdmServer.type == "Intune") {
          mdmServers.push(mdmServer);
        }
      });
    }
  }
}

async function getDataSources() {
  loading.value = true;
  const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
    apiAction: "getIntune",
  });
  dataSources.length = 0;
  dataSources.push(...result);
  loading.value = false;
}

async function saveDataSource() {
  saving.value = true;
  var apiAction = "createIntune";
  if (editDataSource.value) {
    apiAction = "updateIntune";
  }

  dataSource.value.criteria = ruleData.value.criteria;
  dataSource.value.deleted = false;

  if (!Object.keys(dataSource.value).includes("warn")) {
    dataSource.value.warn = {};
    dataSource.value.warn.enabled = false;
  }

  if (!Object.keys(dataSource.value).includes("alert")) {
    dataSource.value.alert = {};
    dataSource.value.alert.enabled = false;
  }
  if (!Object.keys(dataSource.value).includes("chart")) {
    dataSource.value.chart = "count";
  }

  if (!Object.keys(dataSource.value).includes("excludedCustomerId")) {
    dataSource.value.excludedCustomerId = [];
  }

  if (dataSource.value.global == true) {
    dataSource.value.customerId = ["*"];
  }

  try {
    const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
      apiAction: apiAction,
      IntuneDataSource: dataSource.value,
    });
    editDataSource.value = false;
    addDataSource.value = false;
    loading.value = true;
    dataSource.value = {};
    getDataSources();
  } catch (error) {
    alert.value = "Error saving record";
    console.log(error);
  }

  saving.value = false;
}

async function deleteItem(item) {
  dataSource.value = item;
  displayConfirmDelete.value = true;
}

async function confirmDelete() {
  try {
    loading.value = true;
    displayConfirmDelete.value = false;
    const result = await apiQuery("ELA", "dashboard/jamfdatasources", {
      apiAction: "deleteIntune",
      IntuneDataSource: dataSource.value,
    });
    getDataSources();
  } catch (error) {
    alert.value = "Error Deleting record";
    console.log(error);
    loading.value = false;
  }
}

function resetDataSource() {
  dataSource.value = {};
  editDataSource.value = false;
}

function editItem(item) {
  editDataSource.value = true;
  dataSource.value = item;

  // ruleData.value.criteria = dataSource.value.criteria;
  ruleData.value.criteria = [];
  rowInfo.length = 0;
  for (var rule of dataSource.value.criteria) {
    rowInfo.push({ type: "", field: "", is: "", value: "" });
    ruleData.value.criteria.push(rule);
  }
  if (dataSource.value.customerId[0] == "*") {
    dataSource.value.global = true;
  }
  addDataSource.value = true;
}

function addRow() {
  rowInfo.push({ type: "", field: "", is: "", value: "" });
  ruleData.value.criteria.push({ type: "", field: "", is: "", value: "" });
}

function removeRow(item) {
  rowInfo.splice(item, 1);
}

function setGlobal(event) {
  if (dataSource.value.global == true) {
    dataSource.value.customerId.length = 0;
    dataSource.value.excludedCustomerId.length = 0;
  } else {
    dataSource.value.customerId.length = 0;
    dataSource.value.customerId.push("*");
  }
}

onMounted(() => {
  getDataSources();
  getMDMServers();
});

const alertOperators = ["<", "=", ">", "!="];
</script>

<template>
  <template v-if="addDataSource">
    <v-select
      density="compact"
      :items="datasourceTypes"
      label="Data Source Type"
      v-model="dataSource.type"
      item-value="value"
      item-text="title"
    ></v-select>
    <v-text-field
      density="compact"
      v-model="dataSource.name"
      label="Name of Object"
    />

    <v-row no-gutters
      ><v-col cols="3">
        <v-checkbox
          density="compact"
          v-model="dataSource.global"
          label="Global Data Source"
          @click="setGlobal(event)"
        />
      </v-col>
      <v-col cols="7">
        <v-select
          v-if="!dataSource.global"
          density="compact"
          v-model="dataSource.customerId"
          :items="mdmServers"
          item-value="_id"
          item-title="name"
          label="Select MDM Servers to collect data from"
          no-data-text="Loading please wait..."
          multiple
          chips
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-select
      v-if="dataSource.global"
      density="compact"
      v-model="dataSource.excludedCustomerId"
      :items="mdmServers"
      item-value="_id"
      item-title="name"
      label="Select MDM Servers to exclude"
      no-data-text="Loading please wait..."
      multiple
      chips
    ></v-select>
    <v-checkbox
      density="compact"
      v-model="dataSource.SummariseData"
      label="Summarise Data"
    />

    <h3>Search Configuration</h3>
    <template v-for="(item, index) in rowInfo" v-bind:key="item">
      <template v-if="index > 0 || rowInfo.count > 1">
        <v-row>
          <v-col>
            <v-select
              label="And/Or"
              density="compact"
              :items="ruleLogic"
              v-model="ruleData.criteria[index].type"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col cols="4">
          <v-autocomplete
            label="Field"
            density="compact"
            eager="true"
            :items="deviceFields"
            v-model="ruleData.criteria[index].field"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-select
            label="value is"
            density="compact"
            :items="alertOperators"
            v-model="ruleData.criteria[index].is"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            density="compact"
            v-model="ruleData.criteria[index].value"
            label="Static Value"
          />
        </v-col>
        <v-col cols="1">
          <template v-if="index > 0">
            <v-icon class="align-center" color="" @click="removeRow(index)"
              >mdi mdi-delete</v-icon
            >
          </template>
        </v-col>
      </v-row>
    </template>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="2">
        <div @click="addRow()">
          Add Row
          <v-icon color="">mdi-plus-box</v-icon>
        </div>
      </v-col>
    </v-row>

    <template v-if="!dataSource.SummariseData">
      <h3>Alert Configuration</h3>
      <v-checkbox
        density="compact"
        v-model="dataSource.alert.enabled"
        label="Enable Alert"
      />
      <v-select
        v-if="dataSource.alert.enabled"
        label="Alert when value is"
        density="compact"
        :items="alertOperators"
        v-model="dataSource.alert.operator"
      ></v-select>

      <v-text-field
        v-if="dataSource.alert.enabled"
        density="compact"
        v-model="dataSource.alert.value"
        label="Static Value"
      />

      <h3>Warning Configuration</h3>
      <v-checkbox
        density="compact"
        v-model="dataSource.warn.enabled"
        label="Enable Warning"
      />
      <v-select
        v-if="dataSource.warn.enabled"
        label="Warn when value is"
        density="compact"
        :items="alertOperators"
        v-model="dataSource.warn.operator"
      ></v-select>
      <v-text-field
        v-if="dataSource.warn.enabled"
        density="compact"
        v-model="dataSource.warn.value"
        label="Static Value"
      />

      <h3>ServiceNow Event</h3>
      <v-select
        label="Choose the type of ServiceNow Event"
        v-model="dataSource.serviceNowType"
        density="compact"
        :items="serviceNowTypes"
        item-title="name"
        item-value="_id"
      ></v-select>
    </template>
    <v-row class="mb-1">
      <v-col cols="2">
        <v-btn
          :disabled="saving"
          size="small"
          @click="
            addDataSource = false;
            resetDataSource();
          "
          >Cancel</v-btn
        ></v-col
      >
      <v-col cols="2">
        <v-btn size="small" :disabled="saving" @click="saveDataSource()">
          Save</v-btn
        >
      </v-col>
      <v-spacer />
    </v-row>
  </template>

  <template v-if="!addDataSource">
    <v-row no-gutters style="height: 35px" class="mt-2 mb-2">
      <v-col class="flex-grow-0 flex-shrink 0" cols="2">
        <v-btn
          height="33px"
          prepend-icon="mdi-upload"
          size="small"
          @click="addNewPressed()"
          >Add New</v-btn
        >
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Search"
          density="compact"
          variant="underlined"
          v-model="searchValue"
          height="25px"
          class="pl-2"
        />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="dataSources"
      theme-color="#e7541e"
      alternating
      buttons-pagination
      :loading="loading"
      search-field="name"
      :search-value="searchValue"
    >
      <template #item-actions="item">
        <div>
          <v-icon @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)" class="ml-1">mdi-delete</v-icon>
        </div>
      </template>
    </EasyDataTable>
  </template>

  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>

  <v-dialog v-model="displayConfirmDelete" persistent
    ><v-card>
      <v-card-title>Confirm Delete Item</v-card-title>
      <v-card-text
        >Please confirm that you wish to delete this item.</v-card-text
      >
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn @click="displayConfirmDelete = false">Cancel</v-btn
        ><v-btn @click="confirmDelete()">Delete</v-btn></v-card-actions
      >
    </v-card></v-dialog
  >

  <!-- <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay> -->
</template>
