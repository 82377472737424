<script setup>
import { ref } from "vue";
import MSPDeploymentLogs from "./MSPDeploymentLogs.vue";
import MSPDeploymentLogsSearch from "./MSPDeploymentLogsSearch.vue";

console.log("hello");
const selectedTab = ref("");
</script>

<template>
  <v-tabs color="#e7541e" v-model="selectedTab">
    <v-tab value="MSPDeploymentLogs">MSP Deployment Logs</v-tab>
    <v-tab value="MSPDeploymentLogsSearch">Search MSP Deployment Logs</v-tab>
  </v-tabs>

  <v-window v-model="selectedTab">
    <v-window-item value="MSPDeploymentLogs"
      ><MSPDeploymentLogs
    /></v-window-item>
    <v-window-item value="MSPDeploymentLogsSearch"
      ><MSPDeploymentLogsSearch
    /></v-window-item>
  </v-window>
</template>
