
import { defineComponent, onMounted } from "vue";
import NavBar from "./components/NavBar.vue";
import { Auth } from "aws-amplify";

Auth.configure({
  region: process.env.AWS_REGION as string,
  userPoolId: process.env.AWS_USER_POOL_ID as string,
  userPoolWebClientId: process.env.AWS_WEB_CLIENT_ID as string,
  oauth: {
    domain: process.env.AWS_OAUTH_DOMAIN as string,
    //scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    scope: ["email", "openid"],
    redirectSignIn: process.env.AWS_OAUTH_REDIRECT_SIGN_IN as string,
    redirectSignOut: process.env.AWS_OAUTH_REDIRECT_SIGN_OUT as string,
    responseType: "code",
  },
});

export default defineComponent({
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      //
    };
  },
  setup() {
    //     const { initializeAuth } = useAuth();
    //     onMounted(() =>
    //       // initializeAuth({
    //       //   domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
    //       //   client_id: process.env.VUE_APP_AUTH0_CLIENTID as string,
    //       //   audience: process.env.VUE_APP_AUTH0_AUDIENCE as string,
    //       //   scope: "openid profile email",
    //       // })
    //     );
  },
});
