function jamfMobileDevice(data) {
  console.log("Jamf Mobile Device");
  console.log("Data", data);
  var page = [];

  page.push(generalFields(data));

  if (data.ios != null) {
    page.push(hardwareFields(data));
    page.push(securityFields(data));
  } else {
    page.push(hardwareTVOS(data));
  }

  {
    return page;
  }

  function securityFields(data) {
    const section = {
      sectionTitle: "Security",
      fields: [],
      expanded: false,
    };
    section.fields.push(
      addField(
        "Activation lock Enabled",
        data.ios?.security?.activationLockEnabled
      )
    );
    /// KANDJI SPECIFIC
    section.fields.push(
      addField(
        "Activation lock Supported",
        data.ios?.security?.activationLockSupported
      )
    );
    section.fields.push(
      addField(
        "Device Activation lock Enabled",
        data.ios?.security?.deviceActivationLockEnabled
      )
    );
    section.fields.push(
      addField(
        "User Activation lock Enabled",
        data.ios?.security?.userActivationLockEnabled
      )
    );
    /// END KANDJI SPECIFIC
    section.fields.push(
      addField("Passcode Set", data.ios?.security?.passcodePresent)
    );

    section.fields = removeEmpty(section.fields);
    return section;
  }

  function generalFields(data) {
    const section = {
      sectionTitle: "General",
      fields: [],
      expanded: true,
    };
    section.fields.push(addField("Serial Number", data.serialNumber));
    section.fields.push(addField("ID", data.id));
    const lastContact = TimeDifference(
      new Date(data.lastInventoryUpdateTimestamp)
    );
    section.fields.push(addField("Last Contact Time", lastContact));
    const lastEnrollment = TimeDifference(
      new Date(data.lastEnrollmentTimestamp)
    );
    section.fields.push(addField("Last Enrolment", lastEnrollment));
    section.fields.push(addField("Enrollment Method", data.enrollmentMethod));

    section.fields = removeEmpty(section.fields);

    if (data.ios != null) {
      section.panel = {
        serial: data.serialNumber,
        model: data.ios.model,
        storage: data.ios.capacityMb / 1024 + " GB",
      };
    }

    return section;
  }

  function hardwareTVOS(data) {
    const section = {
      sectionTitle: "Hardware",
      fields: [],
      expanded: true,
    };
    section.fields.push(addField("Model Name", data.tvos.model));
    section.fields.push(
      addField("Model Identifier", data.tvos.modelIdentifier)
    );
    section.fields.push(addField("UDID", data.udid));
    section.fields.push(addField("MAC Address", data.wifiMacAddress));

    section.fields = removeEmpty(section.fields);
    return section;
  }

  function hardwareFields(data) {
    const section = {
      sectionTitle: "Hardware",
      fields: [],
      expanded: true,
    };
    section.fields.push(addField("Model Name", data.ios.model));
    section.fields.push(addField("Model Identifier", data.ios.modelIdentifier));
    section.fields.push(addField("UDID", data.udid));
    section.fields.push(addField("MAC Address", data.wifiMacAddress));
    section.fields.push(
      addField("Capacity", data.ios.capacityMb / 1024 + " GB")
    );
    section.fields.push(
      addField(
        "Available",
        Math.round((data.ios.availableMb / 1024) * 100) / 100 + " GB"
      )
    );

    section.fields = removeEmpty(section.fields);
    return section;
  }

  function removeEmpty(fields) {
    console.log(fields);
    return fields.filter((field) => field != undefined);
  }

  function addField(name, value, defaultValue = undefined, suffix = "") {
    if (value == undefined && defaultValue != undefined) {
      console.log("Using default value");
      return { name: name, defaultValue };
    }
    if (value != undefined) {
      return { name: name, value: value + suffix };
    }
    //If here not a valid value
  }

  function formatDate(date) {
    if (date == undefined) {
      return undefined;
    }
    let text = "";
    text = new Date(date).toLocaleString("en-GB");
    if (text == "Invalid Date") {
      return "";
    }
    return text;
  }

  function TimeDifference(dateThen) {
    const dateNow = new Date();
    //const dateThen = new Date(dateISO);

    console.log(dateThen);
    var diff = (dateNow.getTime() - dateThen.getTime()) / 1000;
    if (diff < 60) {
      if (Math.ceil(diff) == 1) {
        return "1 Second Ago";
      }
      return Math.ceil(diff) + " Seconds Ago";
    }
    if (diff < 3600) {
      if (Math.ceil(diff) == 60) {
        return "1 Minute Ago";
      }
      return Math.ceil(diff / 60) + " Minutes Ago";
    }
    if (diff < 86400) {
      if (Math.ceil(diff) == 3600) {
        return "1 Hour Ago";
      }
      return Math.ceil(diff / 60 / 60) + " Hours Ago";
    }
    return dateThen.toLocaleString("en-GB");
  }
}

module.exports = jamfMobileDevice;
