<script setup>
import { defineProps, ref, computed } from "vue";
const showPasscode = ref(false);

const props = defineProps({
  passcode: String,
  modelValue: String,
});

const value = computed({
  get() {
    return props.modelValue;
  },
});
</script>

<template>
  <!-- <v-text-field

    :append-icon="showPasscode ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="showPasscode = !showPasscode"
    :type="showPasscode ? 'text' : 'password'"
    variant="plain"
    density="compact"
    v-model="value"
  ></v-text-field> -->
  <v-btn
    v-if="!showPasscode"
    @click="showPasscode = !showPasscode"
    density="compact"
  >
    Show Passcode
  </v-btn>
  <h3 v-else>{{ value }}</h3>
</template>
