<script setup>
import { apiQuery } from "@/api/j24api";
import { ref, reactive, defineProps, watch, onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";

const appBar = appBarStore();
const loading = ref(false);

const props = defineProps({
  data: Object,
});

const latestCISData = reactive([]);

async function getData() {
  loading.value = true;
  try {
    const result = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "agentCISLatest",
      computerID: props.data.id,
    });
    console.log(result);
    if (result.log) {
      latestCISData.push(...result.log);
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

const headers = [
  {
    text: "CIS Reference",
    value: "cis",
  },
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Enforcement Type",
    value: "enforcmentType",
  },
  {
    text: "Passed",
    value: "passed",
  },
];

onMounted(async () => {
  getData();
});
</script>

<template>
  <template v-if="loading">
    <v-row class="pt-6">
      <v-spacer></v-spacer>
      <v-col>
        <v-progress-circular indeterminate size="64" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>

  <EasyDataTable
    class="mt-3"
    v-if="latestCISData.length"
    :items="latestCISData"
    :headers="headers"
  >
    <template #item-passed="item">
      <template v-if="item.passed">
        <v-icon color="green">mdi-check-bold</v-icon>
        <v-tooltip v-if="item.enforcmentType == 'user'">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" color="yellow-darken-2">mdi-alert</v-icon>
          </template>
          User level items are not always<br />
          enforced and may be changed by the user.
        </v-tooltip>
      </template>
      <v-icon v-else color="red">mdi-close-thick</v-icon>
    </template>

    <template #expand="item">
      <div>
        <v-row>
          <v-col cols="6" class="pa-6">
            {{ item.description }}
          </v-col>
        </v-row>
      </div>
    </template>
  </EasyDataTable>
</template>
