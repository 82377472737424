<template>
  <editor api-key="no-api-key" />
</template>

<script>
import { reactive, ref, onMounted, defineProps, defineEmits } from "vue";
import EditorComponent from "@tinymce/tinymce-vue";

//const editor = EditorComponent();

onMounted(() => {
  console.log("MOUNTED!!!");
  //getData();
});

export default {
  name: "App",
  components: {
    editor: EditorComponent,
  },
};
</script>
