<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";
import displayJSONRecursive from "./displayJSONRecursive.vue";

const jamfURL = ref("");
const logs = ref([]);

// MDM Servers
const mdmServers = reactive([]);

async function getMDMServers() {
  if (mdmServers.length == 0) {
    const result = await apiQuery("J24Auth", "elaadmin/mdmservers", {
      apiAction: "get",
    });
    if (result) {
      result.forEach((mdmServer) => {
        mdmServers.push(mdmServer);
      });
    }
  }
}

function getTimestampFromId(id) {
  return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}

const searchButtonDisabled = ref(false);
async function searchPressed() {
  searchButtonDisabled.value = true;
  const result = await apiQuery("ELA", "dashboard/deploymentlogs", {
    apiAction: "getLogs",
    instance: jamfURL.value,
  });
  if (result) {
    logs.value.length = 0;
    result.forEach((event) => {
      event.logsConverted = convertLogsToArray(event.log);
      event.timestamp = getTimestampFromId(event._id);
      event.timestampFormatted = event.timestamp.toLocaleString("en-GB");
      event.numberOfAdditions = event.logsConverted.logs.length;
      console.log(event);
      logs.value.push(event);
    });
  }
  searchButtonDisabled.value = false;
}

function convertLogsToArray(log) {
  var customisations;
  const array = [];
  const keys = Object.keys(log);
  keys.forEach((key) => {
    if (key == "customisations") {
      customisations = log[key];
    } else {
      if (typeof log[key] == "string") {
        const splitLog = log[key].split(/\|/);
        if (splitLog.length == 2) {
          array.push({ name: key, sha: splitLog[0], jamfID: splitLog[1] });
        }
        //console.log(key);
      }
    }
  });
  return { logs: array, customisations: customisations };
}

const hideTable = ref(false);
const showDetail = ref(false);
const headers = [
  { text: "Timestamp", value: "timestamp", sortable: true },
  { text: "Items Added", value: "numberOfAdditions" },
  { text: "Portal User", value: "portaluser", sortable: true },
];

async function showDetailClicked(log) {
  console.log("Show Detail");
  console.log(log);
  showDetail.value = true;
}

const detailObject = ref({});
const detailLoading = ref("");

async function getDetail(item) {
  detailLoading.value = "Loading Please Wait";
  detailObject.value = {};
  showDetail.value = true;
  detailObject.value.name = item.name;
  const content = await apiQuery("ELA", "dashboard/deploymentlogs", {
    apiAction: "getContent",
    sha: item.sha,
    itemName: item.name,
  });
  if (content.data?.content) {
    detailLoading.value = "";
    detailObject.value.decoded = JSON.parse(atob(content.data.content));
    console.log(detailObject.value.decoded);
  } else {
    detailLoading.value = "Failed to Get Content Try Again";
  }
  //console.log(content);
}

onMounted(() => {
  getMDMServers();
});
</script>

<template>
  <template v-if="logs.length == 0">
    <v-row class="pt-2">
      <v-col
        ><v-select
          label="Select MDM Instance"
          :items="mdmServers"
          item-title="name"
          item-value="url"
          no-data-text="Loading please wait..."
          v-model="jamfURL"
      /></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Or Type URL of Jamf Instance (including https://)"
          v-model="jamfURL"
      /></v-col>
    </v-row>
    <v-row class="pb-2">
      <v-col><v-btn @click="searchPressed()">Search</v-btn></v-col>
    </v-row>
  </template>

  <template v-if="showDetail">
    <v-row
      ><v-col
        ><h4>{{ detailObject.name }}</h4></v-col
      >
      <v-col>{{ detailLoading }}</v-col>
      <v-col class="text-right"
        ><v-icon @click="showDetail = false">mdi-close</v-icon></v-col
      ></v-row
    >
    <v-row class="pa-2">
      <v-col>
        <template v-if="detailObject.decoded">
          <displayJSONRecursive :object="detailObject.decoded" />
        </template>
      </v-col>
    </v-row>
  </template>

  <template v-if="logs.length && !hideTable">
    <EasyDataTable
      :headers="headers"
      :items="logs"
      alternating
      buttons-pagination
      theme-color="#e7541e"
      :disabled="searchButtonDisabled"
    >
      <template #expand="item">
        <v-row
          ><v-col>
            <v-table class="text-left">
              <thead>
                <th>Name</th>
                <th>JamfID</th>
                <th>SHA</th>
              </thead>
              <template
                v-for="objectAdded in item.logsConverted.logs"
                :key="objectAdded"
              >
                <tr class="pt-2 pointer" @click="getDetail(objectAdded)">
                  <td>{{ objectAdded.name }}</td>
                  <td>{{ objectAdded.jamfID }}</td>
                  <td>{{ objectAdded.sha }}</td>
                </tr>
              </template>
            </v-table>
          </v-col></v-row
        >
      </template>
      <template #item-timestamp="{ timestampFormatted }">
        {{ timestampFormatted }}
      </template>
      <template #item-log="{ log }">
        {{ log.length }}
      </template>
    </EasyDataTable>
  </template>
</template>
