<script setup>
import { ref, reactive, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const name = ref("");
const sha = ref("");
const jamfURL = ref("");
const mdmServers = reactive([]);

const logs = reactive([]);

async function search() {
  console.log("Search");
  const result = await apiQuery("ELA", "dashboard/deploymentlogs", {
    apiAction: "searchLogs",
    name: name.value,
    sha: sha.value,
    jamfURL: jamfURL.value,
  });
  if (result) {
    logs.length = 0;
    result.forEach((log) => {
      log.logsConverted = convertLogsToArray(log.log);
      log.timeStamp = getTimestampFromId(log._id);
      log.timestampFormatted = log.timeStamp.toLocaleString("en-GB");
      log.numberOfAdditions = log.logsConverted.logs.length;
      logs.push(log);
    });
  }
  console.log(logs);
}

function convertLogsToArray(log) {
  var customisations;
  const array = [];
  const keys = Object.keys(log);
  keys.forEach((key) => {
    if (key == "customisations") {
      customisations = log[key];
    } else {
      if (typeof log[key] == "string") {
        const splitLog = log[key].split(/\|/);
        if (splitLog.length == 2) {
          array.push({ name: key, sha: splitLog[0], jamfID: splitLog[1] });
        }
        //console.log(key);
      }
    }
  });
  return { logs: array, customisations: customisations };
}

async function getMDMServers() {
  if (mdmServers.length == 0) {
    const result = await apiQuery("J24Auth", "elaadmin/mdmservers", {
      apiAction: "get",
    });
    if (result) {
      result.forEach((mdmServer) => {
        mdmServers.push(mdmServer);
      });
    }
  }
}

onMounted(() => {
  getMDMServers();
});

function getTimestampFromId(id) {
  return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}

const headers = [
  { text: "Timestamp", value: "timestamp", sortable: true },
  { text: "Instance", value: "instance", sortable: true },
  { text: "Items Added", value: "numberOfAdditions", sortable: true },
  { text: "Portal User", value: "portaluser", sortable: true },
];
</script>

<template>
  <v-card v-if="!logs.length" class="pa-2">
    <v-card-title> Search Deployment Logs</v-card-title>
    <v-text-field label="Item Name" v-model="name" />
    <v-text-field label="SHA of Item (optional)" v-model="sha" />
    <v-card class="pa-3">
      <v-card-subtitle>Optionally Choose MDM Server to Search</v-card-subtitle>
      <v-select
        class="pt-2"
        label="MDM Instance (optional)"
        :items="mdmServers"
        item-title="name"
        item-value="url"
        no-data-text="Loading please wait..."
        v-model="jamfURL"
      />
      <v-text-field
        label="(Optional)Type URL of Jamf Instance (including https://)"
        v-model="jamfURL"
      />
    </v-card>
    <v-btn class="mt-3" @click="search()">Search</v-btn>
  </v-card>

  <template v-if="logs.length">
    <v-icon class="pb-3" @click="logs.length = 0">mdi-arrow-left</v-icon>
    <EasyDataTable
      :headers="headers"
      :items="logs"
      alternating
      buttons-pagination
      theme-color="#e7541e"
      :disabled="searchButtonDisabled"
    >
      <template #expand="item">
        <v-row
          ><v-col>
            <v-table class="text-left">
              <thead>
                <th>Name</th>
                <th>JamfID</th>
                <th>SHA</th>
              </thead>
              <template
                v-for="objectAdded in item.logsConverted.logs"
                :key="objectAdded"
              >
                <tr class="pt-2 pointer" @click="getDetail(objectAdded)">
                  <td>{{ objectAdded.name }}</td>
                  <td>{{ objectAdded.jamfID }}</td>
                  <td>{{ objectAdded.sha }}</td>
                </tr>
              </template>
            </v-table>
          </v-col></v-row
        >
      </template>
      <template #item-timestamp="{ timestampFormatted }">
        {{ timestampFormatted }}
      </template>
      <template #item-log="{ log }">
        {{ log.length }}
      </template>
    </EasyDataTable>
  </template>
</template>
