<script setup>
import { Bar } from "vue-chartjs";
import { Line } from "vue-chartjs";
import { reactive, ref, onMounted, defineProps, defineEmits, watch } from "vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  plugins,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler
);

const props = defineProps({
  chartData: Object,
});

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: { yAxes: { suggestedMin: 0, stacked: true } },
  // scales: { xAxes: [{ ticks: { maxRotation: 90, minRotation: 90 } }] },
};
</script>

<template>
  <Line
    :chartData="chartData"
    :height="200"
    :width="400"
    :chart-options="chartOptions"
  />
</template>
