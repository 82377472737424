<script setup>
import { reactive, ref, onMounted, defineProps, watch } from "vue";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import ElaDashboardDrillDown from "../ElaDashboardDrillDown.vue";
import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();
const props = defineProps({
  devices: Object,
  platform: String,
});

const allDevices = reactive([]);
var patchingData = [];
var applicationData = [];
var windowsApplicationData = [];
var platform;
var mdmType;
const summarisedData = reactive([]);
const topApps = reactive([]);
const loading = ref(false);
const filters = ref({
  hideAppStore: false,
  hideBuiltInApps: false,
  showOnlyAppStore: false,
});
const patchingBundleIDs = [];
const daysForFilter = [10, 30, 60, 90];
const filterMenu = ref(false);

watch(
  filters,
  (current) => {
    runFilters(current);
    processData(applicationData, platform);
  },
  { deep: true }
);

function runFilters(filters) {
  // Reset Removed Entries
  applicationData.forEach((data, index) => {
    if (data.removedLog) {
      applicationData[index].log.push(...data.removedLog);
      applicationData[index].removedLog.length = 0;
    }
  });

  if (filters.showOnlyAppStore) {
    removeFromLog("fromMacAppStore", true);
  }

  if (filters.hideAppStore) {
    removeFromLog("fromMacAppStore", false);
  }

  if (filters.patch24Apps) {
    removeNonPatch24Apps();
  }

  function removeNonPatch24Apps() {
    patchingData.AppleSilicon.forEach((patchApp) => {
      let bundleId = patchApp.requirements.find(
        (a) => a.name == "Application Bundle ID"
      );
      if (bundleId && bundleId.value != "") {
        if (!patchingBundleIDs.includes(bundleId.value)) {
          patchingBundleIDs.push(bundleId.value);
        }
      }
    });
    patchingData.Intel.forEach((patchApp) => {
      let bundleId = patchApp.requirements.find(
        (a) => a.name == "Application Bundle ID"
      );
      if (bundleId && bundleId.value != "") {
        if (!patchingBundleIDs.includes(bundleId.value)) {
          patchingBundleIDs.push(bundleId.value);
        }
      }
    });

    applicationData.forEach((data, index) => {
      var tempLog = [];
      var removeLog = [];
      data.log.forEach((log) => {
        if (patchingBundleIDs.includes(log.CFBundleIdentifier)) {
          tempLog.push(log);
        } else {
          removeLog.push(log);
        }
      });
      applicationData[index].log = tempLog;
      applicationData[index].removedLog = removeLog;
    });
  }

  function removeFromLog(key, value) {
    applicationData.forEach((data, index) => {
      var tempLog = [];
      var removedLog = [];
      data.log.forEach((log) => {
        if (log[key] == value) {
          tempLog.push(log);
        } else {
          removedLog.push(log);
        }
      });
      applicationData[index].log = tempLog;
      if (applicationData[index].removedLog) {
        applicationData[index].removedLog.push(...removedLog);
      } else {
        applicationData[index].removedLog = removedLog;
      }
    });
  }
}

function processData(appData, platform) {
  if (platform === "macOS") {
    summarisedData.length = 0;
    appData.forEach((device) => {
      device.log.forEach((application) => {
        // If application is already in the summarisedData add to current entry.
        if (
          !summarisedData.find((a, index) => {
            if (a.CFBundleIdentifier == application.CFBundleIdentifier) {
              summarisedData[index].count += 1;
              summarisedData[index].devices.push(device.serial);

              if (
                !summarisedData[index].versions.find((version, indexb) => {
                  if (
                    version.version == application.CFBundleShortVersionString
                  ) {
                    summarisedData[index].versions[indexb].count += 1;
                    summarisedData[index].versions[indexb].devices.push(
                      device.serial
                    );
                    return true;
                  }
                })
              ) {
                summarisedData[index].versions.push({
                  count: 1,
                  version: application.CFBundleShortVersionString,
                  devices: [device.serial],
                });
              }

              return true;
            }
          })
        ) {
          // Add new application entry
          summarisedData.push({
            ...application,
            count: 1,
            devices: [device.serial],
            versions: [
              {
                version: application.CFBundleShortVersionString,
                devices: [device.serial],
                count: 1,
              },
            ],
            appName: application.path.substr(
              application.path.lastIndexOf("/") + 1,
              application.path.length
            ),
          });
        }
      });
    });

    // Get Top 20 Installed Apps
    summarisedData.sort((a, b) => {
      if (a.count > b.count) {
        return -1;
      }
      if (a.count < b.count) {
        return 1;
      }
      return 0;
    });
    // it 'might' be less than 20, IDK where.
    if (summarisedData.length > 20) {
      topApps.push(...summarisedData.slice(0, 20));
    } else {
      topApps.push(...summarisedData);
    }

    summarisedData.sort((a, b) => {
      if (a.appName.toLowerCase() > b.appName.toLowerCase()) {
        return 1;
      }
      return -1;
    });
  }

  if (platform === "Windows") {
    summarisedData.length = 0;
    appData.forEach((device) => {
      device.log.forEach((application) => {
        // If the application has the install type of msu, remove it.
        if (application.InstallType == "msu") {
          return;
        }

        // If application is already in the summarisedData add to current entry.
        if (
          !summarisedData.find((a, index) => {
            if (a.DisplayName == application.DisplayName) {
              summarisedData[index].count += 1;
              summarisedData[index].devices.push(device.serial);

              if (
                !summarisedData[index].versions.find((version, indexb) => {
                  if (version.DisplayVersion == application.DisplayVersion) {
                    summarisedData[index].versions[indexb].count += 1;
                    summarisedData[index].versions[indexb].devices.push(
                      device.serial
                    );
                    return true;
                  }
                })
              ) {
                summarisedData[index].versions.push({
                  count: 1,
                  version: application.DisplayVersion,
                  devices: [device.serial],
                });
              }

              return true;
            }
          })
        ) {
          // Add new application entry
          summarisedData.push({
            ...application,
            count: 1,
            devices: [device.serial],
            versions: [
              {
                version: application.DisplayVersion,
                devices: [device.serial],
                count: 1,
              },
            ],
            appName: application.DisplayName,
          });
        }
      });
    });
    // Get Top 20 Installed Apps
    summarisedData.sort((a, b) => {
      if (a.count > b.count) {
        return -1;
      }
      if (a.count < b.count) {
        return 1;
      }
      return 0;
    });

    // it 'might' be less than 20, IDK where.
    if (summarisedData.length > 20) {
      topApps.push(...summarisedData.slice(0, 20));
    } else {
      topApps.push(...summarisedData);
    }

    summarisedData.sort((a, b) => {
      if (a.appName.toLowerCase() > b.appName.toLowerCase()) {
        return 1;
      }
      return -1;
    });
  }
}

function getAppDetail(app) {
  applicationDetail.value = app;
  applicationDetail.value.macAppStoreCount = 0;
  applicationDetail.value.patch24App = false;

  var versions = [];
  //var macAppStoreCount = 0;
  patchingData.AppleSilicon.find((patch) => {
    if (app.CFBundleIdentifier == patch.bundleId) {
      applicationDetail.value.patch24App = true;
      applicationDetail.value.latestVersion = patch.version.version;
      return true;
    }
  });

  patchingData.Intel.find((patch) => {
    if (app.CFBundleIdentifier == patch.bundleId) {
      if (applicationDetail.value.patch24App) {
        if (applicationDetail.value.latestVersion != patch.version) {
          applicationDetail.value.IntelLatestVersion = patch.version.version;
        }
      }
      return true;
    }
  });

  var matchingDevices = applicationData.filter((data) => {
    if (
      data.log.find((a) => {
        if (
          a.CFBundleIdentifier == applicationDetail.value.CFBundleIdentifier
        ) {
          if (a.fromMacAppStore) {
            applicationDetail.value.macAppStoreCount++;
          }
          return true;
        }
      })
    ) {
      return true;
    }
  });
  matchingDevices.forEach((device) => {
    const log = device.log.find(
      (log) =>
        log.CFBundleIdentifier == applicationDetail.value.CFBundleIdentifier
    );
    if (
      !versions.find((ver, index) => {
        if (ver.version == log.CFBundleShortVersionString) {
          versions[index].count += 1;
          versions[index].devices.push(device.serial);
          return true;
        }
      })
    ) {
      versions.push({
        count: 1,
        version: log.CFBundleShortVersionString,
        devices: [device.serial],
      });
    }
  });
  applicationDetail.value.versions = versions;
  applicationDetailDrillDownDevices.length = 0;
  applicationDetailDrillDownDevices.push(...applicationDetail.value.devices);
  addLastOpenedToAlldevices(app.path);
}

function getAppDetailWindows(app) {
  applicationDetail.value = app;

  var versions = [];

  var matchingDevices = applicationData.filter((data) => {
    if (
      data.log.find((a) => {
        if (a.DisplayName == applicationDetail.value.DisplayName) {
          return true;
        }
      })
    ) {
      return true;
    }
  });
  matchingDevices.forEach((device) => {
    const log = device.log.find(
      (log) => log.DisplayName == applicationDetail.value.DisplayName
    );
    if (
      !versions.find((ver, index) => {
        if (ver.version == log.DisplayVersion) {
          versions[index].count += 1;
          versions[index].devices.push(device.serial);
          return true;
        }
      })
    ) {
      versions.push({
        count: 1,
        version: log.DisplayVersion,
        devices: [device.serial],
      });
    }
  });
  applicationDetail.value.versions = versions.sort((a, b) => b.count - a.count);
  applicationDetailDrillDownDevices.length = 0;
  applicationDetailDrillDownDevices.push(...applicationDetail.value.devices);
}

async function getData() {
  loading.value = true;
  mdmType = mdmServer.$state.type;
  platform = props.platform;

  switch (platform) {
    case "macOS":
      try {
        const result = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "applicationData",
        });
        if (result.applicationData) {
          applicationData = result.applicationData;
          processData(applicationData, platform);
        }
        const allDevicesResult = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "dashboardDetail",
          id: props.devices.records,
        });
        if (allDevicesResult) {
          // For each device, only add to the alldevices array if the Operating_System_Name variable is macOS
          allDevicesResult.content.forEach((device) => {
            if (device.Operating_System_Name == "macOS") {
              allDevices.push(device);
            }
          });
        }

        const patchingDataResult = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "patchData",
        });
        if (patchingDataResult.payload) {
          patchingData = patchingDataResult.payload;
          processPatching();
        }

        //console.log(patchingData);
      } catch (error) {
        console.log(error);
      }

      break;
    case "Windows":
      try {
        const result = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "windowsApplicationData",
        });
        if (result.applicationData) {
          windowsApplicationData = result.applicationData;
          processData(windowsApplicationData, platform);
        }

        const allDevicesResult = await apiQuery("ELA", "cmd24/query", {
          mdmServerId: appBar.selected,
          dataRequested: "dashboardDetail",
          id: props.devices.records,
        });
        if (allDevicesResult) {
          // For each device, only add to the alldevices array if the Operating_System_Name variable is Windows
          allDevicesResult.content.forEach((device) => {
            if (device.Operating_System_Name == "Windows") {
              allDevices.push(device);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }

      break;
    default:
      console.log("Unknown Platform Type");
  }

  loading.value = false;
}

var appsNeedUpdating = reactive([]);

function processPatching() {
  appsNeedUpdating.length = 0;
  applicationData.forEach((device) => {
    var stream = "AppleSilicon";
    if (device.Apple_Silicon != "Yes") {
      stream = "Intel";
    }
    const appsToAdd = checkApps(device.log, stream);
    //console.log("Apps TO Add", appsToAdd);
    addToNeedsUpdating(appsToAdd, device);
    appsNeedUpdating.sort((a, b) => {
      if (a.count > b.count) {
        return -1;
      }
      if (a.count < b.count) {
        return 1;
      }
      return 0;
    });
  });

  function checkApps(apps, stream) {
    var appsNeedUpdating = [];
    apps.forEach((app) => {
      let appDefinition = patchingData[stream].find(
        (definition) => app.CFBundleIdentifier == definition.bundleId
      );
      if (appDefinition) {
        //console.log("Found patching App", appDefinition);
        const updateStatus = checkForUpdate(app, appDefinition);
        // console.log("Update Status = ", updateStatus);
        if (updateStatus == "RequiresUpdate") {
          // console.log("Adding App to update", app);
          appsNeedUpdating.push({ ...app });
        }
      }
    });
    return appsNeedUpdating;
  }

  function addToNeedsUpdating(apps, device) {
    apps.forEach((app) => {
      const appName = app.path.substr(
        app.path.lastIndexOf("/") + 1,
        app.path.length
      );
      if (
        !appsNeedUpdating.find((a, index) => {
          if (a.appName == appName) {
            appsNeedUpdating[index].count += 1;
            appsNeedUpdating[index].devices.push(device.serial);
            return true;
          }
        })
      ) {
        appsNeedUpdating.push({
          ...app,
          appName: appName,
          count: 1,
          devices: [device.serial],
        });
      }
    });
  }

  function checkForUpdate(app, appDefinition) {
    const version = app.CFBundleShortVersionString;
    const latestVersion = appDefinition.version.version;

    if (version == latestVersion) {
      return "LatestInstalled";
    }

    if (appDefinition.patches.find((patch) => patch.version == version)) {
      return "RequiresUpdate";
    }
    //console.log("Unknown Version", version, latestVersion);
    return "UnknownVersion";
  }
}

onMounted(() => {
  getData();
});

const softwareHeaders = [
  { text: "Application", value: "appName", sortable: true },
  { text: "Identifier", value: "CFBundleIdentifier", sortable: true },
  { text: "Found On", value: "count", sortable: true },
];

const softwareHeadersWindows = [
  { text: "Application", value: "appName", sortable: true },
  { text: "Found On", value: "count", sortable: true },
];
const swListSearchValue = ref("");

function searchFields(headers) {
  var fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

const applicationDetail = ref({});

const applicationDetailDrillDownDevices = reactive([]);

function clickSelect(devices) {
  applicationDetailDrillDownDevices.length = 0;
  applicationDetailDrillDownDevices.push(...devices);
}

function getUsage(appPath, numberDays) {
  // Returns the Devices that have use the App in x days
  var devices = [];
  const compareDate = new Date();
  compareDate.setDate(compareDate.getDate() - numberDays);
  //console.log(compareDate);
  applicationData.forEach((device) => {
    device.log.forEach((log) => {
      if (log.path == appPath && log.lastlaunched) {
        //console.log("Last Launched", log.lastlaunched);
        let date = new Date(log.lastlaunched);
        //console.log(date.toISOString(), compareDate.toISOString());
        if (compareDate < date) {
          devices.push(device.serial);
        }
      }
    });
  });
  return devices;
}

function addLastOpenedToAlldevices(appPath) {
  // Clear Down first
  allDevices.forEach((device, index) => {
    device.lastOpened = undefined;
  });
  allDevices.forEach((device, index) => {
    applicationData.find((data) => {
      if (data.serial == device.Serial_Number) {
        data.log.find((log) => {
          if (log.path == appPath) {
            allDevices[index].lastOpened = log.lastlaunched;
            return true;
          }
        });
        return true;
      }
    });
  });
}

const lastUsageHeader = [
  {
    text: "Last Opened",
    value: "lastOpened",
    sortable: true,
  },
];
</script>

<template>
  <template v-if="props.platform == 'Windows'">
    <template v-if="applicationDetail.appName">
      <v-row>
        <v-col cols="auto">
          <v-btn prepend-icon="mdi-arrow-left" @click="applicationDetail = {}"
            >Back</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <h2>{{ applicationDetail.appName }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3.5">
          <v-card>
            <v-card-text>
              <v-table hover density="compact" height="310" fixed-header>
                <thead>
                  <tr class="font-weight-black">
                    <th class="font-weight-black">Version</th>
                    <th>Number of Devices</th>
                  </tr>
                </thead>
                <tbody>
                  <tr @click="clickSelect(applicationDetail.devices)">
                    <td>All Versions</td>
                    <td>{{ applicationDetail.count }}</td>
                  </tr>
                  <tr
                    @click="clickSelect(version.devices)"
                    v-for="version in applicationDetail.versions"
                    :key="version.version"
                  >
                    <td>{{ version.version }}</td>
                    <td>{{ version.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ElaDashboardDrillDown
            :manualMode="true"
            :allDevices="allDevices"
            :serialNumbers="applicationDetailDrillDownDevices"
            :hideExit="true"
          >
          </ElaDashboardDrillDown>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="12">
          <v-card minHeight="315" :loading="loading">
            <v-card-title>Top 20 Applications</v-card-title>
            <v-card-text>
              <v-table
                class="customTextColour"
                hover
                density="compact"
                height="250"
                fixed-header
              >
                <thead>
                  <tr>
                    <th class="font-weight-bold customTextColour">
                      Application Name
                    </th>
                    <th class="font-weight-bold customTextColour">
                      Installed On
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-if="windowsApplicationData.length === 0 && !loading"
                  >
                    <tr>
                      <td colspan="2" class="text-center helvetica small-font">
                        <br />No Available Data
                      </td>
                    </tr>
                  </template>
                  <tr
                    @click="getAppDetailWindows(app)"
                    v-for="app in topApps"
                    :key="app"
                  >
                    <td>{{ app.appName }}</td>
                    <td>{{ app.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title> All Installed Apps</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    class="mx-0"
                    label="Search..."
                    v-model="swListSearchValue"
                    variant="underlined"
                    hide-details
                    density="compact"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="px-2">
                  <download-csv
                    :data="summarisedData"
                    name="SoftwareReport.csv"
                  >
                    <v-btn prepend-icon="mdi-download"> Download CSV </v-btn>
                  </download-csv>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <EasyDataTable
                    table-class-name="customize-table"
                    :items="summarisedData"
                    :headers="softwareHeadersWindows"
                    :search-field="searchFields(softwareHeadersWindows)"
                    :search-value="swListSearchValue"
                    :loading="loading"
                    alternating
                    theme-color="#e7541e"
                    @clickRow="getAppDetailWindows"
                  ></EasyDataTable>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </template>

  <template v-if="props.platform == 'macOS'">
    <template v-if="applicationDetail.appName">
      <v-row>
        <v-col cols="auto">
          <v-btn prepend-icon="mdi-arrow-left" @click="applicationDetail = {}"
            >Back</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <h2>{{ applicationDetail.appName }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <v-card>
            <v-card-title></v-card-title>

            <v-card-text>
              <v-table density="compact" height="310">
                <tbody>
                  <tr>
                    <td class="font-weight-black">Application Identifier</td>
                    <td>{{ applicationDetail.CFBundleIdentifier }}</td>
                  </tr>
                  <tr>
                    <th class="font-weight-black">Application Path</th>
                    <td>{{ applicationDetail.path }}</td>
                  </tr>
                  <tr>
                    <th class="font-weight-black">Installed From App Store</th>
                    <td>{{ applicationDetail.macAppStoreCount }}</td>
                  </tr>
                  <tr>
                    <th class="font-weight-black">Installed from Other</th>
                    <td>
                      {{
                        applicationDetail.count -
                        applicationDetail.macAppStoreCount
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="font-weight-black">Patch24 Managed App</th>
                    <td>{{ applicationDetail.patch24App ? "Yes" : "No" }}</td>
                  </tr>
                  <tr v-if="applicationDetail.patch24App">
                    <th class="font-weight-black">
                      {{
                        applicationDetail.IntelLatestVersion
                          ? "Latest Version (Apple Silicon)"
                          : "Latest Version"
                      }}
                    </th>
                    <td>
                      {{ applicationDetail.latestVersion }}
                    </td>
                  </tr>
                  <tr v-if="applicationDetail.IntelLatestVersion">
                    <th class="font-weight-black">Latest Version (Intel)</th>
                    <td>{{ applicationDetail.IntelLatestVersion }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3.5">
          <v-card>
            <v-card-text>
              <v-table hover density="compact" height="310" fixed-header>
                <thead>
                  <tr class="font-weight-black">
                    <th class="font-weight-black">Version</th>
                    <th>Number of Devices</th>
                  </tr>
                </thead>
                <tbody>
                  <tr @click="clickSelect(applicationDetail.devices)">
                    <td>All Versions</td>
                    <td>{{ applicationDetail.count }}</td>
                  </tr>
                  <tr
                    @click="clickSelect(version.devices)"
                    v-for="version in applicationDetail.versions"
                    :key="version.version"
                  >
                    <td>{{ version.version }}</td>
                    <td>{{ version.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3.5">
          <v-card>
            <!-- <v-card-title>Application Usage</v-card-title> -->
            <v-card-text>
              <v-table hover density="compact" height="310" fixed-header>
                <thead>
                  <tr>
                    <td>Used in Last</td>
                    <td>Number of Devices</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="day in daysForFilter"
                    :key="day"
                    @click="clickSelect(getUsage(applicationDetail.path, day))"
                  >
                    <td>{{ day }} Days</td>
                    <td>{{ getUsage(applicationDetail.path, day).length }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ElaDashboardDrillDown
            :manualMode="true"
            :allDevices="allDevices"
            :serialNumbers="applicationDetailDrillDownDevices"
            :hideExit="true"
            :mandatoryHeaders="lastUsageHeader"
          >
          </ElaDashboardDrillDown>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="6">
          <v-card minHeight="315" :loading="loading">
            <v-card-title>Top 20 Applications</v-card-title>
            <v-card-text>
              <v-table
                class="customTextColour"
                hover
                density="compact"
                height="250"
                fixed-header
              >
                <thead>
                  <tr>
                    <th class="font-weight-bold customTextColour">
                      Application Name
                    </th>
                    <th class="font-weight-bold customTextColour">
                      Installed On
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="applicationData.length === 0 && !loading">
                    <tr>
                      <td colspan="2" class="text-center helvetica small-font">
                        <br />No Available Data
                      </td>
                    </tr>
                  </template>
                  <tr
                    @click="getAppDetail(app)"
                    v-for="app in topApps"
                    :key="app"
                  >
                    <td>{{ app.appName }}</td>
                    <td>{{ app.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card minHeight="315" :loading="loading">
            <v-card-title>Apps Needing Update (using Patch24)</v-card-title>
            <v-card-text>
              <v-table hover density="compact" height="250" fixed-header>
                <thead>
                  <tr>
                    <th class="font-weight-bold customTextColour">
                      Application Name
                    </th>
                    <th class="font-weight-bold customTextColour">
                      Update Required On
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="applicationData.length === 0 && !loading">
                    <tr>
                      <td colspan="2" class="text-center helvetica small-font">
                        <br />No Available Data
                      </td>
                    </tr>
                  </template>
                  <tr
                    v-for="app in appsNeedUpdating"
                    :key="app.appName"
                    @click="getAppDetail(app)"
                  >
                    <td>{{ app.appName }}</td>
                    <td>{{ app.count }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title> All Installed Apps</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    class="mx-0"
                    label="Search..."
                    v-model="swListSearchValue"
                    variant="underlined"
                    hide-details
                    density="compact"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="px-2">
                  <download-csv
                    :data="summarisedData"
                    name="SoftwareReport.csv"
                  >
                    <v-btn prepend-icon="mdi-download"> Download CSV </v-btn>
                  </download-csv>
                </v-col>
                <v-col cols="auto" class="pl-0 text-right">
                  <v-btn prepend-icon="mdi-filter"
                    >{{ filterMenu ? "Close" : "Filter" }}

                    <v-menu
                      activator="parent"
                      v-model="filterMenu"
                      :close-on-content-click="false"
                    >
                      <v-list>
                        <v-list-item>
                          <v-checkbox-btn
                            label="Hide Mac App Store Apps"
                            density="compact"
                            v-model="filters.hideAppStore"
                            hide-details
                          >
                          </v-checkbox-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-checkbox-btn
                            label="Only Show Mac App Store Apps"
                            density="compact"
                            v-model="filters.showOnlyAppStore"
                            hide-details
                          >
                          </v-checkbox-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-checkbox-btn
                            label="Patch24 Managed Apps"
                            density="compact"
                            v-model="filters.patch24Apps"
                            hide-details
                          >
                          </v-checkbox-btn>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <EasyDataTable
                    table-class-name="customize-table"
                    :items="summarisedData"
                    :headers="softwareHeaders"
                    :search-field="searchFields(softwareHeaders)"
                    :search-value="swListSearchValue"
                    :loading="loading"
                    alternating
                    theme-color="#e7541e"
                    @clickRow="getAppDetail"
                  ></EasyDataTable>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </template>
</template>

<style scoped>
.v-card-title {
  font-weight: 700;
}

.customize-table {
  --easy-table-header-font-color: #000000;
  --easy-table-body-row-font-color: #000000;
  --easy-table-body-even-row-font-color: #000000;
}

.td {
  font-size: 50px;
}

.small-font {
  font-size: 12px;
}

.customTextColour {
  color: #000000 !important;
}
</style>
