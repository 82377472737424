<script setup>
import { ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

console.log("Hello Alerts");
const loading = ref(false);
const alerts = ref([]);

const headers = [
  { text: "MDM Server", value: "mdmServerName", sortable: true },
  { text: "Date Raised", value: "dateCreated", sortable: true },
  { text: "Alert Name", value: "name", sortable: true },
  { text: "Alert Type", value: "alertType", sortable: true },
  { text: "Status", value: "status", sortable: true },
  { text: "Service Now ID", value: "serviceNowRecord", sortable: true },
];

const searchValue = ref("");
function searchFields(headers) {
  const fields = [];
  headers.forEach((header) => {
    fields.push(header.value);
  });
  return fields;
}

const rowsPerPage = 100;

async function getData() {
  try {
    alerts.value.length = 0;
    loading.value = true;
    const results = await apiQuery("ELA", "dashboard/alerts", {
      apiAction: "GetAllOpen",
    });
    console.log("results: ", results);
    if (results) {
      results.forEach((result) => {
        const alertType = getType(result);
        const dateRaisedFormatted = new Date(result.dateCreated).toLocaleString(
          "en-GB"
        );
        alerts.value.push({
          alertType: alertType,
          dateRaisedFormatted: dateRaisedFormatted,
          ...result,
        });
        //alerts.value.push(result);
      });
    }
    console.log("Alerts: ", alerts);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

function getType(item) {
  console.log(item);
  if (item.notification) {
    return "Notification";
  }
  if (item.name.includes("Data Collection Error")) {
    return "Data Collection";
  }
  if (item.dataSourceId) {
    if (item.dataSourceId.includes("JamfInformation")) {
      return "Jamf Information";
    } else {
      return "Alert";
    }
  }
  return "";
}

onMounted(() => {
  getData();
});
</script>

<template>
  <v-row>
    <v-col>
      <v-text-field
        density="compact"
        hide-details
        variant="underlined"
        v-model="searchValue"
        label="Search"
      ></v-text-field>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <h3>Alerts</h3>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <EasyDataTable
        :headers="headers"
        :items="alerts"
        alternating
        buttons-pagination
        :loading="loading"
        theme-color="#e7541e"
        :rows-per-page="rowsPerPage"
        :search-value="searchValue"
        :search-field="searchFields(headers)"
      >
        <template #item-dateCreated="item">
          {{ item.dateRaisedFormatted }}
        </template>
      </EasyDataTable>
    </v-col>
  </v-row>
</template>
