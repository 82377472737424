<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";
import DashboardPieChart from "@/views/dashboard/DashboardPieChart.vue";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();
import { mdmServerStore } from "@/stores/mdmServer";
const mdmServer = mdmServerStore();

appBar.$subscribe((mutation, state) => {
  console.log("Change Triggered", mutation);
  console.log(state);
  if (appBar.selected != "") {
    getData();
  }
});

const data = ref({});

async function getData() {
  try {
    if (appBar.selected != "") {
      const result = await apiQuery("ELA", "cmd24/query", {
        mdmServerId: appBar.selected,
        dataRequested: "dashboard",
        maxage: 86400,
      });
      // console.log(result);
      if (result.content) {
        data.value = result;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getData();
});

const dummyData = {
  labels: ["12.1.0", "10.15.7", "10.14.6", "10.15.6", "13.1.0", "11.5.2"],
  datasets: [
    {
      data: [1, 1, 1, 1, 1, 1],
      backgroundColor: [
        "#e8dff5",
        "#fce1e4",
        "#fcf4dd",
        "#ddedea",
        "#daeaf6",
        "#d4afb9",
      ],
    },
  ],
};

const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

function addChartColors(chartData) {
  const colors = [
    "#e8dff5",
    "#fce1e4",
    "#fcf4dd",
    "#ddedea",
    "#daeaf6",
    "#d4afb9",
    "#d1cfe2",
    "#9cadce",
    "#7ec4cf",
    "#52b2cf",
  ];
  var counter = 0;
  var colourCounter = 0;
  const coloursRequired = chartData.datasets[0].data.length;
  while (counter < coloursRequired) {
    counter++;
    if (!chartData.datasets[0].backgroundColor) {
      chartData.datasets[0].backgroundColor = [colors[colourCounter]];
    } else {
      chartData.datasets[0].backgroundColor.push(colors[colourCounter]);
    }
    if (colourCounter == colors.length) {
      colourCounter = 0;
    }
    colourCounter++;
  }
  return chartData;
}

function preparePieChart(data) {
  var chartData = { labels: [], datasets: [{ data: [] }] };
  for (const key in data) {
    chartData.labels.push(key);
    chartData.datasets[0].data.push(data[key]);
  }
  chartData = addChartColors(chartData);
  return chartData;
}

function FormatDate(date) {
  date = new Date(date).toLocaleString("en-GB");

  return date;
}
</script>

<template>
  <!--Jamf Dashboard -->
  <v-row v-if="data.content && mdmServer.$state.type === 'Jamf'">
    <v-col cols="4">
      <v-card height="150px" v-if="data.content.deviceCounts" class="mb-2">
        <v-card-title class="bg-grey-lighten-2">Managed Devices</v-card-title>
        <v-card-text class="text-left pt-3">
          <tr v-if="data.content.deviceCounts.macOS">
            <th>macOS</th>
            <td class="pl-3">{{ data.content.deviceCounts.macOS }}</td>
          </tr>
          <tr v-if="data.content.deviceCounts.ios">
            <th>iOS</th>
            <td class="pl-3">{{ data.content.deviceCounts.ios }}</td>
          </tr>
        </v-card-text>
      </v-card>
      <v-card height="150px" v-if="data.content.elevateReport">
        <v-card-title class="bg-grey-lighten-2"
          >Elevate24 Elevations</v-card-title
        >
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.elevateReport }}
          </h1>
          <div class="pt-3 text-center">In last 24 Hrs</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- <v-col cols="4">
      <v-card height="150px" class="mb-2">
        <v-card-title class="bg-grey-lighten-2"> Devices Online</v-card-title>
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.deviceCounts.online }} /
            {{
              data.content.deviceCounts.ios + data.content.deviceCounts.macOS
            }}
          </h1>
          <div class="pt-3 text-center">In last 24 Hrs</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
      <v-card height="150px" class="mt-2">
        <v-card-title class="bg-grey-lighten-2"> Devices Offline</v-card-title>
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.deviceCounts.offline }} /
            {{
              data.content.deviceCounts.ios + data.content.deviceCounts.macOS
            }}
          </h1>
          <div class="pt-3 text-center">Over Last 14 days</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col> -->
    <v-col cols="4" v-if="data.content.batteryHealthReport">
      <v-card height="150px">
        <v-card-title class="bg-grey-lighten-2">Battery Health</v-card-title>
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.batteryHealthReport.length }}
          </h1>
          <div class="pt-3 text-center">Devices with poor battery health.</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
      <v-card height="150px" class="mt-2" v-if="data.content.adminAccounts">
        <v-card-title class="bg-grey-lighten-2"
          >Devices with Admin Account</v-card-title
        >
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.adminAccounts.length }}
          </h1>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" v-if="data.content.macOSVersions">
      <v-card height="300px" min-width="400px">
        <v-card-title class="bg-grey-lighten-2">macOS Versions </v-card-title>
        <v-card-text>
          <DashboardPieChart
            :chartData="preparePieChart(data.content.macOSVersions)"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.iosVersions">
      <v-card height="300px" min-width="400px">
        <v-card-title class="bg-grey-lighten-2">iOS Versions </v-card-title>
        <v-card-text>
          <DashboardPieChart
            :chartData="preparePieChart(data.content.iosVersions)"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.windowsVersions">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2">Windows Versions </v-card-title>
        <v-card-text>
          <DashboardPieChart
            :chartData="preparePieChart(data.content.windowsVersions)"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="8" v-if="data.content.cisReport">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2"
          >CIS Benchmark Warnings</v-card-title
        >
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Reference</th>
              <th>Name</th>
              <th># Devices Failing</th>
            </tr>
            <tr v-for="record in data.content.cisReport" :key="record">
              <td>{{ record.cis }}</td>
              <td>{{ record.name }}</td>
              <td>{{ record.count }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4" v-if="data.content.patchingReport">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2"
          >Outstanding Patches</v-card-title
        >
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Application</th>
              <th># Devices</th>
            </tr>
            <tr v-for="patch in data.content.patchingReport" :key="patch">
              <td>{{ patch.application }}</td>
              <td>{{ patch.count }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <!--Intune Dashboard -->
  <v-row v-if="data.content && mdmServer.$state.type === 'Intune'">
    <v-col cols="4">
      <v-card height="150px" v-if="data.content.deviceCounts" class="mb-2">
        <v-card-title class="bg-grey-lighten-2">Managed Devices</v-card-title>
        <v-card-text class="text-left pt-3">
          <tr v-if="data.content.deviceCounts.macOS">
            <th>macOS</th>
            <td class="pl-3">{{ data.content.deviceCounts.macOS }}</td>
          </tr>
          <tr v-if="data.content.deviceCounts.ios">
            <th>iOS</th>
            <td class="pl-3">{{ data.content.deviceCounts.ios }}</td>
          </tr>
          <tr v-if="data.content.deviceCounts.windows">
            <th>Windows</th>
            <td class="pl-3">{{ data.content.deviceCounts.windows }}</td>
          </tr>
        </v-card-text>
      </v-card>
      <v-card height="150px">
        <v-card-title class="bg-grey-lighten-2"
          >Non-Compliant Devices</v-card-title
        >
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.nonCompliantDevices }} /
            {{
              data.content.deviceCounts.ios +
              data.content.deviceCounts.macOS +
              data.content.deviceCounts.windows
            }}
          </h1>
          <div class="pt-3 text-center">In last 24 Hrs</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card height="150px" class="mb-2">
        <v-card-title class="bg-grey-lighten-2"> Devices Online</v-card-title>
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.deviceCounts.online }} /
            {{
              data.content.deviceCounts.ios +
              data.content.deviceCounts.macOS +
              data.content.deviceCounts.windows
            }}
          </h1>
          <div class="pt-3 text-center">In last 24 Hrs</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
      <v-card height="150px" class="mB-2">
        <v-card-title class="bg-grey-lighten-2"> Devices Offline</v-card-title>
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.deviceCounts.offline }} /
            {{
              data.content.deviceCounts.ios +
              data.content.deviceCounts.macOS +
              data.content.deviceCounts.windows
            }}
          </h1>
          <div class="pt-3 text-center">Over Last 14 days</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card height="150px" class="mb-2">
        <v-card-title class="bg-grey-lighten-2"
          >Devices Not Encrypted</v-card-title
        >
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.nonEncryptedDevices }} /
            {{
              data.content.deviceCounts.ios +
              data.content.deviceCounts.macOS +
              data.content.deviceCounts.windows
            }}
          </h1>
          <div class="pt-3 text-center">In last 24 Hrs</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
      <v-card height="150px" class="mb-2">
        <v-card-title class="bg-grey-lighten-2"
          >Personal/BYOD Enrolled</v-card-title
        >
        <v-card-text>
          <h1 class="pt-3 text-center">
            {{ data.content.personalDevices }} /
            {{
              data.content.deviceCounts.ios +
              data.content.deviceCounts.macOS +
              data.content.deviceCounts.windows
            }}
          </h1>
          <div class="pt-3 text-center">In last 24 Hrs</div>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" v-if="data.content.macOSVersions">
      <v-card height="300px" min-width="400px">
        <v-card-title class="bg-grey-lighten-2">macOS Versions </v-card-title>
        <v-card-text>
          <DashboardPieChart
            :chartData="preparePieChart(data.content.macOSVersions)"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.iosVersions">
      <v-card height="300px" min-width="400px">
        <v-card-title class="bg-grey-lighten-2">iOS Versions </v-card-title>
        <v-card-text>
          <DashboardPieChart
            :chartData="preparePieChart(data.content.iosVersions)"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.windowsVersions">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2">Windows Versions </v-card-title>
        <v-card-text>
          <DashboardPieChart
            :chartData="preparePieChart(data.content.windowsVersions)"
            :chartOptions="pieChartOptions"
            height="200"
            width="200"
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" v-if="data.content.nonComplianceList">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2"
          >Non-Compliance Report</v-card-title
        >
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Device</th>
              <th>OS</th>
              <th>Compliance Policy</th>
              <th>Reason</th>
            </tr>
            <tr v-for="record in data.content.nonComplianceList" :key="record">
              <td>{{ record.DeviceName }}</td>
              <td>{{ record.OS }}</td>
              <td>{{ record.PolicyName }}</td>
              <td>{{ record.SettingName }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" v-if="data.content.availableFreeSpace.length != 0">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2"
          >Low Disk Space Report</v-card-title
        >
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Device</th>
              <th>OS</th>
              <th>Total</th>
              <th>Available</th>
              <th>% Free</th>
            </tr>
            <tr v-for="record in data.content.availableFreeSpace" :key="record">
              <td>{{ record.name }}</td>
              <td>{{ record.os }}</td>
              <td>{{ record.totalSpace }}</td>
              <td>{{ record.availableSpace }}</td>
              <td>{{ record.percentageFree }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.tokens">
      <v-card height="300px">
        <v-card-title class="bg-grey-lighten-2">Apple Tokens</v-card-title>
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Type</th>
              <th>Name/Apple ID</th>
              <th>Expiry Date</th>
            </tr>
            <tr v-if="data.content.tokens.apns">
              <td>{{ "APNS" }}</td>
              <td>{{ data.content.tokens.apns.appleIdentifier }}</td>
              <td>
                {{ FormatDate(data.content.tokens.apns.expirationDateTime) }}
              </td>
            </tr>
            <tr v-for="record in data.content.tokens.vpp" :key="record">
              <td>{{ "Apps & Books" }}</td>
              <td>{{ record.displayName }}</td>
              <td>{{ FormatDate(record.expirationDateTime) }}</td>
            </tr>
            <tr v-for="record in data.content.tokens.ade" :key="record">
              <td>{{ "ADE" }}</td>
              <td>{{ record.tokenName }}</td>
              <td>{{ FormatDate(record.tokenExpirationDateTime) }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.topApplications">
      <v-card height="300px" width="350px">
        <v-card-title class="bg-grey-lighten-2"
          >Top 10 Windows Apps</v-card-title
        >
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Application</th>
              <th>#</th>
            </tr>
            <tr
              v-for="record in data.content.topApplications.windows"
              :key="record"
            >
              <td>{{ record.ApplicationName }}</td>
              <td>{{ record.DeviceCount }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" v-if="data.content.topApplications">
      <v-card height="300px" width="350px">
        <v-card-title class="bg-grey-lighten-2">Top 10 iOS Apps</v-card-title>
        <v-card-text>
          <v-table class="text-left">
            <tr>
              <th>Application</th>
              <th>#</th>
            </tr>
            <tr
              v-for="record in data.content.topApplications.ios"
              :key="record"
            >
              <td>{{ record.ApplicationName }}</td>
              <td>{{ record.DeviceCount }}</td>
            </tr>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
