/* eslint-disable */
<script setup>
import { reactive, ref, onMounted } from "vue";
//import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";
import { Auth } from "aws-amplify";

const loading = ref(false);
const commentOverlay = ref(false);
const overlayComment = ref({});
let overlayItem = ref({});
let refreshKey = ref(0);
let features = reactive([]);
let qualifyingArray = reactive([]);
let inProgressArray = reactive([]);
let doneArray = reactive([]);

const headers = [
  { text: "ID", value: "key", sortable: true },
  { text: "Summary", value: "fields.summary", sortable: true },
  { text: "Category", value: "fields.labels", sortable: true },
  { text: "Priority", value: "fields.priority.name", sortable: true },
  { text: "Status", value: "fields.status.name", sortable: true },
  { text: "Contact", value: "assigneeDisplayName", sortable: true },
  {
    text: "Date Created",
    value: "dateCreatedFormat",
    sortable: true,
  },
];

async function getData() {
  try {
    features.length = 0;
    qualifyingArray.length = 0;
    inProgressArray.length = 0;
    doneArray.length = 0;
    loading.value = true;
    const results = await apiQuery("ELA", "features/request", {
      apiAction: "GetIssues",
    });
    if (results) {
      features = results;
      refreshKey.value += 1;
    }
  } catch (error) {
    console.log(error);
  }
  for (var item of features) {
    formatDisplayName(item);
    item.fields.comment.comments.forEach((comment) => {
      formatCommentName(comment, item);
    });
    formatLabels(item);
    if (item.fields.status.name == "Qualifying") {
      qualifyingArray.push(item.fields.status.name);
    } else if (item.fields.status.name == "In Progress") {
      inProgressArray.push(item.fields.status.name);
    } else if (item.fields.status.name == "Done") {
      doneArray.push(item.fields.status.name);
    } else {
      console.log("Item has 'DELETED' status");
    }
  }
  loading.value = false;
}

async function postComment(comment, item) {
  comment = "*" + item.userDisplayName + "*: " + comment;
  try {
    loading.value = true;
    const commentResult = await apiQuery("ELA", "features/request", {
      apiAction: "PostComment",
      comment: comment,
      commentAuthor: item.userSubmitted,
      key: item.key,
      id: item.id,
    });
  } catch (error) {
    console.log(error);
  }
  getData();
  commentOverlay.value = false;
}

onMounted(() => {
  getData();
});

function extractDescription(item) {
  const hasFRValue = item.fields.labels.includes("BugFix");
  if (hasFRValue == true) {
    var t = item.fields.description;
    t = t.substr(0, t.lastIndexOf("Work around?"));
    return t;
  } else {
    var t2 = item.fields.description;
    t2 = t2.substr(0, t2.indexOf("---"));
    return t2;
  }
}

function formatDisplayName(item) {
  let userDisplayName = item.userSubmitted.slice(0, -13);
  userDisplayName = userDisplayName.replaceAll(".", " ");
  let words = userDisplayName.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  userDisplayName = words.join(" ");
  item.userDisplayName = userDisplayName;

  let assigneeDisplayName = "";

  if (item.fields.assignee === null) {
    item.fields.assignee = ".";
    assigneeDisplayName = item.fields.assignee;
  } else {
    assigneeDisplayName = item.fields.assignee.displayName;
  }
  assigneeDisplayName = assigneeDisplayName.replaceAll(".", " ");
  if (assigneeDisplayName != " ") {
    let words2 = assigneeDisplayName.split(" ");
    for (let i = 0; i < words2.length; i++) {
      words2[i] = words2[i][0].toUpperCase() + words2[i].substr(1);
    }
    item.assigneeDisplayName = words2.join(" ");
  } else {
    item.assigneeDisplayName = assigneeDisplayName;
  }
  return item;
}

function formatCommentName(comment, item) {
  comment.externalCommentIndex = comment.body.indexOf("*:");
  if (comment.externalCommentIndex == -1) {
    let commentDisplayName = comment.author.displayName.replaceAll(".", " ");
    let words = commentDisplayName.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    commentDisplayName = words.join(" ");
    comment.commentDisplayName = commentDisplayName;
  } else {
    let exCommentDisplayName = item.userSubmitted.slice(0, -13);
    exCommentDisplayName = exCommentDisplayName.replaceAll(".", " ");
    let words2 = exCommentDisplayName.split(" ");
    for (let i = 0; i < words2.length; i++) {
      words2[i] = words2[i][0].toUpperCase() + words2[i].substr(1);
    }
    exCommentDisplayName = words2.join(" ");
    comment.exCommentDisplayName = exCommentDisplayName;
    comment.body = comment.body.substr(comment.externalCommentIndex + 2);
  }

  return comment;
}

function formatLabels(item) {
  item.fields.labels = item.fields.labels.toString();
  item.fields.labels = item.fields.labels.replaceAll(",", " | ");
  return item;
}

//Username of logged in user is appended to the comment body here also.
function itemPassThrough(item) {
  commentOverlay.value = true;
  overlayItem = item;
  return overlayItem;
}

function simpleMarkdown(mdText) {
  // first, handle syntax for code-block
  mdText = mdText.replace(/\r\n/g, "\n");
  mdText = mdText.replace(
    /\n~~~ *(.*?)\n([\s\S]*?)\n~~~/g,
    '<pre><code title="$1">$2</code></pre>'
  );
  mdText = mdText.replace(
    /\n``` *(.*?)\n([\s\S]*?)\n```/g,
    '<pre><code title="$1">$2</code></pre>'
  );

  // split by "pre>", skip for code-block and process normal text
  var mdHTML = "";
  var mdCode = mdText.split("pre>");

  for (var i = 0; i < mdCode.length; i++) {
    if (mdCode[i].substr(-2) == "</") {
      mdHTML += "<pre>" + mdCode[i] + "pre>";
    } else {
      mdHTML += mdCode[i]
        .replace(/(.*)<$/, "$1")
        .replace(/^##### (.*?)\s*#*$/gm, "<h5>$1</h5>")
        .replace(/^#### (.*?)\s*#*$/gm, '<h4 id="$1">$1</h4>')
        .replace(/^### (.*?)\s*#*$/gm, '<h3 id="$1">$1</h3>')
        .replace(/^## (.*?)\s*#*$/gm, '<h2 id="$1">$1</h2>')
        .replace(/^# (.*?)\s*#*$/gm, '<h1 id="$1">$1</h1>')
        .replace(/^-{3,}|^_{3,}|^\*{3,}/gm, "<hr/>")
        .replace(/``(.*?)``/gm, "<code>$1</code>")
        .replace(/`(.*?)`/gm, "<code>$1</code>")
        .replace(
          /^>> (.*$)/gm,
          "<blockquote><blockquote>$1</blockquote></blockquote>"
        )
        .replace(/^> (.*$)/gm, "<blockquote>$1</blockquote>")
        .replace(/<\/blockquote>\n<blockquote>/g, "\n<br>")
        .replace(/<\/blockquote>\n<br><blockquote>/g, "\n<br>")
        .replace(
          /!\[(.*?)\]\((.*?) "(.*?)"\)/gm,
          '<img alt="$1" src="$2" $3 />'
        )
        .replace(/!\[(.*?)\]\((.*?)\)/gm, '<img alt="$1" src="$2" />')
        .replace(
          /\[(.*?)\]\((.*?) "(.*?)"\)/gm,
          '<a href="$2" title="$3">$1</a>'
        )
        .replace(/<http(.*?)>/gm, '<a href="http$1">http$1</a>')
        .replace(/\[(.*?)\]\(\)/gm, '<a href="$1">$1</a>')
        .replace(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2">$1</a>')
        .replace(/^[*|+|-][ |.](.*)/gm, "<ul><li>$1</li></ul>")
        .replace(/<\/ul>\n<ul>/g, "\n")
        .replace(/^\d[ |.](.*)/gm, "<ol><li>$1</li></ol>")
        .replace(/<\/ol>\n<ol>/g, "\n")
        .replace(/\*\*\*(.*)\*\*\*/gm, "<b><em>$1</em></b>")
        .replace(/\*\*(.*)\*\*/gm, "<b>$1</b>")
        .replace(/\*([\w \d]*)\*/gm, "<em>$1</em>")
        .replace(/___(.*)___/gm, "<b><em>$1</em></b>")
        .replace(/__(.*)__/gm, "<u>$1</u>")
        .replace(/_([\w \d]*)_/gm, "<em>$1</em>")
        .replace(/~~(.*)~~/gm, "<del>$1</del>")
        .replace(/\^\^(.*)\^\^/gm, "<ins>$1</ins>")
        .replace(/ +\n/g, "\n<br/>")
        .replace(/\n\s*\n/g, "\n<p>\n")
        .replace(/^ {4,10}(.*)/gm, "<pre><code>$1</code></pre>")
        .replace(/^\t(.*)/gm, "<pre><code>$1</code></pre>")
        .replace(/<\/code><\/pre>\n<pre><code>/g, "\n")
        .replace(/\\([`_\\*+\-.()[]\{\}])/gm, "$1");
    }
  }

  return mdHTML.trim();
}
</script>

<style scoped>
.card-style {
  margin: 1%;
  padding: 2%;
  text-align: center;
}
.card-title {
  font-weight: 700;
  line-height: 2rem;
  text-align: left;
}
.comment {
  margin: 5%;
  padding: 2%;
}
</style>

<template>
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
      indeterminate
      size="64"
      color="#e7541e"
    ></v-progress-circular>
  </v-overlay>

  <v-row style="padding: 2%">
    <v-col col="6" style="align-self: center">
      <h1 style="color: rgb(231, 84, 30)">Feature Requests</h1>
    </v-col>
    <v-col col="2">
      <v-card>
        <v-card-title class="card-title" v-if="qualifyingArray[0]">
          {{ qualifyingArray[0] }}
        </v-card-title>
        <v-card-title class="card-title" v-else> Qualifying </v-card-title>
        <v-card-text class="card-style">
          <h3>{{ qualifyingArray.length }}</h3>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col col="2">
      <v-card>
        <v-card-title class="card-title" v-if="inProgressArray[0]">
          {{ inProgressArray[0] }}
        </v-card-title>
        <v-card-title class="card-title" v-else> In Progress </v-card-title>
        <v-card-text class="card-style">
          <h3>{{ inProgressArray.length }}</h3>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col col="2">
      <v-card>
        <v-card-title class="card-title" v-if="doneArray[0]">
          {{ doneArray[0] }}
        </v-card-title>
        <v-card-title class="card-title" v-else> Done </v-card-title>
        <v-card-text class="card-style">
          <h3>{{ doneArray.length }}</h3>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

  <EasyDataTable
    style="text-align: center; list-style-position: inside"
    :headers="headers"
    :items="features"
    buttons-pagination
    theme-color="#e7541e"
    :key="refreshKey"
  >
    <template #expand="item">
      <v-row style="padding: 1%">
        <v-col cols="7">
          <v-card>
            <v-card-title class="card-title">{{
              item.fields.summary
            }}</v-card-title>
            <v-card-text class="card-style">
              <blockquote
                v-html="extractDescription(item)"
                style="margin-left: 1vw"
              ></blockquote>
              <br />
              <br />
              <div>
                <v-row>
                  <v-col col="2" float="left">
                    <b>Created by: </b>{{ item.userSubmitted }}
                  </v-col>
                  <v-col col="2">
                    <b>Assigned to: </b>
                    <p>{{ item.assigneeDisplayName }}</p>
                  </v-col>
                  <v-col col="2">
                    <b>Date created: </b>
                    <p>{{ item.dateCreatedFormat }}</p>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card>
            <v-card-title class="card-title">Comments</v-card-title>
            <v-card-text class="card-style">
              <div v-if="item.fields.comment.comments.length == 0">
                <h4>No Comments Yet</h4>
              </div>
              <div v-else>
                <div
                  style="margin-bottom: 3%"
                  v-for="comment in item.fields.comment.comments"
                  :key="comment.id"
                >
                  <div
                    v-if="comment.externalCommentIndex != -1"
                    style="margin-left: 10vw; text-align: left"
                  >
                    <b>{{ comment.exCommentDisplayName }}</b
                    >: {{ comment.dateCommentFormat }}
                    <br />
                    <v-card class="comment rounded-pill">
                      <div>
                        <blockquote
                          v-html="simpleMarkdown(comment.body)"
                          style="margin-left: 2vw"
                        ></blockquote>
                      </div>
                    </v-card>
                  </div>
                  <div v-else style="margin-right: 10vw; text-align: left">
                    <b>{{ comment.commentDisplayName }}</b
                    >: {{ comment.dateCommentFormat }}
                    <br />
                    <v-card class="comment rounded-pill">
                      <div>
                        <blockquote
                          v-html="simpleMarkdown(comment.body)"
                          style="margin-left: 2vw"
                        ></blockquote>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="card-style" @click="itemPassThrough(item)">
            <h2>Leave Comment</h2>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </EasyDataTable>
  <v-overlay
    persistent
    :model-value="commentOverlay"
    contained
    class="align-center justify-center"
  >
    <v-card class="rounded-xl">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-textarea
          v-model="overlayComment.value"
          auto-grow
          prepend-inner-icon="mdi-comment"
          variant="solo-filled"
          clearable
        ></v-textarea>
        <v-row style="padding-top: 3%">
          <v-col>
            <v-btn
              type="button"
              @click="postComment(overlayComment.value, overlayItem)"
            >
              Post Comment
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              @click="
                commentOverlay = false;
                cancelled = true;
              "
              class="align-right"
              style="margin: 1%"
              >Cancel</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
