<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const headers = [
  { text: "Application Name", value: "appName" },
  { text: "Version", value: "version" },
  { text: "Release Date", value: "releaseDate" },
  { text: "SNOW Request", value: "ticketNo" },
  { text: "App Stream", value: "stream" },
  { text: "Approve", value: "actions" },
];

const patchesToApprove = reactive([]);
const loading = ref(false);

async function getUnapproved() {
  loading.value = true;
  let result = await apiQuery("ELA", "patching/patchingVersions", {
    apiAction: "GetUnapproved",
  });
  if (result.length) {
    patchesToApprove.length = 0;
    patchesToApprove.push(...result);
  }
  loading.value = false;
}

function formatDate(dateInput) {
  try {
    let date = new Date(dateInput).toLocaleString("en-GB");
    console.log(date);
    return date;
  } catch (error) {
    return "";
  }
}
function approveUpdate(item) {
  console.log(item);
  showDialog.value = true;
  patchToApprove.value = item;
}

async function confirmApprove() {
  try {
    loading.value = true;
    let result = await apiQuery("ELA", "patching/patchingVersions", {
      apiAction: "ApproveVersion",
      patch: patchToApprove.value,
    });
  } catch (error) {
    console.log(error);
  }
  getUnapproved();
  showDialog.value = false;
}

onMounted(() => {
  getUnapproved();
});

const showDialog = ref(false);
const patchToApprove = ref({});
</script>

<template>
  <h2>Pending Approvals</h2>
  <v-card>
    <v-card-text>
      <EasyDataTable
        :headers="headers"
        :items="patchesToApprove"
        :loading="loading"
        alternating
        theme-color="#e7541e"
      >
        <template #item-releaseDate="item">
          {{ formatDate(item.releaseDate) }}
        </template>
        <template #item-actions="item">
          <v-btn
            size="small"
            color="success"
            prepend-icon="mdi-check"
            @click="approveUpdate(item)"
            >Approve</v-btn
          >
          <!-- <v-btn prepend-icon="mdi-close">Escalate</v-btn> -->
        </template>
      </EasyDataTable>
    </v-card-text>
  </v-card>

  <v-dialog v-model="showDialog" max-width="500">
    <v-card>
      <v-card-title>Confirm Approve Patch</v-card-title>
      <v-card-text>
        Please confirm to approve this version. After approving it will be
        deployed.
        <v-table class="mt-2">
          <tr class="text-left">
            <th>App</th>
            <th>Version</th>
          </tr>
          <tr>
            <td>{{ patchToApprove.appName }}</td>
            <td>{{ patchToApprove.version }}</td>
          </tr>
        </v-table>
        <v-checkbox
          label="Confirm"
          v-model="patchToApprove.confirmed"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions
        ><v-btn variant="tonal" @click="showDialog = false">Cancel</v-btn
        ><v-btn
          :loading="loading"
          v-if="patchToApprove.confirmed"
          variant="tonal"
          @click="confirmApprove()"
          >Submit</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
