<script setup>
import { defineProps } from "vue";
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  Plugin,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const props = defineProps({
  chartData: {},
  chartOptions: {},
  height: Number,
  width: Number,
});
</script>

<template>
  <Pie
    :chartData="props.chartData"
    :chart-options="props.chartOptions"
    :height="props.height"
    :width="props.width"
  />
</template>
