<template>
  <h4 v-if="props.edit">Edit Record</h4>
  <h4 v-else>Add Application</h4>
  <v-text-field
    class="mt-2"
    label="Application Name (Required)"
    v-model="fields['name']"
    density="compact"
    hide-details="auto"
  />
  <!-- <v-text-field
    class="mt-2"
    label="recipie Name (Required)"
    v-model="fields['recipeName']"
    density="compact"
    hide-details="auto"
  /> -->
  <v-text-field
    class="mt-2"
    label="Install Path (Required)"
    v-model="fields['InstallPath']"
    density="compact"
    hide-details="auto"
  />
  <v-text-field
    class="mt-2"
    label="Build Script Name Path (Required)"
    v-model="fields['buildScript']"
    density="compact"
    hide-details="auto"
  />
  <v-file-input
    class="mt-2"
    label="AutoPkg Recipie Zip (Required)"
    v-model="fields['zipLocation']"
    density="compact"
    hide-details="auto"
    accept=".zip"
  />

  <template v-for="(item, index) in blockingData" v-bind:key="item">
    Blocking App {{ index + 1 }}
    <v-icon class="align-center" color="" @click="removeRow(index)"
      >mdi mdi-delete</v-icon
    >
    <v-text-field
      class="mt-2"
      label="Application Name (Include '.app') (Required)"
      v-model="blockingData[index]['appName']"
      density="compact"
      hide-details="auto"
    />
    <v-text-field
      class="mt-2"
      label="Application Bundle ID (Required)"
      v-model="blockingData[index]['bundleId']"
      density="compact"
      hide-details="auto"
    />
  </template>
  <div @click="addRow()">
    Add Blocking Application
    <v-icon color="">mdi-plus-box</v-icon>
  </div>
  <v-row class="mt-2">
    <v-col
      ><v-btn :disabled="saving" @click="saveNewPatch(this)">Save</v-btn></v-col
    >
    <v-col><v-spacer></v-spacer></v-col>
    <v-col class="text-right"
      ><v-btn @click="$emit('cancel')">Cancel</v-btn></v-col
    >
  </v-row>
  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, defineEmits } from "vue";
import { Auth } from "aws-amplify";
const fields = reactive({});
import { apiQuery } from "@/api/j24api";
const alert = ref("");
const saving = ref(false);
const loading = ref(false);
var blockingData = ref([]);

const props = defineProps({
  edit: Boolean,
  item: {},
  email: String,
  currentData: Object,
});

const emit = defineEmits(["updated"]);

// onMounted(async () => {
//   console.log("---");
//   console.log(props.email);
// });

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

async function addRow() {
  blockingData.value.push({ appName: "", bundleId: "" });
}

function removeRow(item) {
  blockingData.value.splice(item, 1);
}

async function saveNewPatch(event) {
  loading.value = true;
  saving.value = true;
  alert.value = "";
  var fieldNumber = 0;
  for (var field in fields) {
    fieldNumber = fieldNumber + 1;
  }
  if (fieldNumber < 4) {
    alert.value = "Please fill in each field.";
    saving.value = false;
    return;
  }

  try {
    fields["fileData"] = await getBase64(fields.zipLocation[0]);
  } catch {
    alert.value = "Unable to parse zip file";
    saving.value = false;
    return;
  }

  if (!fields["InstallPath"].includes(".app")) {
    alert.value =
      "Please give the full install path, eg /Applications/Company Portal.app";
    saving.value = false;
    return;
  }

  var addedDate = new Date(Date.now()).toLocaleString();

  fields["addedBy"] = props.email;
  fields["addedOn"] = addedDate;
  fields["apiAction"] = "AddPatchItem";
  fields["type"] = "recipe";
  fields["killApps"] = blockingData.value;

  try {
    const result = await apiQuery("ELA", "patching/patchitems", fields);
    loading.value = false;
    if (result.statusCode != 200) {
      alert.value = "Error saving record - " + result.body;
      saving.value = false;
      loading.value = false;
    } else {
      emit("updated");
    }
  } catch (error) {
    alert.value = "Error saving record - " + error;
    saving.value = false;
    loading.value = false;
    loading.value = false;
  }
}
</script>
