<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";
import cat24ImportCSV from "../cat24ImportCSV.vue";
import cat24AddRow from "../cat24AddRow.vue";

const appBar = appBarStore();
const addresses = reactive([]);
const loading = ref(false);
const searchField = ref("");
const searchValue = ref("");
const searchFieldSelected = ref("");

const importCSV = ref(false);
const addRow = ref(false);
const editItem = ref({});
const editRow = ref(false);
const displayConfirmDelete = ref(false);
const itemToDelete = ref({});

async function confirmDelete() {
  displayConfirmDelete.value = false;
  loading.value = true;
  await apiQuery("ELA", "cat24/addresses", {
    apiAction: "delete",
    customer: appBar.selected,
    address: itemToDelete.value,
  });
  getData();
}

async function deleteRow(item) {
  itemToDelete.value = item;
  displayConfirmDelete.value = true;
}

function openEdit(item) {
  editItem.value = item;
  editRow.value = true;
}

appBar.$subscribe(() => {
  if (appBar.selected != "") {
    getData();
  }
});

async function getData() {
  loading.value = true;
  const response = await apiQuery("ELA", "cat24/addresses", {
    apiAction: "get",
    customer: appBar.selected,
  });
  if (response.length) {
    addresses.length = 0;
    response.forEach((address) => {
      addresses.push(address);
    });
  }
  loading.value = false;
  console.log(response);
}

const headers = [
  { text: "Email Address", value: "email" },
  { text: "Line 1", value: "line1" },
  { text: "Line 2", value: "line2" },
  { text: "City", value: "city" },
  { text: "Postcode", value: "postcode" },
  { text: "Actions", value: "actions" },
];

function searchFields() {
  var result = [];
  headers.forEach((header) => {
    if (header.text != "Actions") result.push(header.text);
  });
  return result;
}

onMounted(async () => {
  if (appBar.selected != "") {
    getData();
  }
});
</script>

<template>
  <template v-if="!appBar.selected.length">
    <h3>Select Customer from Menu above</h3>
  </template>
  <template v-else>
    <cat24ImportCSV
      v-if="importCSV"
      @cancel="importCSV = !importCSV"
      @updated="(importCSV = !importCSV), getData()"
      importType="addresses"
      :currentData="addresses"
      duplicateCheckField="email"
    />
    <cat24AddRow
      v-if="addRow"
      addType="addresses"
      @cancel="addRow = !addRow"
      @updated="(addRow = !addRow), getData()"
      :currentData="addresses"
    />
    <cat24AddRow
      v-if="editRow"
      edit
      :item="editItem"
      addType="addresses"
      @cancel="editRow = !editRow"
      @updated="(editRow = !editRow), getData()"
    />
    <template v-if="!importCSV && !addRow && !editRow">
      <v-row no-gutters style="height: 35px">
        <v-col class="flex-grow-0 flex-shrink 0" cols="2">
          <v-btn
            height="33px"
            prepend-icon="mdi-upload"
            size="small"
            @click="importCSV = true"
            >Import CSV</v-btn
          >
        </v-col>
        <v-col class="pa-2">
          <v-select
            label="Search Field"
            density="compact"
            variant="underlined"
            v-model="searchFieldSelected"
            :items="searchFields()"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            variant="underlined"
            height="25px"
            class="pl-2"
            density="compact"
            label="Search Value"
            v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn
            height="33px"
            prepend-icon="mdi-plus"
            size="small"
            class="pa-2 ml-3"
            @click="addRow = true"
            >Add New</v-btn
          >
        </v-col>
      </v-row>
      <EasyDataTable
        :headers="headers"
        :items="addresses"
        theme-color="#e7541e"
        alternating
        buttons-pagination
        class="mt-3"
        :loading="loading"
        :search-field="searchField"
        :search-value="searchValue"
      >
        <template #item-actions="item">
          <div>
            <v-icon @click="openEdit(item)">mdi-pencil</v-icon>
            <v-icon class="ml-1" @click="deleteRow(item)">mdi-delete</v-icon>
          </div>
        </template>
      </EasyDataTable>
    </template>
  </template>
  <v-dialog v-model="displayConfirmDelete" persistent
    ><v-card>
      <v-card-title>Confirm Delete Address</v-card-title>
      <v-card-text
        >Please confirm that you wish to delete this address, this action cannot
        be undone.</v-card-text
      >
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn @click="displayConfirmDelete = false">Cancel</v-btn
        ><v-btn @click="confirmDelete()">Delete</v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
