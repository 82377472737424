<script setup>
import { apiQuery } from "@/api/j24api";
import { ref, reactive, defineProps, watch, onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";

const appBar = appBarStore();

const loading = ref(false);
const loadingTxt = ref("");
const props = defineProps({
  data: Object,
});
const messages = reactive([]);
const messagesOriginal = reactive([]);

function correctLogData() {
  messages.forEach((message, index) => {
    if (message.map.type == "heartbeat") {
      messages[index].map.errortype = "heartbeat";
    }
  });
}

async function getData() {
  // Request Report
  loading.value = true;
  loadingTxt.value = "Loading data";
  try {
    const result = await apiQuery("ELA", "cmd24/query", {
      mdmServerId: appBar.selected,
      dataRequested: "patchingDetailsCreate",
      computerID: props.data.id,
      days: timePeriodChosen.value,
    });
    let searchID = result.searchID;
    let maxAttempts = 300;
    var count = 0;
    while (count < maxAttempts) {
      let result = await apiQuery("ELA", "cmd24/query", {
        searchID: searchID,
        dataRequested: "patchingDetailsGet",
        mdmServerId: appBar.selected,
      });
      if (result.state == "Complete") {
        messages.length = 0;
        messages.push(...result.messages);
        correctLogData();
        populateLogTypes();
        messagesOriginal.push(...messages);
        count += maxAttempts;
      }
      console.log(result.response);
      if (result.response.messageCount) {
        loadingTxt.value =
          "Loading data, found " + result.response.messageCount + " records.";
      }

      if (timePeriodChosen.value > "15") {
        await sleep(2500);
      }
      await sleep(500);
      count++;
    }
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

const logTypes = reactive([]);
function populateLogTypes() {
  messages.forEach((message) => {
    var match = false;
    logTypes.forEach((type, index) => {
      if (type.type == message.map.errortype) {
        logTypes[index].count++;
        match = true;
      }
    });
    if (!match) {
      logTypes.push({ type: message.map.errortype, count: 1, selected: true });
    }
  });
}

function logTypeSelectText(type) {
  return type.type + " (" + type.count + ")";
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const headers = [
  { text: "Date Time", value: "map.timestamp", sortable: true },
  { text: "LogType", value: "map.errortype", sortable: true },
  { text: "Message Body", value: "map.body", sortable: true },
];

onMounted(() => {
  getData();
});

function logTypeUpdated() {
  console.log("Testing123");
  var updatedMessages = [];
  messagesOriginal.forEach((message) => {
    logTypes.forEach((type) => {
      if (message.map.errortype == type.type && type.selected) {
        updatedMessages.push(message);
      }
    });
  });
  messages.length = 0;
  messages.push(...updatedMessages);
}

function displayedFields(item) {
  const keys = Object.keys(item);
  const result = [];
  keys.forEach((key) => {
    if (key[0] != "_" && item[key]) {
      result.push({ label: capitalizeFirstLetter(key), value: item[key] });
    }
  });
  return result;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const timePeriodValues = [
  { title: "7 Days", value: 7 },
  { title: "14 Days", value: 14 },
  { title: "30 Days", value: 30 },
  { title: "90 Days", value: 90 },
];

const timePeriodChosen = ref(7);
watch(timePeriodChosen, () => {
  console.log("time period changed");
  getData();
});
</script>

<template>
  <template v-if="loading">
    <v-row class="pt-6">
      <v-spacer></v-spacer>
      <v-col>
        <v-progress-circular indeterminate size="64" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </template>

  <template v-if="messagesOriginal.length">
    <v-row class="my-2">
      <v-col cols="3">
        <v-card color="white" class="text-caption">
          <v-card-title class="pl-1">Log Types</v-card-title>
          <div class="d-flex flex-column">
            <v-select
              label="Time Period"
              :items="timePeriodValues"
              v-model="timePeriodChosen"
            ></v-select>
            <template v-for="type in logTypes" :key="type">
              <v-checkbox
                @change="logTypeUpdated"
                hide-details="true"
                height="0"
                class="logTypeSelector shrink"
                v-model="type.selected"
                :label="logTypeSelectText(type)"
                density="compact"
              ></v-checkbox>
            </template>
          </div>
        </v-card>
      </v-col>
      <v-col cols="9">
        <EasyDataTable
          :items="messages"
          :headers="headers"
          :loading="loading"
          theme-color="#e7541e"
          alternating
        >
          <template #expand="item">
            <v-table>
              <tr v-for="field in displayedFields(item.map)" :key="field.label">
                <td class="font-weight-bold">{{ field.label }}</td>
                <td>{{ field.value }}</td>
              </tr>
            </v-table>
          </template>
        </EasyDataTable>
      </v-col>
    </v-row>
  </template>
</template>

<style scoped>
.logTypeSelector {
  font-size: 12px;
}

.v-label {
  font-size: 12px;
}
</style>
