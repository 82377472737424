<script setup>
import { onMounted, ref } from "vue";
import { appBarStore } from "@/stores/appBar";
const appBar = appBarStore();

onMounted(async () => {
  console.log("appBar: ", appBar);
  if (appBar.appRunning != "FeatureRequest") {
    appBar.$patch({
      appRunning: "FeatureRequest",
      type: "None",
      items: [],
      selected: "",
    });
  }
});

const items = [
  // { title: "Assets", icon: "mdi-archive", link: "AssetsBulkUploader" },
  {
    title: "Requests",
    icon: "mdi-server",
    link: "FeatureRequest",
    value: false,
  },
];

// Required to make the router-view reload when user clicks the link again
// See the key value set on <router-view> below.
const randomKey = ref("");
function updateRandom() {
  randomKey.value = (Math.random() + 1).toString(36).substring(7);
}
</script>

<template>
  <v-navigation-drawer permanent width="190" theme="jigsawDark">
    <v-list>
      <div class="v-list-item">
        <v-icon class="pa-3" color="#e7541e">mdi-account-details</v-icon>
        <div class="text-h7 pa-3 cursor-default">
          <strong>Features</strong>
        </div>
      </div>
      <template v-for="item in items" :key="item.title">
        <v-list-item
          link
          :to="{ name: item.link }"
          color="#e7541e"
          @click="updateRandom()"
        >
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view :key="randomKey" />
    </v-container>
  </v-main>
</template>
