<script setup>
import { onMounted, reactive, ref } from "vue";
import { apiQuery } from "@/api/j24api";
import PatchAppDetail from "./patchAppDetail.vue";

const loading = ref(false);
const apps = reactive([]);

const headers = [
  { text: "Name", value: "name", sortable: true },
  { text: "Intel Version", value: "intelVersion", sortable: true },
  { text: "Apple Silicon Version", value: "asVersion", sortable: true },
  { text: "Last Modified", value: "lastModified", sortable: true },
];

async function getApps() {
  loading.value = true;
  const result = await apiQuery("ELA", "patching/patchingVersions", {
    apiAction: "GetAppsList",
  });
  if (result.apps) {
    apps.length = 0;
    apps.push(...combineApps(result.apps));
  }
  loading.value = false;
}

function combineApps(apps) {
  var results = [];
  apps.forEach((app) => {
    const toAdd = {};
    if (app.stream == "AppleSilicon") {
      toAdd.asVersion = app.currentVersion;
      toAdd.asId = app._id;
    }
    if (app.stream == "Intel") {
      toAdd.intelVersion = app.currentVersion;
      toAdd.intelId = app._id;
    }
    var foundMatch = false;
    results.forEach((match, index) => {
      if (match.name == app.name) {
        foundMatch = true;
        results[index] = { ...results[index], ...toAdd };
        if (match.lastModified < app.lastModified) {
          results[index].lastModified = app.lastModified;
        }
      }
    });
    if (!foundMatch) {
      results.push({
        name: app.name,
        lastModified: app.lastModified,
        ...toAdd,
      });
    }
  });
  return results;
}

function formatDate(date) {
  let dateFormat = new Date(date);
  return dateFormat.toLocaleDateString("en-GB");
}

onMounted(() => {
  getApps();
});

const showPatchDetail = ref(false);
const patchDetail = ref({});
const selectedTab = ref("");

const showPatchDetailClick = (item) => {
  patchDetail.value = item;
  showPatchDetail.value = true;
};
</script>

<template>
  <template v-if="showPatchDetail">
    <v-row align="center">
      <v-col cols="auto">
        <v-icon class="pt-2" size="x-large" @click="showPatchDetail = false"
          >mdi-arrow-left-circle</v-icon
        >
      </v-col>
      <v-col>
        <v-tabs v-model="selectedTab" color="#e7541e">
          <v-tab value="as">Apple Silicon</v-tab>
          <v-tab value="intel">Intel</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-window v-model="selectedTab">
      <v-window-item value="as">
        <v-card>
          <patchAppDetail :appId="patchDetail.asId" />
        </v-card>
      </v-window-item>
      <v-window-item value="intel">
        <PatchAppDetail :appId="patchDetail.intelId" />
      </v-window-item>
    </v-window>
  </template>

  <template v-if="!showPatchDetail">
    <EasyDataTable
      :headers="headers"
      :items="apps"
      :loading="loading"
      alternating
      theme-color="#e7541e"
      @click-row="showPatchDetailClick"
    >
      <template #item-lastModified="item">
        {{ formatDate(item.lastModified) }}
      </template>
    </EasyDataTable>
    <template v-if="!showPatchDetail"> </template>
  </template>
</template>
