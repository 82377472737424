<template>
  <template v-if="!appBar.selected.length">
    <h3>Select Customer from Menu above</h3>
  </template>
  <template v-else>
    <cat24ImportCSV
      v-if="importCSV"
      @cancel="importCSV = !importCSV"
      @updated="(importCSV = !importCSV), getData()"
      importType="assets"
    />
    <cat24AddRow
      v-if="addRow"
      addType="assets"
      @cancel="addRow = !addRow"
      @updated="(addRow = !addRow), getData()"
    />
    <cat24AddRow
      v-if="editRow"
      edit
      :item="editItem"
      addType="assets"
      @cancel="editRow = !editRow"
      @updated="(editRow = !editRow), getData()"
    />
    <template v-if="!importCSV && !addRow && !editRow">
      <v-row no-gutters style="height: 40px">
        <v-col class="flex-grow-0 flex-shrink 0" cols="2">
          <v-btn
            height="33px"
            prepend-icon="mdi-upload"
            size="small"
            class=""
            @click="importCSV = true"
          >
            Import CSV
          </v-btn>
        </v-col>
        <v-col>
          <v-select
            label="Search Field"
            density="compact"
            variant="underlined"
            v-model="searchFieldSelected"
            :items="searchFields()"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            variant="underlined"
            height="25px"
            class="pl-2"
            density="compact"
            label="Search Value"
            v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn
            height="33px"
            prepend-icon="mdi-plus"
            size="small"
            class="pa-2 ml-3"
            @click="addRow = true"
            >Add New</v-btn
          >
        </v-col>
      </v-row>

      <EasyDataTable
        :headers="headers"
        :items="items"
        theme-color="#e7541e"
        alternating
        buttons-pagination
        class="mt-3"
        :loading="loading"
        :search-field="searchField"
        :search-value="searchValue"
      >
        <template #item-actions="item">
          <v-icon @click="openEdit(item)">mdi-pencil</v-icon>
          <v-icon class="ml-1" @click="deleteRow(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </template>
  </template>
  <!-- <h4>{{ token }}</h4>
  <div v-if="token">
    <h4>{{ token }}</h4>
  </div> -->
</template>

<style>
.v-select__selection-text {
  font-size: 0.9em;
}
</style>
<script setup>
import { reactive, ref, onMounted, watch } from "vue";
//import type { Header, Item } from "vue3-easy-data-table";
import cat24ImportCSV from "../cat24ImportCSV.vue";
import cat24AddRow from "../cat24AddRow.vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
const items = reactive([]);

appBar.$subscribe(() => {
  console.log("State Changed");
  if (appBar.selected != "") {
    getData();
  }
});

const headers = [
  { text: "Contact Email Address", value: "contactEmail" },
  { text: "Serial Number", value: "serialNumber" },
  { text: "Make", value: "make" },
  { text: "Model", value: "model" },
  { text: "Unique Identifier", value: "uniqueIdentifier" },
  { text: "IMEIs", value: "imeis" },
  { text: "Actions", value: "actions" },
];

const searchField = ref("");
const searchValue = ref("");
const searchFieldSelected = ref("");

function searchFields() {
  var result = [];
  headers.forEach((header) => {
    if (header.text != "Actions") result.push(header.text);
  });
  return result;
}

watch(searchFieldSelected, (currentValue) => {
  headers.forEach((header) => {
    if (header.text == currentValue) {
      searchField.value = header.value;
    }
  });
});

const importCSV = ref(false);
const addRow = ref(false);
const editItem = ref({});
const editRow = ref(false);
const loading = ref(false);

async function deleteRow(item) {
  loading.value = true;
  await apiQuery("ELA", "cat24/assets", {
    apiAction: "delete",
    customer: appBar.selected,
    asset: item,
  });
  getData();
}

function openEdit(item) {
  editItem.value = item;
  editRow.value = true;
}

async function getData() {
  loading.value = true;
  const response = await apiQuery("ELA", "cat24/assets", {
    apiAction: "get",
    customer: appBar.selected,
  });
  items.length = 0;
  response.forEach((element) => {
    items.push(element);
  });
  loading.value = false;
}

onMounted(async () => {
  if (appBar.selected != "") {
    getData();
  }
});
</script>
