<script setup>
import { ref, reactive, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const loading = ref(false);
const configurations = reactive([]);
const configuration = ref(false);

const headers = [
  { text: "Name", value: "name", sortable: true },
  { text: "Type", value: "type", sortable: true },
  { text: "Actions", value: "actions", sortable: false },
];

const alerts = reactive([]);
const formValid = ref(true);

const permissions = ["Read Case", "Create Case", "Update Case"];
const permissionsState = reactive([]);
const webhookMethods = ["GET", "POST", "PUT", "DELETE"];
const webHookTriggers = ["Case Created", "Case Updated"];

const addingNew = ref(false);
function addNew() {
  configuration.value = {
    accountIds: [""],
    outboundIntegrations: [],
    serviceNowDefaults: {
      assignmentGroup: "db6b0b371be6681064557c95464bcb3e",
      impact: "2",
      urgency: "2",
    },
    permissions: [],
    type: "Case Integration",
  };
  addingNew.value = true;
  formValid.value = true;
  updatePermissionsState();
}

function selectConfiguration(conf) {
  if (!conf.serviceNowDefaults) {
    conf.serviceNowDefaults = {};
  }
  if (!conf.outboundIntegrations) {
    conf.outboundIntegrations = [];
  }
  configuration.value = conf;
  updatePermissionsState();
}

function updatePermissionsState(update = false) {
  console.log("updatePermissionsState", configuration.value);
  if (!update) {
    permissionsState.length = 0;
    permissions.forEach((permission) => {
      permissionsState.push({
        name: permission,
        value: configuration.value.permissions.includes(permission)
          ? true
          : false,
      });
    });
  }
  if (update) {
    permissionsState.forEach((permission) => {
      if (permission.value) {
        if (!configuration.value.permissions.includes(permission.name)) {
          configuration.value.permissions.push(permission.name);
        }
      } else {
        if (configuration.value.permissions.includes(permission.name)) {
          configuration.value.permissions.splice(
            configuration.value.permissions.indexOf(permission.name),
            1
          );
        }
      }
    });
  }
}

async function getConfigurations() {
  loading.value = true;
  try {
    let result = await apiQuery("ELA", "integrationsmanagement", {
      action: "GetConfigs",
    });
    if (result.length > 0) {
      configurations.length = 0;
      configurations.push(...result);
    }
    console.log(result);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

onMounted(() => {
  getConfigurations();
});

function required(v) {
  formValid.value = true;
  if (!v) {
    formValid.value = false;
  }
  return !!v || "Required";
}

async function saveConfig() {
  loading.value = true;
  if (addingNew.value) {
    try {
      let result = await apiQuery("ELA", "integrationsmanagement", {
        action: "CreateConfig",
        ...configuration.value,
      });
      if (result._id) {
        configuration.value = result;
        configuration.value.outboundIntegrations = [];
        addingNew.value = false;
      } else {
        alerts.push({
          type: "error",
          text: "Failed to create new integration",
        });
      }
    } catch (error) {
      alerts.push({
        type: "error",
        text: "Failed to create new integration",
      });
    }
  } else {
    try {
      let result = await apiQuery("ELA", "integrationsmanagement", {
        action: "UpdateConfig",
        ...configuration.value,
      });
      configuration.value = false;
    } catch (error) {
      console.log(error);
      alerts.push({
        type: "error",
        text: "Failed to update integration",
      });
    }
  }
  loading.value = false;
}

async function viewAPIKey(conf) {
  let result = await apiQuery("ELA", "integrationsmanagement", {
    action: "GetAPIKey",
    ...conf,
  });
  if (result.apiKey) {
    configuration.value.apiKey = result.apiKey;
  } else {
    alerts.push({
      type: "error",
      text: "Failed to get API Key",
    });
  }
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-alert
        v-for="alert in alerts"
        :key="alert"
        :color="alert.type"
        closable
      >
        {{ alert.text }}
      </v-alert>
    </v-col>
  </v-row>
  <v-row v-if="!configuration">
    <v-col cols="auto">
      <h3>Integrations</h3>
    </v-col>
    <v-spacer />
    <v-col cols="auto">
      <v-btn @click="addNew()">Add Integration</v-btn>
    </v-col>
  </v-row>
  <v-row v-if="!configuration">
    <v-col cols="12">
      <EasyDataTable
        :headers="headers"
        :items="configurations"
        :loading="loading"
        theme-color="#e7541e"
      >
        <template #item-actions="item">
          <v-icon @click="selectConfiguration(item)">mdi-pencil</v-icon>
        </template>
      </EasyDataTable>
    </v-col>
  </v-row>
  <v-row v-if="configuration">
    <v-col cols="12">
      <v-card>
        <v-card-title><h3>Add/Edit Integration</h3></v-card-title>
        <v-card-text>
          <!-- <v-form v-model="formValid" @submit.prevent="onSubmit"> -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="configuration.name"
                label="Integration Name"
                :rules="[required]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col cols="auto">
              <h2>Service Now Account IDs</h2>
            </v-col>
            <v-col cols="auto">
              <v-btn
                @click="
                  configuration.accountIds.push('');
                  required();
                "
                density="compact"
                >Add</v-btn
              >
            </v-col>
          </v-row>

          <v-text-field
            v-for="(accountId, key) in configuration.accountIds"
            v-model="configuration.accountIds[key]"
            append-icon="mdi-close-circle"
            @click:append="configuration.accountIds.splice(key, 1)"
            label="Account ID"
            :rules="[required]"
            :key="key"
          ></v-text-field>

          <template v-if="!addingNew">
            <v-row>
              <v-col>
                <h2>Integration Permissions</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-for="(permission, key) in permissionsState"
                  v-model="permission.value"
                  :label="permission.name"
                  :key="key"
                  hide-details
                  density="compact"
                  @update:modelValue="updatePermissionsState(true)"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2>API Key</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="auto"
                v-if="!configuration.apiKey && configuration.apiKeyId"
              >
                <v-btn @click="viewAPIKey(configuration)">View API Key</v-btn>
              </v-col>
              <v-col v-if="configuration.apiKey">
                <v-text-field
                  label="API Key"
                  v-model="configuration.apiKey"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2>Service Now Case Defaults</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="configuration.serviceNowDefaults.assignmentGroup"
                  label="Assignment Group of new cases(SYS ID)"
                >
                </v-text-field>
                <v-text-field
                  v-model="configuration.serviceNowDefaults.impact"
                  label="Default Impact for new Cases"
                >
                </v-text-field>
                <v-text-field
                  v-model="configuration.serviceNowDefaults.urgency"
                  label="Default Urgency for new Cases"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h2>Outbound Webhooks</h2>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  @click="
                    configuration.outboundIntegrations.push({
                      name: '',
                      url: '',
                      method: '',
                      triggers: [],
                      headers: [],
                    });
                    required();
                  "
                  >Add</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card
                  class="pa-2"
                  v-for="(webhook, key) in configuration.outboundIntegrations"
                  :key="webhook"
                >
                  <v-card-title>
                    <v-row>
                      <v-col> Webhook #{{ key + 1 }} </v-col>
                      <v-spacer />
                      <v-col cols="auto">
                        <v-btn
                          @click="
                            configuration.outboundIntegrations.splice(key, 1)
                          "
                          >Remove</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="webhook.name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          v-model="webhook.url"
                          label="URL"
                          :rules="[required]"
                        />
                        <v-select
                          v-model="webhook.method"
                          :items="webhookMethods"
                          label="Method"
                        />
                        <v-select
                          v-model="webhook.triggers"
                          :items="webHookTriggers"
                          label="Trigger(s)"
                          chips
                          multiple
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="auto">
                        <h3>HTTP Headers</h3>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          @click="
                            webhook.headers.push({ key: '', value: '' });
                            required();
                          "
                          density="compact"
                          >Add</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row
                      v-for="(header, key) in webhook.headers"
                      :key="header"
                    >
                      <v-col>
                        <v-text-field
                          v-model="header.key"
                          label="Key"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="header.value"
                          label="Value"
                          append-icon="mdi-close-circle"
                          @click:append="
                            webhook.headers.splice(key, 1);
                            required();
                          "
                          :rules="[required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="auto">
              <v-btn
                :loading="loading"
                :disabled="!formValid"
                @click="saveConfig()"
                >Save</v-btn
              >
            </v-col>
            <v-col cols="auto">
              <v-btn @click="configuration = false">Cancel</v-btn>
            </v-col>
          </v-row>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
