<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
    <defs></defs>
    <rect class="a" width="255.12" height="255.12" rx="22.68" />
    <path
      class="b"
      d="M270,328.56V513.41a96.48,96.48,0,0,0,93.38-22.21V350.76A96.5,96.5,0,0,0,270,328.56ZM346,434.31l-27.3,37.53a2.42,2.42,0,0,1-3.9,0l-27.29-37.53a2.41,2.41,0,0,1,1.94-3.83H344a2.41,2.41,0,0,1,2,3.83ZM316.73,374a2.4,2.4,0,0,1,1.95,1L346,412.48a2.41,2.41,0,0,1-2,3.83h-54.6a2.41,2.41,0,0,1-1.94-3.83L314.79,375A2.38,2.38,0,0,1,316.73,374Z"
      transform="translate(-169.94 -293.44)"
    />
    <path
      class="b"
      d="M374.2,362.74V479.23a96.38,96.38,0,0,0,0-116.49Z"
      transform="translate(-169.94 -293.44)"
    />
    <path
      class="b"
      d="M201,421a96.39,96.39,0,0,0,58.22,88.53v-177A96.39,96.39,0,0,0,201,421Z"
      transform="translate(-169.94 -293.44)"
    />
    <path
      class="c"
      d="M289.43,416.31H344a2.41,2.41,0,0,0,2-3.83L318.68,375a2.39,2.39,0,0,0-3.89,0l-27.3,37.53a2.41,2.41,0,0,0,1.94,3.83Z"
      transform="translate(-169.94 -293.44)"
    />
  </svg>
</template>

<style>
.a {
  fill: #000000;
}
.b {
  fill: #fff;
}
.c {
  fill: #ea5e0b;
}
</style>
