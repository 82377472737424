/* eslint-disable */
<script setup>
import { reactive, ref, onMounted, defineProps, watch } from "vue";
//import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";
import { Auth } from "aws-amplify";
import newApp from "./newApp.vue";

const props = defineProps({
  patchInfo: Object,
});

const loading = ref(false);
const patchItems = reactive([]);
const dialog = ref({});
dialog.value.Show = ref(false);
dialog.value.Action = ref("");
dialog.value.Title = ref("");
dialog.value.Text = ref("");
dialog.value.Text2 = ref("");
dialog.value.TxtField = ref(false);
dialog.value.TxtFieldLabel = ref("");
dialog.value.TxtFieldValue = ref("");
dialog.value.TxtField2 = ref(false);
dialog.value.TxtFieldLabel2 = ref("");
dialog.value.TxtFieldValue2 = ref("");
dialog.value.Button = ref("");
dialog.value.ButtonDisabled = ref(false);
dialog.value.StatusTxt = ref("");
const tableData = ref([]);
const steamChoice = ref("Intel");

watch(steamChoice, () => updateData());

const headers = [
  { text: "Version", value: "version", sortable: true },
  { text: "Release Date", value: "releaseDate", sortable: true },
  { text: "Stream", value: "stream", sortable: true },
  { text: "Download", value: "download", sortable: false },
];

async function setup() {
  console.log("mount");
  updateData();

  //console.log(tableData.value[0]);
}

function formatDate(date) {
  var d = new Date(date);
  return d.toLocaleDateString("en-GB");
}

async function updateData() {
  tableData.value = [];

  for (var patch of props.patchInfo[0][steamChoice.value].patches) {
    tableData.value.push(patch);
  }
}

async function downloadPkg(data) {
  console.log(data);
  loading.value = true;
  var downloadURL = await apiQuery("ELA", "patching/patchitems", {
    apiAction: "downloadPkg",
    pkgName: data,
  });
  const link = document.createElement("a");
  link.href = downloadURL.url;
  link.download = data;
  link.click();
  loading.value = false;
}

onMounted(async () => {
  await setup();
});
</script>

<template>
  <v-row>
    <h3 style="padding: 1%">{{ props.patchInfo[0].name }}</h3>
    <v-col class="">
      <v-select
        class="pa-2 ml-3"
        label="Stream"
        density="compact"
        variant="underlined"
        :items="['Intel', 'AppleSilicon']"
        v-model="steamChoice"
        hide-details
      ></v-select>
    </v-col>
    <v-col class="text-right"
      ><v-btn @click="$emit('cancel')">Back</v-btn></v-col
    >
  </v-row>
  <EasyDataTable
    style="text-align: center; list-style-position: inside"
    :headers="headers"
    :items="tableData"
    alternating
    buttons-pagination
    :loading="loading"
    theme-color="#e7541e"
  >
    <template #item-download="name">
      <v-icon
        icon="mdi-download-box"
        size="x-large"
        aria-hidden="false"
        @click="downloadPkg(name.packageName)"
      ></v-icon>
    </template>

    <template #item-releaseDate="name">
      {{ formatDate(name.releaseDate) }}
    </template>
  </EasyDataTable>

  <div class="text-center">
    <v-dialog v-model="dialog.Show" max-width="600">
      <v-card>
        <v-card-title>{{ dialog.Title }}</v-card-title>
        <v-card-text>
          {{ dialog.Text }}
          <v-text-field
            v-if="dialog.TxtField"
            :label="dialog.TxtFieldLabel"
            v-model="dialog.TxtFieldValue"
          ></v-text-field>
          {{ dialog.Text2 }}
          <v-text-field
            v-if="dialog.TxtField2"
            :label="dialog.TxtFieldLabel2"
            v-model="dialog.TxtFieldValue2"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="text-left">
          <v-row class="text-left">
            <v-col class="text-left"
              ><v-btn variant="tonal" @click="resetDialog()"> close </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <v-btn
                variant="tonal"
                @click="dialogButtonPressed()"
                :disabled="dialog.ButtonDisabled"
              >
                <template v-if="dialog.StatusTxt">
                  {{ dialog.StatusTxt }} </template
                ><template v-else>{{ dialog.Button }}</template></v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
