import { Auth, API } from "aws-amplify";
import axios from "axios";

export async function apiQueryAxios(
  name: string,
  endpoint: string,
  payload: any
) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const payloadValue = {
    ...payload,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const reply = await axios.get(
    "https://8wbfq62cch.execute-api.eu-west-1.amazonaws.com/default/TestFunctionCognito",
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
}

export async function apiQuery(
  name: string,
  endpoint: string,
  payload: any,
  method = "POST"
) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const payloadValue = {
    //...payload,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: payload,
  };
  if (method == "POST") {
    return await API.post(name, endpoint, payloadValue);
  }
  if (method == "GET") {
    return await API.get(name, endpoint, payloadValue);
  }
}
