interface cat24Header {
  text: string;
  value: string;
  required: boolean;
  duplicateCheck?: boolean;
  date?: boolean;
  time?: boolean;
  type?: string;
  maxLength?: number;
}

const addressesHeaders: cat24Header[] = [
  { text: "Email Address", value: "email", required: true, type: "text" },
  { text: "Line 1", value: "line1", required: false, type: "text" },
  { text: "Line 2", value: "line2", required: false, type: "text" },
  { text: "City", value: "city", required: false, type: "text" },
  { text: "Postcode", value: "postcode", required: false, type: "text" },
  { text: "Driver Notes", value: "driverNotes", required: false, type: "text" },
  {
    text: "Contact Number",
    value: "contactNumber",
    required: false,
    type: "text",
  },
];

const assetsHeaders: cat24Header[] = [
  {
    text: "Contact Email Address",
    value: "contactEmail",
    required: true,
    type: "text",
  },
  {
    text: "Serial Number",
    value: "serialNumber",
    required: true,
    type: "text",
  },
  { text: "Make", value: "make", required: true, type: "text" },
  { text: "Model", value: "model", required: true, type: "text" },
  {
    text: "Unique Identifier",
    value: "uniqueIdentifier",
    required: true,
    type: "text",
  },
  { text: "IMEIs", value: "imeis", required: true, type: "text" },
];

const contactsHeaders: cat24Header[] = [
  {
    text: "Email Address",
    value: "email",
    required: true,
    duplicateCheck: true,
    type: "text",
  },
  { text: "First Name", value: "firstName", required: true, type: "text" },
  { text: "Last Name", value: "lastName", required: true, type: "text" },
  { text: "Keywords", value: "keyWords", required: true, type: "text" },
  {
    text: "Existing Device",
    value: "existingDevice",
    required: false,
    type: "text",
  },
];

// const deliveryHeaders: cat24Header[] = [
//   { text: "Delivery Date", value: "deliveryDate", required: true },
//   { text: "Max Deliveries", value: "maxDeliveries", required: true },
//   {
//     text: "Reschedule Allowance",
//     value: "rescheduleAllowance",
//     required: true,
//   },
// ];

const deliveryHeaders: cat24Header[] = [
  {
    text: "Slot Date (YYYY-MM-DD)",
    value: "slotDate",
    required: true,
    duplicateCheck: true,
    date: true,
    type: "text",
  },
  {
    text: "Slot Time (HH:MM:SS)",
    value: "slotTime",
    required: true,
    time: true,
    type: "text",
  },
  {
    text: "Max Deliveries",
    value: "maxDeliveries",
    required: true,
    type: "text",
  },
  {
    text: "Reschedule Allowance",
    value: "rescheduleAllowance",
    required: true,
    type: "text",
  },
];

const orderHeaders: cat24Header[] = [
  { text: "First Name", value: "firstName", required: true, type: "text" },
  { text: "Last Name", value: "lastName", required: true, type: "text" },
  { text: "Customer Email", value: "email", required: true, type: "text" },
  {
    text: "Address Line 1",
    value: "Line1",
    required: true,
    type: "text",
    // type: "limit",
    // maxLength: 30,
  },
  {
    text: "Address Line 2",
    value: "Line2",
    required: true,
    type: "text",
    // type: "limit",
    // maxLength: 30,
  },
  { text: "City", value: "city", required: true, type: "text" },
  { text: "Post Code", value: "postcode", required: true, type: "text" },
  {
    text: "Customer Contact Number",
    value: "contactNumber",
    required: true,
    type: "text",
  },
  {
    text: "Delivery Notes",
    value: "driverNotes",
    required: false,
    type: "text",
  },
  {
    text: "Order Notes",
    value: "orderNotes",
    required: false,
    type: "text",
  },
  { text: "Delivery Item", value: "choice", required: true, type: "select" },
  { text: "Delivery Slot", value: "slot", required: true, type: "select" },
];

export {
  cat24Header,
  assetsHeaders,
  contactsHeaders,
  deliveryHeaders,
  orderHeaders,
  addressesHeaders,
};
//export interface cat24Header;
