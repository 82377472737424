import { defineStore } from "pinia";
import { reactive } from "vue";

export const appsListStore = defineStore("appsList", {
  state: () => reactive([] as AppsListObject[]),
});

export interface AppsListObject {
  appName: string;
  icon: string;
  link: string;
  color: string;
  privAppName: string;
}
