<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import { appBarStore } from "@/stores/appBar";
import DashboardPieChart from "../../dashboard/DashboardPieChart.vue";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
const loading = ref(false);
const orders = reactive([]);
const contacts = reactive([]);

const dashboardItems = reactive([]);
const incompleteOrders = reactive([]);
const incompleteOrdersContacts = reactive([]);

const contactsNotPlacedOrder = reactive([]);
const deploymentStatus = ref({});
const contactsNeverLoggedIn = reactive([]);
const contactLoggedInNotPlacedOrder = reactive([]);

async function getData() {
  loading.value = true;
  orders.length = 0;
  contacts.length = 0;
  dashboardItems.length = 0;
  incompleteOrders.length = 0;
  incompleteOrdersContacts.length = 0;
  contactsNotPlacedOrder.length = 0;
  contactsNeverLoggedIn.length = 0;
  const [response, deliverySlots, neverLoggedIn] = await Promise.all([
    apiQuery("ELA", "cat24/orders", {
      apiAction: "getV2",
      customer: appBar.selected,
    }),
    apiQuery("ELA", "cat24/deliveries", {
      apiAction: "get",
      customer: appBar.selected,
    }),
    apiQuery("ELA", "cat24/orders", {
      apiAction: "getContactsNeverLoggedIn",
      customer: appBar.selected,
    }),
  ]);

  if (response.Contacts.length) {
    response.Contacts.forEach((contact) => {
      contacts.push(contact);
    });
  }

  if (response.Orders.length) {
    response.Orders.forEach((order) => {
      orders.push(order);
    });
  }

  if (neverLoggedIn.length) {
    neverLoggedIn.forEach((contact) => {
      contactsNeverLoggedIn.push({
        email: contact.email.toLowerCase(),
        firstName: contact.firstName,
        lastName: contact.lastName,
        keyWords: contact.keyWords,
      });
    });
  }

  getIncompleteOrders();
  getContactsNotPlacedOrder();

  contactLoggedInNotPlacedOrder.length = 0;
  contactsNotPlacedOrder.forEach((contact) => {
    if (!contactsNeverLoggedIn.find((never) => never.email == contact.email)) {
      contactLoggedInNotPlacedOrder.push(contact);
    }
  });

  dashboardItems.length = 0;
  dashboardItems.push({
    title: "Contacts Placed Complete Order",
    txt: orders.length,
    action: undefined,
  });

  dashboardItems.push({
    title: "Contacts Placed Incomplete Order",
    txt: incompleteOrders.length,
    action: undefined,
    download: incompleteOrdersContacts,
    downloadName: "ContactsIncompleteOrder.csv",
  });
  dashboardItems.push({
    title: "Contacts Not Placed Order",
    txt: contacts.length - orders.length,
    action: undefined,
    download: contactsNotPlacedOrder,
    downloadName: "ContactsNotPlacedOrder.csv",
  });

  dashboardItems.push({
    title: "Contact has not logged in",
    txt: contactsNeverLoggedIn.length,
    action: undefined,
    download: contactsNeverLoggedIn,
    downloadName: "ContactsNotLoggedIn.csv",
  });

  dashboardItems.push({
    title: "Logged In Not Placed Order",
    txt: contactLoggedInNotPlacedOrder.length,
    action: undefined,
    download: contactLoggedInNotPlacedOrder,
    downloadName: "LoggedInNotPlacedOrder.csv",
  });

  dashboardItems.push({
    title: "All Contacts",
    txt: contacts.length,
    action: undefined,
    download: contacts,
    downloadName: "AllContacts.csv",
  });

  deploymentStatus.value = addChartColors({
    labels: ["Complete", "Incomplete", "No Order"],
    datasets: [
      {
        data: [
          orders.length - incompleteOrders.length,
          incompleteOrders.length,
          contacts.length - orders.length,
        ],
      },
    ],
  });
  loading.value = false;
}

function getIncompleteOrders() {
  incompleteOrders.length = 0;
  orders.forEach((order) => {
    if (!order._p_deliveryAddress) {
      incompleteOrders.push(order);
      let contactMatch = contacts.find(
        (contact) => contact._id == order._p_contact.replace("Contact$", "")
      );
      if (contactMatch) {
        incompleteOrdersContacts.push({
          email: contactMatch.email.toLowerCase(),
          firstName: contactMatch.firstName,
          lastName: contactMatch.lastName,
          keyWords: contactMatch.keyWords,
        });
      }
    }
  });
}

function getContactsNotPlacedOrder() {
  contacts.forEach((contact) => {
    if (
      !orders.find(
        (order) => contact._id == order._p_contact.replace("Contact$", "")
      )
    ) {
      contactsNotPlacedOrder.push({
        email: contact.email.toLowerCase(),
        firstName: contact.firstName,
        lastName: contact.lastName,
        keyWords: contact.keyWords,
      });
    }
  });
}

const testFunction = async () => {
  console.log("Run Test");
};

appBar.$subscribe(() => {
  if (appBar.selected != "") {
    getData();
  }
});

onMounted(() => {
  if (appBar.selected) {
    loading.value = true;
    getData();
  }
});

const pieChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

function addChartColors(chartData) {
  const colors = [
    "#e7541e",
    "#000000",
    "#888888",
    "#ddedea",
    "#daeaf6",
    "#d4afb9",
    "#d1cfe2",
    "#9cadce",
    "#7ec4cf",
    "#52b2cf",
  ];
  var counter = 0;
  var colourCounter = 0;
  const coloursRequired = chartData.datasets[0].data.length;
  while (counter < coloursRequired) {
    counter++;
    if (!chartData.datasets[0].backgroundColor) {
      chartData.datasets[0].backgroundColor = [colors[colourCounter]];
    } else {
      chartData.datasets[0].backgroundColor.push(colors[colourCounter]);
    }
    if (colourCounter == colors.length) {
      colourCounter = 0;
    }
    colourCounter++;
  }
  return chartData;
}
const cardHeight = 200;
const cardWidth = 350;
</script>

<template>
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
      indeterminate
      size="64"
      color="#e7541e"
    ></v-progress-circular>
  </v-overlay>

  <v-row>
    <v-col v-if="deploymentStatus.datasets" cols="4">
      <v-card max-width="900" class="d-flex flex-column">
        <DashboardPieChart
          :chartData="deploymentStatus"
          :chartOptions="pieChartOptions"
          :height="cardHeight"
          :width="cardWidth"
        />
      </v-card>
    </v-col>
    <v-col v-for="item in dashboardItems" :key="item" cols="4">
      <v-card
        class="d-flex flex-column"
        @click="item.action"
        :height="cardHeight"
      >
        <v-card-title>{{ item.title }}</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="text-h2">
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="text-center mt-2">
              {{ item.txt }}
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="text-right">
          <v-row>
            <v-spacer></v-spacer>
            <v-col>
              <download-csv
                v-if="item.download"
                :data="item.download"
                :name="item.downloadName"
              >
                <v-btn icon="mdi-download" />
              </download-csv>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
